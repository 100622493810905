/*========================================================
                      Contact Form
=========================================================*/
/* MF
========================================================*/
.mailform {
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.mailform input[type="file"] { padding: 11px 16px; }
.mailform input, .mailform textarea {
  padding: 14px 16px;
}
.mailform input:-moz-placeholder, .mailform select:-moz-placeholder, .mailform textarea:-moz-placeholder {
  color: #888;
  opacity: 1;
}
.mailform input::-webkit-input-placeholder, .mailform select::-webkit-input-placeholder, .mailform textarea::-webkit-input-placeholder {
  color: #888;
  opacity: 1;
}
.mailform input::-moz-placeholder, .mailform select::-moz-placeholder, .mailform textarea::-moz-placeholder {
  color: #888;
  opacity: 1;
}
.mailform input:-ms-input-placeholder, .mailform select:-ms-input-placeholder, .mailform textarea:-ms-input-placeholder {
  color: #888;
  opacity: 1;
}

/* MF Input
========================================================*/
.mfInput input:-webkit-autofill ~ .mfIcon span:before,
.mfInput textarea:-webkit-autofill ~ .mfIcon span:before {
  color: #2D2D2D;
}

/* MF Select
========================================================*/
.mailform select {
  padding: 0;
  border: none;
}
.select-option {
  position: relative;
}
.select-departments .dropdown,.select-doctors .dropdown {
  display: none;
}
.select-departments .dropdown.current,.select-doctors .dropdown.current {
  display: block;
}
.mfSelect .show .dropdown, .mfSelect .focus .dropdown { 
  display: block;
  opacity: 1;
}
.mfSelect .dropdown { 
  margin-bottom: 0;
  background: #fff;
  width: 100%;
  border: 1px solid #d1d1d1;
  padding: 0;
  margin-top: -1px;
  position: absolute;
  z-index: 9;
  opacity: 0;
}
.mfSelect .dropdown li {
    border-bottom: 1px solid #e8e8e8;
    padding: 5px 18px;
}
.mfSelect .dropdown li:last-child { 
  border: none;
}
.mfSelect.show .dropdown, .mfSelect.focus .dropdown {
  opacity: 1;
}
.mfSelect .value {
  padding: 14px 18px;
  border: 1px solid #d1d1d1;
  color: #888;
}
.mfSelect .value:after {
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #d1d1d1;
  content: "";
  margin-top: 10px;
  float: right;
}

/* MF Controls
========================================================*/
.mfControls {
  word-spacing: 10px;
  text-align: left;
}
.mfControls > * {
  word-spacing: normal;
  margin-bottom: 5px;
  min-width: 200px;
  width: auto;
  font-family: inherit;
  font-size: 17px;
  letter-spacing: -0.5px;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media (max-width: 767px) {
  .mfControls > * {
    width: 100%;
  }
}

/* Mail Form PlaceHolder
========================================================*/
.mfPlaceHolder {
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  font: inherit;
  cursor: text;
  position: absolute;
  left: 10px;
  top: 0;
  padding: 14px 18px;
  line-height: 26px;
  color: #888;
  opacity: 1;
}
*:-webkit-autofill ~ .mfPlaceHolder {
  opacity: 1;
  -moz-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  -o-transform: translateY(-30%);
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  font-size: 14px;
}
.mfPlaceHolder.state-1 {
  opacity: 1;
  -moz-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  -o-transform: translateY(-30%);
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  font-size: 14px;
}

/* Mail Form Validation
  ========================================================*/
.mfValidation {
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-animation: notifanim-fo 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  -moz-animation: notifanim-fo 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  -ms-animation: notifanim-fo 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  -o-animation: notifanim-fo 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  animation: notifanim-fo 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  background: #888;
  position: absolute;
  width: 210px;
  margin-left: -105px;
  padding: 10px;
  height: 40px;
  line-height: 20px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  bottom: -30px;
  right: 0px;
  font-weight: 300;
  font-size: 12px;
  color: #FFF;
  z-index: 999;
}
.mfValidation:hover {
  background: #6f6f6f;
}
.mfValidation:before {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 10%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent #888 transparent;
}
.mfValidation:hover:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent #6f6f6f transparent;
}
.mfValidation.error {
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  -webkit-animation: notifanim 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  -moz-animation: notifanim 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  -ms-animation: notifanim 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  -o-animation: notifanim 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  animation: notifanim 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}
@media (max-width: 767px) {
  .mfValidation {
    text-align: right;
    top: auto;
    left: auto;
    top: -18px;
    right: 15px;
    background: none;
    padding: 0;
    margin: 0 0 3px;
    min-height: 0;
    color: #DA7B77;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .mfValidation:before {
    display: none;
  }
  .mfValidation:hover {
    background: none;
    right: 5px;
  }

  .mailform.fail, .mailform.success {
    padding-bottom: 60px;
  }

}

@-ms-keyframes notifanim-fo {
  0% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.1);
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    transform: scale(0.3);
    opacity: 0;
  }
}
@-o-keyframes notifanim-fo {
  0% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.1);
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    transform: scale(0.3);
    opacity: 0;
  }
}
@-webkit-keyframes notifanim-fo {
  0% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.1);
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    transform: scale(0.3);
    opacity: 0;
  }
}
@-moz-keyframes notifanim-fo {
  0% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.1);
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    transform: scale(0.3);
    opacity: 0;
  }
}
@keyframes notifanim-fo {
  0% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.1);
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    transform: scale(0.3);
    opacity: 0;
  }
}
@-ms-keyframes notifanim {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.3);
  }
  1% {
    visibility: visible;
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-o-keyframes notifanim {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.3);
  }
  1% {
    visibility: visible;
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes notifanim {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.3);
  }
  1% {
    visibility: visible;
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-moz-keyframes notifanim {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.3);
  }
  1% {
    visibility: visible;
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes notifanim {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.3);
  }
  1% {
    visibility: visible;
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/* Mail Form Date Picker
========================================================*/
/* Mail Form Icon
========================================================*/
/* Mail Form Submit
========================================================*/
.mfProgress {
  position: relative;
}
.mfProgress .cnt, .mfProgress .loader {
  -moz-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}
.mfProgress .loader {
  opacity: 0;
  text-align: center;
  letter-spacing: 10px;
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.mfProgress .loader, .mfProgress .loader:before, .mfProgress .loader:after {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto;
  height: auto;
}
.mfProgress .loader:before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
}
.mfProgress .loader:after {
  content: '';
  width: 30px;
  height: 50px;
  color: #fff;
  font: 400 30px "FontAwesome";
  line-height: 50px;
  -moz-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
  -moz-transform: scale(0) rotate(-60deg);
  -ms-transform: scale(0) rotate(-60deg);
  -o-transform: scale(0) rotate(-60deg);
  -webkit-transform: scale(0) rotate(-60deg);
  transform: scale(0) rotate(-60deg);
}
.mfProgress.sending .cnt, .mfProgress.fail .cnt, .mfProgress.success .cnt {
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
}
.mfProgress.sending .loader, .mfProgress.fail .loader, .mfProgress.success .loader {
  opacity: 1;
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mfProgress.fail .loader:before, .mfProgress.success .loader:before {
  -webkit-animation: fout 0.4s ease-in-out 1 forwards;
  -moz-animation: fout 0.4s ease-in-out 1 forwards;
  -ms-animation: fout 0.4s ease-in-out 1 forwards;
  -o-animation: fout 0.4s ease-in-out 1 forwards;
  animation: fout 0.4s ease-in-out 1 forwards;
  opacity: 0;
}
.mfProgress.fail .loader:after, .mfProgress.success .loader:after {
  -moz-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  -o-transform: scale(1) rotate(0deg);
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.mfProgress.sending .loader:before {
  -webkit-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  -moz-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  -ms-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  -o-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.mfProgress.fail .loader:after {
  content: '\f06a';
}
.mfProgress.success .loader:after {
  content: '\f058';
}

.mfProgress .msg {
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-animation: notifanim-fo 0.4s ease-in-out forwards;
  -moz-animation: notifanim-fo 0.4s ease-in-out forwards;
  -ms-animation: notifanim-fo 0.4s ease-in-out forwards;
  -o-animation: notifanim-fo 0.4s ease-in-out forwards;
  animation: notifanim-fo 0.4s ease-in-out forwards;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 15px;
  font-size: 16px;
  line-height: 20px;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 100%;
  width: 400px;
  margin-left: 20px;
  margin-top: 0;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  background: #DA7B77;
  color: #fff;
  z-index: 9;
}
.mfProgress .msg:before {
  content: '';
  position: absolute;
  margin-top: -6px;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #111 transparent transparent;
  -moz-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}
@media (max-width: 767px) {
  .mfProgress .msg {
    width: 230px;
    top: 100%;
    left: 50%;
    margin-top: 20px;
    margin-left: -115px;
  }
  .mfProgress .msg:before {
    right: 50%;
    top: auto;
    bottom: 100%;
    margin-right: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #111 transparent;
  }
}
.mfProgress.fail .msg, .mfProgress.success .msg {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  -webkit-animation: notifanim 0.4s ease-in-out forwards;
  -moz-animation: notifanim 0.4s ease-in-out forwards;
  -ms-animation: notifanim 0.4s ease-in-out forwards;
  -o-animation: notifanim 0.4s ease-in-out forwards;
  animation: notifanim 0.4s ease-in-out forwards;
}
.mfProgress.fail .msg {
  background: #F44336;
}
.mfProgress.fail .msg:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #F44336 transparent transparent;
}
@media (max-width: 767px) {
  .mfProgress.fail .msg:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #F44336 transparent;
  }
}
.mfProgress.success .msg {
  background: #2E7D32;
}
.mfProgress.success .msg:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #2E7D32 transparent transparent;
}

@media (max-width: 767px) {
    .mfProgress.success .msg:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #2E7D32 transparent;    
  }
}
@-ms-keyframes fout {
  0% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0) translateX(0);
  }
}
@-o-keyframes fout {
  0% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0) translateX(0);
  }
}
@-webkit-keyframes fout {
  0% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0) translateX(0);
  }
}
@-moz-keyframes fout {
  0% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0) translateX(0);
  }
}
@keyframes fout {
  0% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0) translateX(0);
  }
}
@-ms-keyframes motion {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: translateX(-50px) scale(0.3);
  }
  50% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(50px) scale(0.3);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
@-o-keyframes motion {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: translateX(-50px) scale(0.3);
  }
  50% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(50px) scale(0.3);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
@-webkit-keyframes motion {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: translateX(-50px) scale(0.3);
  }
  50% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(50px) scale(0.3);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
@-moz-keyframes motion {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: translateX(-50px) scale(0.3);
  }
  50% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(50px) scale(0.3);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
@keyframes motion {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: translateX(-50px) scale(0.3);
  }
  50% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(50px) scale(0.3);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
/* Mail Form Select
========================================================*/
@-ms-keyframes dropd-anim-fo {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
@-o-keyframes dropd-anim-fo {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
@-webkit-keyframes dropd-anim-fo {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
@-moz-keyframes dropd-anim-fo {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
@keyframes dropd-anim-fo {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
@-ms-keyframes dropd-anim {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes dropd-anim {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes dropd-anim {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-moz-keyframes dropd-anim {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes dropd-anim {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
