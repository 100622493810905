.max-wid_50 {
  max-width: 50%;
}

.footer-social-icons ul li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.project-grid-overlay {
  overflow: hidden;
}

.project-grid-overlay h4 {
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  color: #777;
}

.page-title-section2 {
  padding: 210px 0 70px;
}

.navbar-brand img {
  max-height: 60px;
}

.clients img {
  opacity: 1;
}

.history-mid-line:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  height: 92%;
  width: 2px;
  border: 2px dashed #aaa;
  content: "";
}

.history-img {
  display: block;
  position: relative;
}

.history-img img {
  z-index: 1;
  position: relative;
  box-shadow: 2px 4px 7px #aaa;
}

.history-text {
  margin-bottom: 0;
}

.history-years {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
  color: #444;
}

.history-years span {
  font-size: 46px;
  line-height: 52px;
  opacity: 0.3;
  display: block;
}

.history-years span strong {
  font-size: 58px;
}

.project-grid-style2 .portfolio-icon a {
  margin-top: -13px;
}

.dark-link {
  color: #232323;
}

.mailform select {
  margin: 0 0 15px 0;
}

.mfSelect .dropdown li {
  cursor: pointer;
}

.links-foot:before {
  display: none;
}

.cebit-small-img {
  max-height: 100px;
  width: auto !important;
  /*margin-top: -40px !important;*/
  margin-bottom: 10px !important;
  display: block;
}

.bg-theme-home {
  background-color: #000;
}

.rev_slider .caption,
.rev_slider .tp-caption {
  line-height: normal !important;
}

.home-text-img:after {
  top: 40px;
  left: -30px;
  border-radius: 5px;
}

.portfolio-page .project-grid-overlay {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 3px solid #86bc42;
}

.page-title-section2 .page-title {
  border: none;
  background: none;
  color: #86bc42;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .page-title-section2 {
    padding: 150px 0 30px;
  }
  .history-years {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .history-years span {
    font-size: 30px;
  }
  .history-years span strong {
    font-size: 38px;
  }
  .history-mid-line:before {
    border: 0;
  }
}
