/*-----------------------------------------------------------------------------------

    Theme Name: Fabrex - Business Multipurpose and Corporate Template
    Description: Business Multipurpose and Corporate Template
    Author: chitrakootweb
    Version: 2.0

-----------------------------------------------------------------------------------*/

/* ----------------------------------
        
    01. Common styles
    02. Section heading
    03. Topbar
    04. Navigation
    05. Page title
    06. Slider and Banner
    07. Feature boxes
    08. Service blocks
    09. Process with Icon
    10. Client section
    11. Counter box
    12. Call to action
    13. Video
    14. About area style
    15. Progress bars
    16. Testmonial
    17. Owl carousel
    18. Team member
    19. Project grid
    20. Accordion style
    21. Service detail
    22. Portfolio masonry
    23. Tabs
    24. Pricing table
    25. Shop
    26. Blog block
    27. Count and error box
    28. Contact info
    29. Footer
    
---------------------------------- */

/* ===================================
    Common styles
====================================== */

/* loader */
#preloader{ position:fixed; left:0; top:0; z-index:99999; width:100%; height:100%; overflow:visible; background:#fff; display:table; text-align:center; }
.loader{ display:table-cell; vertical-align:middle; position:relative; width:200px; height:200px; }
.loader-icon{ width:80px; height:80px; border:5px solid #86bc42; border-right-color:#eee; border-radius:50%; position:relative; animation:loader-rotate 1s linear infinite; margin:0 auto; }
@keyframes loader-rotate{ 0%{ transform:rotate(0) }
 100%{ transform:rotate(360deg) }
 }

/* theme color and basic options */
a:hover, a:active{color: #86bc42; text-decoration: none;}
.bg-theme {background-color:#86bc42;}
.bg-theme-90 { background:rgba(134, 188, 66,0.90);  }
.bg-theme-80 { background:rgba(134, 188, 66,0.80);  }
.theme-overlay[data-overlay-dark]:before { background: #86bc42; }
.text-theme-color {color:#86bc42;}
.text-content-big p{ font-weight:400; line-height:200%; margin-bottom:25px; font-size:15px; }
.shadow-primary{ -webkit-box-shadow:0 20px 40px rgba(0, 0, 0, .2); box-shadow:0 20px 40px rgba(0, 0, 0, .2); }
.red { color: red; }
.line-through { text-decoration: line-through; }

/* spacing between columns*/
.half-gutter { margin: 0 -8px; }
.half-gutter > [class*="col-"] { padding: 7px; }

/* primary listing */
.primary-list li{ font-size:14px; font-weight:500; margin-top:15px; list-style-type:none; color:#222; }
.primary-list{ padding-left:0; }
.primary-list li i{ color:#86bc42; padding-right:10px; }
.white-list{ padding-left:0; }
.white-list li{ color:#fff; font-size:14px; font-weight:500; margin-top:15px; list-style-type:none; }
.white-list li i{ color:#86bc42; padding-right:15px; }

/* scroll to top */
.scroll-to-top{ font-size:20px; text-align:center; color:#fff; text-decoration:none; position:fixed; bottom:20px; right:20px; display:none; border-radius:50%; background:#000; border:1px solid #2a2a2a; width:35px; height:35px; line-height:30px; z-index:9999; outline:0; -webkit-transition:all .3s ease; -moz-transition:all .3s ease; -o-transition:all .3s ease }
.scroll-to-top i{ color:#fff; }
.scroll-to-top:hover{ color:#232323; background:#fff; }
.scroll-to-top:hover i{ color:#232323; }
.scroll-to-top:visited{ color:#232323; text-decoration:none; }

/* Button style */ 
.butn{ background:#232323; color:#fff; text-align:center; border-radius:4px; padding:12px 30px; line-height:normal; font-weight:500; text-transform:none !important; position:relative; z-index:9999; display:inline-block; white-space:nowrap; border:none; cursor:pointer; }
.butn span{ z-index:9; position:relative; }
.butn:hover{ color:#fff; border-radius:3px;  }
.butn:after{ content:''; position:absolute; border-radius:4px; bottom:6px; left:0; height:2px; -webkit-transition:width .4s; -o-transition:width .4s; transition:width .4s; width:0; background:#86bc42; height:100%; top:0; z-index:1; }
.butn:hover:after{ width:100%; border-radius:3px;  }
.butn.white{ background:#fff; color:#232323; }
.butn.white:hover{ color:#fff; border-radius:3px; }
.butn.white:after{ background:#232323; border-radius:3px; }
.butn.theme{ background:#86bc42; color:#fff; }
.butn.theme span { color: #fff; }
.butn.theme:hover{ color:#fff; border-radius:3px;  }
.butn.theme:after{ background:#232323; border-radius:3px; }
.butn.theme.white-hover:hover span { color: #232323; }
.butn.theme.white-hover:hover{ color:#232323; }
.butn.theme.white-hover:after{ background:#fff; border-radius:3px; }

.butn.small{ padding:8px 20px; }
.butn.medium{ padding:10px 20px; }

/* Button Style 2*/
.btn-style2 {  display: inline-block; font-size: 14px;  padding: 12px 20px; border: 2px solid #222; color: #232323; letter-spacing: .5px; line-height: 26px; border-radius: 0; text-transform: uppercase; width: auto; font-family: 'Montserrat', sans-serif; font-weight: 600; transition-duration: 0.3s; transition-timing-function: ease-in-out; }
.btn-style2:hover, .btn-style2:active{ background: #86bc42; color: #fff; border-color: #86bc42; }
.btn-style2:focus{ background: #86bc42; color: #fff; box-shadow: none; border-color: #86bc42; }

.btn-style2.white { border-color: #fff; color: #fff; }
.btn-style2.white:hover { border-color: #86bc42;}
.btn-style2 span { text-shadow: none !important; color: #fff !important; }

/* Button Style 3*/
.butn-style3 { text-align: center; display: inline-block; font-size: 14px; padding: 9px 22px; min-width: 150px; background: transparent; border: 2px solid #86bc42; border-radius: 25px; box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important; color: #86bc42; letter-spacing: .5px;  line-height: inherit; width: auto; font-family: 'Montserrat', sans-serif;    font-weight: 600; transition-duration: 0.3s; transition-timing-function: ease-in-out; }
.butn-style3:hover { background: #86bc42; color: #fff; }

.butn-style3.theme { background: #86bc42; border: 2px solid #86bc42; color: #fff; }
.butn-style3.theme:hover { background: transparent; color: #fff; border-color: #fff; }

.butn-style3.white { background: transparent; border: 2px solid #fff; color: #fff; }
.butn-style3:hover { background: #86bc42; color: #fff; border-color: #86bc42; }

@media (max-width: 991px) {
    .butn { padding:10px 28px; }
    .butn.small{ padding:7px 16px; }
    .butn.medium{ padding:8px 18px; }

    .btn-style2 { padding: 10px 18px; }
    .butn-style3 { min-width: 130px; }
}
@media (max-width: 767px) { 

    .butn { padding:8px 26px; }
    .butn.small{ padding:6px 14px; }
    .butn.medium{ padding:6px 16px; }

    .btn-style2 { padding: 8px 14px; }
    .butn-style3 { min-width: inherit; }

    /* spacing between columns*/
    .xs-default-gutter { margin: 0 -15px; }
    .xs-default-gutter > [class*="col-"] { padding-right: 15px; padding-left: 15px; padding-bottom: 0; padding-top: 0; }
}

/* ===================================
    Section heading
====================================== */

.section-heading{ margin-bottom:50px; text-align:center; }
.section-heading.half{ margin-bottom:30px; }
.section-heading h1,.section-heading h2,.section-heading h3,.section-heading h4,.section-heading h5,.section-heading h6{ position:relative; padding-bottom:10px; font-weight:600; margin-bottom:10px; position:relative; line-height:140%; }
.section-heading h1:after,.section-heading h2:after, .section-heading h3:after, .section-heading h4:after, .section-heading h5:after, .section-heading h6:after{ content:''; display:block; width:100px; height:2px; background:#86bc42; margin:0 auto; position:absolute; left:0; right:0; bottom:0; }
.section-heading.white h1,.section-heading.white h2, .section-heading.white h3, .section-heading.white h4, .section-heading.white h5, .section-heading.white h6{ color:#fff; }
.section-heading.grey h1,.section-heading.grey h2, .section-heading.grey h3, .section-heading.grey h4, .section-heading.grey h5, .section-heading.grey h6{ color:#b7b7b7; }
.section-heading.grey h1:after,.section-heading.grey h2:after, .section-heading.grey h3:after, .section-heading.grey h4:after, .section-heading.grey h5:after, .section-heading.grey h6:after{ background:#b7b7b7; }
.section-heading.left{ text-align:left; }
.section-heading.left h1,.section-heading.left h2, .section-heading.left h3, .section-heading.left h4, .section-heading.left h5, .section-heading.left h6{ padding-bottom:15px; }
.section-heading.left h1:after,.section-heading.left h2:after, .section-heading.left h3:after,.section-heading.left h4:after,.section-heading.left h5:after,.section-heading.left h6:after{ margin:0; left:0; }
.section-heading strong{ font-weight:600; }
.section-heading.white p{ color:#fff; }
.section-heading p{ font-size:15px; font-weight:400; padding-top:15px; margin:0 auto; }
.section-heading span{ position:relative; font-size:15px; font-weight:500; color:#86bc42; line-height:200%; margin-bottom:10px; }
.section-heading.white span{ color:#fff; }
.section-heading.grey span{ color:#b7b7b7; }

.section-heading .badge { display: inline-block; padding: 7px 19px; font-size: 12px; font-weight: 700; line-height: 1; text-align: center; white-space: nowrap;
    vertical-align: baseline; border-radius: 14px; color: #86bc42; border:1px solid #86bc42; background: transparent; text-transform: uppercase; letter-spacing: .075em; }

/* title style2 */
.title-style2{ position: relative; padding-bottom: 20px; margin-bottom: 0; }
.title-style2:after{ content: ''; width: 60px; height: 2px; background: #86bc42; position: absolute; bottom: 0; left: calc(50% - 30px); }
.title-style2:before{ content: ''; height: 1px; background: rgba(0, 0, 0, 0.2); position: absolute; bottom: 0; left: 0; right: 0; margin: 0 auto; width: 250px; }

.section-heading.left .title-style2 { left: 0; margin: 0; }
.section-heading.left .title-style2:before { width: 125px; left: 0; margin: 0; }
.section-heading.left .title-style2:after { width: 50px; left: 37px; }

h1.title-style2.white, h2.title-style2.white, h3.title-style2.white, h4.title-style2.white, h5.title-style2.white, h6.title-style2.white { color: #fff; }
.section-heading .title-style2.white:after{ background: #86bc42; }
.section-heading .title-style2.white:before{ background: rgba(255, 255, 255, 0.8); }

/* title style3 */
.section-heading.title-style3 { text-align: left; margin-bottom: 30px; padding-bottom: 10px; border-bottom: 1px solid #eee; }
.section-heading.title-style3 h1, .section-heading.title-style3 h2, .section-heading.title-style3 h3, .section-heading.title-style3 h4, .section-heading.title-style3 h5, .section-heading.title-style3 h6 { padding: 0; margin: 0; }
.section-heading.title-style3 h1:after,.section-heading.title-style3 h2:after, .section-heading.title-style3 h3:after, .section-heading.title-style3 h4:after, .section-heading.title-style3 h5:after, .section-heading.title-style3 h6:after { content: none;  }

/* title overlay */
.title-overlay{ mix-blend-mode: lighten; overflow: hidden; background-color: rgba(255, 255, 255, 0.85); margin: 0; }

/* title style4 */
.section-heading.title-style4 h1, .section-heading.title-style4 h2, .section-heading.title-style4 h3, .section-heading.title-style4 h4, .section-heading.title-style4 h5, .section-heading.title-style4 h6 { text-transform: uppercase; font-weight: 700; padding: 0; margin: 0;}
.section-heading.title-style4 p { padding-top: 0; }
.section-heading.title-style4 span { color: #86bc42; font-size: 32px; font-weight: 700; text-transform: uppercase; }
.section-heading.title-style4 h1:after, .section-heading.title-style4 h2:after, .section-heading.title-style4 h3:after, .section-heading.title-style4 h4:after, .section-heading.title-style4 h5:after, .section-heading.title-style4 h6:after { content: none; }

/* title style5 */
.section-heading.title-style5 h1, .section-heading.title-style5 h2, .section-heading.title-style5 h3, .section-heading.title-style5 h4, .section-heading.title-style5 h5, .section-heading.title-style5 h6 { padding: 0; margin: 0;  }
.section-heading.title-style5.white h1, .section-heading.title-style5.white h2, .section-heading.title-style5.white h3, .section-heading.title-style5.white h4, .section-heading.title-style5.white h5, .section-heading.title-style5.white h6 { color: #fff;  }
.section-heading.title-style5 h1:after, .section-heading.title-style5 h2:after, .section-heading.title-style5 h3:after, .section-heading.title-style5 h4:after, .section-heading.title-style5 h5:after, .section-heading.title-style5 h6:after { content: none; }
.section-heading.title-style5 .separator-left,
.section-heading.title-style5 .separator-right { height: 1px; position:absolute; top:50%; width:70px; margin-top:-1px; margin-bottom: 0;}
.section-heading.title-style5 .separator-left{ left:-80px; }
.section-heading.title-style5 .separator-right{ right:-80px; }
.section-heading.title-style5 .square { width: 10px; height: 10px; background-color: transparent; border-width: 1px; border-style: solid; border-color: #86bc42; display: inline-block; vertical-align: text-bottom; position: relative; }
.section-heading.title-style5.left .square .separator-left{ display: none; }

@media screen and (max-width:1199px){

	.section-heading.title-style4 span { font-size: 30px; }
}

@media screen and (max-width:991px){

    .section-heading { margin-bottom: 30px; }
    .section-heading p { padding-top: 10px; font-size: 14px; }
    .section-heading.title-style4 span { font-size: 28px; }
}

@media screen and (max-width:767px){
    .section-heading.title-style4 span { font-size: 28px; }
}

/* ===================================
    Topbar
====================================== */

/* topbar */
.top-bar-info{ display:inline-block; vertical-align: middle; }
.top-bar-info ul{ margin-bottom:0; }
.top-bar-info li{ font-weight:500; color:#fff; list-style-type:none; font-size:13px; padding:0 5px 0; display:inline-block; margin-bottom:0; }
#top-bar{ display:block; position:relative; z-index:999; background:#1c1c1c; padding: 7px 0; }
.top-bar-info li i{ font-size:16px; color:#86bc42; margin-right:8px; margin-top: 0; display: inline-block; vertical-align: text-bottom; }

.top-social-icon{ padding:0; float:right; margin:0; }
.top-social-icon li{ font-size:14px; list-style-type:none; float:left; text-align:center; margin:0; padding:0 7px; }
.top-social-icon li:last-child { padding-right: 0; }
.top-social-icon li:last-child a { padding-right: 0; }
.top-social-icon li a{  color:#fff; line-height: 28px; -webkit-transition-duration:.3s; transition-duration:.3s; padding:0 3px;  }
.top-social-icon li a:hover { color: #86bc42; }

#top-bar.top-bar-style2 {  background:#f5f5f5; border-bottom: 1px solid rgba(0,0,0,0.1); }
.top-bar-style2 .top-bar-info li{ color:#6f6f6f; }
.top-bar-style2 .top-bar-info li i{ color:#6f6f6f; }

.top-bar-style2 .top-social-icon li a{  color:#6f6f6f; }
.top-bar-style2 .top-social-icon li a:hover { color: #232323; }

/* ===================================
    Navigation
====================================== */

.navbar-nav li.current > a{ color: #86bc42; }
.navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

@media screen and (min-width: 992px){
    
    /*Header style01*/
    .header-style1 .navbar-nav li.current > a{ color: #86bc42; }
    .header-style1 .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }    

    .header-style1.scrollHeader .navbar-nav li.current > a{ color: #86bc42; }
    .header-style1.scrollHeader .navbar-nav li.current > a:hover{ color: #86bc42; }
    .header-style1.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    /*Header style02*/
    .header-style2 .navbar-nav li.current > a{ color: #86bc42; }
    .header-style2 .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    .header-style2.scrollHeader .navbar-nav li.current > a{ color: #86bc42; }
    .header-style2.scrollHeader .navbar-nav li.current > a:hover{ color: #86bc42; }
    .header-style2.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }    

    /*Header style03*/
    .header-style3 { border-bottom: 2px solid rgba(134, 188, 66,0.9); }

    /*Header style04*/
    .header-style4 .navbar-nav li.current > a{ color: #86bc42; }
    .header-style4 .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    /*Header style05*/
    .header-style5 #top-bar { background: #86bc42; }

    /*Header style06*/
    .header-style6 .navbar-default{ background: rgba(134, 188, 66,0.85);}
    .header-style6.scrollHeader .navbar-default { background: #86bc42;  }

    /*Menu area light*/
    .menu_area-light .navbar-nav li.current > a{ color: #86bc42; }
    .menu_area-light .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    .menu_area-light.scrollHeader .navbar-nav li.current > a{ color: #86bc42; }
    .menu_area-light.scrollHeader .navbar-nav li.current > a:hover{ color: #86bc42; }
    .menu_area-light.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }     
}

@media screen and (max-width: 991px){

    /*Header style03*/
    .header-style3 { border-bottom: 2px solid rgba(134, 188, 66, 0.9); }

    /*Header style05*/
    .header-style5 #top-bar { background: #86bc42; }
    .header-style5 .navbar-toggler { background: #86bc42; }    

    /*Header style06*/
    .header-style6 .navbar-default{ background: rgba(134, 188, 66, 0.85); }
}

/* ===================================
    Page title
====================================== */

/*style1*/
.page-title-section{ background-size:cover; background-position:center; }
.page-title-section h1{ font-size:40px; font-weight:600; letter-spacing:1px; line-height:1; color:#fff; margin-bottom:0; }
.page-title-section ul{ margin-bottom:0; margin-top:15px; text-align:left; }
.page-title-section ul li{ display:inline-block; }
.page-title-section ul li:last-child a{ color: #86bc42; }
.page-title-section ul li:after{ content:'\f105'; font-weight: 700; vertical-align: bottom; color:#fff; font-family: Font Awesome\ 5 Free; padding:0 10px; }
.page-title-section ul li:last-child:after{ content:none; }
.page-title-section ul li a{ color:#fff; font-size:14px; font-weight:500; }
.page-title-section .active a, .page-title-section li.active:last-child a { color: #fff;}

@media screen and (max-width:1199px){
    .page-title-section h1{ font-size: 36px; line-height: 40px;  }
}
@media screen and (max-width:991px){
    .page-title-section h1{ font-size: 30px; line-height: 38px;  }
}

@media screen and (max-width:767px){
    .page-title-section h1{ margin-bottom:10px; font-size: 28px; line-height: 36px;  }
    .page-title-section ul{ margin-top: 5px; }
}

/*style2*/
.page-title-section2 { padding: 250px 0 150px; text-align: center; }
.page-title-section2 h1{ font-size:40px; font-weight:600; letter-spacing:1px; line-height:1; color:#fff; margin-bottom:0; }
.page-title-section2 ul{ margin-bottom:0; margin-top:15px; text-align:center; }
.page-title-section2 ul li{ display:inline-block; }
.page-title-section2 ul li:last-child a{ color: #86bc42; }
.page-title-section2 ul li:after{ content:'\f105'; font-weight: 700; vertical-align: middle; color:#fff; font-family: Font Awesome\ 5 Free; padding:0 8px 0 10px; }
.page-title-section2 ul li:last-child:after{ content:none; }
.page-title-section2 ul li a{ color:#fff; font-size:14px; font-weight:500; }
.page-title-section2 .active a, .page-title-section2 li.active:last-child a { color: #fff;}

@media screen and (max-width:1199px){
    .page-title-section2 { padding: 200px 0 100px; }
    .page-title-section2 h1{ font-size: 36px; line-height: 42px;  }
}
@media screen and (max-width:991px){
    .page-title-section2 { padding: 148px 0 75px; }
    .page-title-section2 h1{ margin-bottom:10px; font-size: 30px; line-height: 40px;  }
    .page-title-section2 ul{ margin-top: 5px; }
}

/*style3*/
.page-title-section3 h1{ color: #232323; margin-bottom: 0; }
.page-title-section3 ul{ margin-bottom:0; margin-top:15px; text-align:center; }
.page-title-section3 ul li{ display:inline-block; }
.page-title-section3 ul li:last-child a{ color: #232323; }
.page-title-section3 ul li:after{ content:' \f152'; color:#fff; font-weight: 700; font-family: Font Awesome\ 5 Free; padding:0 10px; }
.page-title-section3 ul li:last-child:after{ content:none; }
.page-title-section3 ul li a{ color:#fff; font-size:14px; font-weight:500; }

@media screen and (max-width:991px){
    .page-title-section3 ul{ margin-top: 5px; }
}

/*style4*/
.page-title-section.small h1{ font-size:28px; font-weight:600; letter-spacing:1px; line-height:normal; color:#fff; margin-bottom:0; }
.page-title-section.small ul{ margin-bottom:0; margin-top:6px; text-align:left; }
.page-title-section.small ul li{ display:inline-block; }
.page-title-section.small ul li:last-child a{ color: #86bc42; }
.page-title-section.small ul li:after{ content:' \f152'; color:#fff; font-weight: 700; font-family: Font Awesome\ 5 Free; padding:0 10px; }
.page-title-section.small ul li:last-child:after{ content:none; }
.page-title-section.small ul li a{ color:#fff; font-size:14px; font-weight:500; }

@media screen and (max-width:767px){
    .page-title-section.small h1{ margin-bottom:5px; font-size: 24px; }
    .page-title-section.small ul{ margin-top: 5px; }
}


/* ===================================
    Slider and Banner
====================================== */

/* Banner */ 
.owl-carousel .caption .overflow-hidden{ display: inline-block; }
.owl-carousel .caption h3{ font-weight: 200; animation-delay: 0.5s; position: relative; display: inline-block; }
.owl-carousel .caption h1{ margin: 10px 0; font-size: 70px; font-weight: 500; text-transform: uppercase; animation-delay: 0.8s; }
.owl-carousel .caption p{ font-size: 18px; color: #eee; word-spacing: 2px; animation-delay: 1.2s; }
.owl-carousel .caption span{ display: inline-block; padding: .2em 0; }
.owl-carousel .caption .butn{ animation-delay: 1.4s; }
.owl-carousel .caption .butn i{ position: relative; top: 1px; z-index: 9; }

/* carousel-style3 */ 
.carousel-style3 .owl-carousel .owl-item img{ width: 100%; }

/* carousel-style4 */ 
.carousel-style4 .owl-carousel .owl-item img{ width: 100%; }

/*home-business-slider*/
.home-business-slider .owl-nav .owl-next { position: absolute; right: 15px; top: 50%; background: none !important; opacity: 0.35; }
.home-business-slider .owl-nav .owl-next:hover { opacity: 1; }
.home-business-slider .owl-nav .owl-prev { position: absolute; left: 15px; top: 50%; background: none !important; opacity: 0.35; }
.home-business-slider .owl-nav .owl-prev:hover { opacity: 1; }

@media only screen and (max-width: 991px){
    .owl-carousel .caption h1{ font-size: 50px; }
    .owl-carousel .caption p{ font-size: 16px; }
}

@media only screen and (max-width: 767px){
    .owl-carousel .caption h1{ font-size: 32px; margin-top: 5px; }
    .owl-carousel .caption h3{ letter-spacing: 1px; }

}
@media only screen and (max-width: 575px){
    .owl-carousel .caption h1{ font-size: 20px; margin-top: 5px; }
}

.slider-fade .owl-item{ height: 100vh; position: relative; }
.slider-fade .item{ position: absolute; top: 0; left: 0; height: 100%; width: 100%; background-size: cover; background-position: center center; }
.slider-fade .item .caption{ width: 100%; z-index: 9; }
.slider-fade .owl-theme .owl-dots{ position: absolute; bottom: 5vh; width: 100%; left: 0; }

/* revolution slider */
.rev_slider_wrapper .tp-caption span{ color:#86bc42; }
.rev_slider_wrapper .butn span{ font-size:14px !important; line-height:24px !important; color:#fff; text-shadow: none; }
.rev_slider_wrapper .butn.white span { color: #232323; }
.rev_slider_wrapper .butn.white:hover span { color: #fff; }

@media (min-width:767px){ 
    .custom-controls .slider-text p{ width:450px !important; font-size:16px !important; line-height:24px !important; }
}

@media (min-width:1024px){
    .rev_slider .max-style{ font-size: 60px !important; line-height: 70px !important; }
    .rev_slider .max-style span{ font-size: 60px !important; line-height: 70px !important; }
}

@media (max-width:1023px){
    .rev_slider .max-style{ font-size: 45px !important; line-height: 50px !important; }
    .rev_slider .max-style span{ font-size: 45px !important; line-height: 50px !important; }
}

@media (max-width:766px){
    .rev_slider .max-style{ font-size: 30px !important; line-height: normal !important; }
    .rev_slider .max-style span{ font-size: 30px !important; line-height: normal !important; }
    .custom-controls .slider-text p{ text-align:center !important; font-size:14px !important; line-height:22px !important; width:480px !important; padding:0 15px; }
    .custom-controls .tp-leftarrow, .custom-controls .tp-rightarrow{ width:40px !important; height:40px !important; }
    .custom-controls .tp-leftarrow{ left:-15px !important; }
    .custom-controls .tp-rightarrow{ left:inherit !important; right:-55px !important; }
    .custom-controls .tparrows:before{ line-height:20px !important; }
 }

@media (max-width:479px){
    .rev_slider .max-style{ font-size: 20px !important; line-height: normal !important; }
    .rev_slider .max-style span{ font-size: 20px !important; line-height: normal !important; }
    .custom-controls .slider-text p{ width:320px !important; padding:0 15px; }
}

/* ===================================
    Feature boxes
====================================== */

/* feature box 01 */
.feature-box-01{ background:#fff; border:5px solid rgba(0, 0, 0, 0.05); padding:25px 20px; text-align:center; transition:all 0.5s ease 0s; border-radius:5px; }
.feature-box-01 p{ margin-bottom:10px; }
.feature-box-01 .icon-tools{ color:#939393; }
.feature-box-01:hover{ background:#ffffff; border:5px solid #86bc42; }
.feature-box-01:hover .icon-tools{ color:#86bc42; }

/* feature box 02 */
.feature-box-02{ transition:all 0.3s; -o-transition:all 0.3s; -webkit-transition:all 0.3s; }
.feature-box-02:hover{ -webkit-box-shadow:0 5px 10px 0 rgba(0, 0, 0, 0.15); box-shadow:0 5px 10px 0 rgba(0, 0, 0, 0.15); }
.feature-box-02:before{ background:#86bc42; content:""; height:0; left:14px; position:absolute; top:0; transition:all 0.2s ease-in-out 0s; width:2px; }
.feature-box-02:hover:before{ height:100%; }

/* feature box 03 */
.feature-box-03{ border:2px solid #383838; padding:25px 20px; text-align:center; transition:all 0.5s ease 0s;}
.feature-box-03 .icon-tools{ color:#939393; }
.feature-box-03 p{color:#9d9d9d;} 
.feature-box-03:hover{ background:#86bc42; border:2px solid #86bc42; }
.feature-box-03:hover p{color:#fff;}

/* feature box 04 */
.feature-box-04{ position: relative; overflow: hidden; transition: all 0.3s; -o-transition: all 0.3s; -webkit-transition: all 0.3s; }
.feature-box-04:hover{ -webkit-box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15); box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15); }
.feature-box-04 .fetaure-image{ position: relative; overflow: hidden; z-index: 1; }
.feature-box-04 .fetaure-image img{ -webkit-transition: all 0.3s; -o-transition: all 0.3s; transition: all 0.3s; }
.feature-box-04 .fetaure-image:hover img{ -webkit-transform: scale(1.2); -ms-transform: scale(1.2); -o-transform: scale(1.2); transform: scale(1.2); }
.feature-box-04 .fetaure-image:after{ content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; -webkit-box-shadow: 0 -130px 70px -70px #000 inset; box-shadow: 0 -130px 70px -70px #000 inset; -webkit-transition: all 0.3s; -o-transition: all 0.3s; transition: all 0.3s; }
.feature-box-04 .package-detail{ width: 100%; bottom: 0; left: 0; padding: 20px 18px; position: absolute; z-index: 9; }
.feature-box-04 .title{ color: #fff; margin-right: 5px; }
.feature-box-04 .price{ width: 60%; float: left; }
.feature-box-04 .people-duration{ float: right; margin-left: 5px; }
.feature-box-04 .package-detail p{ margin: 0 0 0 10px; display: inline-block; color: #fff; text-transform: uppercase; font-weight: 500; }
.feature-box-04 .package-detail p .theme-color{ margin-right: 5px; }
.feature-box-04 .rating li{ float: left; margin-left: 3px; }

@media (max-width:767px){
    .feature-box-04 .package-detail { padding: 20px 10px; }
    .feature-box-04 .price { width: 55%; }
}

/* feature box 05 */
.feature-box-05{ position: relative; overflow: hidden; transition: all 0.3s; -o-transition: all 0.3s; -webkit-transition: all 0.3s; }
.feature-box-05 .fetaure-image{ position: relative; overflow: hidden; }
.feature-box-05 .fetaure-image img{ -webkit-transition: all 0.3s; -o-transition: all 0.3s; transition: all 0.3s; }
.feature-box-05 .fetaure-image:hover img{ -webkit-transform: scale(1.2); -ms-transform: scale(1.2); -o-transform: scale(1.2); transform: scale(1.2); }
.feature-box-05 .fetaure-image:after{ content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; -webkit-box-shadow: 0 -130px 70px -70px rgba(0,0,0,0.65) inset; box-shadow: 0 -130px 70px -70px rgba(0,0,0,0.65) inset; -webkit-transition: all 0.3s; -o-transition: all 0.3s; transition: all 0.3s; }
.feature-box-05 .package-detail{ width: 100%; bottom: 0; left: 0; padding: 20px 18px; position: absolute; z-index: 9; }

/* feature box 06 */
.feature-box-06{ text-align:left; transition:all 0.5s ease 0s;}
.feature-box-06 i{ color:#86bc42; }
.feature-box-06 p{color:#6f6f6f;} 

/* feature flex box */
.feature-flex{ position:relative; margin:30px 0; -webkit-transition-duration:.3s; transition-duration:.3s; }
.feature-flex-square{ position:relative; border-radius:5px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.feature-flex-square-icon{ float:left; width:50px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.feature-flex-square-icon i{ color:#86bc42; border-radius:10px; font-size:32px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.feature-flex-square-content{ float:left; width:75%; padding-left:10px; }
.feature-flex-square-content h4,.feature-flex-square-content h4 a{ color:#232323; font-size:18px; font-weight:500; margin-bottom:0; line-height:normal; }
.feature-flex-square-content p{ font-size:15px; font-weight:400; margin-top:10px; margin-bottom:15px; }
.feature-flex-square-content-button{ display:inline-block; color:#232323; font-size:14px; font-weight:500; }
.feature-flex-square-content-button:after{ content:'\f0da'; font-weight: 700; font-size:14px; font-family: Font Awesome\ 5 Free; color:#232323; margin-left:7px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.feature-flex-square-content-button:hover,.feature-flex-square-content-button:hover:after{ color:#86bc42; }

@media (max-width:991px){
    .feature-flex-square-icon{ text-align:center; }
    .feature-flex-square-icon i{ font-size:30px; }
    .feature-flex-square-content h4,.feature-flex-square-content h4 a{ font-size:16px; }
    .feature-flex-square-content p { font-size: 14px; }
 }

@media (max-width:767px){
    .feature-flex-square-icon{ text-align:left; width: 40px; }
 }

/* feature case block */ 
.case-block{ background:#fff; border:1px solid #ececec; border-radius:5px; height:100%; -webkit-transition-duration:.3s; transition-duration:.3s; overflow:hidden; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); position:relative; }
.case-block:hover{ -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .3); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .3); box-shadow:0 10px 30px 0 rgba(50, 50, 50, .3); }
.case-block img{ width:100%; }
.case-block-inner{ padding:20px 25px 20px 0; }
.case-block h4{ font-size:18px; margin-bottom:0; line-height:normal; font-weight:500; }
.case-block h4:after{ content:''; display:block; width:80px; height:2px; background:#86bc42; margin-top:10px; margin-bottom:15px; -webkit-transition-duration:.4s; transition-duration:.4s; }
.case-block p{ color:#999; font-size:15px; font-weight:400; margin-bottom:12px; }
.case-block-inner a{ font-size:15px; font-weight:500; color:#86bc42; }
.case-block-inner a:after{ content:'\f0da'; font-size:15px; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#86bc42; margin-left:10px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.case-block-inner a:hover,.case-block-inner a:hover:after{ color:#232323; }

@media (max-width:767px){
    .case-block-inner{ padding:25px; }
 }

/* feature partner box */  
.partner-box{ border:1px solid #ececec; }
.partner-box img{ width:100%; }
.partner-text{ padding:25px 40px 25px 0; }
.partner-text span{ color:#86bc42; font-size:13px; font-weight:500; letter-spacing:3px; text-transform:uppercase; }
.partner-box:hover .partner-text span{ color:#999; }
.partner-text h4{ color:#232323; font-size:21px; font-weight:600; margin:5px 0 20px 0; line-height:normal; }
.partner-text h4:after{ content:''; display:block; width:40px; height:2px; background:#86bc42; margin-top:10px; -webkit-transition-duration:.4s; transition-duration:.4s }
.partner-box:hover .partner-text h4:after{ width:80px; }

@media (max-width:767px){
    .partner-text{ padding:25px; }
 }

/* feature feedback box */   
.feedback-box{ position:relative; background:#f9f9f9; border:1px solid #ececec; padding:25px;  }
.feedback-box h5{ font-size:20px; font-weight:500; margin-bottom:15px; }

/* ===================================
    Service blocks
====================================== */

/* services images */
/* .content-bg1 { background-image: url("../img/content/feature-01.jpg"); } */

@media screen and (max-width:991px){
    .sm-bg { min-height: 400px;}
 }

@media screen and (max-width:767px){
    .sm-bg { min-height: 320px;}
 }

/* services blocks */
.services-blocks { margin-top: -90px; }

@media screen and (max-width:1199px){
    .services-blocks { margin-top: -70px; }
}
@media screen and (max-width:991px){
    .services-blocks { margin-top: -50px; }
}

/* service simple */
.service-simple{ position:relative; background:#fff; border:1px solid #ececec; border-radius:5px; overflow:hidden; height:100%; -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.service-simple img{ width:100%; transition:all 0.5s ease 0s; }
.service-simple img:hover{ transform:scale(1.1); }
.service-simple-inner{ position:relative; padding:25px 25px 20px; }
.service-simple-inner p{ max-width:95%; }
.service-simple-inner a{ display:inline-block; }
.service-simple h4{ font-size:18px; line-height:normal; margin-bottom:0; }

/* services block one */
.owl-carousel .service-box{ margin:0 15px 25px 15px; }
.service-box{ background:#fff; overflow:hidden; border:1px solid #eee; border-radius:5px; -webkit-box-shadow:0 10px 20px 0 rgba(50, 50, 50, .12); -moz-box-shadow:0 10px 20px 0 rgba(50, 50, 50, .12); box-shadow:0 10px 20px 0 rgba(50, 50, 50, .12); margin:15px 0; -webkit-transition-duration:.3s; transition-duration:.3s; }
.service-inner-box{ padding:20px; }
.service-icon-box{ width:20%; max-width:50px; float:left; padding-top:2px; }
.service-content-box{ width:80%; float:left; }
.service-box .img-holder{ position:relative; }
.service-box .img-holder:before{ background-color:rgba(0, 26, 87, 0.6); bottom:0; content:""; height:100%; left:0; opacity:0; position:absolute; top:auto; transition:all 0.3s ease 0s; width:100%; z-index:10; }
.service-box:hover .img-holder:before{ opacity:1; }
.service-box .img-holder img{ width:100%; }
.service-box h3{ font-size:16px; margin-bottom:0; line-height:normal; font-weight:500; }
.service-box p{ color:#999; font-size:15px; font-weight:400; margin-top:5px; margin-bottom:0; }
.service-box i{ color:#86bc42; font-size:28px; -webkit-transition-duration:0.3s; transition-duration:0.3s; }
.service-box:hover i{ color:#232323; }

/* services block two */
.services-block-two{ position:relative; }
.services-block-two .inner-box{ background:#ffffff; border:1px solid #ededed; border-radius:5px; padding:30px 30px 30px 95px; height:100%; position:relative; transition-timing-function:ease-in-out; -ms-transition-timing-function:ease-in-out; -moz-transition-timing-function:ease-in-out; -webkit-transition-timing-function:ease-in-out; -o-transition-timing-function:ease-in-out; transition-duration:.5s; -ms-transition-duration:.5s; -moz-transition-duration:.5s; -webkit-transition-duration:.5s; -o-transition-duration:.5s; }
.services-block-two p{ margin-bottom:0; }
.services-block-two h3{ margin-bottom:0; line-height:normal; display: inline-block; }
.services-block-two h3 a{ color:#3c3c3c; display:block; font-size:18px; font-weight:500; margin-bottom:10px; }
.services-block-two h3 a:hover { color:#86bc42; }
.services-block-two h2{ bottom:0; color:#f0f0f0; font-size:36px; font-weight:600; right:10px; margin-bottom:0; position:absolute; }
.services-block-two .icon-box{ color:#86bc42; font-size:36px; left:35px; position:absolute; top:35px; }
.services-block-two.dark .inner-box{ background:#000; border:1px solid #000; }
.services-block-two.dark .inner-box:hover{ background:#000; }
.services-block-two.dark .inner-box:hover h5 a, .services-block-two.dark .inner-box:hover p, .services-block-two.dark .inner-box:hover .icon-box{ color:#fff; }
.services-block-two.dark h3 a{ color:#6f6f6f; }
.services-block-two.dark h2{ color:rgba(255, 255, 255, 0.1); }
.services-block-two.dark .inner-box:hover h2{ color:rgba(255, 255, 255, 0.04); }
.services-block-two.dark .icon-box{ color:#6f6f6f; }

@media screen and (max-width:991px){
    .services-block-two h2{ font-size:36px; }
    .services-block-two h3 a{ font-size:18px; margin-bottom:10px; }
    .services-block-two .icon-box{ top:25px; left:21px; font-size:28px; }
    .services-block-two .inner-box{ padding:20px 20px 20px 75px; }
 }

/* services block three */
 .services-block-three > a, .services-block-three > span{ display: block;  border: 3px solid #d5d5d5; border-radius: 0; text-align: center; background: #fff; padding: 20px; position: relative; }
 .services-block-three > a:before, .services-block-three > span:before{ display: block; content: ""; width: 9%; height: 17%; position: absolute; bottom: -3px; right: -3px; border-bottom: 3px solid #86bc42; border-right: 3px solid #86bc42; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
 .services-block-three > a:after, .services-block-three > span:after{ display: block; content: ""; width: 9%; height: 17%; position: absolute; top: -3px; left: -3px; border-top: 3px solid #86bc42; border-left: 3px solid #86bc42; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
 .services-block-three > a:hover, .services-block-three > span:hover{ opacity: 1; border-color: #d5d5d5; }
 .services-block-three > a:hover:before, .services-block-three > span:hover:before{ width: 95%; height: 90%; }
 .services-block-three > a:hover:after, .services-block-three > span:hover:after{ width: 95%; height: 90%; }
 .services-block-three i{ font-size: 32px; }
 .services-block-three p { margin-bottom: 0; }
 .services-block-three:hover p{ color: #232323; }
 .services-block-three h4{ color: #6f6f6f; font-size: 14px; margin-bottom: 10px; font-weight: 600; }
 .services-block-three:hover h4{ color: #232323; }

/* service-block */
.service-grids .service-block{ overflow:hidden; position:relative; }
.service-grids .img-holder{ position:relative; }
.service-grids .service-block:hover .img-holder:before{ opacity:1; }
.service-grids .service-block .details{ background-color:#fff; box-shadow:0 3px 13px rgba(0, 0, 0, 0.07); padding:45px 45px 50px; position:relative; text-align:center; transition:all 0.5s ease 0s; z-index:20; }
.service-grids .service-block .number{ background-color:#86bc42; border-radius:75px; color:#fff; height:75px; font-size:30px; left:50%; line-height:75px; position:absolute; top:-37.5px; transform:translateX(-50%); width:75px; }
.service-grids .service-block .details h4{ color:#86bc42; font-size:20px; margin:10px 0 15px 0; line-height:normal; }
.service-grids .service-block .details .read-more{ color:#86bc42; font-weight:600; }

@media screen and (min-width:992px){
    .service-grids .service-block{ height:335px; }
    .service-grids .img-holder:before{ background-color:rgba(0, 26, 87, 0.6); bottom:20px; content:""; height:100%; left:0; opacity:0; position:absolute; top:auto; transition:all 0.3s ease 0s; width:100%; z-index:10; }
    .service-grids .service-block .details{ height:195px; position:absolute; top:168px; }
    .service-grids .service-block:hover .details{ height:230px; top:105px; }    
}

@media screen and (min-width:1200px){
    .service-grids .service-block{ height:370px; }
    .service-grids .service-block .details{ height:195px; position:absolute; top:205px; }
    .service-grids .service-block:hover .details{ height:230px; top:170px; }
}

@media (max-width:991px){
    .service-grids .service-block .details{ padding:45px 25px 35px; }
}

@media (max-width:767px){
    .service-grids .service-block .number{ height:65px; line-height:65px; top:-32.5px; width:65px; border-radius:65px; }
 }

/* ===================================
    Process with Icon
====================================== */

.process-step-xs{ position: relative; margin: 15px 0; padding: 0; }
.process-step-xs .process-step-icon { text-align: center; }
.process-step-xs .process-step-icon i{ color: #86bc42; font-size: 47px }
.process-step-xs h3{ position: relative; font-size: 14px; font-weight: 600; color: #6f6f6f; margin: 10px 0; text-align: center; }

.process-steps-xs li{ position: relative; float: left; list-style-type: none; display: inline-block; }

.process-steps-xs li:after{ content: ""; display: block; width: 50%; height: 1px; border-bottom: 2px dotted rgba(0, 0, 0, .1); -webkit-border-radius: 2px; border-radius: 2px; position: absolute; right: -20%; top: 40px; text-align: center }
.process-steps-xs li:last-child:after{ display: none; }

@media (max-width: 767px){
  .process-steps-xs li:nth-child(2):after{ display: none;  }
}

/* ===================================
    Client section
====================================== */

.section-clients{ background:#fff; padding:50px 0; }
.clients img{ opacity:.6; -webkit-transition-duration:.3s; transition-duration:.3s; }
.clients img:hover{ opacity:1; }

@media (max-width:992px){
    .section-clients .item{ text-align:center; width:100%; }
    .section-clients{ padding:30px 0; }
    .section-clients .owl-carousel .owl-item img{ max-width:75%; display:inline-block; }
}

/* ===================================
    Counter box
====================================== */

.counter-box h3,.counter-box h4{ display:inline-block; font-weight:600; }
.social-icons a:hover{ opacity:1; }
.counter-box h3:after,.counter-box h4:after{ content:'+'; }
.counter-box{ position:relative; text-align:center; }
.counter-box h4{ font-size:35px; line-height:normal; margin:0; }
.counter-box h3{ font-size:45px; }

@media (max-width:767px){
    .counter-box p:before{ margin:0; position:absolute; top:45px; }
    .counter-box p:after{ margin:0; left:14px; right:0; top:45px; }
    .counter-box.black p:after{ margin:0; left:14px; right:0; top:45px; }
    .counter-box h4{ font-size:26px; }
}

/* ===================================
    Call to action
====================================== */

.callto-action-imgbox{ display:block; margin-right:50px; padding-left:40px; padding-top:40px; position:relative; z-index:1; }
.callto-action-imgbox:before{ border:2px solid #86bc42; bottom:40px; content:""; left:0; position:absolute; right:40px; top:0; z-index:-1; }
.callto-action-imgbox img{ width:100%; }

@media (max-width:991px){
    .callto-action-imgbox{ margin-right:0; margin-bottom:30px; padding:20px 20px 0 20px; }
 }

/* ===================================
    Video
====================================== */

.story-video{ min-height:400px; height:100%; }
.video_btn{ background-color:#fff; border:2px solid #86bc42; border-radius:50%; color:#ffffff; display:inline-block; font-size:16px; height:50px; line-height:50px; text-align:center; width:50px; transition-duration:.2s; -ms-transition-duration:.2s; -moz-transition-duration:.2s; -webkit-transition-duration:.2s; -o-transition-duration:.2s; }
.video_btn .fa{ color:#86bc42; }
.video:hover .video_btn{ background:#86bc42; }
.video:hover .video_btn .fa{ color:#fff; }

/* ===================================
    About area style
====================================== */

.about-area{ padding:0; }
.about-text{ display:block; padding-bottom:90px; padding-top:90px; }
.about-text .sec-title{ padding-bottom:34px; }
.about-text .inner-content h2{ font-size:24px; font-weight:400; line-height:36px; margin:0 0 14px; color:#fff; }
.about-text .inner-content h2 span{ font-weight:700; }
.about-text .inner-content p{ color:#fff; }
.about-text .inner-content .bottom{ display:block; overflow:hidden; margin-top:25px; }
.about-text .inner-content .bottom p{ margin:0; font-weight:500; }
.about-text .inner-content .bottom .signature{ display:block; margin-top:22px; }
.about-right-box{ position:relative; display:block; overflow:hidden; padding:90px 40px; z-index:1; height:100%; }
.about-right-box:before{ position:absolute; top:0; left:0; width:100%; height:100%; background:#86bc42; opacity:.92; content:""; z-index:-1; }
.about-right-box ul{ margin-bottom:0; }
.about-right-box ul li{ display:block; overflow:hidden; border-bottom:1px solid rgba(255, 255, 255, 0.10); padding-bottom:29px; margin-bottom:30px; }
.about-right-box ul li:last-child{ border-bottom:none; padding-bottom:0; margin-bottom:0; }
.about-right-box ul li .left-content{ display:block; width:70%; }
.about-right-box ul li .left-content .icon{ width:55px; display:table-cell; vertical-align:top; color:rgba(255, 255, 255, 0.5); }
.about-right-box ul li .left-content .icon span:before{ font-size:55px; line-height:55px; }
.about-right-box ul li .left-content .title{ padding-left:30px; display:table-cell; vertical-align:middle; color:rgba(255, 255, 255, 0.5); }
.about-right-box ul li .left-content .title h3{ color:#ffffff; font-size:16px; font-weight:600; text-transform:uppercase; line-height:normal; letter-spacing:1px; margin:0 0 1px; }
.about-right-box ul li .left-content .title span{ color:rgba(255, 255, 255, 0.75); }
.about-right-box ul li .right-content .title{ display:block; margin-top:3px; }
.about-right-box ul li .right-content .title h4{ color:#ffffff; font-size:3.4em; font-weight:600; line-height:normal; margin-bottom:0; }

@media (max-width:1199px){
    .about-text{ padding-top:70px; padding-bottom:70px; }
    .about-right-box{ padding-top:70px; padding-bottom:70px; }
}

@media (max-width:991px){
    .about-area { padding-bottom: 50px; }
    .about-text{ padding-top:50px; padding-bottom:50px; }
    .about-right-box{ padding-top:50px; padding-bottom:50px; }
}
@media (max-width:767px){
    .about-right-box{ padding:40px 20px; }
    .about-right-box ul li{ padding-bottom:20px; margin-bottom:20px; }
    .about-right-box ul li .left-content .title{ padding-left:10px; }
    .about-right-box ul li .left-content .icon span:before{ font-size:36px; line-height:normal; }
    .about-right-box ul li .right-content .title h4{ font-size:26px; }
}

/* ===================================
    Progress bars
====================================== */

.progress-text{ font-size:13px; font-weight:500; color:#232323; margin-bottom:5px; }
.custom-progress{ height:4px; border-radius:50px; box-shadow:none; margin-bottom:15px; }
.custom-bar{ height:100%; background-color:#86bc42; box-shadow:none; }
.custom-bar-dark{ height:100%; background-color:#232323; box-shadow:none; }
.progress-medium{ height:8px; border-radius:50px; box-shadow:none; }
.progress-bold{ height:12px; border-radius:50px; box-shadow:none; }

/* ===================================
    Testmonial
====================================== */

.testmonial-single{ text-align:center; }
#testmonials-carousel .testmonial-single img{ width:auto; display:inline-block; margin-bottom:10px; }
.testmonial-single p:before{ margin:auto auto 15px; content:"\f10d"; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#86bc42; font-size:23px; display:block; }
.testmonial-single p{ text-align:center; font-style:italic; font-weight:600; }
.testmonial-single h4{ font-size:22px; font-weight:500; text-align:center; margin:0 0 5px 0; line-height:normal; }
.testmonial-single h6{ color:#aaa; font-size:12px; text-transform: uppercase; font-weight:500; text-align:center; line-height:normal; }

.testmonial-box{ position:relative; background:#fff; border-radius:5px; padding:25px; border:1px solid #ececec; margin:15px 0; -webkit-transition-duration:.3s; transition-duration:.3s; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.testmonial-box img{ border:2px solid #ececec; border-radius:50%; padding:2px; }
.owl-carousel .testmonial-box img{ width:auto; max-height:120px; display:inline-block; }
.testmonial-box h5{ font-size:18px; font-weight:500; margin:5px 0 0; line-height:normal; }
.testmonial-box span{ font-size:13px; }
.testmonial-box p{ font-size:15px; color:#999; line-height:1.7; margin-top:10px; margin-bottom:10px; }

.testmonial-style2{ position:relative; background:#fff; border-radius:5px; padding:25px; border:1px solid #ececec; margin:0 0 30px 0; -webkit-transition-duration:.3s; transition-duration:.3s; box-shadow:0 0 8px rgba(0, 0, 0, 0.2); -webkit-box-shadow:0 0 8px rgba(0, 0, 0, 0.2); -moz-box-shadow:0 0 8px rgba(0, 0, 0, 0.2); }
.testmonial-style2 img{ border:2px solid #ececec; border-radius:50%; padding:2px; }
.owl-carousel .testmonial-style2 img{ width:auto; max-height:120px; display:inline-block; }
.testmonial-style2 h5{ font-size:18px; font-weight:500; margin:5px 0 0; line-height:normal; }
.testmonial-style2 span{ font-size:13px; }
.testmonial-style2 p{ font-size:15px; color:#999; line-height:1.7; margin-top:10px; margin-bottom:10px; }

.testimonial-style3 .testimonial-item{ display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: nowrap; flex-wrap: nowrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.testimonial-style3 .testimonial-review p { margin-bottom: 0; }
.testimonial-style3 .testimonial-left-col{ -webkit-box-ordinal-group: 0; -ms-flex-order: -1; order: -1; -webkit-box-flex: 1; -ms-flex: 1 0 177px; flex: 1 0 177px; margin-right: 100px; text-align: center; }
.testimonial-style3 .testimonial-pic{ position: relative; margin: 0 0 25px; }
.testimonial-style3 .testimonial-pic:before{ content:'\f10d'; font-weight: 700; font-family: Font Awesome\ 5 Free; display: block; position: absolute; top: 50%; right: -62px; margin-top: -8px; font-size: 21px; color: #86bc42;  }
.testimonial-style3 .testimonial-separator:before, .testimonial-style3 .testimonial-separator:after{ content: ''; display: block; width: 1px; height: 60px; position: absolute; right: -53px; background-color: #6f6f6f; }
.testimonial-style3 .testimonial-separator:after{ bottom: 0; }
.testimonial-style3 .client-info h6{ margin-bottom: 0; font-size: 15px; color: #fff; }
.testimonial-style3 .client-info span{ color: #d6d5d5; }
.testimonial-style3 .testimonial-review{ padding-bottom: 70px; font-size: 18px; line-height: 36px; font-weight: 300; }

.testimonial-style3 .custom-dot{ display:inline-block; z-index:1; margin-top:30px; }
.testimonial-style3 .custom-dot span{ width:12px; height:12px; margin:0 7px; background:none; cursor:pointer; display:block; border:1px solid #fff; -webkit-backface-visibility:visible; border-radius:0; transition-duration:.3s; -webkit-transition-duration:.3s; }
.testimonial-style3 .custom-dot span:hover{ background:#fff; -webkit-backface-visibility:visible; border-radius:0; }

 @media (max-width: 991px){ 
    .testimonial-style3 .testimonial-review{ padding-bottom: 65px; }
 }

 @media (max-width: 767px){ 
    .testimonial-style3 .client-info h6 { font-style: 14px; }
    .testimonial-style3 .custom-dot { margin-top: 20px; }
 }

 @media (max-width: 575px){ 
     .testimonial-style3 .testimonial-review { padding-bottom: 0; }
     .testimonial-style3 .testimonial-left-col{ margin-bottom: 20px; text-align: left; }
     .testimonial-style3 .testimonial-item{ -ms-flex-wrap: wrap; flex-wrap: wrap; }
     .testimonial-style3 .testimonial-separator{ display: none; }
     .testimonial-style3 .testimonial-pic:before{ display: none; }
 }

/* ===================================
    Owl carousel
====================================== */

.custom-dot{ display:inline-block; z-index:1; margin-top:30px; }
.custom-dot span{ width:12px; height:12px; margin:5px; background:none; cursor:pointer; display:block; border:1px solid #fff; -webkit-backface-visibility:visible; border-radius:30px; transition-duration:.3s; -webkit-transition-duration:.3s; }
.custom-dot span:hover{ background:#aaa; -webkit-backface-visibility:visible; border-radius:30px; }
.custom-dot.active span{ width:12px; height:12px; background:#fff; -webkit-transition-duration:.3s; transition-duration:.3s; }
.owl-nav i{ color:#232323; font-size:18px; padding-top:6px; }
.owl-nav .owl-next,.owl-nav .owl-prev{ color:#86bc42; font-size:43px; text-align:center; width:40px; height:40px; background:#fff !important; }
.owl-nav .owl-prev{ left:0; -webkit-transition:all .4s ease 0s; transition:all .4s ease 0s; -webkit-box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); -moz-box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); }
.owl-nav .owl-next{ -webkit-transition:all .4s ease 0s; transition:all .4s ease 0s; -webkit-box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); -moz-box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); }

/* cretive slider */
.about-carousel { position: relative; }
.about-carousel:after{ content: ""; position: absolute; top: 70px; left: -70px; width: 100%; height: 100%; background-color: transparent; border: 30px solid rgba(0,0,0,0.1); }

@media only screen and (max-width: 991px){ 
    .about-carousel{ margin-bottom: 50px; }
    .about-carousel:after{ top: 50px; left: -50px; border: 20px solid rgba(0,0,0,0.1); }    
}
@media only screen and (max-width: 767px){ 
    .about-carousel { margin: 0; }
    .about-carousel:after{ display: none; }
}

/* ===================================
    Team member
====================================== */ 

.team-member{ border:1px solid #ececec; border-radius:5px; -webkit-transition-duration:.3s; transition-duration:.3s; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); background:#fff; overflow:hidden; }
.team-member:hover{ box-shadow:0 10px 30px 0 rgba(50, 50, 50, .28); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.team-member-img{ width:100%; height:auto; }
.team-member-img img{ width:100%; }
.team-member-text{ text-align:center; padding:20px; }
.team-member-text h4{ font-size:18px; font-weight:500; line-height:normal; margin-bottom:3px; -webkit-transition-duration:.6s; transition-duration:.6s; }
.team-member:hover .team-member-text h4{ color:#86bc42; }
.team-member-text h4 a{ color:#232323; }
.team-member-text span{ color:#86bc42; font-size:15px; font-weight:500; letter-spacing:2px; }
.team-member-text p{ color:#999; font-size:14px; font-weight:400; line-height:170%; margin:20px auto 0; max-width:90%; }
.team-member-text ul{ margin-top:15px; padding:8px 0; }
.team-member-text ul li a i{ font-size:17px; line-height:34px; }

 @media screen and (max-width:994px){
    .team-member-text{ padding:20px 10px; }
 }

/* team single img */
.team-single-img{ width:100%; }
.team-single-img img{ border-radius:5px; }
.team-single-text{ padding:5px 50px 15px 15px; }
.team-single-text span{ color:#86bc42; font-size:14px; font-weight:500; }
.team-single-text h4{ color:#232323; font-size:25px; font-weight:500; margin-bottom:10px; }
.team-single-info{ padding:0; }
.team-single-info li strong{ color:#555; font-size:16px; font-weight:500; margin-right:10px; }
.team-single-text li span{ color:#777; font-size:15px; font-weight:400; }

/* team single icon */
.team-single-icons ul li{ display:inline-block; border:1px solid #86bc42; border-radius:50%; color:#86bc42; margin-right:8px; margin-bottom:5px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.team-single-icons ul li a{ color:#86bc42; display:block; font-size:14px; height:25px; line-height:26px; text-align:center; width:25px; }
.team-single-icons ul li:hover{ background:#86bc42; border-color:#86bc42; }
.team-single-icons ul li:hover a{ color:#fff; }

/* team style1 */
.team-style1 .team-member-img{ overflow:hidden; border-radius:5px; }
.team-style1 .team-member-img img{ transition:all 0.5s ease 0s; width:100%; }
.team-style1 .team-member-img:hover img{ transform:scale(1.1); }
.team-style2 .team-member-img img{ transition:all 0.3s ease 0s; }
.team-style2 .team-member-img:hover img{ filter:grayscale(100%); }

/* team style2 */
.team-style2 .team-description{ position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: transparent; opacity: 0; transition: all 200ms ease-in-out; padding: 15px; z-index: 9; }
.team-style2 .social-links{ transform: translateY(15px); transition: transform 300ms ease-in-out, opacity 150ms ease-in; opacity: 0; color: #fff; -webkit-font-smoothing: antialiased; }
.team-style2 .social-links a:hover{ opacity: 0.65; }
.team-style2 .team-description-wrapper{ position: relative; display: table; width: 100%; height: 100%; }
.team-style2 .team-description-content{ display: table-cell; vertical-align: middle; }
.team-style2 .team-member-img{ position: relative; display: block; overflow: hidden; }
.team-style2 .bg-cover{ background-color: rgba(255, 66, 41, 0); opacity: 1; position: absolute; height: 100%; width: 100%; top: 0; transform: scale(2); transition: all 200ms ease-in-out; display: none; z-index: 1; }
.team-style2 .team-member-img > img{ transition: all 0.2s ease 0s; }
.team-style2:hover .bg-cover{ background-color: rgba(0, 0, 0, 0.75); display: block; }
.team-style2:hover .social-links{ transition-delay: 100ms, 100ms; opacity: 1; transform: translateY(0); }
.team-style2:hover .team-member-img > img{ filter: blur(4px) grayscale(4%); }
.team-style2:hover .team-description{ opacity: 1; }

/* team style2 */
.team-style3 .team-description{ position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: transparent; opacity: 0; transition: all 200ms ease-in-out; padding: 15px; z-index: 9; }
.team-style3 .social-links, .team-style3 .about-me{ transform: translateY(15px); transition: transform 300ms ease-in-out,opacity 150ms ease-in; opacity: 0; color: #fff; -webkit-font-smoothing: antialiased; }
.team-style3 .about-me{ position: relative; display: inline-block; color: #f7f7f7; }
.team-style3 .social-links:after{ background: #86bc42; bottom: -12px; content: ""; height: 1px; position: absolute; left: 0; right: 0; margin: 0 auto; width: 75px; }
.team-style3 .social-links a:hover{ opacity: 0.65; }
.team-style3 .team-description-wrapper{ position: relative; display: table; width: 100%; height: 100%; }
.team-style3 .team-description-content{ display: table-cell; vertical-align: middle; }
.team-style3 .team-member-img{ position: relative; display: block; overflow: hidden; }
.team-style3 .bg-cover{ background-color: rgba(255, 66, 41, 0); opacity: 1; position: absolute; height: 100%; width: 100%; top: 0; transform: scale(2); transition: all 200ms ease-in-out; display: none; z-index: 1; }
.team-style3 .team-member-img > img{ transition: all 0.2s ease 0s; }
.team-style3:hover .bg-cover{ background-color: rgba(0, 0, 0, 0.83); display: block; }
.team-style3:hover .social-links{ transition-delay: 100ms,100ms; opacity: 1; transform: translateY(0); }
.team-style3:hover .about-me{ transition-delay: 200ms,200ms; opacity: 1; transform: translateY(0); }
.team-style3:hover .team-member-img > img{ filter: blur(4px) grayscale(4%); }
.team-style3:hover .team-description{ opacity: 1; }


/* ===================================
    Project grid
====================================== */

/* filtering */
.filtering{ margin-bottom:10px; }
.filtering span{ border-bottom:1px solid rgba(0, 0, 0, 0); color:#333333; cursor:pointer; font-size:14px; font-weight:600; letter-spacing:1px; margin-right:20px; display:inline-block; margin-bottom:5px; }
.filtering span:last-child{ margin:0; }
.filtering .active{ border-color:#86bc42; color:#86bc42; }

@media screen and (max-width:767px){
    .filtering span { margin-right: 15px;}
}

.gallery-style2 .project-grid { border: 1px solid rgba(0,0,0,0.1); }

/* project grid */
.project-grid{ display:block; position:relative; margin:15px 0; }
.project-grid-img img{ display:block; width:100%; height:auto; }
.project-grid-overlay{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;   position:absolute; background:rgba(255, 255, 255, 0.95); overflow:hidden; top: 5px; left: 5px; right: 5px; bottom: 5px;  opacity:0; -webkit-transition-duration:.3s; transition-duration:.3s; }
.project-grid-overlay h4 { margin-bottom: 0;  }
.project-grid-overlay h4 a,.project-grid-overlay p{ text-align:center; }
.project-grid:hover .project-grid-overlay{ opacity:1; }
.project-grid-overlay h4 a{ font-size:22px; opacity:0;}
.project-grid-overlay h4 a:hover{ color:#86bc42; }
.project-grid-overlay h4 a:after{ content:''; display:block; width:70px; height:1px; background:#86bc42; margin:15px auto; }
.project-grid:hover h4 a{ opacity:1; line-height:normal; }
.project-grid-overlay p{ color:#666; font-size:15px; margin:0; opacity:0;  }
.project-grid:hover p{  opacity:1; }

.project-grid-overlay.theme{ background:rgba(134, 188, 66, 0.95); left: 0; right: 0; top: 0; bottom: 0; border:5px solid rgba(0, 0, 0, 0.15); }
.project-grid-overlay.theme h4 a { color:#fff; }
.project-grid-overlay.theme h4 a:hover{ color:rgba(255,255,255,0.55); }
.project-grid-overlay.theme h4 a:after{ height:1px; background:#fff;}
.project-grid-overlay.theme p{ color:#fff; }

@media screen and (max-width:1199px){
    .project-grid-overlay h4 { line-height: 20px; }
    .project-grid-overlay h4 a{ font-size:18px;}
}
@media screen and (max-width:767px){
    .project-grid{ margin:20px 0 0 0; }
    .project-grid:hover p{ top:52%; }
 }


/* project grid style2 */
.project-grid-style2 .project-details{ position: relative; overflow: hidden; }
.project-grid-style2 .project-details img{ width: 100%; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); -webkit-transition: all 400ms; transition: all 400ms; }
.project-grid-style2 .portfolio-icon{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; text-align: center; background: rgba(0, 0, 0, 0.5); opacity: 0; -webkit-transition: opacity 0.3s; transition: opacity 0.3s; }
.project-grid-style2 .portfolio-icon a{ position: absolute; top: 50%; -webkit-transform: scale3d(0, 0, 0); transform: scale3d(0, 0, 0); -webkit-transition: all 0.3s; transition: all 0.3s; color: #fff; font-size: 26px; z-index: 9; }
.project-grid-style2 .portfolio-title{ padding: 15px 15px 0 15px; text-align: center; display: block; -webkit-transition: all 0.3s; transition: all 0.3s;  }
.project-grid-style2 .portfolio-title h4{ font-size: 16px; margin-bottom: 0; }
.project-grid-style2 .portfolio-title p { margin-bottom: 0; text-transform: uppercase; font-size: 12px; }
.project-grid-style2:hover .project-details img{ -webkit-transform: scale3d(1.15, 1.15, 1); transform: scale3d(1.15, 1.15, 1); }
.project-grid-style2:hover .portfolio-icon{ opacity: 1; }
.project-grid-style2:hover .portfolio-icon a{ -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
.project-grid-style2:hover .portfolio-icon a:hover{ color: #86bc42; }
.portfolio-post-border{ border: 1px solid #86bc42; bottom: 20px; left: 20px; position: absolute; right: 20px; top: 20px; visibility: hidden; }
.project-grid-style2:hover .portfolio-post-border{ visibility: visible; }


/* project single */
.project-carousel h4:after,.project-single-text h4:after{ content:" "; background:#86bc42; height:2px; }
.project-single-text p{ font-size:15px; font-weight:400; line-height:1.8; }
.project-single-text h5{ color:#232323; font-size:18px; font-weight:500; margin:25px 0 20px; }
.project-single-text h5:after{ display:block; width:70px; margin-top:8px; content:" "; background:#86bc42; height:2px; }
.project-single-text ul li{ color:#232323; font-size:15px; font-weight:500; }
.project-single-text ul li i{ color:#86bc42; font-weight:500; font-size:15px; padding-right:10px; }
.project-single-info{ border:1px solid #ececec; display:block; width:100%; padding:0 20px; }
.project-single-info ul li{ color:#777; font-size:15px; font-weight:500; border-bottom:1px solid #ececec; padding:12px 0; }
.project-single-info ul li:last-child{ border-bottom:none; }
.project-single-info ul li span{ display:inline-block; color:#232323; font-size:15px; font-weight:500; margin-right:25px; width:70px; max-width:100%; }
.project-single-info ul li .value { width: auto; margin-right: 0; color:#6f6f6f;  }
.project-single-img{ padding-bottom:30px; }
.project-single-box-img img{ width:100%; border-radius:10px; -webkit-box-shadow:0 20px 40px rgba(0, 0, 0, .07); box-shadow:0 20px 40px rgba(0, 0, 0, .07); }

/* ===================================
    Accordion style
====================================== */

/*accordion style 1*/
.accordion-style .card{ background:transparent; box-shadow:none; margin-top:0 !important; border:none; }
.accordion-style .card-header{ border:0px; padding:0; border-bottom:1px solid rgba(255, 255, 255, 0.15); background:none; }
.accordion-style .btn-link{ background:#86bc42 !important; color:#fff; font-size:14px; line-height:20px; position:relative; border:none; display:block; width:100%; text-align:left; white-space:normal; border-radius:4px; padding:15px 30px 15px 15px; text-decoration:none; }
.accordion-style .btn-link:hover{ background:#86bc42 !important; border:none; text-decoration:none; }
.accordion-style .btn-link.collapsed:after{ background:none; content:"+"; right:15px; left:inherit; font-size:20px; height:auto; transform:none; width:auto; top:15px; }
.accordion-style .btn-link:after{ background:none; content:"-"; right:17px; left:inherit; font-size:20px; height:auto; transform:none; width:auto; top:15px; position:absolute; color:#fff; }
.accordion-style .card-body{ padding:20px; line-height:24px; text-align:left; }

/*accordion style 2*/
.accordion-style2 .card{ background:transparent; box-shadow:none; margin-top:0 !important; border:none; }
.accordion-style2 .card-header{ border:0px; padding:0; border-bottom:1px solid rgba(255, 255, 255, 0.15); background:none; }
.accordion-style2 .btn-link{ background:#ededed !important; color:#232323; font-size:14px; line-height:20px; position:relative; border:none; display:block; width:100%; text-align:left; white-space:normal; border-radius:0; padding:15px 30px 15px 15px; text-decoration:none; }
.accordion-style2 .btn-link:hover{ background:#ededed !important; border:none; text-decoration:none; }
.accordion-style2 .btn-link.collapsed:after{ background:none; content:"+"; right:15px; left:inherit; font-size:20px; height:auto; transform:none; width:auto; top:15px; }
.accordion-style2 .btn-link:after{ background:none; content:"-"; right:17px; left:inherit; font-size:20px; height:auto; transform:none; width:auto; top:15px; position:absolute; color:#232323; }
.accordion-style2 .card-body{ padding:10px 15px; line-height:24px; text-align:left; background: #f9f9f9; }

/* ===================================
    Service detail
====================================== */

.services-single-menu{ border:1px solid #ececec }
.services-single-menu ul li{ border-bottom:1px solid #ececec; background:#fff; }
.services-single-menu ul li:last-child{ border-bottom:none; }
.services-single-menu ul li:hover{ background:#f9f9f9; }
.services-single-menu ul li.services-active{ background:#86bc42; }
.services-single-menu ul li.services-active a{ color:#fff; }
.services-single-menu ul li a{ display:block; color:#232323; font-size:15px; font-weight:500; padding:17px 0 17px 15px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.callback-box{ background:#232323; border:1px solid #ececec; border-radius:5px; padding:20px 15px 25px 15px; position:relative; }

/* ===================================
    Portfolio masonry
====================================== */

.masonry{ margin:10px; padding:0; -moz-column-gap:10px; -webkit-column-gap:10px; column-gap:10px; transition-duration:.4s; -webkit-transition-duration:.4s; }
.masonry a{ display:block; margin-top:10px; }
.masonry a:first-child{ margin-top:0; }
.masonry-item{ position: relative; overflow:hidden; -webkit-transition-duration:.4s; transition-duration:.4s; }
.masonry-item img{ display:block; width:100%; height:auto; -webkit-transition-duration:.4s; transition-duration:.4s; }
.masonry-item-overlay{ position:absolute; bottom:0; left:0; right:0; height:100%; max-height:100%; width:100%; opacity:0; background:rgba(0, 0, 0, .5); border: 8px solid rgba(255, 255, 255, 0.65); padding:3%; -webkit-transition-duration:.4s; transition-duration:.4s; }
.masonry-item:hover img{ transform:scale(1.2); }
.masonry-item:hover .masonry-item-overlay{ position:absolute; opacity:1; -webkit-transition-duration:.5s; transition-duration:.5s; }
.masonry-item:hover .masonry-item-overlay h4{ opacity:1; transform:translate(0, -50%); -ms-transform:translate(0, -50%); text-align:center; -webkit-transition-duration:.6s; transition-duration:.6s; }
.masonry-item:hover .masonry-item-overlay ul{ opacity:1; transform:translate(0, -50%); -ms-transform:translate(0, -50%); text-align:center; -webkit-transition-duration:.5s; transition-duration:.5s; }
.masonry-item-overlay h4{ color:#fff; font-size:20px; font-weight:400; position:absolute; bottom:40px; margin:0 0 20px 0; line-height:20px; }
.masonry-item-overlay ul{ position:absolute; padding-left:0; bottom:25px; margin:0; }
.masonry-item-overlay ul li{ list-style-type:none; display:inline-block; font-size:16px; font-weight:600; color:#fff; padding-left:0; opacity:.7; }
.masonry-item-overlay ul li:last-child:after{ content:none; }
.masonry-item-overlay ul li:after{ content:' / '; font-size:8px; font-weight:700; margin:0 5px; }
.masonry-item-overlay ul li a{ color:#fff; }
.masonry-item:first-child{ margin-top:0; }

@media only screen and (min-width:576px){
    .masonry{ -moz-column-count:2; -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:768px){
    .masonry{ -moz-column-count:2; -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:992px){
    .masonry{ -moz-column-count:3; -webkit-column-count:3; column-count:3; }
 }

@media only screen and (min-width:1200px){
    .masonry{ -moz-column-count:3; -webkit-column-count:3; column-count:3; }
 }

/* masonry 4 */
.masonry-4{ margin:10px; padding:0; -moz-column-gap:10px; -webkit-column-gap:10px; column-gap:10px; }
.masonry-4 a{ display:block; margin-top:10px; }
.masonry-4 a:first-child{ margin-top:0; }

@media only screen and (min-width:576px){
    .masonry-4{ -moz-column-count:2; -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:768px){
    .masonry-4{ -moz-column-count:2; -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:992px){
    .masonry-4{ -moz-column-count:3; -webkit-column-count:3; column-count:3; }
 }

@media only screen and (min-width:1200px){
    .masonry-4{ -moz-column-count:4; -webkit-column-count:4; column-count:4; }
 }

/* ===================================
    Tabs
====================================== */ 

ul.resp-tabs-list{ margin: 0px; padding: 0px; }
.resp-tabs-list li{ font-weight: 600; font-size: 14px; display: inline-block; padding: 13px 15px; margin: 0 4px 0 0; list-style: none; cursor: pointer; }
.resp-tabs-list li:last-child { margin-right: 0; }
.resp-tabs-list li i { font-size: 20px; padding-right: 5px; vertical-align: text-bottom; }
.resp-tabs-container{ padding: 0px; background-color: #fff; clear: left; }
.resp-tab-content{ display: none; padding: 20px; }
.resp-tabs-list li.resp-tab-active{ border: 1px solid #86bc42; border-bottom: none; border-color: #86bc42 !important; margin-bottom: -1px; padding: 12px 14px 14px 14px; border-top: 4px solid #86bc42 !important; border-bottom: 0px #fff solid; border-bottom: none; background-color: #fff; color: #86bc42; }
.resp-content-active, .resp-accordion-active{ display: block; }
.resp-tab-content{ border: 1px solid #c1c1c1; border-top-color: #c1c1c1; float: left; width: 100%; }
h2.resp-accordion{  cursor: pointer; display: none; font-size: 14px; border: 1px solid #c1c1c1; border-top: 0px solid #c1c1c1; margin: 0px; padding: 14px 15px; float: left; width: 100%; }
h2.resp-tab-active{ border-bottom: 0px solid #c1c1c1 !important; background-color: #86bc42 !important; color: #fff; }
h2.resp-tab-title:last-child{ border-bottom: 12px solid #c1c1c1 !important; background: blue; }

/* vertical tabs */
.resp-vtabs ul.resp-tabs-list{ float: left; width: 30%; }
.resp-vtabs .resp-tabs-list li{ display: block; padding: 15px 15px !important; margin: 0 0 4px; cursor: pointer; float: none; }
.resp-vtabs .resp-tabs-container{ padding: 0px; background-color: #fff; border: 1px solid #86bc42 !important; float: left; width: 70%; min-height: 250px; clear: none; }
.resp-vtabs .resp-tab-content{ border: none; word-wrap: break-word; }
.resp-vtabs li.resp-tab-active{ position: relative; z-index: 1; margin-right: -1px !important; padding: 14px 15px 15px 11px !important; border-top: 1px solid; border: 1px solid #86bc42 !important; border-left: 4px solid #86bc42 !important; margin-bottom: 4px !important; border-right: 1px #FFF solid !important; }
.resp-arrow{ border-color: transparent #232323 #232323 transparent; border-style: solid; border-width: 0 1px 1px 0; float: right; display: block; height: 8px; transform: rotate(45deg); width: 8px; margin-top: 4px;  }
h2.resp-tab-active span.resp-arrow{ border-color: #fff transparent transparent #fff; border-style: solid; border-width: 1px 0 0 1px; float: right; display: block; height: 8px; transform: rotate(45deg); width: 8px; margin-top: 7px; }

/* accordion styles */
.resp-easy-accordion h2.resp-accordion{ display: block; }
.resp-easy-accordion .resp-tab-content{ border: 1px solid #c1c1c1; }
.resp-easy-accordion .resp-tab-content:last-child{ border-bottom: 1px solid #c1c1c1; /* !important; */}
.resp-jfit{ width: 100%; margin: 0px; }
.resp-tab-content-active{ display: block; border-color: #86bc42 !important; }
h2.resp-accordion:first-child{ border-top: 1px solid #c1c1c1; }
h2.resp-accordion.resp-tab-active { border-color: #86bc42 !important; }

/* tab-style4 */
.tab-style4 .resp-tabs-list li.resp-tab-active { background:#86bc42 !important; color: #fff;  }
.tab-style4 .resp-tab-content-active { background:#86bc42 !important; color: #fff; }

/* tab-style5 */
@media only screen and (min-width: 768px){
    .tab-style5 ul.resp-tabs-list { text-align: center; }
    .tab-style5 ul.resp-tabs-list.text-left { padding-left: 15px; }
    .tab-style5 ul.resp-tabs-list li:last-child { margin-right: 0; }
    .tab-style5 ul.resp-tabs-list li.resp-tab-active { border-bottom-color: #86bc42;  color: #86bc42; }
    .tab-style5 .resp-tab-content { border: 1px solid #e6e6e6; border-width: 1px 0 0 0; border-color: #86bc42; }
}

/* tab-style6 */
@media only screen and (min-width: 768px){
    .tab-style6 ul.resp-tabs-list { text-align: center;  border: 1px solid #e6e6e6; border-width: 0 0 1px 0;  }
    .tab-style6 ul.resp-tabs-list li:last-child { margin-right: 0; }
    .tab-style6 ul.resp-tabs-list li.resp-tab-active { border-bottom-color: #86bc42; }
    .tab-style6 ul.resp-tabs-list li.resp-tab-active { color: #86bc42; }
    .tab-style6 .resp-tab-content { border: none; padding: 30px 20px; }
    .tab-style6 .resp-tabs-list .tab-box { display: inline-block; vertical-align: middle; text-align: left; margin-top: 8px; }
    .tab-style6 .resp-tabs-list li.resp-tab-active h6 { color:#86bc42;  }
    .tab-style6 .resp-tabs-list .tab-box h6 { margin: 0; font-weight: 600; font-size: 16px; color: #6f6f6f; line-height: 12px; }
    .tab-style6 .resp-tabs-list .tab-box span { text-transform: lowercase; font-size: 12px; }
    .tab-style6 .resp-tabs-list li { font-size: 18px; border-bottom: 2px solid transparent !important; margin: 0; background:none !important; padding: 0 35px 15px 35px;  text-transform: uppercase;   }
    .tab-style6 .resp-tabs-list li.resp-tab-active { border-width: 0 0 2px 0 !important; border-color: #86bc42 !important; }
    .tab-style6 ul.resp-tabs-list i { margin-right: 15px; border: 1px solid #d5d5d5; border-radius: 70px; width: 70px;  height: 70px; line-height: 68px; font-size: 30px; padding: 0; vertical-align: middle; }
    .tab-style6 ul.resp-tabs-list li.resp-tab-active i { background: #86bc42; border-color: #86bc42; color: #fff; }
}

@media only screen and (max-width: 991px){
    .tab-style6 .resp-tab-content { padding: 20px; }
    .tab-style6 .resp-tabs-list li { padding: 0 15px 15px 15px; } 
}

/* change the breakpoint to set the accordion */
@media only screen and (max-width: 767px){
    ul.resp-tabs-list{ display: none; }
    h2.resp-accordion{ display: block;  }
    h2.resp-accordion i { margin-right: 8px; font-size: 18px; }
    .resp-vtabs .resp-tab-content{ border: 1px solid #C1C1C1; }
    .resp-vtabs .resp-tabs-container{ border: none !important; float: none; width: 100%; min-height: 100px; clear: none; }
    .resp-accordion-closed{ display: none !important; }
    .resp-vtabs .resp-tab-content:last-child{ border-bottom: 1px solid #c1c1c1 !important; }
    .resp-tab-content { background-color: #fff; }

    /* tab-style6 */
    .tab-style6 .tab-box { display: inline-block; vertical-align: top; }
    .tab-style6 h2.resp-accordion i { margin-right: 15px; }
    .tab-style6 .tab-box h6 { margin-bottom: 2px; font-size: 14px; }
    .tab-style6 .tab-box span { font-size: 12px; }
    .tab-style6 .resp-tab-active h6 { color: #fff;  }
} 

/* ===================================
    Pricing table
====================================== */

.price-table{ border-radius:10px; overflow:hidden; background:#fff; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); position:relative; }
.price-table-header{ background:#86bc42; text-align:center; padding:30px; }
.price-table-recommended{ position:relative; z-index:9999; transform:scale(1.09); }
.pricing-list > i{ background:#ffffff; border:1px solid #dddddd; border-radius:100%; color:#86bc42; display:inline-block; font-size:30px; height:80px; line-height:78px; margin-bottom:15px; width:80px; }

.price-table-header h5{ border-bottom:1px solid rgba(255, 255, 255, 0.2); color:#ffffff; display:block; font-size:20px; font-weight:500; margin-bottom:30px; padding:5px 15px 15px; position:relative; text-align:center; text-transform:uppercase; }
.price-table-header h4{ color:#fff; font-size:60px; margin:15px 0 10px; }
.price-table-header h4 sup{ font-size:35px; margin:0 5px; }
.price-table-header span{ color:#fff; font-weight:400; font-size:16px; }

.price-table-content{ padding:20px; }
.price-table-content ul{ padding-left:0; padding-bottom:0; }
.price-table-content ul li{ display:block; list-style-type:none; color:#232323; font-size:14px; font-weight:500; padding:15px; }
.price-table-content ul li:before{ content:'\f00c'; font-weight: 700; font-family: Font Awesome\ 5 Free; display:inline-block; color:#86bc42; margin-right:12px; }
.price-table-content ul li:nth-child(2){ background:#eee; }
.price-table-content a{ display:inline-block; border:2px solid #86bc42; background:#fff; font-family:"Montserrat",sans-serif; color:#232323; font-size:16px; font-weight:500; border-radius:10px; padding:12px 15px; width:100%; margin-top:15px; }
.price-table-content a:hover{ background:#86bc42; color:#fff; }

.pricing-list{ text-align:center; border-radius:10px; background:#fff; border:2px solid #ececec; padding:30px 40px; -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-transition-duration:.5s; transition-duration:.5s; }
.pricing-list.highlight{ background:#86bc42; border-color: transparent; }
.pricing-list.highlight .pricing-list-button{ background:#fff; margin:20px 15px 0; border:2px solid #fff; border-radius:5px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.pricing-list.highlight .pricing-list-button:hover{ background:#86bc42; }
.pricing-list.highlight .pricing-list-button a{ display:block; color:#86bc42; font-size:16px; font-weight:500; padding:8px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.pricing-list.highlight .pricing-list-button:hover a{ color:#fff; }
.pricing-list h4{ color:#232323; font-size:25px; font-weight:500; letter-spacing:2px; margin-bottom:20px; }
.pricing-list h2{ display:inline-block; color:#232323; letter-spacing:2px; }
.pricing-list h2 sup{ color:#232323; font-size:30px; font-weight:500; vertical-align: sub; }
.pricing-list span{ display:inline-block; color:#86bc42; font-weight:500; }
.pricing-list ul{ margin-top:20px }
.pricing-list ul li{ color:#666; font-size:15px; font-weight:400; padding:7px; }
.pricing-list ul li:after{ content:''; display:block; width:240px; max-width:100%; height:1px; background:#ececec; margin:10px auto 0; }
.pricing-list-button{ background:#86bc42; margin:20px 15px 0; border:2px solid #86bc42; border-radius:5px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.pricing-list-button:hover{ background:#fff; }
.pricing-list-button a{ display:block; color:#fff; font-size:16px; font-weight:500; padding:8px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.pricing-list-button:hover a{ color:#86bc42; }

.price-table-style2 .price-table-header{ background:#000; }
.price-table-style2 .price-table-content a{ border-color:#232323; }
.price-table-style2 .price-table-content a:hover{ background:#232323; }
.price-table-style2 .price-table-content ul li:before{ color:#232323; }

@media (max-width:767px){
    .price-table-recommended{ position:relative; z-index:9999; transform:scale(1); }
 }

/* ===================================
    Shop
====================================== */

/* home shop */
.shop-category { -webkit-box-shadow: 1px 5px 22px -3px rgba(0,0,0,0.1); -moz-box-shadow: 1px 5px 22px -3px rgba(0,0,0,0.1); box-shadow: 1px 5px 22px -3px rgba(0,0,0,0.1); }
.shop-category li { border-color: #eaeaea; padding: 0 0 10px 40px; }
.shop-category.list-style-5 li:before { left: 15px; }
.label-offer { position: absolute; left: 0; top: 0; height: 25px; line-height: 25px; display: inline-block;  padding: 0px 12px;   color: #fff; text-transform: uppercase; font-weight: 600; font-size: 12px; z-index: 1; }

.product-grid .border { float: left; width: 100%; position: relative; }
.product-grid .product-info{ padding: 15px; float: left; width: 100%; background:#fafafa; border-top: 1px solid #eeeeee; text-align: center; font-size: 18px; }
.product-grid .product-info > a { margin-bottom: 5px; display: inline-block; font-size: 14px; }
.product-grid .price { font-weight: 600; }
.product-grid .product-block:hover > img  {  opacity: 0.65;  }
.product-grid .buttons { float: left; width: 100%; }
.product-grid .buttons a{ width: 50%; float: left; text-align: center; padding: 8px 0; }

.control-top .owl-nav{ margin: 0; position: absolute; right: -2px; top: -73px; }
.control-top.owl-theme .owl-nav [class*='owl-'] { background:#fff !important; padding: 0 7px; width: 30px; height: 30px; margin: 0 2px; border-radius: 0; border:1px solid #d5d5d5;  }
.control-top.owl-theme .owl-nav i { color: #6f6f6f; padding-top: 4px; }

.v-middle-content{ padding: 50px 8.5%; padding-top: 0; padding-bottom: 0; position: absolute; left: 0; top: 50%; transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); max-width: 65%; display: block; }
.banner-content:before{ position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.65); content: ""; opacity: 0; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
.banner-content:hover:before{ background: rgba(0,0,0,0.65); opacity: 1; }
.banner-content{ letter-spacing: 2px; }
.banner-content .subtitle{ line-height: 22px; text-transform: uppercase; margin-bottom: 5px; font-weight: 600; }
.banner-content .title{ font-size: 44px; line-height: 44px; text-transform: uppercase; font-weight: 500; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
.banner-content:hover .title{ margin-left: 20px; }
.banner-content .view{ font-style: italic; font-size: 12px; line-height: 16px; text-transform: uppercase; border-bottom: 1px solid #373737; display: inline-block; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
.banner-content:hover .view{ margin-left: 20px; border-bottom: 1px solid #fff; }

.feature-product-grid .border { float: left; width: 100%; background: #fafafa; }
.feature-product-grid .product-block{ width: 90px;  height: 90px;  border: 1px solid #eee;  border-width: 0 1px 0 0; float: left; }
.feature-product-grid .product-info { max-width: 61%; padding: 8px 15px; float: left;  }

/* shop product detail*/
.product-detail .label-sale{ margin-left: 5px; vertical-align: top; padding: 2px 7px; }
.product-detail .offer-price { text-decoration: line-through; color: red; }
.product-rating .fas:hover { color: #232323; }

 /* shop product cart*/
.shop-cart-table { width: 100%; overflow-x: auto; }
.shop-cart-table .shop-cart td{ line-height: 26px; vertical-align: middle; padding: 16px 8px; }
.table.cart-sub-total, .table.cart-sub-total td, .table.cart-sub-total th{ border-color: transparent; vertical-align: middle; padding: 10px 30px; }
.table.cart-sub-total td{ padding-right: 0; }
.shop-cart-table input[type="text"]{ height: 35px; width: 50px; margin: 0; }

 /* shop product grid and list*/
.product-list .product-block:hover > img  {  opacity: 0.65;  }
.product-list .buttons { float: left; width: 100%; }
.product-list .buttons a{ float: left; text-align: center; padding: 8px 23px; }
.product-list .buttons a:hover { opacity: 0.8;}

@media (max-width:991px){
    .categories .collapse-sm:after { font-family: Font Awesome\ 5 Free; content: "\f105"; float: right; }
    .categories .collapse-sm.current:after { font-family: Font Awesome\ 5 Free; content: "\f107"; float: right; }
    .categories .shop-category { display: none; }
}

/* ===================================
    Blog block
====================================== */

.latest_blog_inner{ overflow:hidden; }
.image_blog{ position:relative; }
.image_blog > a:after{ background:#86bc42 none repeat scroll 0 0; content:""; height:100%; left:0; opacity:0; position:absolute; top:0; transition:all 0.3s ease 0s; width:100%; }
.image_blog > a:hover:after{ opacity:0.6; }
.image_blog a img{ max-width:100%; }
.image_blog .img_blog_text{ bottom:35px; position:absolute; padding:0 20px; }
.image_blog .img_blog_text h5{ color:#ffffff; font-size:13px; letter-spacing:0.26px; padding-bottom:10px; margin-bottom:0; line-height:normal; }
.image_blog .img_blog_text h3{ color:#ffffff; font-size:18px; font-weight:600; margin-bottom:0; line-height:24px; }
.text_blog{ background:#fafafa none repeat scroll 0 0; padding:35px 40px 25px; transition:all 0.3s linear 0s; }
.text_blog:hover{ background:#86bc42; }
.text_blog h5{ color:#616161; font-size:13px; letter-spacing:0.26px; padding-bottom:18px; margin:0; line-height:normal; transition:all 300ms linear 0s; }
.text_blog:hover h5 { color:#ffffff; }
.text_blog h3{ color:#282828; font-size:18px; font-weight:600; margin-bottom:0; line-height:24px; }
.text_blog:hover h3, .text_blog:hover h3 a{ color:#ffffff; }
.text_blog p{ margin:0; padding:20px 0; transition:all 300ms linear 0s; }
.text_blog:hover p { color:#ffffff; }
.text_blog .blog_user{ border-top:2px solid #ececec; padding-top:15px; }
.text_blog .blog_user h4{ color:#616161; font-size:15px; margin-bottom:0; line-height:normal; letter-spacing:0.3px; transition:all 300ms linear 0s; }
.text_blog .blog_user h4 a{ color:#86bc42; transition:all 300ms linear 0s; }
.text_blog:hover .blog_user h4, .text_blog:hover .blog_user h4 a { color:#ffffff;  }

@media (max-width:992px){
    .text_blog{ padding:16px 20px; }
 }

/* ===================================
    Blog
====================================== */

.blog-grid{ position:relative; background:#fff; border:1px solid #eee; border-radius:5px; overflow:hidden; -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.blog-grid img{ width:100%; }

/* blog style1 */
.blog-style1 .item{ box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15); background: #fff; height: 100%; }
.blog-style1 .item .post-img{ position: relative; overflow: hidden; }
.blog-style1 .item .post-img:hover img{ -webkit-filter: grayscale(100%); filter: grayscale(100%); }
.blog-style1 .item .post-img img{ width: 100%; -webkit-filter: none; filter: none; transition: 0.3s; }
.blog-style1 .item .content{ padding: 30px; text-align: left; }
.blog-style1 .item .content .tag{ font-size: 12px; margin-bottom: 5px; letter-spacing: 1px; }
.blog-style1 .item .content .read-more{ margin-top: 20px; font-weight: 600; }
.blog-style1 .item .content .read-more:hover{ text-decoration: underline; }
.blog-style1 .item .content p{ margin-bottom: 10px; line-height: normal; }
.blog-style1 .item .content h5{ font-size: 16px; line-height: normal; text-transform: inherit; }

.data-box-grid{ display: inline-block; }
.data-box-grid:before {  content: "/"; padding-right: 5px; }
.data-box-grid h5{ font-size:12px; line-height: normal; letter-spacing:2px; display:inline-block; vertical-align:middle; margin-bottom:0; color:#999; }
.blog-grid .data-box-grid p{ font-size:12px; margin-bottom:0; padding:0; border:none; color:#999; display:inline-block; vertical-align:middle; text-transform:uppercase; }
.blog-grid-text{ padding:25px; position:relative; }
.blog-grid-text > span{ color:#86bc42; font-size:13px; padding-right: 5px;  }
.blog-grid-text h4{ font-size:16px; line-height:normal; margin-bottom:3px; }
.blog-grid-text ul li{ display:inline-block; color:#999; font-size:14px; font-weight:500; margin:10px 25px 10px 0; }
.blog-grid-text ul li i{ color:#86bc42; font-size:14px; font-weight:500; margin-right:10px; }
.blog-grid-text p{ color:#999; font-size:15px; font-weight:400; line-height:170%; padding:15px 0 0 0;}

.owl-carousel .blog-grid-simple{ margin:10px 15px 25px; }
.blog-grid-simple-date{ width:100%; }
.blog-grid-simple{ position:relative; background:#fff; border-radius:5px; border:1px solid #ececec; box-shadow:0 10px 15px 0 rgba(50, 50, 50, .16);  -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16);  padding:30px; }
.blog-grid-simple h4,.blog-grid-simple h4 a{ color:#232323; line-height:normal; font-size:21px; font-weight:500; margin-bottom:15px; }
.blog-grid-simple h4 a:hover{ color:#86bc42; }
.blog-grid-simple p{ color:#aaa; font-size:15px; font-weight:400; }
.blog-grid-simple-date i{ color:#888; font-size:20px; }
.blog-grid-simple-date h5{ color:#888; font-size:13px; margin-top:3px; margin-bottom:0; display:inline-block; margin-left:8px; line-height:normal; }
.blog-grid-simple-content{ border-top:1px solid #ececec; padding-top:20px; margin-top:15px; padding-left:15px; padding-right:15px; }
.blog-grid-simple-content a{ display:inline-block; color:#86bc42; font-weight:600; font-size:14px; margin-top:2px }
.blog-grid-simple-content a:after{ content:'\f105'; font-size:14px; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#86bc42; margin-left:10px; -webkit-transition-duration:.3s; transition-duration:.3s; }

.blog-list-left-heading:after,.blog-title-box:after{ content:''; height:2px }
.blog-grid-simple-content a:hover,.blog-grid-simple-content a:hover:after{ color:#232323; }
.blog-list-simple{ margin:0 0 30px 0; border-bottom:1px solid #e8e8e8; padding-bottom:30px; }
.blog-list-simple:last-child{ border-bottom:0; padding-bottom:0; margin-bottom:0; }
.data-box-simple{ display:block; float:right; position:absolute; background:#86bc42; left:20px; top:5px; padding:5px; min-width:60px; text-align:center; }
.blog-list-simple-text > span{ color:#86bc42; font-size:13px; font-weight:500; letter-spacing:2px; text-transform:uppercase; }
.data-box-simple h4{ color:#fff; font-size:30px; font-weight:400; letter-spacing:2px; border-bottom:1px solid #fff; margin-bottom:5px; line-height:normal; }
.data-box-simple p{ color:#fff; font-size:14px; font-weight:400; margin-bottom:0; }

.blog-list-simple-text p{ border-top:1px solid #ececec; color:#999999; font-size:15px; font-weight:400; line-height:170%; margin-bottom:0; padding:15px 0; }
.blog-list-simple-text .meta{ margin:0 0 5px 0; }
.blog-list-simple-text li{ display:inline-block; font-size:12px; color:#777; margin:5px; }
.blog-list-simple-text{ color:#999999; }
.blog-list-simple-text h4{ color:#232323; font-size:20px; font-weight:500; margin:5px 0; line-height:normal; }
.blog-list-simple-text ul{ margin:10px 0; }
.blog-list-simple-text ul li{ color:#999; font-size:14px; font-weight:500; padding:3px 0; }
.blog-list-simple-text ul li i{ color:#86bc42; margin-right:5px; font-size:14px; }

.blogs .post{ margin-bottom:30px; }
.blogs .post .content{ padding:30px; background:#fafafa; }
.blogs .post .content .post-title h5{ font-size:26px; line-height:normal; font-weight:500; margin-bottom:0; }
.blogs .post .content .post-title h5 a{ color:#232323; }
.blogs .post .content .post-title h5 a:hover{ color:#F24259; }
.blogs .post .content .meta{ margin-bottom:15px; }
.blogs .post .content .meta li{ display:inline-block; font-size:12px; color:#777; margin:5px; }
.blogs .post .content .special{ padding:15px; margin:30px 0; border-left:2px solid #111; background:#f7f7f7; font-size:16px; }
.blogs .post .content .btn{ border-color:#6f6f6f; color:#6f6f6f; min-width:auto; padding:6px 20px; font-size:12px; }
.blogs .post .content .btn:hover{ color:#fff; border-color:#F24259; }
.blogs .post .share-post{ margin-top:30px; padding-top:20px; border-top:1px dashed #ddd; text-align:left; }
.blogs .post .share-post span{ font-weight:700; }
.blogs .post .share-post ul{ float:right; }
.blogs .post .share-post ul li{ display:inline-block; margin:0 10px; }
.blogs .post .post-img img{ width:100%; }

.blogs .posts .title-g h3{ font-weight:700; font-size:25px; margin-bottom:10px; }
.blogs .comments-area .comment-box{ padding-bottom:30px; margin-bottom:50px; border-bottom:1px solid #eee; }
.blogs .comments-area .comment-box:nth-child(odd){ margin-left:80px; }
.blogs .comments-area .comment-box:last-child{ margin-bottom:30px; }
.blogs .comment-box .author-thumb{ width:80px; float:left; }
.blogs .comment-box .comment-info{ margin-left:100px; }
.blogs .comment-box .comment-info h6{ font-size:14px; font-weight:600; margin-bottom:10px; }
.blogs .comment-box .comment-info .reply{ margin-top:10px; font-weight:600; }
.blogs .comment-box .comment-info .reply i{ padding-right:5px; font-size:12px; }

.pagination{ border-radius:0; padding:0; margin:0; }
.pagination ul{ display:inline-block; *display:inline; *zoom:1; margin:0 auto; padding:0; }
.pagination li{ display:inline; }
.pagination a{ float:left; padding:0 18px; line-height:40px; text-decoration:none; border:1px solid #dbdbdb; border-left-width:0; background:#fff; }
.pagination a:hover{ background-color:#232323; color:#fff; }
.pagination .active a{ background-color:#f7f7f7; color:#999; cursor:default; }
.pagination .disabled span{ color:#999; background-color:transparent; cursor:default; }
.pagination .disabled a{ color:#999; background-color:transparent; cursor:default; }
.pagination .disabled a:hover{ color:#999; background-color:transparent; cursor:default; }
.pagination li:first-child a{ border-left-width:1px; }

.blockquote{ background:#f1f1f1; border-left:4px solid #86bc42; font-size:16px; font-weight:500; margin:25px 0; padding:20px 30px 30px; }
.blockquote p{ font-size:15px; font-weight:500; line-height:190%; color:#232323; letter-spacing:.5px; }
.blockquote h4{ font-size:20px; font-weight:400; color:#232323; letter-spacing:.5px; }

.side-bar .widget{ margin-bottom:30px; }
.side-bar .widget:last-child{ margin-bottom:0; }
.side-bar .widget .widget-title h6{ position:relative; padding-bottom:5px; font-size:14px; font-weight:600; letter-spacing:1px; margin-bottom:20px; }
.side-bar .widget .widget-title h6:after{ content:''; width:30px; height:1px; background:#86bc42; position:absolute; bottom:0; left:0; }
.side-bar .widget li{ position:relative; padding:0 0 0 12px; margin:0 0 9px 0; color:#6f6f6f; }
.side-bar .widget li:last-child{ margin:0; }
.side-bar .widget li:after{ content:""; width:5px; height:1px; background-color:#6f6f6f; position:absolute; top:12px; left:0; }
.side-bar .widget .social-listing{ margin-bottom:0; }
.side-bar .widget .social-listing li{ list-style:none; display:inline-block; }
.side-bar .widget .social-listing li:after{ background:none; }
.side-bar .widget .social-listing li:first-child{ padding-left:0; }

.side-bar .search form input{ width:calc(100% - 50px); height:50px; padding:0 10px; margin:0; border:1px solid #d1d1d1; background:#f7f7f7; }
.side-bar .search form button{ width:50px; height:50px; line-height:50px; background:#1c1c1c; color:#fff; border:0; float:right; }

@media (max-width:767px){
    .blog-grid-simple-content a:after{ display:none; }
 }

/* ===================================
    Count and error box
====================================== */

/* count back box */
.count-back-box{ width:100%; text-align:center; background-position:center; background-size:cover; position: relative; z-index: 1; }
.count-back-box h1{ color:#232323; font-size:40px; font-weight:500; }

/* error box */
.error-box{ width:100%; height:100%; margin:0 auto; text-align:center; background-position:center; background-size:cover; position: relative; z-index: 1; }
.error-box h2{ color:rgba(204, 204, 204, .2); font-size:250px; font-weight:600; letter-spacing:10px; }
.error-box-text h1{ font-size:200px; color:#86bc42; line-height:1; }
.error-box-text h3{ font-size:40px; color:#232323; line-height:normal; }
.error-box-text h4{ font-size:20px; color:#333; margin-top:20px; }

@media (max-width:767px){ 
    .error-box{ padding:30px; }
    .back-box{ display:none; }
    .error-box-text h1{ font-size:100px; }
}

/* count down */
ul.countdown li{ border-right:1px solid #626262; display:inline-block; padding:0 30px; text-align:center; }
ul.countdown li:last-child{ border:medium none; padding-right:0; }
ul.countdown li span{ font-size:36px; font-weight:700; line-height:normal; position:relative; }
ul.countdown li span::before{ content:""; height:1px; position:absolute; width:100%; }
ul.countdown li p.timeRefDays, ul.countdown li p.timeRefHours, ul.countdown li p.timeRefMinutes, ul.countdown li p.timeRefSeconds{ font-size:12px; font-weight: 500; margin:0; padding:0; text-transform:uppercase; }

@media (max-width:767px){
    ul.countdown li{ padding:0 8px; }
    .social-links li{ margin-right:15px; }
    ul.countdown li span{ font-size:22px; }
}

countdown-style2
/* ===================================
    Contact info
====================================== */

/* social links */
.social-icon-style1{ margin-bottom:0; }
.social-icon-style1 li{ text-align:center; margin-right:4px; display:inline-block; }
.social-icon-style1 li a{ background:#86bc42; border:1px solid #86bc42; color:#fff; border-radius:35px; height:35px; width:35px; line-height:35px; display:inline-block; }
.social-icon-style1 li a:hover{ background:#fff; color:#86bc42; }

.social-icon-style2{ margin-bottom:0; }
.social-icon-style2 li{ text-align:center; margin-right:5px; display:inline-block; }
.social-icon-style2 li:last-child{ margin-right:0; }
.social-icon-style2 li a{ background:#f9f9f9; border:1px solid #86bc42; color:#86bc42; border-radius:35px; height:35px; width:35px; line-height:35px; display:inline-block; }
.social-icon-style2 li a:hover{ background:#86bc42; color:#fff; }

.social-icon-style3{ margin-bottom:0; }
.social-icon-style3 li{ text-align:center; margin-right:10px; display:inline-block; }
.social-icon-style3 li a{ color:#6f6f6f; display:inline-block; font-size:16px; }
.social-icon-style3 li a:hover{ color:#86bc42; }

.social-icon-style4{ padding:0; float:right; margin:0; }
.social-icon-style4 li{ font-size:14px; list-style-type:none; float:left; width:35px; text-align:center; margin:0; padding:13px 0; }
.social-icon-style4 li a{ opacity:.7; color:#fff; -webkit-transition-duration:.3s; transition-duration:.3s; }

.social-icon-style5 li{ display:inline-block; margin-right:30px; }
.social-icon-style5 li:last-child{ margin-right:0; }
.social-icon-style5 li a{ font-size:22px; }
.social-icon-style5 li a:hover{ color:#fff; }

.social-icon-style6{ margin-bottom:0; }
.social-icon-style6 li{ text-align:center; margin-right:5px; display:inline-block; }
.social-icon-style6 li:last-child{ margin-right:0; }
.social-icon-style6 li a{border:1px solid #6f6f6f; color:#6f6f6f; border-radius:35px; height:35px; width:35px; line-height:35px; display:inline-block; }
.social-icon-style6 li a:hover{ background:#232323; color:#fff; }

/* contact detail */
#map{ height:400px; width:100%; }
.contact-info-section{ border-bottom:1px solid #ececec; padding:25px 0; margin-bottom:0; -webkit-transition-duration:.5s; transition-duration:.5s; }
.contact-info-section i{ width:25px; height:25px; padding-top:7px; border-radius:50%; background:#86bc42; color:#fff; font-size:12px; margin-right:5px; line-height:11px; }
.contact-info-section h4{ color:#232323; font-size:18px; font-weight:500; margin-bottom:15px; line-height:normal; }
.contact-info-section p{ color:#999; font-size:15px; font-weight:400; margin:0; }
.contact-box{ text-align:center; background:#fff; border:1px solid #ececec; border-radius:2px; -webkit-box-shadow:0 20px 80px rgba(0, 0, 0, .12); box-shadow:0 20px 80px rgba(0, 0, 0, .12); margin:10px 0; padding:20px 15px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.contact-box i{ background:#86bc42; color:#fff; font-size:35px; margin:10px 0 20px; height:75px; width:75px; line-height:75px; border-radius:50%; }
.contact-box h4{ color:#232323; font-size:18px; font-weight:500; margin:0 0 5px; line-height:normal; }
.contact-box span{ color:#999; font-size:14px; }

/* newsletter */
.newsletter-form input{ display:inline-block; background:#fff; font-size:14px; margin-bottom:0; font-weight:500; outline:0; border:2px solid #eee; min-width:270px; max-width:100%; -moz-border-radius-topleft:30px; -webkit-border-top-left-radius:30px; border-top-left-radius:30px; -moz-border-radius-bottomleft:30px; -webkit-border-bottom-left-radius:30px; border-bottom-left-radius:30px; padding:12px 20px; width:auto; }
.newsletter-form button{ display:inline-block; background:#86bc42; cursor:pointer; color:#fff; border:none; font-size:14px; font-weight:500; -moz-border-radius-topright:30px; -webkit-border-top-right-radius:30px; border-top-right-radius:30px; -moz-border-radius-bottomright:30px; -webkit-border-bottom-right-radius:30px; border-bottom-right-radius:30px; padding:14px 20px; }
.newsletter-form button:hover{ background:#232323; }

@media (max-width: 767px) {
    .newsletter-form button { margin-top: 15px; border-radius: 30px; width: 100%; display: block; }
    .newsletter-form input { border-radius: 30px; width: 100%; display: block; }
}

/* ===================================
    Footer
====================================== */

footer{ padding:50px 0 0; background:#1f1f1f; color:#939393; }
footer p{ margin-bottom:0; }
footer h3{ font-size:18px; font-weight:500; margin-bottom:30px; line-height:1; position:relative; }
footer h3:after{ content:''; display:block; width:70px; height:2px; background:#86bc42; margin-top:12px; }

.footer-social-icons ul{ margin-bottom:0; }
.footer-social-icons ul li{ display:inline-block; border:1px solid #fff; border-radius:50%; color:#fff; margin-right:10px; margin-bottom:5px; -webkit-transition-duration:.3s; transition-duration:.3s }
.footer-social-icons ul li a{ color:#939393; display:block; font-size:15px; height:35px; line-height:34px; text-align:center; width:35px; }
.footer-social-icons ul li:hover { background:#86bc42; border-color:#86bc42; }
.footer-social-icons ul li:hover i { color: #fff; }

/*small*/
.footer-social-icons.small ul li { margin-bottom: 0; }
.footer-social-icons.small ul li a { font-size: 12px; height: 25px; line-height: 26px; width: 25px; }

.footer-social-icons2 ul{ margin-bottom:0; }
.footer-social-icons2 ul li{ display:inline-block; border:1px solid #6f6f6f; border-radius:50%; color:#6f6f6f; margin-right:10px; margin-bottom:0; -webkit-transition-duration:.3s; transition-duration:.3s }
.footer-social-icons2 ul li a{ color:#939393; display:block; font-size:15px; height:35px; line-height:34px; text-align:center; width:35px; }
.footer-social-icons2 ul li:hover { background:#86bc42; border-color:#86bc42; }
.footer-social-icons2 ul li:hover i { color: #fff; }

.footer-subscribe{ margin-top:20px; margin-bottom: 0; position:relative;  }
.footer-subscribe input, .footer-subscribe input:focus{ background:#fff; border-radius:6px; font-size: 14px; border:0px solid; height:40px; outline:none; box-shadow:none; padding:6px 95px 6px 12px; margin-bottom: 0;  }
.footer-subscribe .butn{ position:absolute; right:0; top:0; height:100%; -webkit-border-radius: 0; -webkit-border-top-right-radius: 4px; -webkit-border-bottom-right-radius: 4px; -moz-border-radius: 0; -moz-border-radius-topright: 4px; -moz-border-radius-bottomright: 4px;
border-radius: 0; border-top-right-radius: 4px; border-bottom-right-radius: 4px; color: #fff; line-height: 15px; }
.footer-subscribe .butn:after { -webkit-border-radius: 0; -webkit-border-top-right-radius: 3px; -webkit-border-bottom-right-radius: 3px; -moz-border-radius: 0; -moz-border-radius-topright: 3px; -moz-border-radius-bottomright: 3px;
border-radius: 0; border-top-right-radius: 3px; border-bottom-right-radius: 3px; background: #e5e5e5; }
.footer-subscribe .butn:hover { -webkit-border-radius: 0; -webkit-border-top-right-radius: 3px; -webkit-border-bottom-right-radius: 3px; -moz-border-radius: 0; -moz-border-radius-topright: 3px; -moz-border-radius-bottomright: 3px;
border-radius: 0; border-top-right-radius: 3px; border-bottom-right-radius: 3px;}
.footer-subscribe .butn:hover:after { -webkit-border-radius: 0; -webkit-border-top-right-radius: 3px; -webkit-border-bottom-right-radius: 3px; -moz-border-radius: 0; -moz-border-radius-topright: 3px; -moz-border-radius-bottomright: 3px;
border-radius: 0; border-top-right-radius: 3px; border-bottom-right-radius: 3px;}
.footer-subscribe .butn span { color: #fff; }
.footer-subscribe .butn:hover span { color: #232323; }
.footer-subscribe .butn.theme:after {  background: #000; }
.footer-subscribe .butn.theme:hover span { color: #fff; }
.footer-subscribe .butn.white{ color: #232323; }
.footer-subscribe .butn.white:after { background: #232323; }
.footer-subscribe .butn.theme.white-hover:hover { background:#fff; }
.footer-subscribe .butn.theme.white-hover:hover span { color: #232323; }
.footer-subscribe .butn.theme.white-hover:after { background:#fff; }
.footer-subscribe .butn.theme.grey-hover:hover { background:#e5e5e5; }
.footer-subscribe .butn.theme.grey-hover:hover span { color: #232323; }
.footer-subscribe .butn.theme.grey-hover:after { background:#e5e5e5; }

.footer-list{ margin:0; }
.footer-list li{ list-style-type:none; color:#939393; padding:6px 0; }
.footer-list li:first-child{ padding-top:0; }
.footer-list li a:before{ content:'\f105'; font-weight: 700; vertical-align: bottom; font-family: Font Awesome\ 5 Free; color:#939393; padding-right:8px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.footer-list li a{ font-size:15px; font-weight:400; color:#939393; }
.footer-list li a:hover,.footer-list li a:hover:before{ color:#fff; }

.footer-list-style2{ margin:0; }
.footer-list-style2 li{ list-style-type:none; color:#fff; padding:6px 0; }
.footer-list-style2 li:first-child{ padding-top:0; }
.footer-list-style2 li a:before{ content:'\f105'; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#fff; padding-right:8px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.footer-list-style2 li a{ font-size:15px; font-weight:400; color:#fff; }
.footer-list-style2 li a:hover,.footer-list-style2 li a:hover:before{ opacity: 0.65; }

.footer-list-style3{ margin:0; }
.footer-list-style3 li{ list-style-type:none; color:#6f6f6f; padding:6px 0; }
.footer-list-style3 li:first-child{ padding-top:0; }
.footer-list-style3 li a:before{ content:'\f105'; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#6f6f6f; padding-right:8px; -webkit-transition-duration:.3s; transition-duration:.3s; }
.footer-list-style3 li a{ font-size:15px; font-weight:400; color:#6f6f6f; }
.footer-list-style3 li a:hover,.footer-list-style2 li a:hover:before{ color: #232323; }

.footer-recent-post{ margin:15px 0; }
.footer-recent-post-thumb{ float:left; width:25%; }
.footer-recent-post-content{ float:left; width:75%; padding-left:10px; }
.footer-recent-post-content a{ display:block; color:#939393; font-size:15px; font-weight:400; line-height:normal; margin-top:-5px; }
.footer-recent-post-content a:hover{ color:#fff; }
.footer-recent-post-content span{ font-size:13px; font-weight:300; color: #fff; }

.footer-bar{ padding-top:20px; padding-bottom:20px; margin-top:50px; text-align:center; background:#191919; color: #939393; }
.footer-bar span{ color:#86bc42; font-size:15px; font-weight:400; }

/*footer-top-bar*/
.footer-top-bar { background: #191919; border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

/*footer style2*/
footer h3.footer-title-style2 { margin-bottom: 28px; }
h3.footer-title-style2:after { width: 100%; height: 1px; background: #363637; margin-top: 15px; }

/*footer style3*/
.footer-title-style3{ position: relative; padding-bottom: 20px; margin-bottom: 28px; color: #fff; }
.footer-title-style3:after{ content: ''; width: 60px; height: 2px; background: #86bc42; position: absolute; bottom: 0; left: 20px; }
.footer-title-style3:before{ content: ''; width: 100%; height: 1px; background: rgba(255, 255, 255, 0.2); position: absolute; bottom: 0; left: 0; right: 0; }

/*footer style4*/
.footer-title-style4:after { margin-left: auto; margin-right: auto; }
h3.footer-title-style4 { margin-bottom: 20px; }

/*footer style5*/
h3.footer-title-style5 { color: #fff; }
h3.footer-title-style5:after { background: #fff; }

/*footer style6*/
footer h3.footer-title-style6 { margin-bottom: 28px; color: #636363; }
h3.footer-title-style6:after { width: 100%; height: 1px; background: #d0d0d0; margin-top: 15px; }

/*footer style7*/
footer h3.footer-title-style7 { margin-bottom: 30px; padding-bottom: 20px; }
footer h3.footer-title-style7:after { content: ""; position: absolute; bottom: -10px; left: 18px;  height: 1px;     margin: 0 0 0; width: 70px; margin: 10px 0; background-color: #86bc42; }
footer h3.footer-title-style7:before { content: ""; position: absolute; bottom: -14px; left: 0; display: inline-block; width: 10px; height: 10px; border: 1px solid #86bc42; margin-bottom: 10px; }


@media (max-width: 767px) {
    footer { padding-top: 30px; }
    .footer-bar { margin-top: 30px;}
    .footer-subscribe .butn { line-height: 22px; }

    .footer-bar p { font-size: 13px; }
}