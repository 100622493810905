@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*-----------------------------------------------------------------------------------

    Theme Name: Fabrex - Business Multipurpose and Corporate Template
    Description: Business Multipurpose and Corporate Template
    Author: chitrakootweb
    Version: 2.0

-----------------------------------------------------------------------------------*/

/* ----------------------------------

Table of contents
        
    01. Google font
    02. Reset
    03. Typography
    04. Background color
    05. Custom
    06. Border
    07. Background image
    08. Margin
    09. Padding
    10. Display and float
    11. Position
    12. Width
    13. Responsive Media Query
    
---------------------------------- */

/* ===================================
    Google font
====================================== */

/* ===================================
    Reset
====================================== */

* { margin: 0; padding: 0; box-sizing: border-box; }
body{ font-family: 'Open Sans', sans-serif; -webkit-font-smoothing: antialiased;  font-smoothing: antialiased; font-size:14px; color:#6f6f6f; font-weight: 400;line-height: 24px; }
body, html { -moz-osx-font-smoothing: grayscale; }
a, a:active, a:focus { color: #6f6f6f; text-decoration: none; transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -ms-transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s;  }
a:hover, a:active{color: #232323; text-decoration: none;}
a:focus, a:active, button:focus, button:active,.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {outline: none;}
img {max-width:100%; height:auto; }
input, textarea, select{ border: 1px solid #d1d1d1; font-size: 14px;  padding: 8px 15px; width: 100%; margin: 0 0 15px 0; max-width: 100%; resize: none;}
input[type="button"], input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea, input[type="submit"] { -webkit-appearance: none; outline: none;}
input:focus, textarea:focus{ border-color: #585858; outline: none; }
input[type="button"]:focus{ outline: none; }
select::-ms-expand{ display:none;}
iframe { border: 0;}
p {margin: 0 0 20px; word-spacing: 1px;}
b, strong { font-weight: 600;}
.height-50 { height: 50%; }
.height-100 { height: 100%; }
.last-paragraph-no-margin p:last-of-type {margin-bottom:0;}
.alt-font strong {font-weight: 700 }
.white-space { white-space: normal; }
.word-wrap { word-wrap: break-word; }
.valign { display: flex; align-items: center; }
ul, ol, dl {list-style-position: outside;}
ul li { list-style: none; }
::-moz-selection { color: #000; background:#dbdbdb; }
::selection { color: #000; background:#dbdbdb; }
::-moz-selection { color:#000; background:#dbdbdb; }
::-webkit-input-placeholder { color: #6f6f6f; text-overflow: ellipsis;}
::-moz-placeholder { color: #6f6f6f; text-overflow: ellipsis; opacity:1;} 
:-ms-input-placeholder { color: #6f6f6f; text-overflow: ellipsis; opacity:1;}

/* ===================================
    Typography
====================================== */

/* font family */
.alt-font {font-family: 'Montserrat', sans-serif; font-weight: 500;}
.main-font {font-family: 'Open Sans', sans-serif;}

/* heading */
h1, h2, h3, h4, h5, h6 {margin:0 0 20px; padding:0; letter-spacing: 0; font-weight: 500; font-family: 'Montserrat', sans-serif; color: #232323; line-height: normal;}
h1 {font-size:42px;}
h2 {font-size:34px;}
h3 {font-size:32px;}
h4 {font-size:30px;}
h5 {font-size:26px;}
h6 {font-size:22px;}

/* font size */
.font-size11 {font-size:11px; line-height:14px;}
.font-size12 {font-size:12px; line-height:20px;}
.font-size13 {font-size:13px; line-height:normal;}
.font-size14 {font-size:14px; line-height:normal;}
.font-size15 {font-size:15px; line-height:normal;}
.font-size16 {font-size:16px; line-height:normal;}
.font-size17 {font-size:17px; line-height:normal;}
.font-size18 {font-size:18px; line-height:26px;}
.font-size19 {font-size:19px; line-height:normal;}
.font-size20 {font-size:20px; line-height:26px;}
.font-size22 {font-size:22px; line-height:normal;}
.font-size24 {font-size:24px; line-height:normal;}
.font-size26 {font-size:26px; line-height:normal;}
.font-size28 {font-size:28px; line-height:34px;}
.font-size30 {font-size:30px; line-height:normal;}
.font-size32 {font-size:32px; line-height:normal;}
.font-size34 {font-size:34px; line-height:normal;}
.font-size36 {font-size:36px; line-height:normal;}
.font-size38 {font-size:38px; line-height:normal;}
.font-size40 {font-size:40px; line-height:normal;}
.font-size42 {font-size:42px; line-height:normal;}
.font-size44 {font-size:44px; line-height:normal;}
.font-size46 {font-size:46px; line-height:normal;}
.font-size48 {font-size:48px; line-height:normal;}
.font-size50 {font-size:50px; line-height:normal;}
.font-size80 {font-size:80px; line-height:80px;}
.font-size100 {font-size: 100px; line-height:100px;}
.font-size120 {font-size: 120px; line-height:normal;}
.font-size130 {font-size: 130px; line-height:120px;}

/* text color */
.text-white {color:#fff;}
.text-black {color:#000;}
.text-extra-dark-gray {color:#232323;}
.text-dark-gray {color:#626262;}
.text-extra-medium-gray {color:#757575;}
.text-medium-gray {color:#939393;}
.text-extra-light-gray {color:#b7b7b7;}
.text-light-gray {color:#d6d5d5;}
.text-very-light-gray {color:#ededed;}
.text-default-color { color: #6f6f6f; }

/* letter spacing */
.no-letter-spacing {letter-spacing:0px;}
.letter-spacing-1 {letter-spacing:1px;}
.letter-spacing-2 {letter-spacing:2px;}
.letter-spacing-3 {letter-spacing:3px;}
.letter-spacing-4 {letter-spacing:4px;}
.letter-spacing-5 {letter-spacing:5px;}

/* font weight */
.font-weight-100 {font-weight:100;}
.font-weight-200 {font-weight:200;}
.font-weight-300 {font-weight:300;}
.font-weight-400 {font-weight:400;}
.font-weight-500 {font-weight:500;}
.font-weight-600 {font-weight:600;}
.font-weight-700 {font-weight:700;}
.font-weight-800 {font-weight:800;}
.font-weight-900 {font-weight:900;}

/* line height */
.line-height-normal {line-height:normal;}
.line-height-10 {line-height:10px;}
.line-height-13 {line-height:13px;}
.line-height-18 {line-height:18px;}
.line-height-20 {line-height:20px;}
.line-height-24 {line-height:24px;}
.line-height-22 {line-height:22px;}
.line-height-26 {line-height:26px;}
.line-height-28 {line-height:28px;}
.line-height-30 {line-height:30px;}
.line-height-35 {line-height:35px;}
.line-height-40 {line-height:40px;}
.line-height-45 {line-height:45px;}
.line-height-50 {line-height:50px;}
.line-height-55 {line-height:55px;}
.line-height-60 {line-height:60px;}
.line-height-65 {line-height:65px;}
.line-height-70 {line-height:70px;}
.line-height-75 {line-height:75px;}
.line-height-80 {line-height:80px;}
.line-height-85 {line-height:85px;}
.line-height-90 {line-height:90px;}
.line-height-95 {line-height:95px;}
.line-height-100 {line-height:100px;}
.line-height-110 {line-height:110px;}
.line-height-120 {line-height:120px;}

 /* ===================================
    Background color
====================================== */

.bg-transparent {background-color: transparent;}
.bg-white {background-color:#fff;}
.bg-black {background-color:#000;}
.bg-extra-dark-gray {background-color:#1c1c1c;}
.bg-dark-gray {background-color:#757575;}
.bg-extra-medium-gray {background-color:#939393;}
.bg-medium-gray {background-color:#dbdbdb;}
.bg-extra-light-gray {background-color:#e0e0e0;}
.bg-medium-light-gray {background-color:#ededed;}
.bg-light-gray {background-color:#f7f7f7;}
.bg-very-light-gray {background-color:#fafafa;}
.bg-grey {background-color:#f9f9f9;}
.bg-red {background-color:#ed1b24;}
.bg-transparent-white { background-color: rgba(255,255,255,0.3); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(37%, rgba(255,255,255,0)), color-stop(96%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1))); background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 ); }
.bg-transparent-black { background-color: rgba(0,0,0,0); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0)), color-stop(37%, rgba(0,0,0,0)), color-stop(96%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,0,1))); background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 ); }
.bg-white-opacity { background-color: rgba(255, 255, 255, 0.85); }
.bg-black-opacity { background-color: rgba(0, 0, 0, 0.85); }
.bg-black-opacity-light { background-color: rgba(0, 0, 0, 0.5); }

/* ===================================
    Custom
====================================== */

.center-col {float:none; margin-left:auto; margin-right:auto; }
section {padding: 90px 0; overflow: hidden; background: #fff;}

.big-input, .big-textarea, .big-select select { padding: 18px 25px; font-size: 14px; border-radius: 0;}
.medium-input, .medium-textarea, .medium-select select { padding: 12px 20px; font-size: 14px; line-height: normal; border-radius: 0;}
.small-input, .small-textarea { padding: 12px 15px; font-size: 11px; line-height: normal;}
.small-select select {padding: 15px 15px; line-height: normal;}
.medium-input-light, .medium-textarea-light, .medium-select-light select { padding: 12px 25px; font-size: 12px; line-height: normal;}
 
.required-error {border: 1px solid #ee2323 !important;}
.input-border-bottom.required-error {border-width: 0 !important; border-bottom-width: 1px !important; border-bottom-color: #ee2323 !important;}
#success-contact-form { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a;  padding: 2px 0; display: none; font-size: 11px;}

/* data overlay */
[data-overlay-dark],[data-overlay-light] { position: relative;}
[data-overlay-dark] .position-unset,[data-overlay-light] .position-unset { position: unset !important;}
[data-overlay-dark] .container,[data-overlay-light] .container {  position: relative;  z-index: 2;}
[data-overlay-dark]:before,[data-overlay-light]:before {content: ''; position: absolute; width: 100%; height: 100%; top: 0; left: 0;  z-index: 1;}
[data-overlay-dark]:before { background: #000; }
[data-overlay-light]:before { background: #fff;}
[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {  opacity: 0; }
[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {opacity: 0.1;}
[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before { opacity: 0.2; }
[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before { opacity: 0.3; }
[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before { opacity: 0.4; }
[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before { opacity: 0.5; }
[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before { opacity: 0.6; }
[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before { opacity: 0.7; }
[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before { opacity: 0.8; }
[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before { opacity: 0.9; }
[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {  opacity: 1; }

/* separator */
.separator-line-verticle-extra-small {width:1px; height: 8px;}
.separator-line-verticle-small-thick {width:17px; height: 3px;}
.separator-line-verticle-small {width:1px; height: 13px;}
.separator-line-verticle-small-thick2 {width:64px; height: 7px;}
.separator-line-verticle-large {width:1px; height:20px;}
.separator-line-verticle-extra-large {width:1px; height:30px;}
.separator-line-verticle-medium-thick-full {width:8px; height: 40px;}
.separator-line-verticle-large-thick {width:109px; height: 11px;}
.separator-line-horrizontal-medium-light {width:36px; height: 3px;}
.separator-line-horrizontal-medium-light2 {width:50px; height: 1px;}
.separator-line-horrizontal-medium-light3 {width:18%; height: 1px;}
.separator-line-horrizontal-medium-thick {width:50px; height: 5px;}
.separator-line-horrizontal-full {width:100%; height: 1px;}

/* list style 1 */
.list-style-none li {list-style: none;}

.list-style-1 li {padding: 5px 0}

/* list style 2 */
.list-style-2 {list-style: none; margin: 0; padding: 0;}
.list-style-2 li {position: relative; padding: 0 0 0 12px; margin: 0 0 9px 0;}
.list-style-2 li:before {content: ""; width: 5px; height: 1px; background-color: #232323; position: absolute; top: 12px; left: 0; }

/* list style 3 */
.list-style-3 { list-style: none; margin: 0; padding: 0;}
.list-style-3 li { position: relative; padding: 0 0 0 22px; margin: 0 0 15px 0;}
.list-style-3 li:before { content: ""; width: 10px; height: 1px; background-color: #232323; position: absolute; top: 13px; left: 0; }

/* list style 4 */
.list-style-4 { list-style: none; margin: 0; padding: 0;}
.list-style-4 li { position: relative; padding: 0 0 10px 22px; margin: 0 0 10px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-4 li:before { content: "\F152"; position: absolute; top: 0; left: 0; font-family: Font Awesome\ 5 Free; }
.list-style-4 li:last-child {border-bottom: 0;}
.list-style-4.list-style-color li {border-color: rgba(255,255,255,.15);}
.list-style-4.list-style-color li:before {color: #ff214f}

/* list style 5 */
.list-style-5 { list-style: none; margin: 0; padding: 0;}
.list-style-5 li { position: relative; padding: 0 0 10px 25px; margin: 0 0 10px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-5 li:before { content: "\F192"; position: absolute; top: 0; left: 0; font-family: Font Awesome\ 5 Free; color: #c0c0c0; }
.list-style-5 li:first-child { padding-top: 10px; }
.list-style-5 li:first-child:before { top: 11px; }
.list-style-5 li:last-child {border-bottom: 0; margin-bottom: 0;}
.list-style-5.list-style-color li {border-color: rgba(255,255,255,.15);}
.list-style-5.list-style-color li:before {color: #232323;}

/* list style 6 */
.list-style-6 {list-style: none; margin: 0; padding: 0;}
.list-style-6 li {position: relative; padding: 0 15px 8px 0; margin: 0 0 8px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-6 li span { position: absolute; top: 3px; right: 0;}
.list-style-6 li:last-child {border-bottom: none}
.list-style-6 li:before {content: "";}

/* list style 7 */
.list-style-7 {list-style: none; margin: 0; padding: 0;}
.list-style-7 li {padding: 0 0 13px 0; margin: 0 0 10px 0;}
.list-style-7 li:last-child {margin: 0; padding: 0;}

/* list style 8 */
.list-style-8 {list-style: none; margin: 0; padding: 0;}
.list-style-8 li {position: relative; padding:15px 0; margin: 0 0 4px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-8 li span {margin-bottom: 2px}
.list-style-8 li p {margin-bottom: 0}
.list-style-8 li:last-child {border-bottom: none}
.list-style-8 li:before {content: "";}

/* list style 9 */
.list-style-9 {list-style: none; margin: 0; padding: 0;}
.list-style-9 li {position: relative; padding:15px 0; margin: 0 0 4px 0; border-bottom: 1px solid #373737;}
.list-style-9 li span {margin-bottom: 2px}
.list-style-9 li p {margin-bottom: 0}
.list-style-9 li:last-child {border-bottom: none}
.list-style-9 li:before {content: "";}

/* list style 10 */
.list-style-10 {list-style: none; margin: 0; padding: 0;}
.list-style-10 li {padding:15px 0; margin: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-10 li i {float: left; margin-top: 5px}
.list-style-10 li span {padding-left: 15px; display: table}
.list-style-10 li:last-child {border-bottom: none}

/* list style 6 */
.list-style-11 {list-style: none; margin: 0; padding: 0;}
.list-style-11 li {position: relative; padding: 0 0 8px 0; margin: 0 0 8px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-11 li span { position: absolute; top: 3px; right: 0;}
.list-style-11 li:last-child {border-bottom: none}
.list-style-11 li:before {content: "";}

.list-style-12 { position: relative;}
.list-style-12 li { margin-bottom: 10px; padding-left: 35px; position: relative; }
.list-style-12 li i { left: 0; line-height: normal; position: absolute; top: 2px; }

/* divider */
.divider-full {width:100%; height: 1px; display: inline-block}

/* opacity */
.opacity-very-light {position: absolute; height: 100%; width: 100%; opacity: 0.2; top:0; left: 0;}
.opacity-light {position: absolute; height: 100%; width: 100%; opacity: 0.3; top:0; left: 0;}
.opacity-extra-medium {position: absolute; height: 100%; width: 100%; opacity: 0.5; top:0; left: 0;}
.opacity-medium {position: absolute; height: 100%; width: 100%; opacity: 0.75; top:0; left: 0;}
.opacity-full {position: absolute; height: 100%; width: 100%; opacity: 0.8; top:0; left: 0;}
.opacity-full-dark {position: absolute; height: 100%; width: 100%; opacity: 0.9; top:0; left: 0;}

.opacity1 {opacity:.1;}
.opacity2 {opacity:.2;}
.opacity3 {opacity:.3;}
.opacity4 {opacity:.4;}
.opacity5 {opacity:.5;}
.opacity6 {opacity:.6;}
.opacity7 {opacity:.7;}
.opacity8 {opacity:.8;}
.opacity9 {opacity:.9;}

/* box-shadow */
.btn-shadow { box-shadow: 0 0 6px rgba(0,0,0,.3);}
.box-shadow-light { box-shadow: 0 0 3px rgba(0,0,0,.1);}
.box-shadow { box-shadow: 0 0 3px rgba(0,0,0, .2);}
.box-shadow-dark { box-shadow: 0 0 4px rgba(0,0,0,.4);}
.box-shadow-large { box-shadow: 0 0 12px rgba(0,0,0,.1);}
.box-shadow-primary { box-shadow: 0 18px 38px rgba(0,0,0,.2);}
.box-shadow-none{ box-shadow: none !important; }

/* z-index */
.z-index-1111 {z-index:1111;}
.z-index-111 {z-index:111;}
.z-index-1 {z-index:1;}
.z-index-2 {z-index:2;}
.z-index-3 {z-index:3;}
.z-index-4 {z-index:4;}
.z-index-5 {z-index:5;}
.z-index-9 {z-index:9;}
.z-index-0 {z-index:0;}
.z-index-minus2 {z-index:-2;}

/* verticle align */
.vertical-align-middle {vertical-align: middle;}
.vertical-align-top {vertical-align: top;}
.vertical-align-bottom {vertical-align: bottom;}
.vertical-middle {-webkit-transform: translateY(-50%); transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); position: absolute; top: 50%; left:0; right: 0;}
.clear-both{ clear:both;}
.line-break { display:block;}
.no-transition *  { transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; -ms-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s; }
.absolute-middle-center {left: 50%; top: 50%; position: absolute; -webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); }

/* ===================================
    Border
====================================== */

.border-all {border: 1px solid #ededed}

/* boder width */
.border-width-1 {border-width:1px !important;}
.border-width-2 {border-width:2px !important;}
.border-width-3 {border-width:3px !important;}
.border-width-4 {border-width:4px !important;}
.border-width-5 {border-width:5px !important;}
.border-width-6 {border-width:6px !important;}
.border-width-7 {border-width:7px !important;}
.border-width-8 {border-width:8px !important;}
.border-width-9 {border-width:9px !important;}
.border-width-10 {border-width:10px !important;}
.border-width-20 {border-width:20px !important;}

/* boder align */
.border-top {border-top: 1px solid;}
.border-bottom {border-bottom: 1px solid;}
.border-left {border-left: 1px solid;}
.border-right {border-right: 1px solid;}
.border-lr {border-left: 1px solid; border-right: 1px solid;}
.border-tb {border-top: 1px solid; border-bottom: 1px solid;}

/* border color */
.border-color-white {border-color:#fff !important;}
.border-color-black {border-color:#000 !important;}
.border-color-extra-dark-gray {border-color:#232323 !important;}
.border-color-medium-dark-gray {border-color:#363636 !important;}
.border-color-dark-gray {border-color:#939393 !important;}
.border-color-extra-medium-gray {border-color:#dbdbdb !important;}
.border-color-medium-gray {border-color:#e4e4e4 !important;}
.border-color-extra-light-gray {border-color:#ededed !important;}
.border-color-light-gray {border-color:#f5f5f5 !important;}
.border-color-light-white { border-color:rgba(255, 255, 255, 0.2) !important; } 
.border-color-light-black { border-color:rgba(0, 0, 0, 0.1) !important; } 

/* boder style */
.border-dotted {border-style: dotted !important;}
.border-dashed {border-style: dashed !important;}
.border-solid {border-style: solid !important;}
.border-double {border-style: double !important;}
.border-groove {border-style: groove !important;}
.border-ridge {border-style: ridge !important;}
.border-inset {border-style: inset !important;}
.border-outset {border-style: outset !important;}
.border-none {border-style: none !important;}
.border-hidden {border-style: hidden !important;}
.border-transperent {border-color: transparent !important;}

.no-border-all { border:none !important; }
.no-border-top {border-top:0 !important;}
.no-border-bottom {border-bottom:0 !important;}
.no-border-right {border-right:0 !important;}
.no-border-left {border-left:0 !important;}

/* border for inner box */
.inner-border{ border: 8px solid rgba(255,255,255,0.35); height: calc(100% - 40px); width: calc(100% - 40px); left: 20px; top: 20px; position: absolute;}
.inner-border2{ border: 8px solid rgba(255,255,255,0.35); height: calc(100% - 80px); width: calc(100% - 110px); left: 55px; top: 40px; position: absolute;}

 /* transparent border */
.border-black-light {border-color: rgba(0,0,0,.1) !important;}
.border-white-light {border-color: rgba(255,255,255,.1) !important;}

/* border radius */
.border-radius-1 {border-radius:1px;}
.border-radius-2 {border-radius:2px;}
.border-radius-3 {border-radius:3px;}
.border-radius-4 {border-radius:4px;}
.border-radius-5 {border-radius:5px;}
.border-radius-6 {border-radius:6px;}
.border-radius-7 {border-radius:7px;}
.border-radius-8 {border-radius:8px;}
.border-radius-9 {border-radius:9px;}
.border-radius-10 {border-radius:10px;}
.border-radius-50 {border-radius:50%;}
.border-radius-100 {border-radius:100%;}
.border-radius-none {border-radius:0 !important;}

/* ===================================
    Background image
====================================== */

.parallax {position: relative; background-size: cover; overflow: hidden; background-attachment: fixed;  }
.fix-background {position: relative;  background-size: cover; animation-duration: 0s; animation-fill-mode: none; -webkit-animation-duration: 0s;  -webkit-animation-fill-mode: none; -moz-animation-duration: 0s;  -moz-animation-fill-mode: none; -ms-animation-duration: 0s; -ms-animation-fill-mode: none; -o-animation-fill-mode: none; overflow: hidden; background-position: center center; background-repeat: no-repeat; background-attachment: fixed; }
.cover-background {position: relative !important; background-size: cover !important; overflow: hidden !important; background-position: center !important; background-repeat: no-repeat !important;}
.background-size-inherit {background-size: inherit;}
.background-attachment-inherit {background-attachment: inherit;}
.background-position-left {background-position: left center;}
.background-position-left-bottom {background-position: left bottom;}
.background-position-right {background-position: right center;}
.background-position-top {background-position: center top !important;}
.up-down-ani {display:table; position: relative; -webkit-animation-name: up-down-animation; -webkit-animation-duration:.7s; -webkit-animation-timing-function: linear; -webkit-animation-delay:.7s; -webkit-animation-iteration-count: infinite; -webkit-animation-direction: alternate; -moz-animation-name: up-down-animation; -moz-animation-duration:.7s; -moz-animation-timing-function: linear; -moz-animation-delay:.7s; -moz-animation-iteration-count: infinite; -ms-animation-name: up-down-animation; -ms-animation-duration:.7s; -ms-animation-timing-function: linear; -ms-animation-delay:.7s; -ms-animation-iteration-count: infinite; -ms-animation-direction: alternate; -o-animation-direction: alternate; -o-animation-name: up-down-animation; -o-animation-duration:.7s; -o-animation-timing-function: linear; -o-animation-delay:.7s; -o-animation-iteration-count: infinite; animation-direction: alternate; animation-name: up-down-animation; animation-duration:.7s; animation-timing-function: linear; animation-delay:.7s; animation-iteration-count: infinite; animation-direction: alternate; margin:0 auto; }
.background-position-x-50 {background-position-x: 50% !important;}
.background-size-contain {background-size: contain;}

/*==============================
    Margin
================================*/

.margin-half-all {margin:.5%;}
.margin-one-all {margin:1%;}
.margin-one-half-all {margin:1.5%;}
.margin-two-all {margin:2%;}
.margin-two-half-all {margin:2.5%;}
.margin-three-all {margin:3%;}
.margin-three-half-all {margin:3.5%;}
.margin-four-all {margin:4%;}
.margin-four-half-all {margin:4.5%;}
.margin-five-all {margin:5%;}
.margin-five-half-all {margin:5.5%;}
.margin-six-all {margin:6%;}
.margin-six-half-all {margin:6.5%;}
.margin-seven-all {margin:7%;}
.margin-seven-half-all {margin:7.5%;}
.margin-eight-all {margin:8%;}
.margin-eight-half-all {margin:8.5%;}
.margin-nine-all {margin:9%;}
.margin-nine-half-all {margin:9.5%;}
.margin-ten-all {margin:10%;}
.margin-ten-half-all {margin:10.5%;}
.margin-eleven-all {margin:11%;}
.margin-eleven-half-all {margin:11.5%;}
.margin-twelve-all {margin:12%;}
.margin-twelve-half-all {margin:12.5%;}
.margin-thirteen-all {margin:13%;}
.margin-thirteen-half-all {margin:13.5%;}
.margin-fourteen-all {margin:14%;}
.margin-fourteen-half-all {margin:14.5%;}
.margin-fifteen-all {margin:15%;}
.margin-fifteen-half-all {margin:15.5%;}
.margin-sixteen-all {margin:16%;}
.margin-sixteen-half-all {margin:16.5%;}
.margin-seventeen-all {margin:17%;}
.margin-seventeen-half-all {margin:17.5%;}
.margin-eighteen-all {margin:18%;}
.margin-eighteen-half-all {margin:18.5%;}
.margin-nineteen-all {margin:19%;}
.margin-nineteen-half-all {margin:19.5%;}
.margin-twenty-all {margin:20%;}
.margin-twenty-half-all {margin:20.5%;}
.margin-twenty-one-all {margin:21%;}
.margin-twenty-one-half-all {margin:21.5%;}
.margin-twenty-two-all {margin:22%;}
.margin-twenty-two-half-all {margin:22.5%;}
.margin-twenty-three-all {margin:23%;}
.margin-twenty-three-half-all {margin:23.5%;}
.margin-twenty-four-all {margin:24%;}
.margin-twenty-four-half-all {margin:24.5%;}
.margin-twenty-five-all {margin:25%;}
.margin-5px-all {margin:5px;}
.margin-10px-all {margin:10px;}
.margin-15px-all {margin:15px;}
.margin-20px-all {margin:20px;}
.margin-25px-all {margin:25px;}
.margin-30px-all {margin:30px;}
.margin-35px-all {margin:35px;}
.margin-40px-all {margin:40px;}
.margin-45px-all {margin:45px;}
.margin-50px-all {margin:50px;}
.margin-55px-all {margin:55px;}
.margin-60px-all {margin:60px;}
.margin-65px-all {margin:65px;}
.margin-70px-all {margin:70px;}
.margin-75px-all {margin:75px;}
.margin-80px-all {margin:80px;}
.margin-85px-all {margin:85px;}
.margin-90px-all {margin:90px;}
.margin-95px-all {margin:95px;}
.margin-100px-all {margin:100px;}

.no-margin {margin:0 !important;}
.no-margin-lr {margin-left: 0 !important; margin-right: 0 !important;}
.no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important;}
.no-margin-top {margin-top:0 !important;}
.no-margin-bottom {margin-bottom:0 !important;}
.no-margin-left {margin-left:0 !important;}
.no-margin-right {margin-right:0 !important;}
.margin-lr-auto {margin-left:auto !important; margin-right:auto !important;}
.margin-auto {margin: 0 auto !important;}

/* margin top */
.margin-one-top {margin-top:1%;}
.margin-one-half-top {margin-top:1.5%;}
.margin-two-top {margin-top:2%;}
.margin-two-half-top {margin-top:2.5%;}
.margin-three-top {margin-top:3%;}
.margin-three-half-top {margin-top:3.5%;}
.margin-four-top {margin-top:4%;}
.margin-four-half-top {margin-top:4.5%;}
.margin-five-top {margin-top:5%;}
.margin-five-half-top {margin-top:5.5%;}
.margin-six-top {margin-top:6%;}
.margin-six-half-top {margin-top:6.5%;}
.margin-seven-top {margin-top:7%;}
.margin-seven-half-top {margin-top:7.5%;}
.margin-eight-top {margin-top:8%;}
.margin-eight-half-top {margin-top:8.5%;}
.margin-nine-top {margin-top:9%;}
.margin-nine-half-top {margin-top:9.5%;}
.margin-ten-top {margin-top:10%;}
.margin-ten-half-top {margin-top:10.5%;}
.margin-eleven-top {margin-top:11%;}
.margin-eleven-half-top {margin-top:11.5%;}
.margin-twelve-top {margin-top:12%;}
.margin-twelve-half-top {margin-top:12.5%;}
.margin-thirteen-top {margin-top:13%;}
.margin-thirteen-half-top {margin-top:13.5%;}
.margin-fourteen-top {margin-top:14%;}
.margin-fourteen-half-top {margin-top:14.5%;}
.margin-fifteen-top {margin-top:15%;}
.margin-fifteen-half-top {margin-top:15.5%;}
.margin-sixteen-top {margin-top:16%;}
.margin-sixteen-half-top {margin-top:16.5%;}
.margin-seventeen-top {margin-top:17%;}
.margin-seventeen-half-top {margin-top:17.5%;}
.margin-eighteen-top {margin-top:18%;}
.margin-eighteen-half-top {margin-top:18.5%;}
.margin-nineteen-top {margin-top:19%;}
.margin-nineteen-half-top {margin-top:19.5%;}
.margin-twenty-top {margin-top:20%;}
.margin-twenty-half-top {margin-top:20.5%;}
.margin-twenty-one-top {margin-top:21%;}
.margin-twenty-one-half-top {margin-top:21.5%;}
.margin-twenty-two-top {margin-top:22%;}
.margin-twenty-two-half-top {margin-top:22.5%;}
.margin-twenty-three-top {margin-top:23%;}
.margin-twenty-three-half-top {margin-top:23.5%;}
.margin-twenty-four-top {margin-top:24%;}
.margin-twenty-four-half-top {margin-top:24.5%;}
.margin-twenty-five-top {margin-top:25%;}
.margin-5px-top {margin-top:5px;}
.margin-8px-top {margin-top:8px;}
.margin-10px-top {margin-top:10px;}
.margin-15px-top {margin-top:15px;}
.margin-20px-top {margin-top:20px;}
.margin-25px-top {margin-top:25px;}
.margin-30px-top {margin-top:30px;}
.margin-35px-top {margin-top:35px;}
.margin-40px-top {margin-top:40px;}
.margin-45px-top {margin-top:45px;}
.margin-50px-top {margin-top:50px;}
.margin-55px-top {margin-top:55px;}
.margin-60px-top {margin-top:60px;}
.margin-65px-top {margin-top:65px;}
.margin-70px-top {margin-top:70px;}
.margin-75px-top {margin-top:75px;}
.margin-80px-top {margin-top:80px;}
.margin-90px-top {margin-top:90px;}
.margin-100px-top {margin-top:100px;}

/* margin bottom */
.margin-one-bottom {margin-bottom:1%;}
.margin-one-half-bottom {margin-bottom:1.5%;}
.margin-two-bottom {margin-bottom:2%;}
.margin-two-half-bottom {margin-bottom:2.5%;}
.margin-three-bottom {margin-bottom:3%;}
.margin-three-half-bottom {margin-bottom:3.5%;}
.margin-four-bottom {margin-bottom:4%;}
.margin-four-half-bottom {margin-bottom:4.5%;}
.margin-five-bottom {margin-bottom:5%;}
.margin-five-half-bottom {margin-bottom:5.5%;}
.margin-six-bottom {margin-bottom:6%;}
.margin-six-half-bottom {margin-bottom:6.5%;}
.margin-seven-bottom {margin-bottom:7%;}
.margin-seven-half-bottom {margin-bottom:7.5%;}
.margin-eight-bottom {margin-bottom:8%;}
.margin-eight-half-bottom {margin-bottom:8.5%;}
.margin-nine-bottom {margin-bottom:9%;}
.margin-nine-half-bottom {margin-bottom:9.5%;}
.margin-ten-bottom {margin-bottom:10%;}
.margin-ten-half-bottom {margin-bottom:10.5%;}
.margin-eleven-bottom {margin-bottom:11%;}
.margin-eleven-half-bottom {margin-bottom:11.5%;}
.margin-twelve-bottom {margin-bottom:12%;}
.margin-twelve-half-bottom {margin-bottom:12.5%;}
.margin-thirteen-bottom {margin-bottom:13%;}
.margin-thirteen-half-bottom {margin-bottom:13.5%;}
.margin-fourteen-bottom {margin-bottom:14%;}
.margin-fourteen-half-bottom {margin-bottom:14.5%;}
.margin-fifteen-bottom {margin-bottom:15%;}
.margin-fifteen-half-bottom {margin-bottom:15.5%;}
.margin-sixteen-bottom {margin-bottom:16%;}
.margin-sixteen-half-bottom {margin-bottom:16.5%;}
.margin-seventeen-bottom {margin-bottom:17%;}
.margin-seventeen-half-bottom {margin-bottom:17.5%;}
.margin-eighteen-bottom {margin-bottom:18%;}
.margin-eighteen-half-bottom {margin-bottom:18.5%;}
.margin-nineteen-bottom {margin-bottom:19%;}
.margin-nineteen-half-bottom {margin-bottom:19.5%;}
.margin-twenty-bottom {margin-bottom:20%;}
.margin-twenty-half-bottom {margin-bottom:20.5%;}
.margin-twenty-one-bottom {margin-bottom:21%;}
.margin-twenty-one-half-bottom {margin-bottom:21.5%;}
.margin-twenty-two-bottom {margin-bottom:22%;}
.margin-twenty-two-half-bottom {margin-bottom:22.5%;}
.margin-twenty-three-bottom {margin-bottom:23%;}
.margin-twenty-three-half-bottom {margin-bottom:23.5%;}
.margin-twenty-four-bottom {margin-bottom:24%;}
.margin-twenty-four-half-bottom {margin-bottom:24.5%;}
.margin-twenty-five-bottom {margin-bottom:25%;}
.margin-5px-bottom {margin-bottom:5px;}
.margin-8px-bottom {margin-bottom:8px;}
.margin-10px-bottom {margin-bottom:10px;}
.margin-15px-bottom {margin-bottom:15px;}
.margin-20px-bottom {margin-bottom:20px;}
.margin-25px-bottom {margin-bottom:25px;}
.margin-30px-bottom {margin-bottom:30px;}
.margin-35px-bottom {margin-bottom:35px;}
.margin-40px-bottom {margin-bottom:40px;}
.margin-45px-bottom {margin-bottom:45px;}
.margin-50px-bottom {margin-bottom:50px;}
.margin-55px-bottom {margin-bottom:55px;}
.margin-60px-bottom {margin-bottom:60px;}
.margin-65px-bottom {margin-bottom:65px;}
.margin-70px-bottom {margin-bottom:70px;}
.margin-75px-bottom {margin-bottom:75px;}
.margin-80px-bottom {margin-bottom:80px;}
.margin-85px-bottom {margin-bottom:85px;}
.margin-90px-bottom {margin-bottom:90px;}
.margin-95px-bottom {margin-bottom:95px;}
.margin-100px-bottom {margin-bottom:100px;}

/* margin right */
.margin-one-right {margin-right:1%;}
.margin-one-half-right {margin-right:1.5%;}
.margin-two-right {margin-right:2%;}
.margin-two-half-right {margin-right:2.5%;}
.margin-three-right {margin-right:3%;}
.margin-three-half-right {margin-right:3.5%;}
.margin-four-right {margin-right:4%;}
.margin-four-half-right {margin-right:4.5%;}
.margin-five-right {margin-right:5%;}
.margin-five-half-right {margin-right:5.5%;}
.margin-six-right {margin-right:6%;}
.margin-six-half-right {margin-right:6.5%;}
.margin-seven-right {margin-right:7%;}
.margin-seven-half-right {margin-right:7.5%;}
.margin-eight-right {margin-right:8%;}
.margin-eight-half-right {margin-right:8.5%;}
.margin-nine-right {margin-right:9%;}
.margin-nine-half-right {margin-right:9.5%;}
.margin-ten-right {margin-right:10%;}
.margin-ten-half-right {margin-right:10.5%;}
.margin-eleven-right {margin-right:11%;}
.margin-eleven-half-right {margin-right:11.5%;}
.margin-twelve-right {margin-right:12%;}
.margin-twelve-half-right {margin-right:12.5%;}
.margin-thirteen-right {margin-right:13%;}
.margin-thirteen-half-right {margin-right:13.5%;}
.margin-fourteen-right {margin-right:14%;}
.margin-fourteen-half-right {margin-right:14.5%;}
.margin-fifteen-right {margin-right:15%;}
.margin-fifteen-half-right {margin-right:15.5%;}
.margin-sixteen-right {margin-right:16%;}
.margin-sixteen-half-right {margin-right:16.5%;}
.margin-seventeen-right {margin-right:17%;}
.margin-seventeen-half-right {margin-right:17.5%;}
.margin-eighteen-right {margin-right:18%;}
.margin-eighteen-half-right {margin-right:18.5%;}
.margin-nineteen-right {margin-right:19%;}
.margin-nineteen-half-right {margin-right:19.5%;}
.margin-twenty-right {margin-right:20%;}
.margin-twenty-half-right {margin-right:20.5%;}
.margin-twenty-right {margin-right:21%;}
.margin-twenty-one-half-right {margin-right:21.5%;}
.margin-twenty-two-right {margin-right:22%;}
.margin-twenty-two-half-right {margin-right:22.5%;}
.margin-twenty-three-right {margin-right:23%;}
.margin-twenty-three-half-right {margin-right:23.5%;}
.margin-twenty-four-right {margin-right:24%;}
.margin-twenty-four-half-right {margin-right:24.5%;}
.margin-twenty-five-right {margin-right:25%;}
.margin-5px-right {margin-right:5px;}
.margin-10px-right {margin-right:10px;}
.margin-15px-right {margin-right:15px;}
.margin-20px-right {margin-right:20px;}
.margin-25px-right {margin-right:25px;}
.margin-30px-right {margin-right:30px;}
.margin-35px-right {margin-right:35px;}
.margin-40px-right {margin-right:40px;}
.margin-45px-right {margin-right:45px;}
.margin-50px-right {margin-right:50px;}
.margin-55px-right {margin-right:55px;}
.margin-60px-right {margin-right:60px;}
.margin-65px-right {margin-right:65px;}
.margin-70px-right {margin-right:70px;}
.margin-75px-right {margin-right:75px;}
.margin-80px-right {margin-right:80px;}
.margin-85px-right {margin-right:85px;}
.margin-90px-right {margin-right:90px;}
.margin-95px-right {margin-right:95px;}
.margin-100px-right {margin-right:100px;}

/* margin left */
.margin-one-left {margin-left:1%;}
.margin-one-half-left {margin-left:1.5%;}
.margin-two-left {margin-left:2%;}
.margin-two-half-left {margin-left:2.5%;}
.margin-three-left {margin-left:3%;}
.margin-three-half-left {margin-left:3.5%;}
.margin-four-left {margin-left:4%;}
.margin-four-half-left {margin-left:4.5%;}
.margin-five-left {margin-left:5%;}
.margin-five-half-left {margin-left:5.5%;}
.margin-six-left {margin-left:6%;}
.margin-six-half-left {margin-left:6.5%;}
.margin-seven-left {margin-left:7%;}
.margin-seven-half-left {margin-left:7.5%;}
.margin-eight-left {margin-left:8%;}
.margin-eight-half-left {margin-left:8.5%;}
.margin-nine-left {margin-left:9%;}
.margin-nine-half-left {margin-left:9.5%;}
.margin-ten-left {margin-left:10%;}
.margin-ten-half-left {margin-left:10.5%;}
.margin-eleven-left {margin-left:11%;}
.margin-eleven-half-left {margin-left:11.5%;}
.margin-twelve-left {margin-left:12%;}
.margin-twelve-half-left {margin-left:12.5%;}
.margin-thirteen-left {margin-left:13%;}
.margin-thirteen-half-left {margin-left:13.5%;}
.margin-fourteen-left {margin-left:14%;}
.margin-fourteen-half-left {margin-left:14.5%;}
.margin-fifteen-left {margin-left:15%;}
.margin-fifteen-half-left {margin-left:15.5%;}
.margin-sixteen-left {margin-left:16%;}
.margin-sixteen-half-left {margin-left:16.5%;}
.margin-seventeen-left {margin-left:17%;}
.margin-seventeen-half-left {margin-left:17.5%;}
.margin-eighteen-left {margin-left:18%;}
.margin-eighteen-half-left {margin-left:18.5%;}
.margin-nineteen-left {margin-left:19%;}
.margin-nineteen-half-left {margin-left:19.5%;}
.margin-twenty-left {margin-left:20%;}
.margin-twenty-half-left {margin-left:20.5%;}
.margin-twenty-one-left {margin-left:21%;}
.margin-twenty-one-half-left {margin-left:21.5%;}
.margin-twenty-two-left {margin-left:22%;}
.margin-twenty-two-half-left {margin-left:22.5%;}
.margin-twenty-three-left {margin-left:23%;}
.margin-twenty-three-half-left {margin-left:23.5%;}
.margin-twenty-four-left {margin-left:24%;}
.margin-twenty-four-half-left {margin-left:24.5%;}
.margin-twenty-five-left {margin-left:25%;}
.margin-5px-left {margin-left:5px;}
.margin-10px-left {margin-left:10px;}
.margin-15px-left {margin-left:15px;}
.margin-20px-left {margin-left:20px;}
.margin-25px-left {margin-left:25px;}
.margin-30px-left {margin-left:30px;}
.margin-35px-left {margin-left:35px;}
.margin-40px-left {margin-left:40px;}
.margin-45px-left {margin-left:45px;}
.margin-50px-left {margin-left:50px;}
.margin-55px-left {margin-left:55px;}
.margin-60px-left {margin-left:60px;}
.margin-65px-left {margin-left:65px;}
.margin-70px-left {margin-left:70px;}
.margin-75px-left {margin-left:75px;}
.margin-80px-left {margin-left:80px;}
.margin-85px-left {margin-left:85px;}
.margin-90px-left {margin-left:90px;}
.margin-95px-left {margin-left:95px;}
.margin-100px-left {margin-left:100px;}

/* margin left and right */
.margin-one-lr {margin-left:1%; margin-right:1%;}
.margin-one-half-lr {margin-left:1.5%; margin-right:1.5%;}
.margin-two-lr {margin-left:2%; margin-right:2%;}
.margin-two-half-lr {margin-left:2.5%; margin-right:2.5%;}
.margin-three-lr {margin-left:3%; margin-right:3%;}
.margin-three-half-lr {margin-left:3.5%; margin-right:3.5%;}
.margin-four-lr {margin-left:4%; margin-right:4%;}
.margin-four-half-lr {margin-left:4.5%; margin-right:4.5%;}
.margin-five-lr {margin-left:5%; margin-right:5%;}
.margin-five-half-lr {margin-left:5.5%; margin-right:5.5%;}
.margin-six-lr {margin-left:6%; margin-right:6%;}
.margin-six-half-lr {margin-left:6.5%; margin-right:6.5%;}
.margin-seven-lr {margin-left:7%; margin-right:7%;}
.margin-seven-half-lr {margin-left:7.5%; margin-right:7.5%;}
.margin-eight-lr {margin-left:8%; margin-right:8%;}
.margin-eight-half-lr {margin-left:8.5%; margin-right:8.5%;}
.margin-nine-lr {margin-left:9%; margin-right:9%;}
.margin-nine-half-lr {margin-left:9.5%; margin-right:9.5%;}
.margin-ten-lr {margin-left:10%; margin-right:10%;}
.margin-ten-half-lr {margin-left:10.5%; margin-right:10.5%;}
.margin-eleven-lr {margin-left:11%; margin-right:11%;}
.margin-eleven-half-lr {margin-left:11.5%; margin-right:11.5%;}
.margin-twelve-lr {margin-left:12%; margin-right:12%;}
.margin-twelve-half-lr {margin-left:12.5%; margin-right:12.5%;}
.margin-thirteen-lr {margin-left:13%; margin-right:13%;}
.margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
.margin-fourteen-lr {margin-left:14%; margin-right:14%;}
.margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
.margin-fifteen-lr {margin-left:15%; margin-right:15%;}
.margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
.margin-sixteen-lr {margin-left:16%; margin-right:16%;}
.margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
.margin-seventeen-lr {margin-left:17%; margin-right:17%;}
.margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
.margin-eighteen-lr {margin-left:18%; margin-right:18%;}
.margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
.margin-nineteen-lr {margin-left:19%; margin-right:19%;}
.margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
.margin-twenty-lr {margin-left:20%; margin-right:20%;}
.margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
.margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
.margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
.margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
.margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
.margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
.margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
.margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
.margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
.margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
.margin-5px-lr {margin-left:5px; margin-right:5px;}
.margin-10px-lr {margin-left:10px; margin-right:10px;}
.margin-15px-lr {margin-left:15px; margin-right:15px;}
.margin-20px-lr {margin-left:20px; margin-right:20px;}
.margin-25px-lr {margin-left:25px; margin-right:25px;}
.margin-30px-lr {margin-left:30px; margin-right:30px;}
.margin-35px-lr {margin-left:35px; margin-right:35px;}
.margin-40px-lr {margin-left:40px; margin-right:40px;}
.margin-45px-lr {margin-left:45px; margin-right:45px;}
.margin-50px-lr {margin-left:50px; margin-right:50px;}
.margin-55px-lr {margin-left:55px; margin-right:55px;}
.margin-60px-lr {margin-left:60px; margin-right:60px;}
.margin-65px-lr {margin-left:65px; margin-right:65px;}
.margin-70px-lr {margin-left:70px; margin-right:70px;}
.margin-75px-lr {margin-left:75px; margin-right:75px;}
.margin-80px-lr {margin-left:80px; margin-right:80px;}
.margin-85px-lr {margin-left:85px; margin-right:85px;}
.margin-90px-lr {margin-left:90px; margin-right:90px;}
.margin-95px-lr {margin-left:95px; margin-right:95px;}
.margin-100px-lr {margin-left:100px; margin-right:100px;}

/* margin top and bottom */
.margin-one-tb {margin-top:1%; margin-bottom:1%;}
.margin-one-half-tb {margin-top:1.5%; margin-bottom:1.5%;}
.margin-two-tb {margin-top:2%; margin-bottom:2%;}
.margin-two-half-tb {margin-top:2.5%; margin-bottom:2.5%;}
.margin-three-tb {margin-top:3%; margin-bottom:3%;}
.margin-three-half-tb {margin-top:3.5%; margin-bottom:3.5%;}
.margin-four-tb {margin-top:4%; margin-bottom:4%;}
.margin-four-half-tb {margin-top:4.5%; margin-bottom:4.5%;}
.margin-five-tb {margin-top:5%; margin-bottom:5%;}
.margin-five-half-tb {margin-top:5.5%; margin-bottom:5.5%;}
.margin-six-tb {margin-top:6%; margin-bottom:6%;}
.margin-six-half-tb {margin-top:6.5%; margin-bottom:6.5%;}
.margin-seven-tb {margin-top:7%; margin-bottom:7%;}
.margin-seven-half-tb {margin-top:7.5%; margin-bottom:7.5%;}
.margin-eight-tb {margin-top:8%; margin-bottom:8%;}
.margin-eight-half-tb {margin-top:8.5%; margin-bottom:8.5%;}
.margin-nine-tb {margin-top:9%; margin-bottom:9%;}
.margin-nine-half-tb {margin-top:9.5%; margin-bottom:9.5%;}
.margin-ten-tb {margin-top:10%; margin-bottom:10%;}
.margin-ten-half-tb {margin-top:10.5%; margin-bottom:10.5%;}
.margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
.margin-eleven-half-tb {margin-top:11.5%; margin-bottom:11.5%;}
.margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
.margin-twelve-half-tb {margin-top:12.5%; margin-bottom:12.5%;}
.margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
.margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
.margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
.margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
.margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
.margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
.margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
.margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
.margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
.margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
.margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
.margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
.margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
.margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
.margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
.margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
.margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
.margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
.margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
.margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
.margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
.margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
.margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
.margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
.margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
.margin-5px-tb {margin-top:5px; margin-bottom:5px;}
.margin-10px-tb {margin-top:10px; margin-bottom:10px;}
.margin-15px-tb {margin-top:15px; margin-bottom:15px;}
.margin-20px-tb {margin-top:20px; margin-bottom:20px;}
.margin-25px-tb {margin-top:25px; margin-bottom:25px;}
.margin-30px-tb {margin-top:30px; margin-bottom:30px;}
.margin-35px-tb {margin-top:35px; margin-bottom:35px;}
.margin-40px-tb {margin-top:40px; margin-bottom:40px;}
.margin-45px-tb {margin-top:45px; margin-bottom:45px;}
.margin-50px-tb {margin-top:50px; margin-bottom:50px;}
.margin-55px-tb {margin-top:55px; margin-bottom:55px;}
.margin-60px-tb {margin-top:60px; margin-bottom:60px;}
.margin-65px-tb {margin-top:65px; margin-bottom:65px;}
.margin-70px-tb {margin-top:70px; margin-bottom:70px;}
.margin-75px-tb {margin-top:75px; margin-bottom:75px;}
.margin-80px-tb {margin-top:80px; margin-bottom:80px;}
.margin-85px-tb {margin-top:85px; margin-bottom:85px;}
.margin-90px-tb {margin-top:90px; margin-bottom:90px;}
.margin-95px-tb {margin-top:95px; margin-bottom:95px;}
.margin-100px-tb {margin-top:100px; margin-bottom:100px;}

/*===============================
    Padding
=================================*/

.no-padding {padding:0 !important;}
.no-padding-lr {padding-left: 0 !important; padding-right: 0 !important;}
.no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important;}
.no-padding-top {padding-top:0 !important;}
.no-padding-bottom {padding-bottom:0 !important;}
.no-padding-left {padding-left:0 !important;}
.no-padding-right {padding-right:0 !important;}
.padding-one-all {padding:1%;}
.padding-one-half-all {padding:1.5%;}
.padding-two-all {padding:2%;}
.padding-two-half-all {padding:2.5%;}
.padding-three-all {padding:3%;}
.padding-three-half-all {padding:3.5%;}
.padding-four-all {padding:4%;}
.padding-four-half-all {padding:4.5%;}
.padding-five-all {padding:5%;}
.padding-five-half-all {padding:5.5%;}
.padding-six-all {padding:6%;}
.padding-six-half-all {padding:6.5%;}
.padding-seven-all {padding:7%;}
.padding-seven-half-all {padding:7.5%;}
.padding-eight-all {padding:8%;}
.padding-eight-half-all {padding:8.5%;}
.padding-nine-all {padding:9%;}
.padding-nine-half-all {padding:9.5%;}
.padding-ten-all {padding:10%;}
.padding-ten-half-all {padding:10.5%;}
.padding-eleven-all {padding:11%;}
.padding-eleven-half-all {padding:11.5%;}
.padding-twelve-all {padding:12%;}
.padding-twelve-half-all {padding:12.5%;}
.padding-thirteen-all {padding:13%;}
.padding-thirteen-half-all {padding:13.5%;}
.padding-fourteen-all {padding:14%;}
.padding-fourteen-half-all {padding:14.5%;}
.padding-fifteen-all {padding:15%;}
.padding-fifteen-half-all {padding:15.5%;}
.padding-sixteen-all {padding:16%;}
.padding-sixteen-half-all {padding:16.5%;}
.padding-seventeen-all {padding:17%;}
.padding-seventeen-half-all {padding:17.5%;}
.padding-eighteen-all {padding:18%;}
.padding-eighteen-half-all {padding:18.5%;}
.padding-nineteen-all {padding:19%;}
.padding-nineteen-half-all {padding:19.5%;}
.padding-twenty-all {padding:20%;}
.padding-twenty-half-all {padding:20.5%;}
.padding-twenty-one-all {padding:21%;}
.padding-twenty-one-half-all {padding:21.5%;}
.padding-twenty-two-all {padding:22%;}
.padding-twenty-two-half-all {padding:22.5%;}
.padding-twenty-three-all {padding:23%;}
.padding-twenty-three-half-all {padding:23.5%;}
.padding-twenty-four-all {padding:24%;}
.padding-twenty-four-half-all {padding:24.5%;}
.padding-twenty-five-all {padding:25%;}
.padding-twenty-five-half-all {padding:25.5%;}
.padding-5px-all {padding:5px;}
.padding-10px-all {padding:10px;}
.padding-15px-all {padding:15px;}
.padding-20px-all {padding:20px;}
.padding-25px-all {padding:25px;}
.padding-30px-all {padding:30px;}
.padding-35px-all {padding:35px;}
.padding-40px-all {padding:40px;}
.padding-45px-all {padding:45px;}
.padding-50px-all {padding:50px;}
.padding-55px-all {padding:55px;}
.padding-60px-all {padding:60px;}
.padding-65px-all {padding:65px;}
.padding-70px-all {padding:70px;}
.padding-75px-all {padding:75px;}
.padding-80px-all {padding:80px;}
.padding-85px-all {padding:85px;}
.padding-90px-all {padding:90px;}
.padding-95px-all {padding:95px;}
.padding-100px-all {padding:100px;}

/* padding top */
.padding-one-top {padding-top:1%;}
.padding-one-half-top {padding-top:1.5%;}
.padding-two-top {padding-top:2%;}
.padding-two-half-top {padding-top:2.5%;}
.padding-three-top {padding-top:3%;}
.padding-three-half-top {padding-top:3.5%;}
.padding-four-top {padding-top:4%;}
.padding-four-half-top {padding-top:4.5%;}
.padding-five-top {padding-top:5%;}
.padding-five-half-top {padding-top:5.5%;}
.padding-six-top {padding-top:6%;}
.padding-six-half-top {padding-top:6.5%;}
.padding-seven-top {padding-top:7%;}
.padding-seven-half-top {padding-top:7.5%;}
.padding-eight-top {padding-top:8%;}
.padding-eight-half-top {padding-top:8.5%;}
.padding-nine-top {padding-top:9%;}
.padding-nine-half-top {padding-top:9.5%;}
.padding-ten-top {padding-top:10%;}
.padding-ten-half-top {padding-top:10.5%;}
.padding-eleven-top {padding-top:11%;}
.padding-eleven-half-top {padding-top:11.5%;}
.padding-twelve-top {padding-top:12%;}
.padding-twelve-half-top {padding-top:12.5%;}
.padding-thirteen-top {padding-top:13%;}
.padding-thirteen-half-top {padding-top:13.5%;}
.padding-fourteen-top {padding-top:14%;}
.padding-fourteen-half-top {padding-top:14.5%;}
.padding-fifteen-top {padding-top:15%;}
.padding-fifteen-half-top {padding-top:15.5%;}
.padding-sixteen-top {padding-top:16%;}
.padding-sixteen-half-top {padding-top:16.5%;}
.padding-seventeen-top {padding-top:17%;}
.padding-seventeen-half-top {padding-top:17.5%;}
.padding-eighteen-top {padding-top:18%;}
.padding-eighteen-half-top {padding-top:18.5%;}
.padding-nineteen-top {padding-top:19%;}
.padding-nineteen-half-top {padding-top:19.5%;}
.padding-twenty-top {padding-top:20%;}
.padding-twenty-half-top {padding-top:20.5%;}
.padding-twenty-one-top {padding-top:21%;}
.padding-twenty-one-half-top {padding-top:21.5%;}
.padding-twenty-two-top {padding-top:22%;}
.padding-twenty-two-half-top {padding-top:22.5%;}
.padding-twenty-two-top {padding-top:22%;}
.padding-twenty-two-half-top {padding-top:22.5%;}
.padding-twenty-three-top {padding-top:23%;}
.padding-twenty-three-half-top {padding-top:23.5%;}
.padding-twenty-four-top {padding-top:24%;}
.padding-twenty-four-half-top {padding-top:24.5%;}
.padding-twenty-five-top {padding-top:25%;}
.padding-5px-top {padding-top:5px;}
.padding-10px-top {padding-top:10px;}
.padding-15px-top {padding-top:15px;}
.padding-20px-top {padding-top:20px;}
.padding-25px-top {padding-top:25px;}
.padding-30px-top {padding-top:30px;}
.padding-35px-top {padding-top:35px;}
.padding-40px-top {padding-top:40px;}
.padding-45px-top {padding-top:45px;}
.padding-50px-top {padding-top:50px;}
.padding-55px-top {padding-top:55px;}
.padding-60px-top {padding-top:60px;}
.padding-65px-top {padding-top:65px;}
.padding-70px-top {padding-top:70px;}
.padding-75px-top {padding-top:75px;}
.padding-80px-top {padding-top:80px;}
.padding-85px-top {padding-top:85px;}
.padding-90px-top {padding-top:90px;}
.padding-95px-top {padding-top:95px;}
.padding-100px-top {padding-top:100px;}

/* padding bottom */
.padding-one-bottom {padding-bottom:1%;}
.padding-one-half-bottom {padding-bottom:1.5%;}
.padding-two-bottom {padding-bottom:2%;}
.padding-two-half-bottom {padding-bottom:2.5%;}
.padding-three-bottom {padding-bottom:3%;}
.padding-three-half-bottom {padding-bottom:3.5%;}
.padding-four-bottom {padding-bottom:4%;}
.padding-four-half-bottom {padding-bottom:4.5%;}
.padding-five-bottom {padding-bottom:5%;}
.padding-five-half-bottom {padding-bottom:5.5%;}
.padding-six-bottom {padding-bottom:6%;}
.padding-six-half-bottom {padding-bottom:6.5%;}
.padding-seven-bottom {padding-bottom:7%;}
.padding-seven-half-bottom {padding-bottom:7.5%;}
.padding-eight-bottom {padding-bottom:8%;}
.padding-eight-half-bottom {padding-bottom:8.5%;}
.padding-nine-bottom {padding-bottom:9%;}
.padding-nine-half-bottom {padding-bottom:9.5%;}
.padding-ten-bottom {padding-bottom:10%;}
.padding-ten-half-bottom {padding-bottom:10.5%;}
.padding-eleven-bottom {padding-bottom:11%;}
.padding-eleven-half-bottom {padding-bottom:11.5%;}
.padding-twelve-bottom {padding-bottom:12%;}
.padding-twelve-half-bottom {padding-bottom:12.5%;}
.padding-thirteen-bottom {padding-bottom:13%;}
.padding-thirteen-half-bottom {padding-bottom:13.5%;}
.padding-fourteen-bottom {padding-bottom:14%;}
.padding-fourteen-half-bottom {padding-bottom:14.5%;}
.padding-fifteen-bottom {padding-bottom:15%;}
.padding-fifteen-half-bottom {padding-bottom:15.5%;}
.padding-sixteen-bottom {padding-bottom:16%;}
.padding-sixteen-half-bottom {padding-bottom:16.5%;}
.padding-seventeen-bottom {padding-bottom:17%;}
.padding-seventeen-half-bottom {padding-bottom:17.5%;}
.padding-eighteen-bottom {padding-bottom:18%;}
.padding-eighteen-half-bottom {padding-bottom:18.5%;}
.padding-nineteen-bottom {padding-bottom:19%;}
.padding-nineteen-half-bottom {padding-bottom:19.5%;}
.padding-twenty-bottom {padding-bottom:20%;}
.padding-twenty-half-bottom {padding-bottom:20.5%;}
.padding-twenty-one-bottom {padding-bottom:21%;}
.padding-twenty-one-half-bottom {padding-bottom:21.5%;}
.padding-twenty-two-bottom {padding-bottom:22%;}
.padding-twenty-two-half-bottom {padding-bottom:22.5%;}
.padding-twenty-three-bottom {padding-bottom:23%;}
.padding-twenty-three-half-bottom {padding-bottom:23.5%;}
.padding-twenty-four-bottom {padding-bottom:24%;}
.padding-twenty-four-half-bottom {padding-bottom:24.5%;}
.padding-twenty-five-bottom {padding-bottom:25%;}
.padding-5px-bottom {padding-bottom:5px;}
.padding-10px-bottom {padding-bottom:10px;}
.padding-15px-bottom {padding-bottom:15px;}
.padding-20px-bottom {padding-bottom:20px;}
.padding-25px-bottom {padding-bottom:25px;}
.padding-30px-bottom {padding-bottom:30px;}
.padding-35px-bottom {padding-bottom:35px;}
.padding-40px-bottom {padding-bottom:40px;}
.padding-45px-bottom {padding-bottom:45px;}
.padding-50px-bottom {padding-bottom:50px;}
.padding-55px-bottom {padding-bottom:55px;}
.padding-60px-bottom {padding-bottom:60px;}
.padding-65px-bottom {padding-bottom:65px;}
.padding-70px-bottom {padding-bottom:70px;}
.padding-75px-bottom {padding-bottom:75px;}
.padding-80px-bottom {padding-bottom:80px;}
.padding-85px-bottom {padding-bottom:85px;}
.padding-90px-bottom {padding-bottom:90px;}
.padding-95px-bottom {padding-bottom:95px;}
.padding-100px-bottom {padding-bottom:100px;}

/* padding right */
.padding-one-right {padding-right:1%;}
.padding-one-half-right {padding-right:1.5%;}
.padding-two-right {padding-right:2%;}
.padding-two-half-right {padding-right:2.5%;}
.padding-three-right {padding-right:3%;}
.padding-three-half-right {padding-right:3.5%;}
.padding-four-right {padding-right:4%;}
.padding-four-half-right {padding-right:4.5%;}
.padding-five-right {padding-right:5%;}
.padding-five-half-right {padding-right:5.5%;}
.padding-six-right {padding-right:6%;}
.padding-six-half-right {padding-right:6.5%;}
.padding-seven-right {padding-right:7%;}
.padding-seven-half-right {padding-right:7.5%;}
.padding-eight-right {padding-right:8%;}
.padding-eight-half-right {padding-right:8.5%;}
.padding-nine-right {padding-right:9%;}
.padding-nine-half-right {padding-right:9.5%;}
.padding-ten-right {padding-right:10%;}
.padding-ten-half-right {padding-right:10.5%;}
.padding-eleven-right {padding-right:11%;}
.padding-eleven-half-right {padding-right:11.5%;}
.padding-twelve-right {padding-right:12%;}
.padding-twelve-half-right {padding-right:12.5%;}
.padding-thirteen-right {padding-right:13%;}
.padding-thirteen-half-right {padding-right:13.5%;}
.padding-fourteen-right {padding-right:14%;}
.padding-fourteen-half-right {padding-right:14.5%;}
.padding-fifteen-right {padding-right:15%;}
.padding-fifteen-half-right {padding-right:15.5%;}
.padding-sixteen-right {padding-right:16%;}
.padding-sixteen-half-right {padding-right:16.5%;}
.padding-seventeen-right {padding-right:17%;}
.padding-seventeen-half-right {padding-right:17.5%;}
.padding-eighteen-right {padding-right:18%;}
.padding-eighteen-half-right {padding-right:18.5%;}
.padding-nineteen-right {padding-right:19%;}
.padding-nineteen-half-right {padding-right:19.5%;}
.padding-twenty-right {padding-right:20%;}
.padding-twenty-half-right {padding-right:20.5%;}
.padding-twenty-one-right {padding-right:21%;}
.padding-twenty-one-half-right {padding-right:21.5%;}
.padding-twenty-two-right {padding-right:22%;}
.padding-twenty-two-half-right {padding-right:22.5%;}
.padding-twenty-three-right {padding-right:23%;}
.padding-twenty-three-half-right {padding-right:23.5%;}
.padding-twenty-four-right {padding-right:24%;}
.padding-twenty-four-half-right {padding-right:24.5%;}
.padding-twenty-five-right {padding-right:25%;}
.padding-5px-right {padding-right:5px;}
.padding-10px-right {padding-right:10px;}
.padding-15px-right {padding-right:15px;}
.padding-20px-right {padding-right:20px;}
.padding-25px-right {padding-right:25px;}
.padding-30px-right {padding-right:30px;}
.padding-35px-right {padding-right:35px;}
.padding-40px-right {padding-right:40px;}
.padding-45px-right {padding-right:45px;}
.padding-50px-right {padding-right:50px;}
.padding-55px-right {padding-right:55px;}
.padding-60px-right {padding-right:60px;}
.padding-65px-right {padding-right:65px;}
.padding-70px-right {padding-right:70px;}
.padding-75px-right {padding-right:75px;}
.padding-80px-right {padding-right:80px;}
.padding-85px-right {padding-right:85px;}
.padding-90px-right {padding-right:90px;}
.padding-95px-right {padding-right:95px;}
.padding-100px-right {padding-right:100px;}

/* padding left */
.padding-one-left {padding-left:1%;}
.padding-one-half-left {padding-left:1.5%;}
.padding-two-left {padding-left:2%;}
.padding-two-half-left {padding-left:2.5%;}
.padding-three-left {padding-left:3%;}
.padding-three-half-left {padding-left:3.5%;}
.padding-four-left {padding-left:4%;}
.padding-four-half-left {padding-left:4.5%;}
.padding-five-left {padding-left:5%;}
.padding-five-half-left {padding-left:5.5%;}
.padding-six-left {padding-left:6%;}
.padding-six-half-left {padding-left:6.5%;}
.padding-seven-left {padding-left:7%;}
.padding-seven-half-left {padding-left:7.5%;}
.padding-eight-left {padding-left:8%;}
.padding-eight-half-left {padding-left:8.5%;}
.padding-nine-left {padding-left:9%;}
.padding-nine-half-left {padding-left:9.5%;}
.padding-ten-left {padding-left:10%;}
.padding-ten-half-left {padding-left:10.5%;}
.padding-eleven-left {padding-left:11%;}
.padding-eleven-half-left {padding-left:11.5%;}
.padding-twelve-left {padding-left:12%;}
.padding-twelve-half-left {padding-left:12.5%;}
.padding-thirteen-left {padding-left:13%;}
.padding-thirteen-half-left {padding-left:13.5%;}
.padding-fourteen-left {padding-left:14%;}
.padding-fourteen-half-left {padding-left:14.5%;}
.padding-fifteen-left {padding-left:15%;}
.padding-fifteen-half-left {padding-left:15.5%;}
.padding-sixteen-left {padding-left:16%;}
.padding-sixteen-half-left {padding-left:16.5%;}
.padding-seventeen-left {padding-left:17%;}
.padding-seventeen-half-left {padding-left:17.5%;}
.padding-eighteen-left {padding-left:18%;}
.padding-eighteen-half-left {padding-left:18.5%;}
.padding-nineteen-left {padding-left:19%;}
.padding-nineteen-half-left {padding-left:19.5%;}
.padding-twenty-left {padding-left:20%;}
.padding-twenty-half-left {padding-left:20.5%;}
.padding-twenty-one-left {padding-left:21%;}
.padding-twenty-one-half-left {padding-left:21.5%;}
.padding-twenty-two-left {padding-left:22%;}
.padding-twenty-two-half-left {padding-left:22.5%;}
.padding-twenty-three-left {padding-left:23%;}
.padding-twenty-three-half-left {padding-left:23.5%;}
.padding-twenty-four-left {padding-left:24%;}
.padding-twenty-four-half-left {padding-left:24.5%;}
.padding-twenty-five-left {padding-left:25%;}
.padding-5px-left {padding-left:5px;}
.padding-10px-left {padding-left:10px;}
.padding-15px-left {padding-left:15px;}
.padding-20px-left {padding-left:20px;}
.padding-25px-left {padding-left:25px;}
.padding-30px-left {padding-left:30px;}
.padding-35px-left {padding-left:35px;}
.padding-40px-left {padding-left:40px;}
.padding-45px-left {padding-left:45px;}
.padding-50px-left {padding-left:50px;}
.padding-55px-left {padding-left:55px;}
.padding-60px-left {padding-left:60px;}
.padding-65px-left {padding-left:65px;}
.padding-70px-left {padding-left:70px;}
.padding-75px-left {padding-left:75px;}
.padding-80px-left {padding-left:80px;}
.padding-85px-left {padding-left:85px;}
.padding-90px-left {padding-left:90px;}
.padding-95px-left {padding-left:95px;}
.padding-100px-left {padding-left:100px;}

/* padding top and bottom */
.padding-one-tb {padding-top:1%; padding-bottom:1%;}
.padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
.padding-two-tb {padding-top:2%; padding-bottom:2%;}
.padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
.padding-three-tb {padding-top:3%; padding-bottom:3%;}
.padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
.padding-four-tb {padding-top:4%; padding-bottom:4%;}
.padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
.padding-five-tb {padding-top:5%; padding-bottom:5%;}
.padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
.padding-six-tb {padding-top:6%; padding-bottom:6%;}
.padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
.padding-seven-tb {padding-top:7%; padding-bottom:7%;}
.padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
.padding-eight-tb {padding-top:8%; padding-bottom:8%;}
.padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
.padding-nine-tb {padding-top:9%; padding-bottom:9%;}
.padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
.padding-ten-tb {padding-top:10%; padding-bottom:10%;}
.padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
.padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
.padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
.padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
.padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
.padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
.padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
.padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
.padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
.padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
.padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
.padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
.padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
.padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
.padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
.padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
.padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
.padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
.padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
.padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
.padding-twenty-half-tb {padding-top:20.5%; padding-bottom:20%;}
.padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
.padding-twenty-one-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
.padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
.padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
.padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
.padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
.padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
.padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
.padding-twenty-five-tb {padding-top:25%; padding-bottom:25%;}
.padding-thirty-tb {padding-top:30%; padding-bottom:30%;}
.padding-5px-tb {padding-top:5px; padding-bottom:5px;}
.padding-8px-tb {padding-top:8px; padding-bottom:8px;}
.padding-10px-tb {padding-top:10px; padding-bottom:10px;}
.padding-15px-tb {padding-top:15px; padding-bottom:15px;}
.padding-20px-tb {padding-top:20px; padding-bottom:20px;}
.padding-25px-tb {padding-top:25px; padding-bottom:25px;}
.padding-30px-tb {padding-top:30px; padding-bottom:30px;}
.padding-35px-tb {padding-top:35px; padding-bottom:35px;}
.padding-40px-tb {padding-top:40px; padding-bottom:40px;}
.padding-45px-tb {padding-top:45px; padding-bottom:45px;}
.padding-50px-tb {padding-top:50px; padding-bottom:50px;}
.padding-55px-tb {padding-top:55px; padding-bottom:55px;}
.padding-60px-tb {padding-top:60px; padding-bottom:60px;}
.padding-65px-tb {padding-top:65px; padding-bottom:65px;}
.padding-70px-tb {padding-top:70px; padding-bottom:70px;}
.padding-75px-tb {padding-top:75px; padding-bottom:75px;}
.padding-80px-tb {padding-top:80px; padding-bottom:80px;}
.padding-85px-tb {padding-top:85px; padding-bottom:85px;}
.padding-90px-tb {padding-top:90px; padding-bottom:90px;}
.padding-95px-tb {padding-top:95px; padding-bottom:95px;}
.padding-100px-tb {padding-top:100px; padding-bottom:100px;}

/* padding left and right */
.padding-one-lr {padding-left:1%; padding-right:1%;}
.padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
.padding-two-lr {padding-left:2%; padding-right:2%;}
.padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
.padding-three-lr {padding-left:3%; padding-right:3%;}
.padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
.padding-four-lr {padding-left:4%; padding-right:4%;}
.padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
.padding-five-lr {padding-left:5%; padding-right:5%;}
.padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
.padding-six-lr {padding-left:6%; padding-right:6%;}
.padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
.padding-seven-lr {padding-left:7%; padding-right:7%;}
.padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
.padding-eight-lr {padding-left:8%; padding-right:8%;}
.padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
.padding-nine-lr {padding-left:9%; padding-right:9%;}
.padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
.padding-ten-lr {padding-left:10%; padding-right:10%;}
.padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
.padding-eleven-lr {padding-left:11%; padding-right:11%;}
.padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
.padding-twelve-lr {padding-left:12%; padding-right:12%;}
.padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
.padding-thirteen-lr {padding-left:13%; padding-right:13%;}
.padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
.padding-fourteen-lr {padding-left:14%; padding-right:14%;}
.padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
.padding-fifteen-lr {padding-left:15%; padding-right:15%;}
.padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
.padding-sixteen-lr {padding-left:16%; padding-right:16%;}
.padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
.padding-seventeen-lr {padding-left:17%; padding-right:17%;}
.padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
.padding-eighteen-lr {padding-left:18%; padding-right:18%;}
.padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
.padding-nineteen-lr {padding-left:19%; padding-right:19%;}
.padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
.padding-twenty-lr {padding-left:20%; padding-right:20%;}
.padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
.padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
.padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
.padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
.padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
.padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
.padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
.padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
.padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
.padding-twenty-five-lr {padding-left:25%; padding-right:25%;}
.padding-5px-lr {padding-left:5px; padding-right:5px;}
.padding-10px-lr {padding-left:10px; padding-right:10px;}
.padding-15px-lr {padding-left:15px; padding-right:15px;}
.padding-20px-lr {padding-left:20px; padding-right:20px;}
.padding-25px-lr {padding-left:25px; padding-right:25px;}
.padding-30px-lr {padding-left:30px; padding-right:30px;}
.padding-35px-lr {padding-left:35px; padding-right:35px;}
.padding-40px-lr {padding-left:40px; padding-right:40px;}
.padding-45px-lr {padding-left:45px; padding-right:45px;}
.padding-50px-lr {padding-left:50px; padding-right:50px;}
.padding-55px-lr {padding-left:55px; padding-right:55px;}
.padding-60px-lr {padding-left:60px; padding-right:60px;}
.padding-65px-lr {padding-left:65px; padding-right:65px;}
.padding-70px-lr {padding-left:70px; padding-right:70px;}
.padding-75px-lr {padding-left:75px; padding-right:75px;}
.padding-80px-lr {padding-left:80px; padding-right:80px;}
.padding-85px-lr {padding-left:85px; padding-right:85px;}
.padding-90px-lr {padding-left:90px; padding-right:90px;}
.padding-95px-lr {padding-left:95px; padding-right:95px;}
.padding-100px-lr {padding-left:100px; padding-right:100px;}

/*==============================
    Display and float
================================*/

.display-block {display:block !important;}
.display-inline-block {display:inline-block !important;}
.display-inline {display:inline !important;}
.display-none {display:none !important;}
.display-inherit {display:inherit !important;}
.display-table {display:table !important;}
.display-table-cell {display:table-cell !important;}
.overflow-hidden {overflow:hidden !important;}
.overflow-visible {overflow:visible !important;}
.overflow-auto {overflow:auto !important;}

/*float*/
.float-left{float: left !important;}
.float-right{float: right !important;}
.float-none{float: none !important;}

/*============================== 
    Position
================================*/

.position-inherit {position:inherit !important;}
.position-relative {position:relative !important;}
.position-absolute {position:absolute !important;}
.position-fixed {position:fixed !important;}
.position-right {right:0 !important;}
.position-left {left:0 !important;}
.position-top {top:0 !important;}

 
/*================================
    Width
================================*/

.width-1px {width:1px;}
.width-2px {width:2px;}
.width-3px {width:3px;}
.width-4px {width:4px;}
.width-5px {width:5px;}
.width-6px {width:6px;}
.width-7px {width:7px;}
.width-8px {width:8px;}
.width-9px {width:9px;}
.width-10px {width:10px;}
.width-15px {width:15px;}
.width-20px {width:20px;}
.width-25px {width:25px;}
.width-30px {width:30px;}
.width-35px {width:35px;}
.width-40px {width:40px;}
.width-50px {width:50px;}
.width-55px {width:55px;}
.width-60px {width:60px;}
.width-65px {width:65px;}
.width-70px {width:70px;}
.width-75px {width:75px;}
.width-80px {width:80px;}
.width-85px {width:85px;}
.width-90px {width:90px;}
.width-100px {width:100px;}
.width-120px {width:120px;}
.width-130px {width:130px;}
.width-150px {width:150px;}
.width-180px {width:180px;}
.width-200px {width:200px;}
.width-250px {width:250px;}
.width-300px {width:300px;}
.width-350px {width:350px;}
.width-400px {width:400px;}
.width-450px {width:450px;}
.width-500px {width:500px;}
.width-550px {width:550px;}
.width-600px {width:600px;}
.width-650px {width:650px;}
.width-700px {width:700px;}
.width-750px {width:750px;}
.width-800px {width:800px;}
.width-850px {width:850px;}
.width-900px {width:900px;}
.width-950px {width:950px;}
.width-1000px {width:1000px;}

.width-10 {width:10%;}
.width-12 {width:12%;}
.width-15 {width:15%;}
.width-20 {width:20%;}
.width-25 {width:25%;}
.width-30 {width:30%;}
.width-35 {width:35%;}
.width-40 {width:40%;}
.width-45 {width:45%;}
.width-50 {width:50%;}
.width-55 {width:55%;}
.width-60 {width:60%;}
.width-65 {width:65%;}
.width-70 {width:70%;}
.width-75 {width:75%;}
.width-80 {width:80%;}
.width-85 {width:85%;}
.width-90 {width:90%;}
.width-95 {width:95%;}
.width-100 {width:100%;}
.width-auto {width: auto;}

/*================================
    Responsive Media Query
================================*/


@media (max-width: 1199px) {
    
    section {padding: 70px 0;}
    .md-height-auto { height: auto; }

    /* typography */
    h1, h2, h3, h4, h5, h6 {margin:0 0 18px; padding:0; letter-spacing: 0; }
    h1 {font-size:40px;}
    h2 {font-size:32px;}
    h3 {font-size:30px;}
    h4 {font-size:28px;}
    h5 {font-size:24px;}
    h6 {font-size:20px;}

    /* line height */
    .md-line-height-normal {line-height:normal;}
    .md-line-height-10 {line-height:10px;}
    .md-line-height-13 {line-height:13px;}
    .md-line-height-18 {line-height:18px;}
    .md-line-height-20 {line-height:20px;}
    .md-line-height-24 {line-height:24px;}
    .md-line-height-22 {line-height:22px;}
    .md-line-height-26 {line-height:26px;}
    .md-line-height-28 {line-height:28px;}
    .md-line-height-30 {line-height:30px;}
    .md-line-height-35 {line-height:35px;}
    .md-line-height-40 {line-height:40px;}
    .md-line-height-45 {line-height:45px;}
    .md-line-height-50 {line-height:50px;}
    .md-line-height-55 {line-height:55px;}
    .md-line-height-60 {line-height:60px;}
    .md-line-height-65 {line-height:65px;}
    .md-line-height-70 {line-height:70px;}
    .md-line-height-75 {line-height:75px;}
    .md-line-height-80 {line-height:80px;}
    .md-line-height-85 {line-height:85px;}
    .md-line-height-90 {line-height:90px;}
    .md-line-height-95 {line-height:95px;}
    .md-line-height-100 {line-height:100px;}
    .md-line-height-110 {line-height:110px;}
    .md-line-height-120 {line-height:120px;}

    /* font size */
    .md-font-size11 {font-size:11px; line-height:14px;}
    .md-font-size12 {font-size:12px; line-height:20px;}
    .md-font-size13 {font-size:13px; line-height:normal;}
    .md-font-size14 {font-size:14px; line-height:normal;}
    .md-font-size15 {font-size:15px; line-height:normal;}
    .md-font-size16 {font-size:16px; line-height:normal;}
    .md-font-size17 {font-size:17px; line-height:normal;}
    .md-font-size18 {font-size:18px; line-height:26px;}
    .md-font-size19 {font-size:19px; line-height:normal;}
    .md-font-size20 {font-size:20px; line-height:normal;}
    .md-font-size22 {font-size:22px; line-height:normal;}
    .md-font-size24 {font-size:24px; line-height:normal;}
    .md-font-size26 {font-size:26px; line-height:normal;}
    .md-font-size28 {font-size:28px; line-height:34px;}
    .md-font-size30 {font-size:30px; line-height:normal;}
    .md-font-size32 {font-size:32px; line-height:normal;}
    .md-font-size34 {font-size:34px; line-height:normal;}
    .md-font-size36 {font-size:36px; line-height:normal;}
    .md-font-size38 {font-size:38px; line-height:normal;}
    .md-font-size40 {font-size:40px; line-height:normal;}
    .md-font-size42 {font-size:42px; line-height:normal;}
    .md-font-size44 {font-size:44px; line-height:normal;}
    .md-font-size46 {font-size:46px; line-height:normal;}
    .md-font-size48 {font-size:48px; line-height:normal;}
    .md-font-size50 {font-size:50px; line-height:normal;}
    .md-font-size80 {font-size:80px; line-height:80px;}
    .md-font-size100 {font-size: 100px; line-height:100px;}
    .md-font-size120 {font-size: 120px; line-height:normal;}
    .md-font-size130 {font-size: 130px; line-height:120px;}    

    /*text-alignment*/
    .md-text-center {text-align: center;}
    .md-text-left {text-align: left;}
    .md-text-right {text-align: right;}

    /*float*/
    .md-float-left{float: left !important;}
    .md-float-right{float: right !important;}
    .md-float-none{float: none !important;}

    /* verticle align */
    .md-vertical-align-middle {vertical-align: middle ;}
    .md-vertical-align-top {vertical-align: top ;}
    .md-vertical-align-bottom {vertical-align: bottom ;}
    .md-absolute-middle-inherit {left: 0; top: 0; position: relative; -webkit-transform: translateX(0) translateY(0); transform: translateX(0) translateY(0); -o-transform: translateX(0) translateY(0); }

    /* image position*/ 
    .md-background-image-left { background-position: left;}
    .md-background-image-right { background-position: right;}
    .md-background-image-center{ background-position: center;}

    /* margin */
    .md-margin-one-all {margin:1%;}
    .md-margin-one-half-all {margin:1.5%;}
    .md-margin-two-all {margin:2%;}
    .md-margin-two-half-all {margin:2.5%;}
    .md-margin-three-all {margin:3%;}
    .md-margin-three-half-all {margin:3.5%;}
    .md-margin-four-all {margin:4%;}
    .md-margin-four-half-all {margin:4.5%;}
    .md-margin-five-all {margin:5%;}
    .md-margin-five-half-all {margin:5.5%;}
    .md-margin-six-all {margin:6%;}
    .md-margin-six-half-all {margin:6.5%;}
    .md-margin-seven-all {margin:7%;}
    .md-margin-seven-half-all {margin:7.5%;}
    .md-margin-eight-all {margin:8%;}
    .md-margin-eight-half-all {margin:8.5%;}
    .md-margin-nine-all {margin:9%;}
    .md-margin-nine-half-all {margin:9.5%;}
    .md-margin-ten-all {margin:10%;}
    .md-margin-ten-half-all {margin:10.5%;}
    .md-margin-eleven-all {margin:11%;}
    .md-margin-eleven-half-all {margin:11.5%;}
    .md-margin-twelve-all {margin:12%;}
    .md-margin-twelve-half-all {margin:12.5%;}
    .md-margin-thirteen-all {margin:13%;}
    .md-margin-thirteen-half-all {margin:13.5%;}
    .md-margin-fourteen-all {margin:14%;}
    .md-margin-fourteen-half-all {margin:14.5%;}
    .md-margin-fifteen-all {margin:15%;}
    .md-margin-fifteen-half-all {margin:15.5%;}
    .md-margin-sixteen-all {margin:16%;}
    .md-margin-sixteen-half-all {margin:16.5%;}
    .md-margin-seventeen-all {margin:17%;}
    .md-margin-seventeen-half-all {margin:17.5%;}
    .md-margin-eighteen-all {margin:18%;}
    .md-margin-eighteen-half-all {margin:18.5%;}
    .md-margin-nineteen-all {margin:19%;}
    .md-margin-nineteen-half-all {margin:19.5%;}
    .md-margin-twenty-all {margin:20%;}
    .md-margin-twenty-half-all {margin:20.5%;}
    .md-margin-twenty-one-all {margin:21%;}
    .md-margin-twenty-one-half-all {margin:21.5%;}
    .md-margin-twenty-two-all {margin:22%;}
    .md-margin-twenty-two-half-all {margin:22.5%;}
    .md-margin-twenty-three-all {margin:23%;}
    .md-margin-twenty-three-half-all {margin:23.5%;}
    .md-margin-twenty-four-all {margin:24%;}
    .md-margin-twenty-four-half-all {margin:24.5%;}
    .md-margin-twenty-five-all {margin:25%;}
    .md-margin-5px-all {margin:5px !important;}
    .md-margin-10px-all {margin:10px !important;}
    .md-margin-15px-all {margin:15px !important;}
    .md-margin-20px-all {margin:20px !important;}
    .md-margin-25px-all {margin:25px !important;}
    .md-margin-30px-all {margin:30px !important;}
    .md-margin-35px-all {margin:35px !important;}
    .md-margin-40px-all {margin:40px !important;}
    .md-margin-45px-all {margin:45px !important;}
    .md-margin-50px-all {margin:50px !important;}
    .md-margin-55px-all {margin:55px !important;}
    .md-margin-60px-all {margin:60px !important;}
    .md-margin-65px-all {margin:65px !important;}
    .md-margin-70px-all {margin:70px !important;}
    .md-margin-75px-all {margin:75px !important;}
    .md-margin-80px-all {margin:80px !important;}
    .md-margin-85px-all {margin:85px !important;}
    .md-margin-90px-all {margin:90px !important;}
    .md-margin-95px-all {margin:95px !important;}
    .md-margin-100px-all {margin:100px !important;}

    .md-no-margin {margin:0 !important;}
    .md-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important;}
    .md-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important;}
    .md-no-margin-top {margin-top:0 !important;}
    .md-no-margin-bottom {margin-bottom:0 !important;}
    .md-no-margin-left {margin-left:0 !important;}
    .md-no-margin-right {margin-right:0 !important;}
    .md-margin-lr-auto {margin-left:auto !important; margin-right:auto !important;}
    .md-margin-auto {margin: 0 auto !important;}

    /* margin top */
    .md-margin-one-top {margin-top:1%;}
    .md-margin-one-half-top {margin-top:1.5%;}
    .md-margin-two-top {margin-top:2%;}
    .md-margin-two-half-top {margin-top:2.5%;}
    .md-margin-three-top {margin-top:3%;}
    .md-margin-three-half-top {margin-top:3.5%;}
    .md-margin-four-top {margin-top:4%;}
    .md-margin-four-half-top {margin-top:4.5%;}
    .md-margin-five-top {margin-top:5%;}
    .md-margin-five-half-top {margin-top:5.5%;}
    .md-margin-six-top {margin-top:6%;}
    .md-margin-six-half-top {margin-top:6.5%;}
    .md-margin-seven-top {margin-top:7%;}
    .md-margin-seven-half-top {margin-top:7.5%;}
    .md-margin-eight-top {margin-top:8%;}
    .md-margin-eight-half-top {margin-top:8.5%;}
    .md-margin-nine-top {margin-top:9%;}
    .md-margin-nine-half-top {margin-top:9.5%;}
    .md-margin-ten-top {margin-top:10%;}
    .md-margin-ten-half-top {margin-top:10.5%;}
    .md-margin-eleven-top {margin-top:11%;}
    .md-margin-eleven-half-top {margin-top:11.5%;}
    .md-margin-twelve-top {margin-top:12%;}
    .md-margin-twelve-half-top {margin-top:12.5%;}
    .md-margin-thirteen-top {margin-top:13%;}
    .md-margin-thirteen-half-top {margin-top:13.5%;}
    .md-margin-fourteen-top {margin-top:14%;}
    .md-margin-fourteen-half-top {margin-top:14.5%;}
    .md-margin-fifteen-top {margin-top:15%;}
    .md-margin-fifteen-half-top {margin-top:15.5%;}
    .md-margin-sixteen-top {margin-top:16%;}
    .md-margin-sixteen-half-top {margin-top:16.5%;}
    .md-margin-seventeen-top {margin-top:17%;}
    .md-margin-seventeen-half-top {margin-top:17.5%;}
    .md-margin-eighteen-top {margin-top:18%;}
    .md-margin-eighteen-half-top {margin-top:18.5%;}
    .md-margin-nineteen-top {margin-top:19%;}
    .md-margin-nineteen-half-top {margin-top:19.5%;}
    .md-margin-twenty-top {margin-top:20%;}
    .md-margin-twenty-half-top {margin-top:20.5%;}
    .md-margin-twenty-one-top {margin-top:21%;}
    .md-margin-twenty-one-half-top {margin-top:21.5%;}
    .md-margin-twenty-two-top {margin-top:22%;}
    .md-margin-twenty-two-half-top {margin-top:22.5%;}
    .md-margin-twenty-top {margin-top:23%;}
    .md-margin-twenty-half-top {margin-top:23.5%;}
    .md-margin-twenty-four-top {margin-top:24%;}
    .md-margin-twenty-four-half-top {margin-top:24.5%;}
    .md-margin-twenty-five-top {margin-top:25%;}
    .md-margin-5px-top {margin-top:5px !important;}
    .md-margin-8px-top {margin-top:8px !important;}
    .md-margin-10px-top {margin-top:10px !important;}
    .md-margin-15px-top {margin-top:15px !important;}
    .md-margin-20px-top {margin-top:20px !important;}
    .md-margin-25px-top {margin-top:25px !important;}
    .md-margin-30px-top {margin-top:30px !important;}
    .md-margin-35px-top {margin-top:35px !important;}
    .md-margin-40px-top {margin-top:40px !important;}
    .md-margin-45px-top {margin-top:45px !important;}
    .md-margin-50px-top {margin-top:50px !important;}
    .md-margin-55px-top {margin-top:55px !important;}
    .md-margin-60px-top {margin-top:60px !important;}
    .md-margin-65px-top {margin-top:65px !important;}
    .md-margin-70px-top {margin-top:70px !important;}
    .md-margin-75px-top {margin-top:75px !important;}
    .md-margin-80px-top {margin-top:80px !important;}
    .md-margin-85px-top {margin-top:85px !important;}
    .md-margin-90px-top {margin-top:90px !important;}
    .md-margin-95px-top {margin-top:95px !important;}
    .md-margin-100px-top {margin-top:100px !important;}

    /* margin bottom */
    .md-margin-one-bottom {margin-bottom:1%;}
    .md-margin-one-half-bottom {margin-bottom:1.5%;}
    .md-margin-two-bottom {margin-bottom:2%;}
    .md-margin-two-half-bottom {margin-bottom:2.5%;}
    .md-margin-three-bottom {margin-bottom:3%;}
    .md-margin-three-half-bottom {margin-bottom:3.5%;}
    .md-margin-four-bottom {margin-bottom:4%;}
    .md-margin-four-half-bottom {margin-bottom:4.5%;}
    .md-margin-five-bottom {margin-bottom:5%;}
    .md-margin-five-half-bottom {margin-bottom:5.5%;}
    .md-margin-six-bottom {margin-bottom:6%;}
    .md-margin-six-half-bottom {margin-bottom:6.5%;}
    .md-margin-seven-bottom {margin-bottom:7%;}
    .md-margin-seven-half-bottom {margin-bottom:7.5%;}
    .md-margin-eight-bottom {margin-bottom:8%;}
    .md-margin-eight-half-bottom {margin-bottom:8.5%;}
    .md-margin-nine-bottom {margin-bottom:9%;}
    .md-margin-nine-half-bottom {margin-bottom:9.5%;}
    .md-margin-ten-bottom {margin-bottom:10%;}
    .md-margin-ten-half-bottom {margin-bottom:10.5%;}
    .md-margin-eleven-bottom {margin-bottom:11%;}
    .md-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .md-margin-twelve-bottom {margin-bottom:12%;}
    .md-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .md-margin-thirteen-bottom {margin-bottom:13%;}
    .md-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .md-margin-fourteen-bottom {margin-bottom:14%;}
    .md-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .md-margin-fifteen-bottom {margin-bottom:15%;}
    .md-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .md-margin-sixteen-bottom {margin-bottom:16%;}
    .md-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .md-margin-seventeen-bottom {margin-bottom:17%;}
    .md-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .md-margin-eighteen-bottom {margin-bottom:18%;}
    .md-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .md-margin-nineteen-bottom {margin-bottom:19%;}
    .md-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .md-margin-twenty-bottom {margin-bottom:20%;}
    .md-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .md-margin-twenty-one-bottom {margin-bottom:21%;}
    .md-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .md-margin-twenty-two-bottom {margin-bottom:22%;}
    .md-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .md-margin-twenty-three-bottom {margin-bottom:23%;}
    .md-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .md-margin-twenty-five-bottom {margin-bottom:24%;}
    .md-margin-twenty-five-half-bottom {margin-bottom:24.5%;}
    .md-margin-twenty-five-bottom {margin-bottom:25%;}
    .md-margin-5px-bottom {margin-bottom:5px !important;}
    .md-margin-8px-bottom {margin-bottom:8px !important;}
    .md-margin-10px-bottom {margin-bottom:10px !important;}
    .md-margin-15px-bottom {margin-bottom:15px !important;}
    .md-margin-20px-bottom {margin-bottom:20px !important;}
    .md-margin-25px-bottom {margin-bottom:25px !important;}
    .md-margin-30px-bottom {margin-bottom:30px !important;}
    .md-margin-35px-bottom {margin-bottom:35px !important;}
    .md-margin-40px-bottom {margin-bottom:40px !important;}
    .md-margin-45px-bottom {margin-bottom:45px !important;}
    .md-margin-50px-bottom {margin-bottom:50px !important;}
    .md-margin-55px-bottom {margin-bottom:55px !important;}
    .md-margin-60px-bottom {margin-bottom:60px !important;}
    .md-margin-65px-bottom {margin-bottom:65px !important;}
    .md-margin-70px-bottom {margin-bottom:70px !important;}
    .md-margin-75px-bottom {margin-bottom:75px !important;}
    .md-margin-80px-bottom {margin-bottom:80px !important;}
    .md-margin-85px-bottom {margin-bottom:85px !important;}
    .md-margin-90px-bottom {margin-bottom:90px !important;}
    .md-margin-95px-bottom {margin-bottom:95px !important;}
    .md-margin-100px-bottom {margin-bottom:100px !important;}

    /* margin right */
    .md-margin-one-right {margin-right:1%;}
    .md-margin-one-half-right {margin-right:1.5%;}
    .md-margin-two-right {margin-right:2%;}
    .md-margin-two-half-right {margin-right:2.5%;}
    .md-margin-three-right {margin-right:3%;}
    .md-margin-three-half-right {margin-right:3.5%;}
    .md-margin-four-right {margin-right:4%;}
    .md-margin-four-half-right {margin-right:4.5%;}
    .md-margin-five-right {margin-right:5%;}
    .md-margin-five-half-right {margin-right:5.5%;}
    .md-margin-six-right {margin-right:6%;}
    .md-margin-six-half-right {margin-right:6.5%;}
    .md-margin-seven-right {margin-right:7%;}
    .md-margin-seven-half-right {margin-right:7.5%;}
    .md-margin-eight-right {margin-right:8%;}
    .md-margin-eight-half-right {margin-right:8.5%;}
    .md-margin-nine-right {margin-right:9%;}
    .md-margin-nine-half-right {margin-right:9.5%;}
    .md-margin-ten-right {margin-right:10%;}
    .md-margin-ten-half-right {margin-right:10.5%;}
    .md-margin-eleven-right {margin-right:11%;}
    .md-margin-eleven-half-right {margin-right:11.5%;}
    .md-margin-twelve-right {margin-right:12%;}
    .md-margin-twelve-half-right {margin-right:12.5%;}
    .md-margin-thirteen-right {margin-right:13%;}
    .md-margin-thirteen-half-right {margin-right:13.5%;}
    .md-margin-fourteen-right {margin-right:14%;}
    .md-margin-fourteen-half-right {margin-right:14.5%;}
    .md-margin-fifteen-right {margin-right:15%;}
    .md-margin-fifteen-half-right {margin-right:15.5%;}
    .md-margin-sixteen-right {margin-right:16%;}
    .md-margin-sixteen-half-right {margin-right:16.5%;}
    .md-margin-seventeen-right {margin-right:17%;}
    .md-margin-seventeen-half-right {margin-right:17.5%;}
    .md-margin-eighteen-right {margin-right:18%;}
    .md-margin-eighteen-half-right {margin-right:18.5%;}
    .md-margin-nineteen-right {margin-right:19%;}
    .md-margin-nineteen-half-right {margin-right:19.5%;}
    .md-margin-twenty-right {margin-right:20%;}
    .md-margin-twenty-half-right {margin-right:20.5%;}
    .md-margin-twenty-one-right {margin-right:21%;}
    .md-margin-twenty-one-half-right {margin-right:21.5%;}
    .md-margin-twenty-two-right {margin-right:22%;}
    .md-margin-twenty-two-half-right {margin-right:22.5%;}
    .md-margin-twenty-three-right {margin-right:23%;}
    .md-margin-twenty-three-half-right {margin-right:23.5%;}
    .md-margin-twenty-four-right {margin-right:24%;}
    .md-margin-twenty-four-half-right {margin-right:24.5%;}
    .md-margin-twenty-five-right {margin-right:25%;}
    .md-margin-10px-right {margin-right:10px !important;}
    .md-margin-15px-right {margin-right:15px !important;}
    .md-margin-20px-right {margin-right:20px !important;}
    .md-margin-25px-right {margin-right:25px !important;}
    .md-margin-30px-right {margin-right:30px !important;}
    .md-margin-35px-right {margin-right:35px !important;}
    .md-margin-40px-right {margin-right:40px !important;}
    .md-margin-45px-right {margin-right:45px !important;}
    .md-margin-50px-right {margin-right:50px !important;}
    .md-margin-55px-right {margin-right:55px !important;}
    .md-margin-60px-right {margin-right:60px !important;}
    .md-margin-65px-right {margin-right:65px !important;}
    .md-margin-70px-right {margin-right:70px !important;}
    .md-margin-75px-right {margin-right:75px !important;}
    .md-margin-80px-right {margin-right:80px !important;}
    .md-margin-85px-right {margin-right:85px !important;}
    .md-margin-90px-right {margin-right:90px !important;}
    .md-margin-95px-right {margin-right:95px !important;}
    .md-margin-100px-right {margin-right:100px !important;}

    /* margin left */
    .md-margin-one-left {margin-left:1%;}
    .md-margin-one-half-left {margin-left:1.5%;}
    .md-margin-two-left {margin-left:2%;}
    .md-margin-two-half-left {margin-left:2.5%;}
    .md-margin-three-left {margin-left:3%;}
    .md-margin-three-half-left {margin-left:3.5%;}
    .md-margin-four-left {margin-left:4%;}
    .md-margin-four-half-left {margin-left:4.5%;}
    .md-margin-five-left {margin-left:5%;}
    .md-margin-five-half-left {margin-left:5.5%;}
    .md-margin-six-left {margin-left:6%;}
    .md-margin-six-half-left {margin-left:6.5%;}
    .md-margin-seven-left {margin-left:7%;}
    .md-margin-seven-half-left {margin-left:7.5%;}
    .md-margin-eight-left {margin-left:8%;}
    .md-margin-eight-half-left {margin-left:8.5%;}
    .md-margin-nine-left {margin-left:9%;}
    .md-margin-nine-half-left {margin-left:9.5%;}
    .md-margin-ten-left {margin-left:10%;}
    .md-margin-ten-half-left {margin-left:10.5%;}
    .md-margin-eleven-left {margin-left:11%;}
    .md-margin-eleven-half-left {margin-left:11.5%;}
    .md-margin-twelve-left {margin-left:12%;}
    .md-margin-twelve-half-left {margin-left:12.5%;}
    .md-margin-thirteen-left {margin-left:13%;}
    .md-margin-thirteen-half-left {margin-left:13.5%;}
    .md-margin-fourteen-left {margin-left:14%;}
    .md-margin-fourteen-half-left {margin-left:14.5%;}
    .md-margin-fifteen-left {margin-left:15%;}
    .md-margin-fifteen-half-left {margin-left:15.5%;}
    .md-margin-sixteen-left {margin-left:16%;}
    .md-margin-sixteen-half-left {margin-left:16.5%;}
    .md-margin-seventeen-left {margin-left:17%;}
    .md-margin-seventeen-half-left {margin-left:17.5%;}
    .md-margin-eighteen-left {margin-left:18%;}
    .md-margin-eighteen-half-left {margin-left:18.5%;}
    .md-margin-nineteen-left {margin-left:19%;}
    .md-margin-nineteen-half-left {margin-left:19.5%;}
    .md-margin-twenty-left {margin-left:20%;}
    .md-margin-twenty-half-left {margin-left:20.5%;}
    .md-margin-twenty-one-left {margin-left:21%;}
    .md-margin-twenty-one-half-left {margin-left:21.5%;}
    .md-margin-twenty-two-left {margin-left:22%;}
    .md-margin-twenty-two-half-left {margin-left:22.5%;}
    .md-margin-twenty-three-left {margin-left:23%;}
    .md-margin-twenty-three-half-left {margin-left:23.5%;}
    .md-margin-twenty-four-left {margin-left:24%;}
    .md-margin-twenty-four-half-left {margin-left:24.5%;}
    .md-margin-twenty-five-left {margin-left:25%;}
    .md-margin-5px-left {margin-left:5px !important;}
    .md-margin-10px-left {margin-left:10px !important;}
    .md-margin-15px-left {margin-left:15px !important;}
    .md-margin-20px-left {margin-left:20px !important;}
    .md-margin-25px-left {margin-left:25px !important;}
    .md-margin-30px-left {margin-left:30px !important;}
    .md-margin-35px-left {margin-left:35px !important;}
    .md-margin-40px-left {margin-left:40px !important;}
    .md-margin-45px-left {margin-left:45px !important;}
    .md-margin-50px-left {margin-left:50px !important;}
    .md-margin-55px-left {margin-left:55px !important;}
    .md-margin-60px-left {margin-left:60px !important;}
    .md-margin-65px-left {margin-left:65px !important;}
    .md-margin-70px-left {margin-left:70px !important;}
    .md-margin-75px-left {margin-left:75px !important;}
    .md-margin-80px-left {margin-left:80px !important;}
    .md-margin-85px-left {margin-left:85px !important;}
    .md-margin-90px-left {margin-left:90px !important;}
    .md-margin-95px-left {margin-left:95px !important;}
    .md-margin-100px-left {margin-left:100px !important;}

    /* margin left right */
    .md-margin-one-lr {margin-left:1%; margin-right:1%;}
    .md-margin-one-half-lr {margin-left:1.5%; margin-right:1.5%;}
    .md-margin-two-lr {margin-left:2%; margin-right:2%;}
    .md-margin-two-half-lr {margin-left:2.5%; margin-right:2.5%;}
    .md-margin-three-lr {margin-left:3%; margin-right:3%;}
    .md-margin-three-half-lr {margin-left:3.5%; margin-right:3.5%;}
    .md-margin-four-lr {margin-left:4%; margin-right:4%;}
    .md-margin-four-half-lr {margin-left:4.5%; margin-right:4.5%;}
    .md-margin-five-lr {margin-left:5%; margin-right:5%;}
    .md-margin-five-half-lr {margin-left:5.5%; margin-right:5.5%;}
    .md-margin-six-lr {margin-left:6%; margin-right:6%;}
    .md-margin-six-half-lr {margin-left:6.5%; margin-right:6.5%;}
    .md-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .md-margin-seven-half-lr {margin-left:7.5%; margin-right:7.5%;}
    .md-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .md-margin-eight-half-lr {margin-left:8.5%; margin-right:8.5%;}
    .md-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .md-margin-nine-half-lr {margin-left:9.5%; margin-right:9.5%;}
    .md-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .md-margin-ten-half-lr {margin-left:10.5%; margin-right:10.5%;}
    .md-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .md-margin-eleven-half-lr {margin-left:11.5%; margin-right:11.5%;}
    .md-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .md-margin-twelve-half-lr {margin-left:12.5%; margin-right:12.5%;}
    .md-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .md-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .md-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .md-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .md-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .md-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .md-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .md-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .md-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .md-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .md-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .md-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .md-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .md-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .md-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .md-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .md-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .md-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .md-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .md-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .md-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .md-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .md-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .md-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .md-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .md-margin-5px-lr {margin-left:5px !important; margin-right:5px !important;}
    .md-margin-10px-lr {margin-left:10px !important; margin-right:10px !important;}
    .md-margin-15px-lr {margin-left:15px !important; margin-right:15px !important;}
    .md-margin-20px-lr {margin-left:20px !important; margin-right:20px !important;}
    .md-margin-25px-lr {margin-left:25px !important; margin-right:25px !important;}
    .md-margin-30px-lr {margin-left:30px !important; margin-right:30px !important;}
    .md-margin-35px-lr {margin-left:35px !important; margin-right:35px !important;}
    .md-margin-40px-lr {margin-left:40px !important; margin-right:40px !important;}
    .md-margin-45px-lr {margin-left:45px !important; margin-right:45px !important;}
    .md-margin-50px-lr {margin-left:50px !important; margin-right:50px !important;}
    .md-margin-55px-lr {margin-left:55px !important; margin-right:55px !important;}
    .md-margin-60px-lr {margin-left:60px !important; margin-right:60px !important;}
    .md-margin-65px-lr {margin-left:65px !important; margin-right:60px !important;}
    .md-margin-70px-lr {margin-left:70px !important; margin-right:65px !important;}
    .md-margin-75px-lr {margin-left:75px !important; margin-right:70px !important;}
    .md-margin-80px-lr {margin-left:80px !important; margin-right:75px !important;}
    .md-margin-85px-lr {margin-left:85px !important; margin-right:80px !important;}
    .md-margin-90px-lr {margin-left:90px !important; margin-right:85px !important;}
    .md-margin-95px-lr {margin-left:95px !important; margin-right:90px !important;}
    .md-margin-100px-lr {margin-left:100px !important; margin-right:100px !important;}

    /* margin top bottom */
    .md-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .md-margin-one-half-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .md-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .md-margin-two-half-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .md-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .md-margin-three-half-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .md-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .md-margin-four-half-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .md-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .md-margin-five-half-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .md-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .md-margin-six-half-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .md-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .md-margin-seven-half-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .md-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .md-margin-eight-half-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .md-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .md-margin-nine-half-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .md-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .md-margin-ten-half-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .md-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .md-margin-eleven-half-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .md-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .md-margin-twelve-half-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .md-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .md-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .md-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .md-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .md-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .md-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .md-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .md-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .md-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .md-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .md-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .md-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .md-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .md-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .md-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .md-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .md-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .md-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .md-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .md-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .md-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .md-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .md-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .md-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .md-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .md-margin-5px-tb {margin-top:5px !important; margin-bottom:5px !important;}
    .md-margin-10px-tb {margin-top:10px !important; margin-bottom:10px !important;}
    .md-margin-15px-tb {margin-top:15px !important; margin-bottom:15px !important;}
    .md-margin-20px-tb {margin-top:20px !important; margin-bottom:20px !important;}
    .md-margin-25px-tb {margin-top:25px !important; margin-bottom:25px !important;}
    .md-margin-30px-tb {margin-top:30px !important; margin-bottom:30px !important;}
    .md-margin-35px-tb {margin-top:35px !important; margin-bottom:35px !important;}
    .md-margin-40px-tb {margin-top:40px !important; margin-bottom:40px !important;}
    .md-margin-45px-tb {margin-top:45px !important; margin-bottom:45px !important;}
    .md-margin-50px-tb {margin-top:50px !important; margin-bottom:50px !important;}
    .md-margin-55px-tb {margin-top:55px !important; margin-bottom:55px !important;}
    .md-margin-60px-tb {margin-top:60px !important; margin-bottom:60px !important;}
    .md-margin-65px-tb {margin-top:65px !important; margin-bottom:60px !important;}
    .md-margin-70px-tb {margin-top:70px !important; margin-bottom:65px !important;}
    .md-margin-75px-tb {margin-top:75px !important; margin-bottom:70px !important;}
    .md-margin-80px-tb {margin-top:80px !important; margin-bottom:75px !important;}
    .md-margin-85px-tb {margin-top:85px !important; margin-bottom:80px !important;}
    .md-margin-90px-tb {margin-top:90px !important; margin-bottom:85px !important;}
    .md-margin-95px-tb {margin-top:95px !important; margin-bottom:90px !important;}
    .md-margin-100px-tb {margin-top:100px !important; margin-bottom:100px !important;}


    /* padding */
    .md-no-padding {padding:0 !important;}
    .md-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important;}
    .md-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important;}
    .md-no-padding-top {padding-top:0 !important;}
    .md-no-padding-bottom {padding-bottom:0 !important;}
    .md-no-padding-left {padding-left:0 !important;}
    .md-no-padding-right {padding-right:0 !important;}
    .md-padding-one-all {padding:1%;}
    .md-padding-one-half-all {padding:1.5%;}
    .md-padding-two-all {padding:2%;}
    .md-padding-two-half-all {padding:2.5%;}
    .md-padding-three-all {padding:3%;}
    .md-padding-three-half-all {padding:3.5%;}
    .md-padding-four-all {padding:4%;}
    .md-padding-four-half-all {padding:4.5%;}
    .md-padding-five-all {padding:5%;}
    .md-padding-five-half-all {padding:5.5%;}
    .md-padding-six-all {padding:6%;}
    .md-padding-six-half-all {padding:6.5%;}
    .md-padding-seven-all {padding:7%;}
    .md-padding-seven-half-all {padding:7.5%;}
    .md-padding-eight-all {padding:8%;}
    .md-padding-eight-half-all {padding:8.5%;}
    .md-padding-nine-all {padding:9%;}
    .md-padding-nine-half-all {padding:9.5%;}
    .md-padding-ten-all {padding:10%;}
    .md-padding-ten-half-all {padding:10.5%;}
    .md-padding-eleven-all {padding:11%;}
    .md-padding-eleven-half-all {padding:11.5%;}
    .md-padding-twelve-all {padding:12%;}
    .md-padding-twelve-half-all {padding:12.5%;}
    .md-padding-thirteen-all {padding:13%;}
    .md-padding-thirteen-half-all {padding:13.5%;}
    .md-padding-fourteen-all {padding:14%;}
    .md-padding-fourteen-half-all {padding:14.5%;}
    .md-padding-fifteen-all {padding:15%;}
    .md-padding-fifteen-half-all {padding:15.5%;}
    .md-padding-sixteen-all {padding:16%;}
    .md-padding-sixteen-half-all {padding:16.5%;}
    .md-padding-seventeen-all {padding:17%;}
    .md-padding-seventeen-half-all {padding:17.5%;}
    .md-padding-eighteen-all {padding:18%;}
    .md-padding-eighteen-half-all {padding:18.5%;}
    .md-padding-nineteen-all {padding:19%;}
    .md-padding-nineteen-half-all {padding:19.5%;}
    .md-padding-twenty-all {padding:20%;}
    .md-padding-twenty-half-all {padding:20.5%;}
    .md-padding-twenty-one-all {padding:21%;}
    .md-padding-twenty-one-half-all {padding:21.5%;}
    .md-padding-twenty-two-all {padding:22%;}
    .md-padding-twenty-two-half-all {padding:22.5%;}
    .md-padding-twenty-three-all {padding:23%;}
    .md-padding-twenty-three-half-all {padding:23.5%;}
    .md-padding-twenty-four-all {padding:24%;}
    .md-padding-twenty-four-half-all {padding:24.5%;}
    .md-padding-twenty-five-all {padding:25%;}
    .md-padding-5px-all {padding:5px !important;}
    .md-padding-10px-all {padding:10px !important;}
    .md-padding-15px-all {padding:15px !important;}
    .md-padding-20px-all {padding:20px !important;}
    .md-padding-25px-all {padding:25px !important;}
    .md-padding-30px-all {padding:30px !important;}
    .md-padding-35px-all {padding:35px !important;}
    .md-padding-40px-all {padding:40px !important;}
    .md-padding-45px-all {padding:45px !important;}
    .md-padding-50px-all {padding:50px !important;}
    .md-padding-55px-all {padding:55px !important;}
    .md-padding-60px-all {padding:60px !important;}
    .md-padding-65px-all {padding:65px !important;}
    .md-padding-70px-all {padding:70px !important;}
    .md-padding-75px-all {padding:75px !important;}
    .md-padding-80px-all {padding:80px !important;}
    .md-padding-85px-all {padding:85px !important;}
    .md-padding-90px-all {padding:90px !important;}
    .md-padding-95px-all {padding:95px !important;}
    .md-padding-100px-all {padding:100px !important;}

    /* padding top */
    .md-padding-one-top {padding-top:1%;}
    .md-padding-one-half-top {padding-top:1.5%;}
    .md-padding-two-top {padding-top:2%;}
    .md-padding-two-half-top {padding-top:2.5%;}
    .md-padding-three-top {padding-top:3%;}
    .md-padding-three-half-top {padding-top:3.5%;}
    .md-padding-four-top {padding-top:4%;}
    .md-padding-four-half-top {padding-top:4.5%;}
    .md-padding-five-top {padding-top:5%;}
    .md-padding-five-half-top {padding-top:5.5%;}
    .md-padding-six-top {padding-top:6%;}
    .md-padding-six-half-top {padding-top:6.5%;}
    .md-padding-seven-top {padding-top:7%;}
    .md-padding-seven-half-top {padding-top:7.5%;}
    .md-padding-eight-top {padding-top:8%;}
    .md-padding-eight-half-top {padding-top:8.5%;}
    .md-padding-nine-top {padding-top:9%;}
    .md-padding-nine-half-top {padding-top:9.5%;}
    .md-padding-ten-top {padding-top:10%;}
    .md-padding-ten-half-top {padding-top:10.5%;}
    .md-padding-eleven-top {padding-top:11%;}
    .md-padding-eleven-half-top {padding-top:11.5%;}
    .md-padding-twelve-top {padding-top:12%;}
    .md-padding-twelve-half-top {padding-top:12.5%;}
    .md-padding-thirteen-top {padding-top:13%;}
    .md-padding-thirteen-half-top {padding-top:13.5%;}
    .md-padding-fourteen-top {padding-top:14%;}
    .md-padding-fourteen-half-top {padding-top:14.5%;}
    .md-padding-fifteen-top {padding-top:15%;}
    .md-padding-fifteen-half-top {padding-top:15.5%;}
    .md-padding-sixteen-top {padding-top:16%;}
    .md-padding-sixteen-half-top {padding-top:16.5%;}
    .md-padding-seventeen-top {padding-top:17%;}
    .md-padding-seventeen-half-top {padding-top:17.5%;}
    .md-padding-eighteen-top {padding-top:18%;}
    .md-padding-eighteen-half-top {padding-top:18.5%;}
    .md-padding-nineteen-top {padding-top:19%;}
    .md-padding-nineteen-half-top {padding-top:19.5%;}
    .md-padding-twenty-top {padding-top:20%;}
    .md-padding-twenty-half-top {padding-top:20.5%;}
    .md-padding-twenty-one-top {padding-top:21%;}
    .md-padding-twenty-one-half-top {padding-top:21.5%;}
    .md-padding-twenty-two-top {padding-top:22%;}
    .md-padding-twenty-two-half-top {padding-top:22.5%;}
    .md-padding-twenty-three-top {padding-top:23%;}
    .md-padding-twenty-three-half-top {padding-top:23.5%;}
    .md-padding-twenty-four-top {padding-top:24%;}
    .md-padding-twenty-four-half-top {padding-top:24.5%;}
    .md-padding-twenty-five-top {padding-top:25%;}
    .md-padding-5px-top {padding-top:5px !important;}
    .md-padding-10px-top {padding-top:10px !important;}
    .md-padding-15px-top {padding-top:15px !important;}
    .md-padding-20px-top {padding-top:20px !important;}
    .md-padding-25px-top {padding-top:25px !important;}
    .md-padding-30px-top {padding-top:30px !important;}
    .md-padding-35px-top {padding-top:35px !important;}
    .md-padding-40px-top {padding-top:40px !important;}
    .md-padding-45px-top {padding-top:45px !important;}
    .md-padding-50px-top {padding-top:50px !important;}
    .md-padding-55px-top {padding-top:55px !important;}
    .md-padding-60px-top {padding-top:60px !important;}
    .md-padding-65px-top {padding-top:65px !important;}
    .md-padding-70px-top {padding-top:70px !important;}
    .md-padding-75px-top {padding-top:75px !important;}
    .md-padding-80px-top {padding-top:80px !important;}
    .md-padding-85px-top {padding-top:85px !important;}
    .md-padding-90px-top {padding-top:90px !important;}
    .md-padding-95px-top {padding-top:95px !important;}
    .md-padding-100px-top {padding-top:100px !important;}

    /* padding bottom */
    .md-padding-one-bottom {padding-bottom:1%;}
    .md-padding-one-half-bottom {padding-bottom:1.5%;}
    .md-padding-two-bottom {padding-bottom:2%;}
    .md-padding-two-half-bottom {padding-bottom:2.5%;}
    .md-padding-three-bottom {padding-bottom:3%;}
    .md-padding-three-half-bottom {padding-bottom:3.5%;}
    .md-padding-four-bottom {padding-bottom:4%;}
    .md-padding-four-half-bottom {padding-bottom:4.5%;}
    .md-padding-five-bottom {padding-bottom:5%;}
    .md-padding-five-half-bottom {padding-bottom:5.5%;}
    .md-padding-six-bottom {padding-bottom:6%;}
    .md-padding-six-half-bottom {padding-bottom:6.5%;}
    .md-padding-seven-bottom {padding-bottom:7%;}
    .md-padding-seven-half-bottom {padding-bottom:7.5%;}
    .md-padding-eight-bottom {padding-bottom:8%;}
    .md-padding-eight-half-bottom {padding-bottom:8.5%;}
    .md-padding-nine-bottom {padding-bottom:9%;}
    .md-padding-nine-half-bottom {padding-bottom:9.5%;}
    .md-padding-ten-bottom {padding-bottom:10%;}
    .md-padding-ten-half-bottom {padding-bottom:10.5%;}
    .md-padding-eleven-bottom {padding-bottom:11%;}
    .md-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .md-padding-twelve-bottom {padding-bottom:12%;}
    .md-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .md-padding-thirteen-bottom {padding-bottom:13%;}
    .md-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .md-padding-fourteen-bottom {padding-bottom:14%;}
    .md-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .md-padding-fifteen-bottom {padding-bottom:15%;}
    .md-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .md-padding-sixteen-bottom {padding-bottom:16%;}
    .md-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .md-padding-seventeen-bottom {padding-bottom:17%;}
    .md-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .md-padding-eighteen-bottom {padding-bottom:18%;}
    .md-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .md-padding-nineteen-bottom {padding-bottom:19%;}
    .md-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .md-padding-twenty-bottom {padding-bottom:20%;}
    .md-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .md-padding-twenty-one-bottom {padding-bottom:21%;}
    .md-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .md-padding-twenty-two-bottom {padding-bottom:22%;}
    .md-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .md-padding-twenty-three-bottom {padding-bottom:23%;}
    .md-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .md-padding-twenty-four-bottom {padding-bottom:24%;}
    .md-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .md-padding-twenty-five-bottom {padding-bottom:25%;}
    .md-padding-5px-bottom {padding-bottom:5px !important;}
    .md-padding-10px-bottom {padding-bottom:10px !important;}
    .md-padding-15px-bottom {padding-bottom:15px !important;}
    .md-padding-20px-bottom {padding-bottom:20px !important;}
    .md-padding-25px-bottom {padding-bottom:25px !important;}
    .md-padding-30px-bottom {padding-bottom:30px !important;}
    .md-padding-35px-bottom {padding-bottom:35px !important;}
    .md-padding-40px-bottom {padding-bottom:40px !important;}
    .md-padding-45px-bottom {padding-bottom:45px !important;}
    .md-padding-50px-bottom {padding-bottom:50px !important;}
    .md-padding-55px-bottom {padding-bottom:55px !important;}
    .md-padding-60px-bottom {padding-bottom:60px !important;}
    .md-padding-65px-bottom {padding-bottom:65px !important;}
    .md-padding-70px-bottom {padding-bottom:70px !important;}
    .md-padding-75px-bottom {padding-bottom:75px !important;}
    .md-padding-80px-bottom {padding-bottom:80px !important;}
    .md-padding-85px-bottom {padding-bottom:85px !important;}
    .md-padding-90px-bottom {padding-bottom:90px !important;}
    .md-padding-95px-bottom {padding-bottom:95px !important;}
    .md-padding-100px-bottom {padding-bottom:100px !important;}

    /* padding right */
    .md-padding-one-right {padding-right:1%;}
    .md-padding-one-half-right {padding-right:1.5%;}
    .md-padding-two-right {padding-right:2%;}
    .md-padding-two-half-right {padding-right:2.5%;}
    .md-padding-three-right {padding-right:3%;}
    .md-padding-three-half-right {padding-right:3.5%;}
    .md-padding-four-right {padding-right:4%;}
    .md-padding-four-half-right {padding-right:4.5%;}
    .md-padding-five-right {padding-right:5%;}
    .md-padding-five-half-right {padding-right:5.5%;}
    .md-padding-six-right {padding-right:6%;}
    .md-padding-six-half-right {padding-right:6.5%;}
    .md-padding-seven-right {padding-right:7%;}
    .md-padding-seven-half-right {padding-right:7.5%;}
    .md-padding-eight-right {padding-right:8%;}
    .md-padding-eight-half-right {padding-right:8.5%;}
    .md-padding-nine-right {padding-right:9%;}
    .md-padding-nine-half-right {padding-right:9.5%;}
    .md-padding-ten-right {padding-right:10%;}
    .md-padding-ten-half-right {padding-right:10.5%;}
    .md-padding-eleven-right {padding-right:11%;}
    .md-padding-eleven-half-right {padding-right:11.5%;}
    .md-padding-twelve-right {padding-right:12%;}
    .md-padding-twelve-half-right {padding-right:12.5%;}
    .md-padding-thirteen-right {padding-right:13%;}
    .md-padding-thirteen-half-right {padding-right:13.5%;}
    .md-padding-fourteen-right {padding-right:14%;}
    .md-padding-fourteen-half-right {padding-right:14.5%;}
    .md-padding-fifteen-right {padding-right:15%;}
    .md-padding-fifteen-half-right {padding-right:15.5%;}
    .md-padding-sixteen-right {padding-right:16%;}
    .md-padding-sixteen-half-right {padding-right:16.5%;}
    .md-padding-seventeen-right {padding-right:17%;}
    .md-padding-seventeen-half-right {padding-right:17.5%;}
    .md-padding-eighteen-right {padding-right:18%;}
    .md-padding-eighteen-half-right {padding-right:18.5%;}
    .md-padding-nineteen-right {padding-right:19%;}
    .md-padding-nineteen-half-right {padding-right:19.5%;}
    .md-padding-twenty-right {padding-right:20%;}
    .md-padding-twenty-half-right {padding-right:20.5%;}
    .md-padding-twenty-one-right {padding-right:21%;}
    .md-padding-twenty-one-half-right {padding-right:21.5%;}
    .md-padding-twenty-two-right {padding-right:22%;}
    .md-padding-twenty-two-half-right {padding-right:22.5%;}
    .md-padding-twenty-three-right {padding-right:23%;}
    .md-padding-twenty-three-half-right {padding-right:23.5%;}
    .md-padding-twenty-four-right {padding-right:24%;}
    .md-padding-twenty-four-half-right {padding-right:24.5%;}
    .md-padding-twenty-five-right {padding-right:25%;}
    .md-padding-5px-right {padding-right:5px !important;}
    .md-padding-10px-right {padding-right:10px !important;}
    .md-padding-15px-right {padding-right:15px !important;}
    .md-padding-20px-right {padding-right:20px !important;}
    .md-padding-25px-right {padding-right:25px !important;}
    .md-padding-30px-right {padding-right:30px !important;}
    .md-padding-35px-right {padding-right:35px !important;}
    .md-padding-40px-right {padding-right:40px !important;}
    .md-padding-45px-right {padding-right:45px !important;}
    .md-padding-50px-right {padding-right:50px !important;}
    .md-padding-55px-right {padding-right:55px !important;}
    .md-padding-60px-right {padding-right:60px !important;}
    .md-padding-65px-right {padding-right:65px !important;}
    .md-padding-70px-right {padding-right:70px !important;}
    .md-padding-75px-right {padding-right:75px !important;}
    .md-padding-80px-right {padding-right:80px !important;}
    .md-padding-85px-right {padding-right:85px !important;}
    .md-padding-90px-right {padding-right:90px !important;}
    .md-padding-95px-right {padding-right:95px !important;}
    .md-padding-100px-right {padding-right:100px !important;}

    /* padding left */
    .md-padding-one-left {padding-left:1%;}
    .md-padding-one-half-left {padding-left:1.5%;}
    .md-padding-two-left {padding-left:2%;}
    .md-padding-two-half-left {padding-left:2.5%;}
    .md-padding-three-left {padding-left:3%;}
    .md-padding-three-half-left {padding-left:3.5%;}
    .md-padding-four-left {padding-left:4%;}
    .md-padding-four-half-left {padding-left:4.5%;}
    .md-padding-five-left {padding-left:5%;}
    .md-padding-five-half-left {padding-left:5.5%;}
    .md-padding-six-left {padding-left:6%;}
    .md-padding-six-half-left {padding-left:6.5%;}
    .md-padding-seven-left {padding-left:7%;}
    .md-padding-seven-half-left {padding-left:7.5%;}
    .md-padding-eight-left {padding-left:8%;}
    .md-padding-eight-half-left {padding-left:8.5%;}
    .md-padding-nine-left {padding-left:9%;}
    .md-padding-nine-half-left {padding-left:9.5%;}
    .md-padding-ten-left {padding-left:10%;}
    .md-padding-ten-half-left {padding-left:10.5%;}
    .md-padding-eleven-left {padding-left:11%;}
    .md-padding-eleven-half-left {padding-left:11.5%;}
    .md-padding-twelve-left {padding-left:12%;}
    .md-padding-twelve-half-left {padding-left:12.5%;}
    .md-padding-thirteen-left {padding-left:13%;}
    .md-padding-thirteen-half-left {padding-left:13.5%;}
    .md-padding-fourteen-left {padding-left:14%;}
    .md-padding-fourteen-half-left {padding-left:14.5%;}
    .md-padding-fifteen-left {padding-left:15%;}
    .md-padding-fifteen-half-left {padding-left:15.5%;}
    .md-padding-sixteen-left {padding-left:16%;}
    .md-padding-sixteen-half-left {padding-left:16.5%;}
    .md-padding-seventeen-left {padding-left:17%;}
    .md-padding-seventeen-half-left {padding-left:17.5%;}
    .md-padding-eighteen-left {padding-left:18%;}
    .md-padding-eighteen-half-left {padding-left:18.5%;}
    .md-padding-nineteen-left {padding-left:19%;}
    .md-padding-nineteen-half-left {padding-left:19.5%;}
    .md-padding-twenty-left {padding-left:20%;}
    .md-padding-twenty-half-left {padding-left:20.5%;}
    .md-padding-twenty-one-left {padding-left:21%;}
    .md-padding-twenty-one-half-left {padding-left:21.5%;}
    .md-padding-twenty-two-left {padding-left:22%;}
    .md-padding-twenty-two-half-left {padding-left:22.5%;}
    .md-padding-twenty-three-left {padding-left:23%;}
    .md-padding-twenty-three-half-left {padding-left:23.5%;}
    .md-padding-twenty-four-left {padding-left:24%;}
    .md-padding-twenty-four-half-left {padding-left:24.5%;}
    .md-padding-twenty-five-left {padding-left:25%;}
    .md-padding-5px-left {padding-left:5px !important;}
    .md-padding-10px-left {padding-left:10px !important;}
    .md-padding-15px-left {padding-left:15px !important;}
    .md-padding-20px-left {padding-left:20px !important;}
    .md-padding-25px-left {padding-left:25px !important;}
    .md-padding-30px-left {padding-left:30px !important;}
    .md-padding-35px-left {padding-left:35px !important;}
    .md-padding-40px-left {padding-left:40px !important;}
    .md-padding-45px-left {padding-left:45px !important;}
    .md-padding-50px-left {padding-left:50px !important;}
    .md-padding-55px-left {padding-left:55px !important;}
    .md-padding-60px-left {padding-left:60px !important;}
    .md-padding-65px-left {padding-left:65px !important;}
    .md-padding-70px-left {padding-left:70px !important;}
    .md-padding-75px-left {padding-left:75px !important;}
    .md-padding-80px-left {padding-left:80px !important;}
    .md-padding-85px-left {padding-left:85px !important;}
    .md-padding-90px-left {padding-left:90px !important;}
    .md-padding-95px-left {padding-left:95px !important;}
    .md-padding-100px-left {padding-left:100px !important;}

    /* padding top bottom */
    .md-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .md-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .md-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .md-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .md-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .md-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .md-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .md-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .md-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .md-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .md-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .md-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .md-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .md-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .md-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .md-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .md-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .md-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .md-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .md-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .md-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .md-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .md-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .md-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .md-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .md-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .md-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .md-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .md-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .md-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .md-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .md-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .md-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .md-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .md-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .md-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .md-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .md-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .md-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .md-padding-twenty-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .md-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .md-padding-twenty-one-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .md-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .md-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .md-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .md-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .md-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .md-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .md-padding-twenty-five-tb {padding-top:25%; padding-bottom:25%;}
    .md-padding-5px-tb {padding-top:5px !important; padding-bottom:5px !important;}
    .md-padding-8px-tb {padding-top:8px !important; padding-bottom:8px !important;}
    .md-padding-10px-tb {padding-top:10px !important; padding-bottom:10px !important;}
    .md-padding-15px-tb {padding-top:15px !important; padding-bottom:15px !important;}
    .md-padding-20px-tb {padding-top:20px !important; padding-bottom:20px !important;}
    .md-padding-25px-tb {padding-top:25px !important; padding-bottom:25px !important;}
    .md-padding-30px-tb {padding-top:30px !important; padding-bottom:30px !important;}
    .md-padding-35px-tb {padding-top:35px !important; padding-bottom:35px !important;}
    .md-padding-40px-tb {padding-top:40px !important; padding-bottom:40px !important;}
    .md-padding-45px-tb {padding-top:45px !important; padding-bottom:45px !important;}
    .md-padding-50px-tb {padding-top:50px !important; padding-bottom:50px !important;}
    .md-padding-55px-tb {padding-top:55px !important; padding-bottom:55px !important;}
    .md-padding-60px-tb {padding-top:60px !important; padding-bottom:60px !important;}
    .md-padding-65px-tb {padding-top:65px !important; padding-bottom:65px !important;}
    .md-padding-70px-tb {padding-top:70px !important; padding-bottom:70px !important;}
    .md-padding-75px-tb {padding-top:75px !important; padding-bottom:75px !important;}
    .md-padding-80px-tb {padding-top:80px !important; padding-bottom:80px !important;}
    .md-padding-85px-tb {padding-top:85px !important; padding-bottom:85px !important;}
    .md-padding-90px-tb {padding-top:90px !important; padding-bottom:90px !important;}
    .md-padding-95px-tb {padding-top:95px !important; padding-bottom:95px !important;}
    .md-padding-100px-tb {padding-top:100px !important; padding-bottom:100px !important;}

    /* padding left right */
    .md-padding-one-lr {padding-left:1%; padding-right:1%;}
    .md-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .md-padding-two-lr {padding-left:2%; padding-right:2%;}
    .md-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .md-padding-three-lr {padding-left:3%; padding-right:3%;}
    .md-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .md-padding-four-lr {padding-left:4%; padding-right:4%;}
    .md-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .md-padding-five-lr {padding-left:5%; padding-right:5%;}
    .md-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .md-padding-six-lr {padding-left:6%; padding-right:6%;}
    .md-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .md-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .md-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .md-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .md-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .md-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .md-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .md-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .md-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .md-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .md-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .md-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .md-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .md-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .md-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .md-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .md-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .md-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .md-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .md-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .md-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .md-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .md-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .md-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .md-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .md-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .md-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .md-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .md-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .md-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .md-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .md-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .md-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .md-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .md-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .md-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .md-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .md-padding-twenty-five-lr {padding-left:25%; padding-right:25%;}
    .md-padding-5px-lr {padding-left:5px !important; padding-right:5px !important;}
    .md-padding-10px-lr {padding-left:10px !important; padding-right:10px !important;}
    .md-padding-15px-lr {padding-left:15px !important; padding-right:15px !important;}
    .md-padding-20px-lr {padding-left:20px !important; padding-right:20px !important;}
    .md-padding-25px-lr {padding-left:25px !important; padding-right:25px !important;}
    .md-padding-30px-lr {padding-left:30px !important; padding-right:30px !important;}
    .md-padding-35px-lr {padding-left:35px !important; padding-right:35px !important;}
    .md-padding-40px-lr {padding-left:40px !important; padding-right:40px !important;}
    .md-padding-45px-lr {padding-left:45px !important; padding-right:45px !important;}
    .md-padding-50px-lr {padding-left:50px !important; padding-right:50px !important;}
    .md-padding-55px-lr {padding-left:55px !important; padding-right:55px !important;}
    .md-padding-60px-lr {padding-left:60px !important; padding-right:60px !important;}
    .md-padding-65px-lr {padding-left:65px !important; padding-right:65px !important;}
    .md-padding-70px-lr {padding-left:70px !important; padding-right:70px !important;}
    .md-padding-75px-lr {padding-left:75px !important; padding-right:75px !important;}
    .md-padding-80px-lr {padding-left:80px !important; padding-right:80px !important;}
    .md-padding-85px-lr {padding-left:85px !important; padding-right:85px !important;}
    .md-padding-90px-lr {padding-left:90px !important; padding-right:90px !important;}
    .md-padding-95px-lr {padding-left:95px !important; padding-right:95px !important;}
    .md-padding-100px-lr {padding-left:100px !important; padding-right:100px !important;}

    /* width */
    .md-width-10 {width:10%;}
    .md-width-15 {width:15%;}
    .md-width-20 {width:20%;}
    .md-width-25 {width:25%;}
    .md-width-30 {width:30%;}
    .md-width-35 {width:35%;}
    .md-width-40 {width:40%;}
    .md-width-45 {width:45%;}
    .md-width-50 {width:50%;}
    .md-width-55 {width:55%;}
    .md-width-60 {width:60%;}
    .md-width-65 {width:65%;}
    .md-width-70 {width:70%;}
    .md-width-75 {width:75%;}
    .md-width-80 {width:80%;}
    .md-width-85 {width:85%;}
    .md-width-90 {width:90%;}
    .md-width-95 {width:95%;}
    .md-width-100 {width:100%;}
    .md-width-auto {width: auto}

    .md-width-50px {width:50px;}
    .md-width-70px {width:70px;}
    .md-width-75px {width:75px;}
    .md-width-80px {width:80px;}
    .md-width-85px {width:85px;}
    .md-width-90px {width:90px;}
    .md-width-95px {width:95px;}
    .md-width-100px {width:100px;}
    .md-width-120px {width:120px;}
    .md-width-130px {width:130px;}
    .md-width-150px {width:150px;}
    .md-width-180px {width:180px;}
    .md-width-200px {width:200px;}
    .md-width-250px {width:250px;}
    .md-width-300px {width:300px;}
    .md-width-350px {width:350px;}
    .md-width-400px {width:400px;}
    .md-width-450px {width:450px;}
    .md-width-500px {width:500px;}
    .md-width-550px {width:550px;}
    .md-width-600px {width:600px;}
    .md-width-650px {width:650px;}
    .md-width-700px {width:700px;}
    .md-width-750px {width:750px;}
    .md-width-800px {width:800px;}
    .md-width-850px {width:850px;}
    .md-width-900px {width:900px;}
    .md-width-950px {width:950px;}
    .md-width-1000px {width:1000px;}

    /* display */
    .md-display-block {display:block !important;}
    .md-overflow-hidden {overflow:hidden !important;}
    .md-overflow-auto {overflow:auto !important;}
    .md-display-inline-block {display:inline-block !important;}
    .md-display-inline {display:inline !important;}
    .md-display-none {display:none !important;}
    .md-display-inherit {display:inherit !important;}
    .md-display-table {display:table !important;}
    .md-display-table-cell {display:table-cell !important;}

    /* position */
    .md-position-inherit {position:inherit}
    .md-position-relative {position:relative; z-index: 5}
    .md-position-absolute {position:absolute;}
    .md-position-fixed {position:fixed;}

    /* boder align */
    .md-border-top {border-top: 1px solid;}
    .md-border-bottom {border-bottom: 1px solid;}
    .md-border-left {border-left: 1px solid;}
    .md-border-right {border-right: 1px solid;}
    .md-border-lr {border-left: 1px solid; border-right: 1px solid;}
    .md-border-tb {border-top: 1px solid; border-bottom: 1px solid;}

    /* border */
    .md-no-border-top {border-top:0 !important;}
    .md-no-border-bottom {border-bottom:0 !important;}
    .md-no-border-right {border-right:0 !important;}
    .md-no-border-left {border-left:0 !important;}

}

@media (max-width: 1050px) {
    .parallax {background-attachment: inherit;}
}

@media (max-width: 991px) {

    .sm-height-auto { height: auto; }

    /* typography */
    h1, h2, h3, h4, h5, h6 {margin:0 0 16px; padding:0; letter-spacing: 0;}
    h1 {font-size:38px;}
    h2 {font-size:30px;}
    h3 {font-size:28px;}
    h4 {font-size:26px;}
    h5 {font-size:22px;}
    h6 {font-size:18px;}
    p { margin: 0 0 20px; }

    /* font size */
    .sm-font-size11 {font-size:11px; line-height:14px;}
    .sm-font-size12 {font-size:12px; line-height:20px;}
    .sm-font-size13 {font-size:13px; line-height:normal;}
    .sm-font-size14 {font-size:14px; line-height:normal;}
    .sm-font-size15 {font-size:15px; line-height:normal;}
    .sm-font-size16 {font-size:16px; line-height:normal;}
    .sm-font-size17 {font-size:17px; line-height:normal;}
    .sm-font-size18 {font-size:18px; line-height:26px;}
    .sm-font-size19 {font-size:19px; line-height:normal;}
    .sm-font-size20 {font-size:20px; line-height:normal;}
    .sm-font-size22 {font-size:22px; line-height:normal;}
    .sm-font-size24 {font-size:24px; line-height:normal;}
    .sm-font-size26 {font-size:26px; line-height:normal;}
    .sm-font-size28 {font-size:28px; line-height:34px;}
    .sm-font-size30 {font-size:30px; line-height:normal;}
    .sm-font-size32 {font-size:32px; line-height:normal;}
    .sm-font-size34 {font-size:34px; line-height:normal;}
    .sm-font-size36 {font-size:36px; line-height:normal;}
    .sm-font-size38 {font-size:38px; line-height:normal;}
    .sm-font-size40 {font-size:40px; line-height:normal;}
    .sm-font-size42 {font-size:42px; line-height:normal;}
    .sm-font-size44 {font-size:44px; line-height:normal;}
    .sm-font-size46 {font-size:46px; line-height:normal;}
    .sm-font-size48 {font-size:48px; line-height:normal;}
    .sm-font-size50 {font-size:50px; line-height:normal;}
    .sm-font-size80 {font-size:80px; line-height:80px;}
    .sm-font-size100 {font-size: 100px; line-height:100px;}
    .sm-font-size120 {font-size: 120px; line-height:normal;}
    .sm-font-size130 {font-size: 130px; line-height:120px;} 

    /* line height */
    .sm-line-height-normal {line-height:normal;}
    .sm-line-height-10 {line-height:10px;}
    .sm-line-height-13 {line-height:13px;}
    .sm-line-height-18 {line-height:18px;}
    .sm-line-height-20 {line-height:20px;}
    .sm-line-height-24 {line-height:24px;}
    .sm-line-height-22 {line-height:22px;}
    .sm-line-height-26 {line-height:26px;}
    .sm-line-height-28 {line-height:28px;}
    .sm-line-height-30 {line-height:30px;}
    .sm-line-height-35 {line-height:35px;}
    .sm-line-height-40 {line-height:40px;}
    .sm-line-height-45 {line-height:45px;}
    .sm-line-height-50 {line-height:50px;}
    .sm-line-height-55 {line-height:55px;}
    .sm-line-height-60 {line-height:60px;}
    .sm-line-height-65 {line-height:65px;}
    .sm-line-height-70 {line-height:70px;}
    .sm-line-height-75 {line-height:75px;}
    .sm-line-height-80 {line-height:80px;}
    .sm-line-height-85 {line-height:85px;}
    .sm-line-height-90 {line-height:90px;}
    .sm-line-height-95 {line-height:95px;}
    .sm-line-height-100 {line-height:100px;}
    .sm-line-height-110 {line-height:110px;}
    .sm-line-height-120 {line-height:120px;}    

    /* custom */
    section {padding: 50px 0;}
    .sm-center-col {float: none; margin: 0 auto;}

    /*float*/
    .sm-float-left{float: left !important;}
    .sm-float-right{float: right !important;}
    .sm-float-none{float: none !important;}
 

    /*text-alignment*/
    .sm-text-center {text-align: center;}
    .sm-text-left {text-align: left;}
    .sm-text-right {text-align: right;}

    /* verticle align */
    .sm-vertical-align-middle {vertical-align: middle;}
    .sm-vertical-align-top {vertical-align: top;}
    .sm-vertical-align-bottom {vertical-align: bottom;}
    .sm-absolute-middle-inherit {left: 0; top: 0; position: relative; -webkit-transform: translateX(0) translateY(0); transform: translateX(0) translateY(0); -o-transform: translateX(0) translateY(0); }    

    /* image position*/ 
    .sm-background-image-left { background-position: left;}
    .sm-background-image-right { background-position: right;}
    .sm-background-image-center{ background-position: center;}

    /* margin */
    .sm-margin-one-all {margin:1%;}
    .sm-margin-one-half-all {margin:1.5%;}
    .sm-margin-two-all {margin:2%;}
    .sm-margin-two-half-all {margin:2.5%;}
    .sm-margin-three-all {margin:3%;}
    .sm-margin-three-half-all {margin:3.5%;}
    .sm-margin-four-all {margin:4%;}
    .sm-margin-four-half-all {margin:4.5%;}
    .sm-margin-five-all {margin:5%;}
    .sm-margin-five-half-all {margin:5.5%;}
    .sm-margin-six-all {margin:6%;}
    .sm-margin-six-half-all {margin:6.5%;}
    .sm-margin-seven-all {margin:7%;}
    .sm-margin-seven-half-all {margin:7.5%;}
    .sm-margin-eight-all {margin:8%;}
    .sm-margin-eight-half-all {margin:8.5%;}
    .sm-margin-nine-all {margin:9%;}
    .sm-margin-nine-half-all {margin:9.5%;}
    .sm-margin-ten-all {margin:10%;}
    .sm-margin-ten-half-all {margin:10.5%;}
    .sm-margin-eleven-all {margin:11%;}
    .sm-margin-eleven-half-all {margin:11.5%;}
    .sm-margin-twelve-all {margin:12%;}
    .sm-margin-twelve-half-all {margin:12.5%;}
    .sm-margin-thirteen-all {margin:13%;}
    .sm-margin-thirteen-half-all {margin:13.5%;}
    .sm-margin-fourteen-all {margin:14%;}
    .sm-margin-fourteen-half-all {margin:14.5%;}
    .sm-margin-fifteen-all {margin:15%;}
    .sm-margin-fifteen-half-all {margin:15.5%;}
    .sm-margin-sixteen-all {margin:16%;}
    .sm-margin-sixteen-half-all {margin:16.5%;}
    .sm-margin-seventeen-all {margin:17%;}
    .sm-margin-seventeen-half-all {margin:17.5%;}
    .sm-margin-eighteen-all {margin:18%;}
    .sm-margin-eighteen-half-all {margin:18.5%;}
    .sm-margin-nineteen-all {margin:19%;}
    .sm-margin-nineteen-half-all {margin:19.5%;}
    .sm-margin-twenty-all {margin:20%;}
    .sm-margin-twenty-half-all {margin:20.5%;}
    .sm-margin-twenty-one-all {margin:21%;}
    .sm-margin-twenty-one-half-all {margin:21.5%;}
    .sm-margin-twenty-two-all {margin:22%;}
    .sm-margin-twenty-two-half-all {margin:22.5%;}
    .sm-margin-twenty-three-all {margin:23%;}
    .sm-margin-twenty-three-half-all {margin:23.5%;}
    .sm-margin-twenty-four-all {margin:24%;}
    .sm-margin-twenty-four-half-all {margin:24.5%;}
    .sm-margin-twenty-five-all {margin:25%;}
    .sm-margin-5px-all {margin:5px !important;}
    .sm-margin-10px-all {margin:10px !important;}
    .sm-margin-15px-all {margin:15px !important;}
    .sm-margin-20px-all {margin:20px !important;}
    .sm-margin-25px-all {margin:25px !important;}
    .sm-margin-30px-all {margin:30px !important;}
    .sm-margin-35px-all {margin:35px !important;}
    .sm-margin-40px-all {margin:40px !important;}
    .sm-margin-45px-all {margin:45px !important;}
    .sm-margin-50px-all {margin:50px !important;}
    .sm-margin-55px-all {margin:55px !important;}
    .sm-margin-60px-all {margin:60px !important;}
    .sm-margin-65px-all {margin:65px !important;}
    .sm-margin-70px-all {margin:70px !important;}
    .sm-margin-75px-all {margin:75px !important;}
    .sm-margin-80px-all {margin:80px !important;}
    .sm-margin-85px-all {margin:85px !important;}
    .sm-margin-90px-all {margin:90px !important;}
    .sm-margin-95px-all {margin:95px !important;}
    .sm-margin-100px-all {margin:100px !important;}

    .sm-no-margin {margin:0 !important;}
    .sm-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important;}
    .sm-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important;}
    .sm-no-margin-top {margin-top:0 !important;}
    .sm-no-margin-bottom {margin-bottom:0 !important;}
    .sm-no-margin-left {margin-left:0 !important;}
    .sm-no-margin-right {margin-right:0 !important;}
    .sm-margin-lr-auto {margin-left:auto !important; margin-right:auto !important;}
    .sm-margin-auto {margin: 0 auto !important;}

    /* margin top */
    .sm-margin-one-top {margin-top:1%;}
    .sm-margin-one-half-top {margin-top:1.5%;}
    .sm-margin-two-top {margin-top:2%;}
    .sm-margin-two-half-top {margin-top:2.5%;}
    .sm-margin-three-top {margin-top:3%;}
    .sm-margin-three-half-top {margin-top:3.5%;}
    .sm-margin-four-top {margin-top:4%;}
    .sm-margin-four-half-top {margin-top:4.5%;}
    .sm-margin-five-top {margin-top:5%;}
    .sm-margin-five-half-top {margin-top:5.5%;}
    .sm-margin-six-top {margin-top:6%;}
    .sm-margin-six-half-top {margin-top:6.5%;}
    .sm-margin-seven-top {margin-top:7%;}
    .sm-margin-seven-half-top {margin-top:7.5%;}
    .sm-margin-eight-top {margin-top:8%;}
    .sm-margin-eight-half-top {margin-top:8.5%;}
    .sm-margin-nine-top {margin-top:9%;}
    .sm-margin-nine-half-top {margin-top:9.5%;}
    .sm-margin-ten-top {margin-top:10%;}
    .sm-margin-ten-half-top {margin-top:10.5%;}
    .sm-margin-eleven-top {margin-top:11%;}
    .sm-margin-eleven-half-top {margin-top:11.5%;}
    .sm-margin-twelve-top {margin-top:12%;}
    .sm-margin-twelve-half-top {margin-top:12.5%;}
    .sm-margin-thirteen-top {margin-top:13%;}
    .sm-margin-thirteen-half-top {margin-top:13.5%;}
    .sm-margin-fourteen-top {margin-top:14%;}
    .sm-margin-fourteen-half-top {margin-top:14.5%;}
    .sm-margin-fifteen-top {margin-top:15%;}
    .sm-margin-fifteen-half-top {margin-top:15.5%;}
    .sm-margin-sixteen-top {margin-top:16%;}
    .sm-margin-sixteen-half-top {margin-top:16.5%;}
    .sm-margin-seventeen-top {margin-top:17%;}
    .sm-margin-seventeen-half-top {margin-top:17.5%;}
    .sm-margin-eighteen-top {margin-top:18%;}
    .sm-margin-eighteen-half-top {margin-top:18.5%;}
    .sm-margin-nineteen-top {margin-top:19%;}
    .sm-margin-nineteen-half-top {margin-top:19.5%;}
    .sm-margin-twenty-top {margin-top:20%;}
    .sm-margin-twenty-half-top {margin-top:20.5%;}
    .sm-margin-twenty-one-top {margin-top:21%;}
    .sm-margin-twenty-one-half-top {margin-top:21.5%;}
    .sm-margin-twenty-two-top {margin-top:22%;}
    .sm-margin-twenty-two-half-top {margin-top:22.5%;}
    .sm-margin-twenty-three-top {margin-top:23%;}
    .sm-margin-twenty-three-half-top {margin-top:23.5%;}
    .sm-margin-twenty-four-top {margin-top:24%;}
    .sm-margin-twenty-four-half-top {margin-top:24.5%;}
    .sm-margin-twenty-five-top {margin-top:25%;}
    .sm-margin-5px-top {margin-top:5px !important;}
    .sm-margin-8px-top {margin-top:8px !important;}
    .sm-margin-10px-top {margin-top:10px !important;}
    .sm-margin-15px-top {margin-top:15px !important;}
    .sm-margin-20px-top {margin-top:20px !important;}
    .sm-margin-25px-top {margin-top:25px !important;}
    .sm-margin-30px-top {margin-top:30px !important;}
    .sm-margin-35px-top {margin-top:35px !important;}
    .sm-margin-40px-top {margin-top:40px !important;}
    .sm-margin-45px-top {margin-top:45px !important;}
    .sm-margin-50px-top {margin-top:50px !important;}
    .sm-margin-55px-top {margin-top:55px !important;}
    .sm-margin-60px-top {margin-top:60px !important;}
    .sm-margin-65px-top {margin-top:65px !important;}
    .sm-margin-70px-top {margin-top:70px !important;}
    .sm-margin-75px-top {margin-top:75px !important;}
    .sm-margin-80px-top {margin-top:80px !important;}
    .sm-margin-85px-top {margin-top:85px !important;}
    .sm-margin-90px-top {margin-top:90px !important;}
    .sm-margin-95px-top {margin-top:95px !important;}
    .sm-margin-100px-top {margin-top:100px !important;}

    /* margin bottom */
    .sm-margin-one-bottom {margin-bottom:1%;}
    .sm-margin-one-half-bottom {margin-bottom:1.5%;}
    .sm-margin-two-bottom {margin-bottom:2%;}
    .sm-margin-two-half-bottom {margin-bottom:2.5%;}
    .sm-margin-three-bottom {margin-bottom:3%;}
    .sm-margin-three-half-bottom {margin-bottom:3.5%;}
    .sm-margin-four-bottom {margin-bottom:4%;}
    .sm-margin-four-half-bottom {margin-bottom:4.5%;}
    .sm-margin-five-bottom {margin-bottom:5%;}
    .sm-margin-five-half-bottom {margin-bottom:5.5%;}
    .sm-margin-six-bottom {margin-bottom:6%;}
    .sm-margin-six-half-bottom {margin-bottom:6.5%;}
    .sm-margin-seven-bottom {margin-bottom:7%;}
    .sm-margin-seven-half-bottom {margin-bottom:7.5%;}
    .sm-margin-eight-bottom {margin-bottom:8%;}
    .sm-margin-eight-half-bottom {margin-bottom:8.5%;}
    .sm-margin-nine-bottom {margin-bottom:9%;}
    .sm-margin-nine-half-bottom {margin-bottom:9.5%;}
    .sm-margin-ten-bottom {margin-bottom:10%;}
    .sm-margin-ten-half-bottom {margin-bottom:10.5%;}
    .sm-margin-eleven-bottom {margin-bottom:11%;}
    .sm-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .sm-margin-twelve-bottom {margin-bottom:12%;}
    .sm-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .sm-margin-thirteen-bottom {margin-bottom:13%;}
    .sm-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .sm-margin-fourteen-bottom {margin-bottom:14%;}
    .sm-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .sm-margin-fifteen-bottom {margin-bottom:15%;}
    .sm-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .sm-margin-sixteen-bottom {margin-bottom:16%;}
    .sm-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .sm-margin-seventeen-bottom {margin-bottom:17%;}
    .sm-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .sm-margin-eighteen-bottom {margin-bottom:18%;}
    .sm-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .sm-margin-nineteen-bottom {margin-bottom:19%;}
    .sm-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .sm-margin-twenty-bottom {margin-bottom:20%;}
    .sm-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .sm-margin-twenty-one-bottom {margin-bottom:21%;}
    .sm-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .sm-margin-twenty-two-bottom {margin-bottom:22%;}
    .sm-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .sm-margin-twenty-three-bottom {margin-bottom:23%;}
    .sm-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .sm-margin-twenty-four-bottom {margin-bottom:24%;}
    .sm-margin-twenty-four-half-bottom {margin-bottom:24.5%;}
    .sm-margin-twenty-five-bottom {margin-bottom:25%;}
    .sm-margin-5px-bottom {margin-bottom:5px !important;}
    .sm-margin-8px-bottom {margin-bottom:8px !important;}
    .sm-margin-10px-bottom {margin-bottom:10px !important;}
    .sm-margin-15px-bottom {margin-bottom:15px !important;}
    .sm-margin-20px-bottom {margin-bottom:20px !important;}
    .sm-margin-25px-bottom {margin-bottom:25px !important;}
    .sm-margin-30px-bottom {margin-bottom:30px !important;}
    .sm-margin-35px-bottom {margin-bottom:35px !important;}
    .sm-margin-40px-bottom {margin-bottom:40px !important;}
    .sm-margin-45px-bottom {margin-bottom:45px !important;}
    .sm-margin-50px-bottom {margin-bottom:50px !important;}
    .sm-margin-55px-bottom {margin-bottom:55px !important;}
    .sm-margin-60px-bottom {margin-bottom:60px !important;}
    .sm-margin-65px-bottom {margin-bottom:65px !important;}
    .sm-margin-70px-bottom {margin-bottom:70px !important;}
    .sm-margin-75px-bottom {margin-bottom:75px !important;}
    .sm-margin-80px-bottom {margin-bottom:80px !important;}
    .sm-margin-85px-bottom {margin-bottom:85px !important;}
    .sm-margin-90px-bottom {margin-bottom:90px !important;}
    .sm-margin-95px-bottom {margin-bottom:95px !important;}
    .sm-margin-100px-bottom {margin-bottom:100px !important;}

    /* margin right */
    .sm-margin-one-right {margin-right:1%;}
    .sm-margin-one-half-right {margin-right:1.5%;}
    .sm-margin-two-right {margin-right:2%;}
    .sm-margin-two-half-right {margin-right:2.5%;}
    .sm-margin-three-right {margin-right:3%;}
    .sm-margin-three-half-right {margin-right:3.5%;}
    .sm-margin-four-right {margin-right:4%;}
    .sm-margin-four-half-right {margin-right:4.5%;}
    .sm-margin-five-right {margin-right:5%;}
    .sm-margin-five-half-right {margin-right:5.5%;}
    .sm-margin-six-right {margin-right:6%;}
    .sm-margin-six-half-right {margin-right:6.5%;}
    .sm-margin-seven-right {margin-right:7%;}
    .sm-margin-seven-half-right {margin-right:7.5%;}
    .sm-margin-eight-right {margin-right:8%;}
    .sm-margin-eight-half-right {margin-right:8.5%;}
    .sm-margin-nine-right {margin-right:9%;}
    .sm-margin-nine-half-right {margin-right:9.5%;}
    .sm-margin-ten-right {margin-right:10%;}
    .sm-margin-ten-half-right {margin-right:10.5%;}
    .sm-margin-eleven-right {margin-right:11%;}
    .sm-margin-eleven-half-right {margin-right:11.5%;}
    .sm-margin-twelve-right {margin-right:12%;}
    .sm-margin-twelve-half-right {margin-right:12.5%;}
    .sm-margin-thirteen-right {margin-right:13%;}
    .sm-margin-thirteen-half-right {margin-right:13.5%;}
    .sm-margin-fourteen-right {margin-right:14%;}
    .sm-margin-fourteen-half-right {margin-right:14.5%;}
    .sm-margin-fifteen-right {margin-right:15%;}
    .sm-margin-fifteen-half-right {margin-right:15.5%;}
    .sm-margin-sixteen-right {margin-right:16%;}
    .sm-margin-sixteen-half-right {margin-right:16.5%;}
    .sm-margin-seventeen-right {margin-right:17%;}
    .sm-margin-seventeen-half-right {margin-right:17.5%;}
    .sm-margin-eighteen-right {margin-right:18%;}
    .sm-margin-eighteen-half-right {margin-right:18.5%;}
    .sm-margin-nineteen-right {margin-right:19%;}
    .sm-margin-nineteen-half-right {margin-right:19.5%;}
    .sm-margin-twenty-right {margin-right:20%;}
    .sm-margin-twenty-half-right {margin-right:20.5%;}
    .sm-margin-twenty-one-right {margin-right:21%;}
    .sm-margin-twenty-one-half-right {margin-right:21.5%;}
    .sm-margin-twenty-two-right {margin-right:22%;}
    .sm-margin-twenty-two-half-right {margin-right:22.5%;}
    .sm-margin-twenty-three-right {margin-right:23%;}
    .sm-margin-twenty-three-half-right {margin-right:23.5%;}
    .sm-margin-twenty-four-right {margin-right:24%;}
    .sm-margin-twenty-four-half-right {margin-right:24.5%;}
    .sm-margin-twenty-five-right {margin-right:25%;}
    .sm-margin-10px-right {margin-right:10px !important;}
    .sm-margin-15px-right {margin-right:15px !important;}
    .sm-margin-20px-right {margin-right:20px !important;}
    .sm-margin-25px-right {margin-right:25px !important;}
    .sm-margin-30px-right {margin-right:30px !important;}
    .sm-margin-35px-right {margin-right:35px !important;}
    .sm-margin-40px-right {margin-right:40px !important;}
    .sm-margin-45px-right {margin-right:45px !important;}
    .sm-margin-50px-right {margin-right:50px !important;}
    .sm-margin-55px-right {margin-right:55px !important;}
    .sm-margin-60px-right {margin-right:60px !important;}
    .sm-margin-65px-right {margin-right:65px !important;}
    .sm-margin-70px-right {margin-right:70px !important;}
    .sm-margin-75px-right {margin-right:75px !important;}
    .sm-margin-80px-right {margin-right:80px !important;}
    .sm-margin-85px-right {margin-right:85px !important;}
    .sm-margin-90px-right {margin-right:90px !important;}
    .sm-margin-95px-right {margin-right:95px !important;}
    .sm-margin-100px-right {margin-right:100px !important;}

    /* margin left */
    .sm-margin-one-left {margin-left:1%;}
    .sm-margin-one-half-left {margin-left:1.5%;}
    .sm-margin-two-left {margin-left:2%;}
    .sm-margin-two-half-left {margin-left:2.5%;}
    .sm-margin-three-left {margin-left:3%;}
    .sm-margin-three-half-left {margin-left:3.5%;}
    .sm-margin-four-left {margin-left:4%;}
    .sm-margin-four-half-left {margin-left:4.5%;}
    .sm-margin-five-left {margin-left:5%;}
    .sm-margin-five-half-left {margin-left:5.5%;}
    .sm-margin-six-left {margin-left:6%;}
    .sm-margin-six-half-left {margin-left:6.5%;}
    .sm-margin-seven-left {margin-left:7%;}
    .sm-margin-seven-half-left {margin-left:7.5%;}
    .sm-margin-eight-left {margin-left:8%;}
    .sm-margin-eight-half-left {margin-left:8.5%;}
    .sm-margin-nine-left {margin-left:9%;}
    .sm-margin-nine-half-left {margin-left:9.5%;}
    .sm-margin-ten-left {margin-left:10%;}
    .sm-margin-ten-half-left {margin-left:10.5%;}
    .sm-margin-eleven-left {margin-left:11%;}
    .sm-margin-eleven-half-left {margin-left:11.5%;}
    .sm-margin-twelve-left {margin-left:12%;}
    .sm-margin-twelve-half-left {margin-left:12.5%;}
    .sm-margin-thirteen-left {margin-left:13%;}
    .sm-margin-thirteen-half-left {margin-left:13.5%;}
    .sm-margin-fourteen-left {margin-left:14%;}
    .sm-margin-fourteen-half-left {margin-left:14.5%;}
    .sm-margin-fifteen-left {margin-left:15%;}
    .sm-margin-fifteen-half-left {margin-left:15.5%;}
    .sm-margin-sixteen-left {margin-left:16%;}
    .sm-margin-sixteen-half-left {margin-left:16.5%;}
    .sm-margin-seventeen-left {margin-left:17%;}
    .sm-margin-seventeen-half-left {margin-left:17.5%;}
    .sm-margin-eighteen-left {margin-left:18%;}
    .sm-margin-eighteen-half-left {margin-left:18.5%;}
    .sm-margin-nineteen-left {margin-left:19%;}
    .sm-margin-nineteen-half-left {margin-left:19.5%;}
    .sm-margin-twenty-left {margin-left:20%;}
    .sm-margin-twenty-half-left {margin-left:20.5%;}
    .sm-margin-twenty-one-left {margin-left:21%;}
    .sm-margin-twenty-one-half-left {margin-left:21.5%;}
    .sm-margin-twenty-two-left {margin-left:22%;}
    .sm-margin-twenty-two-half-left {margin-left:22.5%;}
    .sm-margin-twenty-three-left {margin-left:23%;}
    .sm-margin-twenty-three-half-left {margin-left:23.5%;}
    .sm-margin-twenty-four-left {margin-left:24%;}
    .sm-margin-twenty-four-half-left {margin-left:24.5%;}
    .sm-margin-twenty-five-left {margin-left:25%;}
    .sm-margin-5px-left {margin-left:5px !important;}
    .sm-margin-10px-left {margin-left:10px !important;}
    .sm-margin-15px-left {margin-left:15px !important;}
    .sm-margin-20px-left {margin-left:20px !important;}
    .sm-margin-25px-left {margin-left:25px !important;}
    .sm-margin-30px-left {margin-left:30px !important;}
    .sm-margin-35px-left {margin-left:35px !important;}
    .sm-margin-40px-left {margin-left:40px !important;}
    .sm-margin-45px-left {margin-left:45px !important;}
    .sm-margin-50px-left {margin-left:50px !important;}
    .sm-margin-55px-left {margin-left:55px !important;}
    .sm-margin-60px-left {margin-left:60px !important;}
    .sm-margin-65px-left {margin-left:65px !important;}
    .sm-margin-70px-left {margin-left:70px !important;}
    .sm-margin-75px-left {margin-left:75px !important;}
    .sm-margin-80px-left {margin-left:80px !important;}
    .sm-margin-85px-left {margin-left:85px !important;}
    .sm-margin-90px-left {margin-left:90px !important;}
    .sm-margin-95px-left {margin-left:95px !important;}
    .sm-margin-100px-left {margin-left:100px !important;}

    /* margin left right */
    .sm-margin-one-lr {margin-left:1%; margin-right:1%;}
    .sm-margin-one-lr {margin-left:1.5%; margin-right:1.5%;}
    .sm-margin-two-lr {margin-left:2%; margin-right:2%;}
    .sm-margin-two-lr {margin-left:2.5%; margin-right:2.5%;}
    .sm-margin-three-lr {margin-left:3%; margin-right:3%;}
    .sm-margin-three-lr {margin-left:3.5%; margin-right:3.5%;}
    .sm-margin-four-lr {margin-left:4%; margin-right:4%;}
    .sm-margin-four-lr {margin-left:4.5%; margin-right:4.5%;}
    .sm-margin-five-lr {margin-left:5%; margin-right:5%;}
    .sm-margin-five-lr {margin-left:5.5%; margin-right:5.5%;}
    .sm-margin-six-lr {margin-left:6%; margin-right:6%;}
    .sm-margin-six-lr {margin-left:6.5%; margin-right:6.5%;}
    .sm-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .sm-margin-seven-lr {margin-left:7.5%; margin-right:7.5%;}
    .sm-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .sm-margin-eight-lr {margin-left:8.5%; margin-right:8.5%;}
    .sm-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .sm-margin-nine-lr {margin-left:9.5%; margin-right:9.5%;}
    .sm-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .sm-margin-ten-lr {margin-left:10.5%; margin-right:10.5%;}
    .sm-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .sm-margin-eleven-lr {margin-left:11.5%; margin-right:11.5%;}
    .sm-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .sm-margin-twelve-lr {margin-left:12.5%; margin-right:12.5%;}
    .sm-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .sm-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .sm-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .sm-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .sm-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .sm-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .sm-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .sm-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .sm-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .sm-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .sm-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .sm-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .sm-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .sm-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .sm-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .sm-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .sm-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .sm-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .sm-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .sm-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .sm-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .sm-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .sm-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .sm-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .sm-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .sm-margin-5px-lr {margin-left:5px !important; margin-right:5px !important;}
    .sm-margin-10px-lr {margin-left:10px !important; margin-right:10px !important;}
    .sm-margin-15px-lr {margin-left:15px !important; margin-right:15px !important;}
    .sm-margin-20px-lr {margin-left:20px !important; margin-right:20px !important;}
    .sm-margin-25px-lr {margin-left:25px !important; margin-right:25px !important;}
    .sm-margin-30px-lr {margin-left:30px !important; margin-right:30px !important;}
    .sm-margin-35px-lr {margin-left:35px !important; margin-right:35px !important;}
    .sm-margin-40px-lr {margin-left:40px !important; margin-right:40px !important;}
    .sm-margin-45px-lr {margin-left:45px !important; margin-right:45px !important;}
    .sm-margin-50px-lr {margin-left:50px !important; margin-right:50px !important;}
    .sm-margin-55px-lr {margin-left:55px !important; margin-right:55px !important;}
    .sm-margin-60px-lr {margin-left:60px !important; margin-right:60px !important;}
    .sm-margin-65px-lr {margin-left:65px !important; margin-right:65px !important;}
    .sm-margin-70px-lr {margin-left:70px !important; margin-right:70px !important;}
    .sm-margin-75px-lr {margin-left:75px !important; margin-right:75px !important;}
    .sm-margin-80px-lr {margin-left:80px !important; margin-right:80px !important;}
    .sm-margin-85px-lr {margin-left:85px !important; margin-right:85px !important;}
    .sm-margin-90px-lr {margin-left:90px !important; margin-right:90px !important;}
    .sm-margin-95px-lr {margin-left:95px !important; margin-right:95px !important;}
    .sm-margin-100px-lr {margin-left:100px !important; margin-right:100px !important;}

    /* margin top bottom */
    .sm-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .sm-margin-one-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .sm-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .sm-margin-two-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .sm-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .sm-margin-three-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .sm-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .sm-margin-four-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .sm-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .sm-margin-five-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .sm-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .sm-margin-six-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .sm-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .sm-margin-seven-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .sm-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .sm-margin-eight-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .sm-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .sm-margin-nine-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .sm-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .sm-margin-ten-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .sm-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .sm-margin-eleven-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .sm-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .sm-margin-twelve-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .sm-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .sm-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .sm-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .sm-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .sm-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .sm-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .sm-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .sm-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .sm-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .sm-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .sm-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .sm-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .sm-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .sm-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .sm-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .sm-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .sm-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .sm-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .sm-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .sm-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .sm-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .sm-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .sm-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .sm-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .sm-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .sm-margin-5px-tb {margin-top:5px !important; margin-bottom:5px !important;}
    .sm-margin-10px-tb {margin-top:10px !important; margin-bottom:10px !important;}
    .sm-margin-15px-tb {margin-top:15px !important; margin-bottom:15px !important;}
    .sm-margin-20px-tb {margin-top:20px !important; margin-bottom:20px !important;}
    .sm-margin-25px-tb {margin-top:25px !important; margin-bottom:25px !important;}
    .sm-margin-30px-tb {margin-top:30px !important; margin-bottom:30px !important;}
    .sm-margin-35px-tb {margin-top:35px !important; margin-bottom:35px !important;}
    .sm-margin-40px-tb {margin-top:40px !important; margin-bottom:40px !important;}
    .sm-margin-45px-tb {margin-top:45px !important; margin-bottom:45px !important;}
    .sm-margin-50px-tb {margin-top:50px !important; margin-bottom:50px !important;}
    .sm-margin-55px-tb {margin-top:55px !important; margin-bottom:55px !important;}
    .sm-margin-60px-tb {margin-top:60px !important; margin-bottom:60px !important;}
    .sm-margin-65px-tb {margin-top:65px !important; margin-bottom:65px !important;}
    .sm-margin-70px-tb {margin-top:70px !important; margin-bottom:70px !important;}
    .sm-margin-75px-tb {margin-top:75px !important; margin-bottom:75px !important;}
    .sm-margin-80px-tb {margin-top:80px !important; margin-bottom:80px !important;}
    .sm-margin-85px-tb {margin-top:85px !important; margin-bottom:85px !important;}
    .sm-margin-90px-tb {margin-top:90px !important; margin-bottom:90px !important;}
    .sm-margin-95px-tb {margin-top:95px !important; margin-bottom:95px !important;}
    .sm-margin-100px-tb {margin-top:100px !important; margin-bottom:100px !important;}

 
    /* padding */
    .sm-no-padding {padding:0 !important;}
    .sm-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important;}
    .sm-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important;}
    .sm-no-padding-top {padding-top:0 !important;}
    .sm-no-padding-bottom {padding-bottom:0 !important;}
    .sm-no-padding-left {padding-left:0 !important;}
    .sm-no-padding-right {padding-right:0 !important;}
    .sm-padding-one-all {padding:1%;}
    .sm-padding-one-half-all {padding:1.5%;}
    .sm-padding-two-all {padding:2%;}
    .sm-padding-two-half-all {padding:2.5%;}
    .sm-padding-three-all {padding:3%;}
    .sm-padding-three-half-all {padding:3.5%;}
    .sm-padding-four-all {padding:4%;}
    .sm-padding-four-half-all {padding:4.5%;}
    .sm-padding-five-all {padding:5%;}
    .sm-padding-five-half-all {padding:5.5%;}
    .sm-padding-six-all {padding:6%;}
    .sm-padding-six-half-all {padding:6.5%;}
    .sm-padding-seven-all {padding:7%;}
    .sm-padding-seven-half-all {padding:7.5%;}
    .sm-padding-eight-all {padding:8%;}
    .sm-padding-eight-half-all {padding:8.5%;}
    .sm-padding-nine-all {padding:9%;}
    .sm-padding-nine-half-all {padding:9.5%;}
    .sm-padding-ten-all {padding:10%;}
    .sm-padding-ten-half-all {padding:10.5%;}
    .sm-padding-eleven-all {padding:11%;}
    .sm-padding-eleven-half-all {padding:11.5%;}
    .sm-padding-twelve-all {padding:12%;}
    .sm-padding-twelve-half-all {padding:12.5%;}
    .sm-padding-thirteen-all {padding:13%;}
    .sm-padding-thirteen-half-all {padding:13.5%;}
    .sm-padding-fourteen-all {padding:14%;}
    .sm-padding-fourteen-half-all {padding:14.5%;}
    .sm-padding-fifteen-all {padding:15%;}
    .sm-padding-fifteen-half-all {padding:15.5%;}
    .sm-padding-sixteen-all {padding:16%;}
    .sm-padding-sixteen-half-all {padding:16.5%;}
    .sm-padding-seventeen-all {padding:17%;}
    .sm-padding-seventeen-half-all {padding:17.5%;}
    .sm-padding-eighteen-all {padding:18%;}
    .sm-padding-eighteen-half-all {padding:18.5%;}
    .sm-padding-nineteen-all {padding:19%;}
    .sm-padding-nineteen-half-all {padding:19.5%;}
    .sm-padding-twenty-all {padding:20%;}
    .sm-padding-twenty-half-all {padding:20.5%;}
    .sm-padding-twenty-one-all {padding:21%;}
    .sm-padding-twenty-one-half-all {padding:21.5%;}
    .sm-padding-twenty-two-all {padding:22%;}
    .sm-padding-twenty-two-half-all {padding:22.5%;}
    .sm-padding-twenty-three-all {padding:23%;}
    .sm-padding-twenty-three-half-all {padding:23.5%;}
    .sm-padding-twenty-four-all {padding:24%;}
    .sm-padding-twenty-four-half-all {padding:24.5%;}
    .sm-padding-twenty-five-all {padding:25%;}
    .sm-padding-5px-all {padding:5px !important;}
    .sm-padding-10px-all {padding:10px !important;}
    .sm-padding-15px-all {padding:15px !important;}
    .sm-padding-20px-all {padding:20px !important;}
    .sm-padding-25px-all {padding:25px !important;}
    .sm-padding-30px-all {padding:30px !important;}
    .sm-padding-35px-all {padding:35px !important;}
    .sm-padding-40px-all {padding:40px !important;}
    .sm-padding-45px-all {padding:45px !important;}
    .sm-padding-50px-all {padding:50px !important;}
    .sm-padding-55px-all {padding:55px !important;}
    .sm-padding-60px-all {padding:60px !important;}
    .sm-padding-65px-all {padding:65px !important;}
    .sm-padding-70px-all {padding:70px !important;}
    .sm-padding-75px-all {padding:75px !important;}
    .sm-padding-80px-all {padding:80px !important;}
    .sm-padding-85px-all {padding:85px !important;}
    .sm-padding-90px-all {padding:90px !important;}
    .sm-padding-95px-all {padding:95px !important;}
    .sm-padding-100px-all {padding:100px !important;}

    /* padding top */
    .sm-padding-one-top {padding-top:1%;}
    .sm-padding-one-half-top {padding-top:1.5%;}
    .sm-padding-two-top {padding-top:2%;}
    .sm-padding-two-half-top {padding-top:2.5%;}
    .sm-padding-three-top {padding-top:3%;}
    .sm-padding-three-half-top {padding-top:3.5%;}
    .sm-padding-four-top {padding-top:4%;}
    .sm-padding-four-half-top {padding-top:4.5%;}
    .sm-padding-five-top {padding-top:5%;}
    .sm-padding-five-half-top {padding-top:5.5%;}
    .sm-padding-six-top {padding-top:6%;}
    .sm-padding-six-half-top {padding-top:6.5%;}
    .sm-padding-seven-top {padding-top:7%;}
    .sm-padding-seven-half-top {padding-top:7.5%;}
    .sm-padding-eight-top {padding-top:8%;}
    .sm-padding-eight-half-top {padding-top:8.5%;}
    .sm-padding-nine-top {padding-top:9%;}
    .sm-padding-nine-half-top {padding-top:9.5%;}
    .sm-padding-ten-top {padding-top:10%;}
    .sm-padding-ten-half-top {padding-top:10.5%;}
    .sm-padding-eleven-top {padding-top:11%;}
    .sm-padding-eleven-half-top {padding-top:11.5%;}
    .sm-padding-twelve-top {padding-top:12%;}
    .sm-padding-twelve-half-top {padding-top:12.5%;}
    .sm-padding-thirteen-top {padding-top:13%;}
    .sm-padding-thirteen-half-top {padding-top:13.5%;}
    .sm-padding-fourteen-top {padding-top:14%;}
    .sm-padding-fourteen-half-top {padding-top:14.5%;}
    .sm-padding-fifteen-top {padding-top:15%;}
    .sm-padding-fifteen-half-top {padding-top:15.5%;}
    .sm-padding-sixteen-top {padding-top:16%;}
    .sm-padding-sixteen-half-top {padding-top:16.5%;}
    .sm-padding-seventeen-top {padding-top:17%;}
    .sm-padding-seventeen-half-top {padding-top:17.5%;}
    .sm-padding-eighteen-top {padding-top:18%;}
    .sm-padding-eighteen-half-top {padding-top:18.5%;}
    .sm-padding-nineteen-top {padding-top:19%;}
    .sm-padding-nineteen-half-top {padding-top:19.5%;}
    .sm-padding-twenty-top {padding-top:20%;}
    .sm-padding-twenty-half-top {padding-top:20.5%;}
    .sm-padding-twenty-one-top {padding-top:21%;}
    .sm-padding-twenty-one-half-top {padding-top:21.5%;}
    .sm-padding-twenty-two-top {padding-top:22%;}
    .sm-padding-twenty-two-half-top {padding-top:22.5%;}
    .sm-padding-twenty-three-top {padding-top:23%;}
    .sm-padding-twenty-three-half-top {padding-top:23.5%;}
    .sm-padding-twenty-four-top {padding-top:24%;}
    .sm-padding-twenty-four-half-top {padding-top:24.5%;}
    .sm-padding-twenty-five-top {padding-top:25%;}
    .sm-padding-5px-top {padding-top:5px !important;}
    .sm-padding-10px-top {padding-top:10px !important;}
    .sm-padding-15px-top {padding-top:15px !important;}
    .sm-padding-20px-top {padding-top:20px !important;}
    .sm-padding-25px-top {padding-top:25px !important;}
    .sm-padding-30px-top {padding-top:30px !important;}
    .sm-padding-35px-top {padding-top:35px !important;}
    .sm-padding-40px-top {padding-top:40px !important;}
    .sm-padding-45px-top {padding-top:45px !important;}
    .sm-padding-50px-top {padding-top:50px !important;}
    .sm-padding-55px-top {padding-top:55px !important;}
    .sm-padding-60px-top {padding-top:60px !important;}
    .sm-padding-65px-top {padding-top:65px !important;}
    .sm-padding-70px-top {padding-top:70px !important;}
    .sm-padding-75px-top {padding-top:75px !important;}
    .sm-padding-80px-top {padding-top:80px !important;}
    .sm-padding-85px-top {padding-top:85px !important;}
    .sm-padding-90px-top {padding-top:90px !important;}
    .sm-padding-95px-top {padding-top:95px !important;}
    .sm-padding-100px-top {padding-top:100px !important;}

    /* padding bottom */
    .sm-padding-one-bottom {padding-bottom:1%;}
    .sm-padding-one-half-bottom {padding-bottom:1.5%;}
    .sm-padding-two-bottom {padding-bottom:2%;}
    .sm-padding-two-half-bottom {padding-bottom:2.5%;}
    .sm-padding-three-bottom {padding-bottom:3%;}
    .sm-padding-three-half-bottom {padding-bottom:3.5%;}
    .sm-padding-four-bottom {padding-bottom:4%;}
    .sm-padding-four-half-bottom {padding-bottom:4.5%;}
    .sm-padding-five-bottom {padding-bottom:5%;}
    .sm-padding-five-half-bottom {padding-bottom:5.5%;}
    .sm-padding-six-bottom {padding-bottom:6%;}
    .sm-padding-six-half-bottom {padding-bottom:6.5%;}
    .sm-padding-seven-bottom {padding-bottom:7%;}
    .sm-padding-seven-half-bottom {padding-bottom:7.5%;}
    .sm-padding-eight-bottom {padding-bottom:8%;}
    .sm-padding-eight-half-bottom {padding-bottom:8.5%;}
    .sm-padding-nine-bottom {padding-bottom:9%;}
    .sm-padding-nine-half-bottom {padding-bottom:9.5%;}
    .sm-padding-ten-bottom {padding-bottom:10%;}
    .sm-padding-ten-half-bottom {padding-bottom:10.5%;}
    .sm-padding-eleven-bottom {padding-bottom:11%;}
    .sm-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .sm-padding-twelve-bottom {padding-bottom:12%;}
    .sm-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .sm-padding-thirteen-bottom {padding-bottom:13%;}
    .sm-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .sm-padding-fourteen-bottom {padding-bottom:14%;}
    .sm-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .sm-padding-fifteen-bottom {padding-bottom:15%;}
    .sm-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .sm-padding-sixteen-bottom {padding-bottom:16%;}
    .sm-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .sm-padding-seventeen-bottom {padding-bottom:17%;}
    .sm-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .sm-padding-eighteen-bottom {padding-bottom:18%;}
    .sm-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .sm-padding-nineteen-bottom {padding-bottom:19%;}
    .sm-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .sm-padding-twenty-bottom {padding-bottom:20%;}
    .sm-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .sm-padding-twenty-one-bottom {padding-bottom:21%;}
    .sm-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .sm-padding-twenty-two-bottom {padding-bottom:22%;}
    .sm-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .sm-padding-twenty-three-bottom {padding-bottom:23%;}
    .sm-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .sm-padding-twenty-four-bottom {padding-bottom:24%;}
    .sm-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .sm-padding-twenty-five-bottom {padding-bottom:25%;}
    .sm-padding-5px-bottom {padding-bottom:5px !important;}
    .sm-padding-10px-bottom {padding-bottom:10px !important;}
    .sm-padding-15px-bottom {padding-bottom:15px !important;}
    .sm-padding-20px-bottom {padding-bottom:20px !important;}
    .sm-padding-25px-bottom {padding-bottom:25px !important;}
    .sm-padding-30px-bottom {padding-bottom:30px !important;}
    .sm-padding-35px-bottom {padding-bottom:35px !important;}
    .sm-padding-40px-bottom {padding-bottom:40px !important;}
    .sm-padding-45px-bottom {padding-bottom:45px !important;}
    .sm-padding-50px-bottom {padding-bottom:50px !important;}
    .sm-padding-55px-bottom {padding-bottom:55px !important;}
    .sm-padding-60px-bottom {padding-bottom:60px !important;}
    .sm-padding-65px-bottom {padding-bottom:65px !important;}
    .sm-padding-70px-bottom {padding-bottom:70px !important;}
    .sm-padding-75px-bottom {padding-bottom:75px !important;}
    .sm-padding-80px-bottom {padding-bottom:80px !important;}
    .sm-padding-85px-bottom {padding-bottom:85px !important;}
    .sm-padding-90px-bottom {padding-bottom:90px !important;}
    .sm-padding-95px-bottom {padding-bottom:95px !important;}
    .sm-padding-100px-bottom {padding-bottom:100px !important;}

    /* padding right */
    .sm-padding-one-right {padding-right:1%;}
    .sm-padding-one-half-right {padding-right:1.5%;}
    .sm-padding-two-right {padding-right:2%;}
    .sm-padding-two-half-right {padding-right:2.5%;}
    .sm-padding-three-right {padding-right:3%;}
    .sm-padding-three-half-right {padding-right:3.5%;}
    .sm-padding-four-right {padding-right:4%;}
    .sm-padding-four-half-right {padding-right:4.5%;}
    .sm-padding-five-right {padding-right:5%;}
    .sm-padding-five-half-right {padding-right:5.5%;}
    .sm-padding-six-right {padding-right:6%;}
    .sm-padding-six-half-right {padding-right:6.5%;}
    .sm-padding-seven-right {padding-right:7%;}
    .sm-padding-seven-half-right {padding-right:7.5%;}
    .sm-padding-eight-right {padding-right:8%;}
    .sm-padding-eight-half-right {padding-right:8.5%;}
    .sm-padding-nine-right {padding-right:9%;}
    .sm-padding-nine-half-right {padding-right:9.5%;}
    .sm-padding-ten-right {padding-right:10%;}
    .sm-padding-ten-half-right {padding-right:10.5%;}
    .sm-padding-eleven-right {padding-right:11%;}
    .sm-padding-eleven-half-right {padding-right:11.5%;}
    .sm-padding-twelve-right {padding-right:12%;}
    .sm-padding-twelve-half-right {padding-right:12.5%;}
    .sm-padding-thirteen-right {padding-right:13%;}
    .sm-padding-thirteen-half-right {padding-right:13.5%;}
    .sm-padding-fourteen-right {padding-right:14%;}
    .sm-padding-fourteen-half-right {padding-right:14.5%;}
    .sm-padding-fifteen-right {padding-right:15%;}
    .sm-padding-fifteen-half-right {padding-right:15.5%;}
    .sm-padding-sixteen-right {padding-right:16%;}
    .sm-padding-sixteen-half-right {padding-right:16.5%;}
    .sm-padding-seventeen-right {padding-right:17%;}
    .sm-padding-seventeen-half-right {padding-right:17.5%;}
    .sm-padding-eighteen-right {padding-right:18%;}
    .sm-padding-eighteen-half-right {padding-right:18.5%;}
    .sm-padding-nineteen-right {padding-right:19%;}
    .sm-padding-nineteen-half-right {padding-right:19.5%;}
    .sm-padding-twenty-right {padding-right:20%;}
    .sm-padding-twenty-half-right {padding-right:20.5%;}
    .sm-padding-twenty-one-right {padding-right:21%;}
    .sm-padding-twenty-one-half-right {padding-right:21.5%;}
    .sm-padding-twenty-two-right {padding-right:22%;}
    .sm-padding-twenty-two-half-right {padding-right:22.5%;}
    .sm-padding-twenty-three-right {padding-right:23%;}
    .sm-padding-twenty-three-half-right {padding-right:23.5%;}
    .sm-padding-twenty-four-right {padding-right:24%;}
    .sm-padding-twenty-four-half-right {padding-right:24.5%;}
    .sm-padding-twenty-five-right {padding-right:25%;}
    .sm-padding-5px-right {padding-right:5px !important;}
    .sm-padding-10px-right {padding-right:10px !important;}
    .sm-padding-15px-right {padding-right:15px !important;}
    .sm-padding-20px-right {padding-right:20px !important;}
    .sm-padding-25px-right {padding-right:25px !important;}
    .sm-padding-30px-right {padding-right:30px !important;}
    .sm-padding-35px-right {padding-right:35px !important;}
    .sm-padding-40px-right {padding-right:40px !important;}
    .sm-padding-45px-right {padding-right:45px !important;}
    .sm-padding-50px-right {padding-right:50px !important;}
    .sm-padding-55px-right {padding-right:55px !important;}
    .sm-padding-60px-right {padding-right:60px !important;}
    .sm-padding-65px-right {padding-right:65px !important;}
    .sm-padding-70px-right {padding-right:70px !important;}
    .sm-padding-75px-right {padding-right:75px !important;}
    .sm-padding-80px-right {padding-right:80px !important;}
    .sm-padding-85px-right {padding-right:85px !important;}
    .sm-padding-90px-right {padding-right:90px !important;}
    .sm-padding-95px-right {padding-right:95px !important;}
    .sm-padding-100px-right {padding-right:100px !important;}

    /* padding left */
    .sm-padding-one-left {padding-left:1%;}
    .sm-padding-one-half-left {padding-left:1.5%;}
    .sm-padding-two-left {padding-left:2%;}
    .sm-padding-two-half-left {padding-left:2.5%;}
    .sm-padding-three-left {padding-left:3%;}
    .sm-padding-three-half-left {padding-left:3.5%;}
    .sm-padding-four-left {padding-left:4%;}
    .sm-padding-four-half-left {padding-left:4.5%;}
    .sm-padding-five-left {padding-left:5%;}
    .sm-padding-five-half-left {padding-left:5.5%;}
    .sm-padding-six-left {padding-left:6%;}
    .sm-padding-six-half-left {padding-left:6.5%;}
    .sm-padding-seven-left {padding-left:7%;}
    .sm-padding-seven-half-left {padding-left:7.5%;}
    .sm-padding-eight-left {padding-left:8%;}
    .sm-padding-eight-half-left {padding-left:8.5%;}
    .sm-padding-nine-left {padding-left:9%;}
    .sm-padding-nine-half-left {padding-left:9.5%;}
    .sm-padding-ten-left {padding-left:10%;}
    .sm-padding-ten-half-left {padding-left:10.5%;}
    .sm-padding-eleven-left {padding-left:11%;}
    .sm-padding-eleven-half-left {padding-left:11.5%;}
    .sm-padding-twelve-left {padding-left:12%;}
    .sm-padding-twelve-half-left {padding-left:12.5%;}
    .sm-padding-thirteen-left {padding-left:13%;}
    .sm-padding-thirteen-half-left {padding-left:13.5%;}
    .sm-padding-fourteen-left {padding-left:14%;}
    .sm-padding-fourteen-half-left {padding-left:14.5%;}
    .sm-padding-fifteen-left {padding-left:15%;}
    .sm-padding-fifteen-half-left {padding-left:15.5%;}
    .sm-padding-sixteen-left {padding-left:16%;}
    .sm-padding-sixteen-half-left {padding-left:16.5%;}
    .sm-padding-seventeen-left {padding-left:17%;}
    .sm-padding-seventeen-half-left {padding-left:17.5%;}
    .sm-padding-eighteen-left {padding-left:18%;}
    .sm-padding-eighteen-half-left {padding-left:18.5%;}
    .sm-padding-nineteen-left {padding-left:19%;}
    .sm-padding-nineteen-half-left {padding-left:19.5%;}
    .sm-padding-twenty-left {padding-left:20%;}
    .sm-padding-twenty-half-left {padding-left:20.5%;}
    .sm-padding-twenty-one-left {padding-left:21%;}
    .sm-padding-twenty-one-half-left {padding-left:21.5%;}
    .sm-padding-twenty-two-left {padding-left:22%;}
    .sm-padding-twenty-two-half-left {padding-left:22.5%;}
    .sm-padding-twenty-three-left {padding-left:23%;}
    .sm-padding-twenty-three-half-left {padding-left:23.5%;}
    .sm-padding-twenty-four-left {padding-left:24%;}
    .sm-padding-twenty-four-half-left {padding-left:24.5%;}
    .sm-padding-twenty-five-left {padding-left:25%;}
    .sm-padding-5px-left {padding-left:5px !important;}
    .sm-padding-10px-left {padding-left:10px !important;}
    .sm-padding-15px-left {padding-left:15px !important;}
    .sm-padding-20px-left {padding-left:20px !important;}
    .sm-padding-25px-left {padding-left:25px !important;}
    .sm-padding-30px-left {padding-left:30px !important;}
    .sm-padding-35px-left {padding-left:35px !important;}
    .sm-padding-40px-left {padding-left:40px !important;}
    .sm-padding-45px-left {padding-left:45px !important;}
    .sm-padding-50px-left {padding-left:50px !important;}
    .sm-padding-55px-left {padding-left:55px !important;}
    .sm-padding-60px-left {padding-left:60px !important;}
    .sm-padding-65px-left {padding-left:65px !important;}
    .sm-padding-70px-left {padding-left:70px !important;}
    .sm-padding-75px-left {padding-left:75px !important;}
    .sm-padding-80px-left {padding-left:80px !important;}
    .sm-padding-85px-left {padding-left:85px !important;}
    .sm-padding-90px-left {padding-left:90px !important;}
    .sm-padding-95px-left {padding-left:95px !important;}
    .sm-padding-100px-left {padding-left:100px !important;}

    /* padding top bottom */
    .sm-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .sm-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .sm-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .sm-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .sm-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .sm-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .sm-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .sm-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .sm-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .sm-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .sm-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .sm-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .sm-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .sm-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .sm-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .sm-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .sm-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .sm-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .sm-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .sm-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .sm-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .sm-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .sm-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .sm-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .sm-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .sm-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .sm-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .sm-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .sm-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .sm-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .sm-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .sm-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .sm-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .sm-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .sm-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .sm-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .sm-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .sm-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .sm-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .sm-padding-twenty-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .sm-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .sm-padding-twenty-one-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .sm-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .sm-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .sm-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .sm-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .sm-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .sm-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .sm-padding-twenty-tb {padding-top:25%; padding-bottom:25%;}
    .sm-padding-5px-tb {padding-top:5px !important; padding-bottom:5px !important;}
    .sm-padding-8px-tb {padding-top:8px !important; padding-bottom:8px !important;}
    .sm-padding-10px-tb {padding-top:10px !important; padding-bottom:10px !important;}
    .sm-padding-15px-tb {padding-top:15px !important; padding-bottom:15px !important;}
    .sm-padding-20px-tb {padding-top:20px !important; padding-bottom:20px !important;}
    .sm-padding-25px-tb {padding-top:25px !important; padding-bottom:25px !important;}
    .sm-padding-30px-tb {padding-top:30px !important; padding-bottom:30px !important;}
    .sm-padding-35px-tb {padding-top:35px !important; padding-bottom:35px !important;}
    .sm-padding-40px-tb {padding-top:40px !important; padding-bottom:40px !important;}
    .sm-padding-45px-tb {padding-top:45px !important; padding-bottom:45px !important;}
    .sm-padding-50px-tb {padding-top:50px !important; padding-bottom:50px !important;}
    .sm-padding-55px-tb {padding-top:55px !important; padding-bottom:55px !important;}
    .sm-padding-60px-tb {padding-top:60px !important; padding-bottom:60px !important;}
    .sm-padding-65px-tb {padding-top:65px !important; padding-bottom:65px !important;}
    .sm-padding-70px-tb {padding-top:70px !important; padding-bottom:70px !important;}
    .sm-padding-75px-tb {padding-top:75px !important; padding-bottom:75px !important;}
    .sm-padding-80px-tb {padding-top:80px !important; padding-bottom:80px !important;}
    .sm-padding-85px-tb {padding-top:85px !important; padding-bottom:85px !important;}
    .sm-padding-90px-tb {padding-top:90px !important; padding-bottom:90px !important;}
    .sm-padding-95px-tb {padding-top:95px !important; padding-bottom:95px !important;}
    .sm-padding-100px-tb {padding-top:100px !important; padding-bottom:100px !important;}

    /* padding left right */
    .sm-padding-one-lr {padding-left:1%; padding-right:1%;}
    .sm-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .sm-padding-two-lr {padding-left:2%; padding-right:2%;}
    .sm-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .sm-padding-three-lr {padding-left:3%; padding-right:3%;}
    .sm-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .sm-padding-four-lr {padding-left:4%; padding-right:4%;}
    .sm-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .sm-padding-five-lr {padding-left:5%; padding-right:5%;}
    .sm-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .sm-padding-six-lr {padding-left:6%; padding-right:6%;}
    .sm-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .sm-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .sm-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .sm-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .sm-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .sm-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .sm-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .sm-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .sm-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .sm-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .sm-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .sm-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .sm-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .sm-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .sm-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .sm-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .sm-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .sm-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .sm-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .sm-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .sm-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .sm-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .sm-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .sm-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .sm-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .sm-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .sm-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .sm-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .sm-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .sm-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .sm-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .sm-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .sm-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .sm-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .sm-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .sm-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .sm-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .sm-padding-twenty-five-lr {padding-left:25%; padding-right:25%;}
    .sm-padding-5px-lr {padding-right:5px !important; padding-left:5px !important;}
    .sm-padding-10px-lr {padding-right:10px !important; padding-left:10px !important;}
    .sm-padding-15px-lr {padding-right:15px !important; padding-left:15px !important;}
    .sm-padding-20px-lr {padding-right:20px !important; padding-left:20px !important;}
    .sm-padding-25px-lr {padding-right:25px !important; padding-left:25px !important;}
    .sm-padding-30px-lr {padding-right:30px !important; padding-left:30px !important;}
    .sm-padding-35px-lr {padding-right:35px !important; padding-left:35px !important;}
    .sm-padding-40px-lr {padding-right:40px !important; padding-left:40px !important;}
    .sm-padding-45px-lr {padding-right:45px !important; padding-left:45px !important;}
    .sm-padding-50px-lr {padding-right:50px !important; padding-left:50px !important;}
    .sm-padding-55px-lr {padding-right:55px !important; padding-left:55px !important;}
    .sm-padding-60px-lr {padding-right:60px !important; padding-left:60px !important;}
    .sm-padding-65px-lr {padding-right:65px !important; padding-left:65px !important;}
    .sm-padding-70px-lr {padding-right:70px !important; padding-left:70px !important;}
    .sm-padding-75px-lr {padding-right:75px !important; padding-left:75px !important;}
    .sm-padding-80px-lr {padding-right:80px !important; padding-left:80px !important;}
    .sm-padding-85px-lr {padding-right:85px !important; padding-left:85px !important;}
    .sm-padding-90px-lr {padding-right:90px !important; padding-left:90px !important;}
    .sm-padding-95px-lr {padding-right:95px !important; padding-left:95px !important;}
    .sm-padding-100px-lr {padding-right:100px !important; padding-left:100px !important;}

    /* width */
    .sm-width-10 {width:10%;}
    .sm-width-15 {width:15%;}
    .sm-width-20 {width:20%;}
    .sm-width-25 {width:25%;}
    .sm-width-30 {width:30%;}
    .sm-width-35 {width:35%;}
    .sm-width-40 {width:40%;}
    .sm-width-45 {width:45%;}
    .sm-width-50 {width:50%;}
    .sm-width-55 {width:55%;}
    .sm-width-60 {width:60%;}
    .sm-width-65 {width:65%;}
    .sm-width-70 {width:70%;}
    .sm-width-75 {width:75%;}
    .sm-width-80 {width:80%;}
    .sm-width-85 {width:85%;}
    .sm-width-90 {width:90%;}
    .sm-width-95 {width:95%;}
    .sm-width-100 {width:100%;}
    .sm-width-auto {width: auto}

    .sm-width-50px {width:50px;}
    .sm-width-70px {width:70px;}
    .sm-width-75px {width:75px;}
    .sm-width-80px {width:80px;}
    .sm-width-85px {width:85px;}
    .sm-width-90px {width:90px;}
    .sm-width-95px {width:95px;}
    .sm-width-100px {width:100px;}
    .sm-width-120px {width:120px;}
    .sm-width-130px {width:130px;}
    .sm-width-150px {width:150px;}
    .sm-width-180px {width:180px;}
    .sm-width-200px {width:200px;}
    .sm-width-250px {width:250px;}
    .sm-width-300px {width:300px;}
    .sm-width-350px {width:350px;}
    .sm-width-400px {width:400px;}
    .sm-width-450px {width:450px;}
    .sm-width-500px {width:500px;}
    .sm-width-550px {width:550px;}
    .sm-width-600px {width:600px;}
    .sm-width-650px {width:650px;}
    .sm-width-700px {width:700px;}
    .sm-width-750px {width:750px;}
    .sm-width-800px {width:800px;}
    .sm-width-850px {width:850px;}
    .sm-width-900px {width:900px;}
    .sm-width-950px {width:950px;}
    .sm-width-1000px {width:1000px;}

    /* display */
    .sm-display-block {display:block !important;}
    .sm-overflow-hidden {overflow:hidden !important;}
    .sm-overflow-auto {overflow:auto !important;}
    .sm-display-inline-block {display:inline-block !important;}
    .sm-display-inline {display:inline !important;}
    .sm-display-none {display:none !important;}
    .sm-display-inherit {display:inherit !important;}
    .sm-display-table {display:table !important;}
    .sm-display-table-cell {display:table-cell !important;}
    .sm-display-flex-inherit { -ms-box-orient: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit;  flex-flow: inherit; -o-flex-flow: inherit;}

    /* clear-both */
    .sm-clear-both {clear: both;}

    /* position */
    .sm-position-inherit {position:inherit !important;}
    .sm-position-initial {position:static !important;position:initial !important;}
    .sm-position-relative {position:relative !important; z-index: 5}
    .sm-position-absolute {position:absolute !important;}
    .sm-position-fixed {position:fixed !important;}
 
     /* boder align */
    .sm-border-top {border-top: 1px solid;}
    .sm-border-bottom {border-bottom: 1px solid;}
    .sm-border-left {border-left: 1px solid;}
    .sm-border-right {border-right: 1px solid;}
    .sm-border-lr {border-left: 1px solid; border-right: 1px solid;}
    .sm-border-tb {border-top: 1px solid; border-bottom: 1px solid;}

    /* border */
    .sm-no-border-top {border-top:0 !important;}
    .sm-no-border-bottom {border-bottom:0 !important;}
    .sm-no-border-right {border-right:0 !important;}
    .sm-no-border-left {border-left:0 !important;}
    .sm-no-border { border: 0 !important;}

    /* parallax */
    .parallax {background-position: center;}

   
    /* opacity */
    .sm-opacity1 {opacity:.1;}
    .sm-opacity2 {opacity:.2;}
    .sm-opacity3 {opacity:.3;}
    .sm-opacity4 {opacity:.4;}
    .sm-opacity5 {opacity:.5;}
    .sm-opacity6 {opacity:.6;}
    .sm-opacity7 {opacity:.7;}
    .sm-opacity8 {opacity:.8;}
    .sm-opacity9 {opacity:.9;}
    .sm-opacity10 {opacity:1;}
 
}

@media (max-width: 767px) {

    .xs-height-auto { height: auto; }

    h1, h2, h3, h4, h5, h6 {margin:0 0 14px; padding:0; letter-spacing: 0;}
    h1 {font-size:34px;}
    h2 {font-size:28px;}
    h3 {font-size:26px;}
    h4 {font-size:24px;}
    h5 {font-size:20px;}
    h6 {font-size:16px;}

    /* font size */
    .xs-font-size11 {font-size:11px; line-height:14px;}
    .xs-font-size12 {font-size:12px; line-height:20px;}
    .xs-font-size13 {font-size:13px; line-height:normal;}
    .xs-font-size14 {font-size:14px; line-height:normal;}
    .xs-font-size15 {font-size:15px; line-height:normal;}
    .xs-font-size16 {font-size:16px; line-height:normal;}
    .xs-font-size17 {font-size:17px; line-height:normal;}
    .xs-font-size18 {font-size:18px; line-height:26px;}
    .xs-font-size19 {font-size:19px; line-height:normal;}
    .xs-font-size20 {font-size:20px; line-height:normal;}
    .xs-font-size22 {font-size:22px; line-height:normal;}
    .xs-font-size24 {font-size:24px; line-height:normal;}
    .xs-font-size26 {font-size:26px; line-height:normal;}
    .xs-font-size28 {font-size:28px; line-height:34px;}
    .xs-font-size30 {font-size:30px; line-height:normal;}
    .xs-font-size32 {font-size:32px; line-height:normal;}
    .xs-font-size34 {font-size:34px; line-height:normal;}
    .xs-font-size36 {font-size:36px; line-height:normal;}
    .xs-font-size38 {font-size:38px; line-height:normal;}
    .xs-font-size40 {font-size:40px; line-height:normal;}
    .xs-font-size42 {font-size:42px; line-height:normal;}
    .xs-font-size44 {font-size:44px; line-height:normal;}
    .xs-font-size46 {font-size:46px; line-height:normal;}
    .xs-font-size48 {font-size:48px; line-height:normal;}
    .xs-font-size50 {font-size:50px; line-height:normal;}
    .xs-font-size80 {font-size:80px; line-height:80px;}
    .xs-font-size100 {font-size: 100px; line-height:100px;}
    .xs-font-size120 {font-size: 120px; line-height:normal;}
    .xs-font-size130 {font-size: 130px; line-height:120px;}    

    /* line height */
    .xs-line-height-normal {line-height:normal;}
    .xs-line-height-10 {line-height:10px;}
    .xs-line-height-13 {line-height:13px;}
    .xs-line-height-18 {line-height:18px;}
    .xs-line-height-20 {line-height:20px;}
    .xs-line-height-24 {line-height:24px;}
    .xs-line-height-22 {line-height:22px;}
    .xs-line-height-26 {line-height:26px;}
    .xs-line-height-28 {line-height:28px;}
    .xs-line-height-30 {line-height:30px;}
    .xs-line-height-35 {line-height:35px;}
    .xs-line-height-40 {line-height:40px;}
    .xs-line-height-45 {line-height:45px;}
    .xs-line-height-50 {line-height:50px;}
    .xs-line-height-55 {line-height:55px;}
    .xs-line-height-60 {line-height:60px;}
    .xs-line-height-65 {line-height:65px;}
    .xs-line-height-70 {line-height:70px;}
    .xs-line-height-75 {line-height:75px;}
    .xs-line-height-80 {line-height:80px;}
    .xs-line-height-85 {line-height:85px;}
    .xs-line-height-90 {line-height:90px;}
    .xs-line-height-95 {line-height:95px;}
    .xs-line-height-100 {line-height:100px;}
    .xs-line-height-110 {line-height:110px;}
    .xs-line-height-120 {line-height:120px;}  

    /* opacity */
    .xs-opacity1 {opacity:.1;}
    .xs-opacity2 {opacity:.2;}
    .xs-opacity3 {opacity:.3;}
    .xs-opacity4 {opacity:.4;}
    .xs-opacity5 {opacity:.5;}
    .xs-opacity6 {opacity:.6;}
    .xs-opacity7 {opacity:.7;}
    .xs-opacity8 {opacity:.8;}
    .xs-opacity9 {opacity:.9;}
    .xs-opacity10 {opacity:1;}

    /*text-alignment*/
    .xs-text-center {text-align: center !important;}
    .xs-text-left {text-align: left !important;}
    .xs-text-right {text-align: right !important;}

    /*float*/
    .xs-float-left{float: left !important;}
    .xs-float-right{float: right !important;}
    .xs-float-none{float: none !important;}

    /* verticle align */
    .xs-vertical-align-middle {vertical-align: middle;}
    .xs-vertical-align-top {vertical-align: top;}
    .xs-vertical-align-bottom {vertical-align: bottom;}
    .xs-absolute-middle-inherit {left: 0; top: 0; position: relative; -webkit-transform: translateX(0) translateY(0); transform: translateX(0) translateY(0); -o-transform: translateX(0) translateY(0); }    

    /* image position*/ 
    .xs-background-image-left { background-position: left !important;}
    .xs-background-image-right { background-position: right !important;}
    .xs-background-image-center { background-position: center !important;}

    /* margin */
    .xs-margin-one-all {margin:1%;}
    .xs-margin-one-half-all {margin:1.5%;}
    .xs-margin-two-all {margin:2%;}
    .xs-margin-two-half-all {margin:2.5%;}
    .xs-margin-three-all {margin:3%;}
    .xs-margin-three-half-all {margin:3.5%;}
    .xs-margin-four-all {margin:4%;}
    .xs-margin-four-half-all {margin:4.5%;}
    .xs-margin-five-all {margin:5%;}
    .xs-margin-five-half-all {margin:5.5%;}
    .xs-margin-six-all {margin:6%;}
    .xs-margin-six-half-all {margin:6.5%;}
    .xs-margin-seven-all {margin:7%;}
    .xs-margin-seven-half-all {margin:7.5%;}
    .xs-margin-eight-all {margin:8%;}
    .xs-margin-eight-half-all {margin:8.5%;}
    .xs-margin-nine-all {margin:9%;}
    .xs-margin-nine-half-all {margin:9.5%;}
    .xs-margin-ten-all {margin:10%;}
    .xs-margin-ten-half-all {margin:10.5%;}
    .xs-margin-eleven-all {margin:11%;}
    .xs-margin-eleven-half-all {margin:11.5%;}
    .xs-margin-twelve-all {margin:12%;}
    .xs-margin-twelve-half-all {margin:12.5%;}
    .xs-margin-thirteen-all {margin:13%;}
    .xs-margin-thirteen-half-all {margin:13.5%;}
    .xs-margin-fourteen-all {margin:14%;}
    .xs-margin-fourteen-half-all {margin:14.5%;}
    .xs-margin-fifteen-all {margin:15%;}
    .xs-margin-fifteen-half-all {margin:15.5%;}
    .xs-margin-sixteen-all {margin:16%;}
    .xs-margin-sixteen-half-all {margin:16.5%;}
    .xs-margin-seventeen-all {margin:17%;}
    .xs-margin-seventeen-half-all {margin:17.5%;}
    .xs-margin-eighteen-all {margin:18%;}
    .xs-margin-eighteen-half-all {margin:18.5%;}
    .xs-margin-nineteen-all {margin:19%;}
    .xs-margin-nineteen-half-all {margin:19.5%;}
    .xs-margin-twenty-all {margin:20%;}
    .xs-margin-twenty-half-all {margin:20.5%;}
    .xs-margin-twenty-one-all {margin:21%;}
    .xs-margin-twenty-one-half-all {margin:21.5%;}
    .xs-margin-twenty-two-all {margin:22%;}
    .xs-margin-twenty-two-half-all {margin:22.5%;}
    .xs-margin-twenty-three-all {margin:23%;}
    .xs-margin-twenty-three-half-all {margin:23.5%;}
    .xs-margin-twenty-four-all {margin:24%;}
    .xs-margin-twenty-four-half-all {margin:24.5%;}
    .xs-margin-twenty-five-all {margin:25%;}

    .xs-no-margin {margin:0 !important;}
    .xs-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important;}
    .xs-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important;}
    .xs-no-margin-top {margin-top:0 !important;}
    .xs-no-margin-bottom {margin-bottom:0 !important;}
    .xs-no-margin-left {margin-left:0 !important;}
    .xs-no-margin-right {margin-right:0 !important;}
    .xs-margin-lr-auto {margin-left:auto !important; margin-right:auto !important;}
    .xs-margin-auto {margin: 0 auto !important;}

    /* margin top */
    .xs-margin-one-top {margin-top:1%;}
    .xs-margin-one-half-top {margin-top:1.5%;}
    .xs-margin-two-top {margin-top:2%;}
    .xs-margin-two-half-top {margin-top:2.5%;}
    .xs-margin-three-top {margin-top:3%;}
    .xs-margin-three-half-top {margin-top:3.5%;}
    .xs-margin-four-top {margin-top:4%;}
    .xs-margin-four-half-top {margin-top:4.5%;}
    .xs-margin-five-top {margin-top:5%;}
    .xs-margin-five-half-top {margin-top:5.5%;}
    .xs-margin-six-top {margin-top:6%;}
    .xs-margin-six-half-top {margin-top:6.5%;}
    .xs-margin-seven-top {margin-top:7%;}
    .xs-margin-seven-half-top {margin-top:7.5%;}
    .xs-margin-eight-top {margin-top:8%;}
    .xs-margin-eight-half-top {margin-top:8.5%;}
    .xs-margin-nine-top {margin-top:9%;}
    .xs-margin-nine-half-top {margin-top:9.5%;}
    .xs-margin-ten-top {margin-top:10%;}
    .xs-margin-ten-half-top {margin-top:10.5%;}
    .xs-margin-eleven-top {margin-top:11%;}
    .xs-margin-eleven-half-top {margin-top:11.5%;}
    .xs-margin-twelve-top {margin-top:12%;}
    .xs-margin-twelve-half-top {margin-top:12.5%;}
    .xs-margin-thirteen-top {margin-top:13%;}
    .xs-margin-thirteen-half-top {margin-top:13.5%;}
    .xs-margin-fourteen-top {margin-top:14%;}
    .xs-margin-fourteen-half-top {margin-top:14.5%;}
    .xs-margin-fifteen-top {margin-top:15%;}
    .xs-margin-fifteen-half-top {margin-top:15.5%;}
    .xs-margin-sixteen-top {margin-top:16%;}
    .xs-margin-sixteen-half-top {margin-top:16.5%;}
    .xs-margin-seventeen-top {margin-top:17%;}
    .xs-margin-seventeen-half-top {margin-top:17.5%;}
    .xs-margin-eighteen-top {margin-top:18%;}
    .xs-margin-eighteen-half-top {margin-top:18.5%;}
    .xs-margin-nineteen-top {margin-top:19%;}
    .xs-margin-nineteen-half-top {margin-top:19.5%;}
    .xs-margin-twenty-top {margin-top:20%;}
    .xs-margin-twenty-half-top {margin-top:20.5%;}
    .xs-margin-twenty-one-top {margin-top:21%;}
    .xs-margin-twenty-one-half-top {margin-top:21.5%;}
    .xs-margin-twenty-two-top {margin-top:22%;}
    .xs-margin-twenty-two-half-top {margin-top:22.5%;}
    .xs-margin-twenty-three-top {margin-top:23%;}
    .xs-margin-twenty-three-half-top {margin-top:23.5%;}
    .xs-margin-twenty-four-top {margin-top:24%;}
    .xs-margin-twenty-four-half-top {margin-top:24.5%;}
    .xs-margin-twenty-five-top {margin-top:25%;}
    .xs-margin-5px-top {margin-top:5px !important;}
    .xs-margin-8px-top {margin-top:8px !important;}
    .xs-margin-10px-top {margin-top:10px !important;}
    .xs-margin-15px-top {margin-top:15px !important;}
    .xs-margin-20px-top {margin-top:20px !important;}
    .xs-margin-25px-top {margin-top:25px !important;}
    .xs-margin-30px-top {margin-top:30px !important;}
    .xs-margin-35px-top {margin-top:35px !important;}
    .xs-margin-40px-top {margin-top:40px !important;}
    .xs-margin-45px-top {margin-top:45px !important;}
    .xs-margin-50px-top {margin-top:50px !important;}
    .xs-margin-55px-top {margin-top:55px !important;}
    .xs-margin-60px-top {margin-top:60px !important;}
    .xs-margin-65px-top {margin-top:65px !important;}
    .xs-margin-70px-top {margin-top:70px !important;}
    .xs-margin-75px-top {margin-top:75px !important;}
    .xs-margin-80px-top {margin-top:80px !important;}
    .xs-margin-85px-top {margin-top:85px !important;}
    .xs-margin-90px-top {margin-top:90px !important;}
    .xs-margin-95px-top {margin-top:95px !important;}
    .xs-margin-100px-top {margin-top:100px !important;}

    /* margin bottom */
    .xs-margin-one-bottom {margin-bottom:1%;}
    .xs-margin-one-half-bottom {margin-bottom:1.5%;}
    .xs-margin-two-bottom {margin-bottom:2%;}
    .xs-margin-two-half-bottom {margin-bottom:2.5%;}
    .xs-margin-three-bottom {margin-bottom:3%;}
    .xs-margin-three-half-bottom {margin-bottom:3.5%;}
    .xs-margin-four-bottom {margin-bottom:4%;}
    .xs-margin-four-half-bottom {margin-bottom:4.5%;}
    .xs-margin-five-bottom {margin-bottom:5%;}
    .xs-margin-five-half-bottom {margin-bottom:5.5%;}
    .xs-margin-six-bottom {margin-bottom:6%;}
    .xs-margin-six-half-bottom {margin-bottom:6.5%;}
    .xs-margin-seven-bottom {margin-bottom:7%;}
    .xs-margin-seven-half-bottom {margin-bottom:7.5%;}
    .xs-margin-eight-bottom {margin-bottom:8%;}
    .xs-margin-eight-half-bottom {margin-bottom:8.5%;}
    .xs-margin-nine-bottom {margin-bottom:9%;}
    .xs-margin-nine-half-bottom {margin-bottom:9.5%;}
    .xs-margin-ten-bottom {margin-bottom:10%;}
    .xs-margin-ten-half-bottom {margin-bottom:10.5%;}
    .xs-margin-eleven-bottom {margin-bottom:11%;}
    .xs-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .xs-margin-twelve-bottom {margin-bottom:12%;}
    .xs-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .xs-margin-thirteen-bottom {margin-bottom:13%;}
    .xs-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .xs-margin-fourteen-bottom {margin-bottom:14%;}
    .xs-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .xs-margin-fifteen-bottom {margin-bottom:15%;}
    .xs-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .xs-margin-sixteen-bottom {margin-bottom:16%;}
    .xs-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .xs-margin-seventeen-bottom {margin-bottom:17%;}
    .xs-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .xs-margin-eighteen-bottom {margin-bottom:18%;}
    .xs-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .xs-margin-nineteen-bottom {margin-bottom:19%;}
    .xs-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .xs-margin-twenty-bottom {margin-bottom:20%;}
    .xs-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .xs-margin-twenty-one-bottom {margin-bottom:21%;}
    .xs-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .xs-margin-twenty-two-bottom {margin-bottom:22%;}
    .xs-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .xs-margin-twenty-three-bottom {margin-bottom:23%;}
    .xs-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .xs-margin-twenty-four-bottom {margin-bottom:24%;}
    .xs-margin-twenty-four-half-bottom {margin-bottom:24.5%;}
    .xs-margin-twenty-five-bottom {margin-bottom:25%;}
    .xs-margin-5px-bottom {margin-bottom:5px !important;}
    .xs-margin-8px-bottom {margin-bottom:8px !important;}
    .xs-margin-10px-bottom {margin-bottom:10px !important;}
    .xs-margin-15px-bottom {margin-bottom:15px !important;}
    .xs-margin-20px-bottom {margin-bottom:20px !important;}
    .xs-margin-25px-bottom {margin-bottom:25px !important;}
    .xs-margin-30px-bottom {margin-bottom:30px !important;}
    .xs-margin-35px-bottom {margin-bottom:35px !important;}
    .xs-margin-40px-bottom {margin-bottom:40px !important;}
    .xs-margin-45px-bottom {margin-bottom:45px !important;}
    .xs-margin-50px-bottom {margin-bottom:50px !important;}
    .xs-margin-55px-bottom {margin-bottom:55px !important;}
    .xs-margin-60px-bottom {margin-bottom:60px !important;}
    .xs-margin-65px-bottom {margin-bottom:65px !important;}
    .xs-margin-70px-bottom {margin-bottom:70px !important;}
    .xs-margin-75px-bottom {margin-bottom:75px !important;}
    .xs-margin-80px-bottom {margin-bottom:80px !important;}
    .xs-margin-85px-bottom {margin-bottom:85px !important;}
    .xs-margin-90px-bottom {margin-bottom:90px !important;}
    .xs-margin-95px-bottom {margin-bottom:95px !important;}
    .xs-margin-100px-bottom {margin-bottom:100px !important;}

    /* margin right */
    .xs-margin-one-right {margin-right:1%;}
    .xs-margin-one-half-right {margin-right:1.5%;}
    .xs-margin-two-right {margin-right:2%;}
    .xs-margin-two-half-right {margin-right:2.5%;}
    .xs-margin-three-right {margin-right:3%;}
    .xs-margin-three-half-right {margin-right:3.5%;}
    .xs-margin-four-right {margin-right:4%;}
    .xs-margin-four-half-right {margin-right:4.5%;}
    .xs-margin-five-right {margin-right:5%;}
    .xs-margin-five-half-right {margin-right:5.5%;}
    .xs-margin-six-right {margin-right:6%;}
    .xs-margin-six-half-right {margin-right:6.5%;}
    .xs-margin-seven-right {margin-right:7%;}
    .xs-margin-seven-half-right {margin-right:7.5%;}
    .xs-margin-eight-right {margin-right:8%;}
    .xs-margin-eight-half-right {margin-right:8.5%;}
    .xs-margin-nine-right {margin-right:9%;}
    .xs-margin-nine-half-right {margin-right:9.5%;}
    .xs-margin-ten-right {margin-right:10%;}
    .xs-margin-ten-half-right {margin-right:10.5%;}
    .xs-margin-eleven-right {margin-right:11%;}
    .xs-margin-eleven-half-right {margin-right:11.5%;}
    .xs-margin-twelve-right {margin-right:12%;}
    .xs-margin-twelve-half-right {margin-right:12.5%;}
    .xs-margin-thirteen-right {margin-right:13%;}
    .xs-margin-thirteen-half-right {margin-right:13.5%;}
    .xs-margin-fourteen-right {margin-right:14%;}
    .xs-margin-fourteen-half-right {margin-right:14.5%;}
    .xs-margin-fifteen-right {margin-right:15%;}
    .xs-margin-fifteen-half-right {margin-right:15.5%;}
    .xs-margin-sixteen-right {margin-right:16%;}
    .xs-margin-sixteen-half-right {margin-right:16.5%;}
    .xs-margin-seventeen-right {margin-right:17%;}
    .xs-margin-seventeen-half-right {margin-right:17.5%;}
    .xs-margin-eighteen-right {margin-right:18%;}
    .xs-margin-eighteen-half-right {margin-right:18.5%;}
    .xs-margin-nineteen-right {margin-right:19%;}
    .xs-margin-nineteen-half-right {margin-right:19.5%;}
    .xs-margin-twenty-right {margin-right:20%;}
    .xs-margin-twenty-half-right {margin-right:20.5%;}
    .xs-margin-twenty-one-right {margin-right:21%;}
    .xs-margin-twenty-one-half-right {margin-right:21.5%;}
    .xs-margin-twenty-two-right {margin-right:22%;}
    .xs-margin-twenty-two-half-right {margin-right:22.5%;}
    .xs-margin-twenty-three-right {margin-right:23%;}
    .xs-margin-twenty-three-half-right {margin-right:23.5%;}
    .xs-margin-twenty-four-right {margin-right:24%;}
    .xs-margin-twenty-four-half-right {margin-right:24.5%;}
    .xs-margin-twenty-five-right {margin-right:25%;}
    .xs-margin-10px-right {margin-right:10px !important;}
    .xs-margin-15px-right {margin-right:15px !important;}
    .xs-margin-20px-right {margin-right:20px !important;}
    .xs-margin-25px-right {margin-right:25px !important;}
    .xs-margin-30px-right {margin-right:30px !important;}
    .xs-margin-35px-right {margin-right:35px !important;}
    .xs-margin-40px-right {margin-right:40px !important;}
    .xs-margin-45px-right {margin-right:45px !important;}
    .xs-margin-50px-right {margin-right:50px !important;}
    .xs-margin-55px-right {margin-right:55px !important;}
    .xs-margin-60px-right {margin-right:60px !important;}
    .xs-margin-65px-right {margin-right:65px !important;}
    .xs-margin-70px-right {margin-right:70px !important;}
    .xs-margin-75px-right {margin-right:75px !important;}
    .xs-margin-80px-right {margin-right:80px !important;}
    .xs-margin-85px-right {margin-right:85px !important;}
    .xs-margin-90px-right {margin-right:90px !important;}
    .xs-margin-95px-right {margin-right:95px !important;}
    .xs-margin-100px-right {margin-right:100px !important;}

    /* margin left */
    .xs-margin-one-left {margin-left:1%;}
    .xs-margin-one-half-left {margin-left:1.5%;}
    .xs-margin-two-left {margin-left:2%;}
    .xs-margin-two-half-left {margin-left:2.5%;}
    .xs-margin-three-left {margin-left:3%;}
    .xs-margin-three-half-left {margin-left:3.5%;}
    .xs-margin-four-left {margin-left:4%;}
    .xs-margin-four-half-left {margin-left:4.5%;}
    .xs-margin-five-left {margin-left:5%;}
    .xs-margin-five-half-left {margin-left:5.5%;}
    .xs-margin-six-left {margin-left:6%;}
    .xs-margin-six-half-left {margin-left:6.5%;}
    .xs-margin-seven-left {margin-left:7%;}
    .xs-margin-seven-half-left {margin-left:7.5%;}
    .xs-margin-eight-left {margin-left:8%;}
    .xs-margin-eight-half-left {margin-left:8.5%;}
    .xs-margin-nine-left {margin-left:9%;}
    .xs-margin-nine-half-left {margin-left:9.5%;}
    .xs-margin-ten-left {margin-left:10%;}
    .xs-margin-ten-half-left {margin-left:10.5%;}
    .xs-margin-eleven-left {margin-left:11%;}
    .xs-margin-eleven-half-left {margin-left:11.5%;}
    .xs-margin-twelve-left {margin-left:12%;}
    .xs-margin-twelve-half-left {margin-left:12.5%;}
    .xs-margin-thirteen-left {margin-left:13%;}
    .xs-margin-thirteen-half-left {margin-left:13.5%;}
    .xs-margin-fourteen-left {margin-left:14%;}
    .xs-margin-fourteen-half-left {margin-left:14.5%;}
    .xs-margin-fifteen-left {margin-left:15%;}
    .xs-margin-fifteen-half-left {margin-left:15.5%;}
    .xs-margin-sixteen-left {margin-left:16%;}
    .xs-margin-sixteen-half-left {margin-left:16.5%;}
    .xs-margin-seventeen-left {margin-left:17%;}
    .xs-margin-seventeen-half-left {margin-left:17.5%;}
    .xs-margin-eighteen-left {margin-left:18%;}
    .xs-margin-eighteen-half-left {margin-left:18.5%;}
    .xs-margin-nineteen-left {margin-left:19%;}
    .xs-margin-nineteen-half-left {margin-left:19.5%;}
    .xs-margin-twenty-left {margin-left:20%;}
    .xs-margin-twenty-half-left {margin-left:20.5%;}
    .xs-margin-twenty-one-left {margin-left:21%;}
    .xs-margin-twenty-one-half-left {margin-left:21.5%;}
    .xs-margin-twenty-two-left {margin-left:22%;}
    .xs-margin-twenty-two-half-left {margin-left:22.5%;}
    .xs-margin-twenty-three-left {margin-left:23%;}
    .xs-margin-twenty-three-half-left {margin-left:23.5%;}
    .xs-margin-twenty-four-left {margin-left:24%;}
    .xs-margin-twenty-four-half-left {margin-left:24.5%;}
    .xs-margin-twenty-five-left {margin-left:25%;}
    .xs-margin-5px-left {margin-left:5px !important;}
    .xs-margin-10px-left {margin-left:10px !important;}
    .xs-margin-15px-left {margin-left:15px !important;}
    .xs-margin-20px-left {margin-left:20px !important;}
    .xs-margin-25px-left {margin-left:25px !important;}
    .xs-margin-30px-left {margin-left:30px !important;}
    .xs-margin-35px-left {margin-left:35px !important;}
    .xs-margin-40px-left {margin-left:40px !important;}
    .xs-margin-45px-left {margin-left:45px !important;}
    .xs-margin-50px-left {margin-left:50px !important;}
    .xs-margin-55px-left {margin-left:55px !important;}
    .xs-margin-60px-left {margin-left:60px !important;}
    .xs-margin-65px-left {margin-left:65px !important;}
    .xs-margin-70px-left {margin-left:70px !important;}
    .xs-margin-75px-left {margin-left:75px !important;}
    .xs-margin-80px-left {margin-left:80px !important;}
    .xs-margin-85px-left {margin-left:85px !important;}
    .xs-margin-90px-left {margin-left:90px !important;}
    .xs-margin-95px-left {margin-left:95px !important;}
    .xs-margin-100px-left {margin-left:100px !important;}

    /* margin left right */
    .xs-margin-one-lr {margin-left:1%; margin-right:1%;}
    .xs-margin-one-lr {margin-left:1.5%; margin-right:1.5%;}
    .xs-margin-two-lr {margin-left:2%; margin-right:2%;}
    .xs-margin-two-lr {margin-left:2.5%; margin-right:2.5%;}
    .xs-margin-three-lr {margin-left:3%; margin-right:3%;}
    .xs-margin-three-lr {margin-left:3.5%; margin-right:3.5%;}
    .xs-margin-four-lr {margin-left:4%; margin-right:4%;}
    .xs-margin-four-lr {margin-left:4.5%; margin-right:4.5%;}
    .xs-margin-five-lr {margin-left:5%; margin-right:5%;}
    .xs-margin-five-lr {margin-left:5.5%; margin-right:5.5%;}
    .xs-margin-six-lr {margin-left:6%; margin-right:6%;}
    .xs-margin-six-lr {margin-left:6.5%; margin-right:6.5%;}
    .xs-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .xs-margin-seven-lr {margin-left:7.5%; margin-right:7.5%;}
    .xs-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .xs-margin-eight-lr {margin-left:8.5%; margin-right:8.5%;}
    .xs-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .xs-margin-nine-lr {margin-left:9.5%; margin-right:9.5%;}
    .xs-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .xs-margin-ten-lr {margin-left:10.5%; margin-right:10.5%;}
    .xs-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .xs-margin-eleven-lr {margin-left:11.5%; margin-right:11.5%;}
    .xs-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .xs-margin-twelve-lr {margin-left:12.5%; margin-right:12.5%;}
    .xs-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .xs-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .xs-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .xs-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .xs-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .xs-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .xs-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .xs-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .xs-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .xs-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .xs-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .xs-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .xs-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .xs-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .xs-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .xs-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .xs-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .xs-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .xs-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .xs-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .xs-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .xs-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .xs-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .xs-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .xs-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .xs-margin-5px-lr {margin-left:5px !important; margin-right:5px !important;}
    .xs-margin-10px-lr {margin-left:10px !important; margin-right:10px !important;}
    .xs-margin-15px-lr {margin-left:15px !important; margin-right:15px !important;}
    .xs-margin-20px-lr {margin-left:20px !important; margin-right:20px !important;}
    .xs-margin-25px-lr {margin-left:25px !important; margin-right:25px !important;}
    .xs-margin-30px-lr {margin-left:30px !important; margin-right:30px !important;}
    .xs-margin-35px-lr {margin-left:35px !important; margin-right:35px !important;}
    .xs-margin-40px-lr {margin-left:40px !important; margin-right:40px !important;}
    .xs-margin-45px-lr {margin-left:45px !important; margin-right:45px !important;}
    .xs-margin-50px-lr {margin-left:50px !important; margin-right:50px !important;}
    .xs-margin-55px-lr {margin-left:55px !important; margin-right:55px !important;}
    .xs-margin-60px-lr {margin-left:60px !important; margin-right:60px !important;}
    .xs-margin-65px-lr {margin-left:65px !important; margin-right:65px !important;}
    .xs-margin-70px-lr {margin-left:70px !important; margin-right:70px !important;}
    .xs-margin-75px-lr {margin-left:75px !important; margin-right:75px !important;}
    .xs-margin-80px-lr {margin-left:80px !important; margin-right:80px !important;}
    .xs-margin-85px-lr {margin-left:85px !important; margin-right:85px !important;}
    .xs-margin-90px-lr {margin-left:90px !important; margin-right:90px !important;}
    .xs-margin-95px-lr {margin-left:95px !important; margin-right:95px !important;}
    .xs-margin-100px-lr {margin-left:100px !important; margin-right:100px !important;}

    /* margin top bottom */
    .xs-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .xs-margin-one-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .xs-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .xs-margin-two-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .xs-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .xs-margin-three-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .xs-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .xs-margin-four-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .xs-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .xs-margin-five-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .xs-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .xs-margin-six-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .xs-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .xs-margin-seven-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .xs-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .xs-margin-eight-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .xs-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .xs-margin-nine-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .xs-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .xs-margin-ten-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .xs-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .xs-margin-eleven-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .xs-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .xs-margin-twelve-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .xs-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .xs-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .xs-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .xs-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .xs-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .xs-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .xs-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .xs-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .xs-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .xs-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .xs-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .xs-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .xs-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .xs-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .xs-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .xs-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .xs-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .xs-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .xs-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .xs-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .xs-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .xs-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .xs-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .xs-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .xs-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .xs-margin-5px-tb {margin-top:5px !important; margin-bottom:5px !important;}
    .xs-margin-10px-tb {margin-top:10px !important; margin-bottom:10px !important;}
    .xs-margin-15px-tb {margin-top:15px !important; margin-bottom:15px !important;}
    .xs-margin-20px-tb {margin-top:20px !important; margin-bottom:20px !important;}
    .xs-margin-25px-tb {margin-top:25px !important; margin-bottom:25px !important;}
    .xs-margin-30px-tb {margin-top:30px !important; margin-bottom:30px !important;}
    .xs-margin-35px-tb {margin-top:35px !important; margin-bottom:35px !important;}
    .xs-margin-40px-tb {margin-top:40px !important; margin-bottom:40px !important;}
    .xs-margin-45px-tb {margin-top:45px !important; margin-bottom:45px !important;}
    .xs-margin-50px-tb {margin-top:50px !important; margin-bottom:50px !important;}
    .xs-margin-55px-tb {margin-top:55px !important; margin-bottom:55px !important;}
    .xs-margin-60px-tb {margin-top:60px !important; margin-bottom:60px !important;}
    .xs-margin-65px-tb {margin-top:65px !important; margin-bottom:65px !important;}
    .xs-margin-70px-tb {margin-top:70px !important; margin-bottom:70px !important;}
    .xs-margin-75px-tb {margin-top:75px !important; margin-bottom:75px !important;}
    .xs-margin-80px-tb {margin-top:80px !important; margin-bottom:80px !important;}
    .xs-margin-85px-tb {margin-top:85px !important; margin-bottom:85px !important;}
    .xs-margin-90px-tb {margin-top:90px !important; margin-bottom:90px !important;}
    .xs-margin-95px-tb {margin-top:95px !important; margin-bottom:95px !important;}
    .xs-margin-100px-tb {margin-top:100px !important; margin-bottom:100px !important;}
  
    /* padding */
    .xs-no-padding {padding:0 !important;}
    .xs-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important;}
    .xs-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important;}
    .xs-no-padding-top {padding-top:0 !important;}
    .xs-no-padding-bottom {padding-bottom:0 !important;}
    .xs-no-padding-left {padding-left:0 !important;}
    .xs-no-padding-right {padding-right:0 !important;}
    .xs-padding-one-all {padding:1%;}
    .xs-padding-one-half-all {padding:1.5%;}
    .xs-padding-two-all {padding:2%;}
    .xs-padding-two-half-all {padding:2.5%;}
    .xs-padding-three-all {padding:3%;}
    .xs-padding-three-half-all {padding:3.5%;}
    .xs-padding-four-all {padding:4%;}
    .xs-padding-four-half-all {padding:4.5%;}
    .xs-padding-five-all {padding:5%;}
    .xs-padding-five-half-all {padding:5.5%;}
    .xs-padding-six-all {padding:6%;}
    .xs-padding-six-half-all {padding:6.5%;}
    .xs-padding-seven-all {padding:7%;}
    .xs-padding-seven-half-all {padding:7.5%;}
    .xs-padding-eight-all {padding:8%;}
    .xs-padding-eight-half-all {padding:8.5%;}
    .xs-padding-nine-all {padding:9%;}
    .xs-padding-nine-half-all {padding:9.5%;}
    .xs-padding-ten-all {padding:10%;}
    .xs-padding-ten-half-all {padding:10.5%;}
    .xs-padding-eleven-all {padding:11%;}
    .xs-padding-eleven-half-all {padding:11.5%;}
    .xs-padding-twelve-all {padding:12%;}
    .xs-padding-twelve-half-all {padding:12.5%;}
    .xs-padding-thirteen-all {padding:13%;}
    .xs-padding-thirteen-half-all {padding:13.5%;}
    .xs-padding-fourteen-all {padding:14%;}
    .xs-padding-fourteen-half-all {padding:14.5%;}
    .xs-padding-fifteen-all {padding:15%;}
    .xs-padding-fifteen-half-all {padding:15.5%;}
    .xs-padding-sixteen-all {padding:16%;}
    .xs-padding-sixteen-half-all {padding:16.5%;}
    .xs-padding-seventeen-all {padding:17%;}
    .xs-padding-seventeen-half-all {padding:17.5%;}
    .xs-padding-eighteen-all {padding:18%;}
    .xs-padding-eighteen-half-all {padding:18.5%;}
    .xs-padding-nineteen-all {padding:19%;}
    .xs-padding-nineteen-half-all {padding:19.5%;}
    .xs-padding-twenty-all {padding:20%;}
    .xs-padding-twenty-half-all {padding:20.5%;}
    .xs-padding-twenty-one-all {padding:21%;}
    .xs-padding-twenty-one-half-all {padding:21.5%;}
    .xs-padding-twenty-two-all {padding:22%;}
    .xs-padding-twenty-two-half-all {padding:22.5%;}
    .xs-padding-twenty-three-all {padding:23%;}
    .xs-padding-twenty-three-half-all {padding:23.5%;}
    .xs-padding-twenty-four-all {padding:24%;}
    .xs-padding-twenty-four-half-all {padding:24.5%;}
    .xs-padding-twenty-five-all {padding:25%;}
    .xs-padding-5px-all {padding:5px !important;}
    .xs-padding-10px-all {padding:10px !important;}
    .xs-padding-15px-all {padding:15px !important;}
    .xs-padding-20px-all {padding:20px !important;}
    .xs-padding-25px-all {padding:25px !important;}
    .xs-padding-30px-all {padding:30px !important;}
    .xs-padding-35px-all {padding:35px !important;}
    .xs-padding-40px-all {padding:40px !important;}
    .xs-padding-45px-all {padding:45px !important;}
    .xs-padding-50px-all {padding:50px !important;}
    .xs-padding-55px-all {padding:55px !important;}
    .xs-padding-60px-all {padding:60px !important;}
    .xs-padding-65px-all {padding:65px !important;}
    .xs-padding-70px-all {padding:70px !important;}
    .xs-padding-75px-all {padding:75px !important;}
    .xs-padding-80px-all {padding:80px !important;}
    .xs-padding-85px-all {padding:85px !important;}
    .xs-padding-90px-all {padding:90px !important;}
    .xs-padding-95px-all {padding:95px !important;}
    .xs-padding-100px-all {padding:100px !important;}

    /* padding top */
    .xs-padding-one-top {padding-top:1%;}
    .xs-padding-one-half-top {padding-top:1.5%;}
    .xs-padding-two-top {padding-top:2%;}
    .xs-padding-two-half-top {padding-top:2.5%;}
    .xs-padding-three-top {padding-top:3%;}
    .xs-padding-three-half-top {padding-top:3.5%;}
    .xs-padding-four-top {padding-top:4%;}
    .xs-padding-four-half-top {padding-top:4.5%;}
    .xs-padding-five-top {padding-top:5%;}
    .xs-padding-five-half-top {padding-top:5.5%;}
    .xs-padding-six-top {padding-top:6%;}
    .xs-padding-six-half-top {padding-top:6.5%;}
    .xs-padding-seven-top {padding-top:7%;}
    .xs-padding-seven-half-top {padding-top:7.5%;}
    .xs-padding-eight-top {padding-top:8%;}
    .xs-padding-eight-half-top {padding-top:8.5%;}
    .xs-padding-nine-top {padding-top:9%;}
    .xs-padding-nine-half-top {padding-top:9.5%;}
    .xs-padding-ten-top {padding-top:10%;}
    .xs-padding-ten-half-top {padding-top:10.5%;}
    .xs-padding-eleven-top {padding-top:11%;}
    .xs-padding-eleven-half-top {padding-top:11.5%;}
    .xs-padding-twelve-top {padding-top:12%;}
    .xs-padding-twelve-half-top {padding-top:12.5%;}
    .xs-padding-thirteen-top {padding-top:13%;}
    .xs-padding-thirteen-half-top {padding-top:13.5%;}
    .xs-padding-fourteen-top {padding-top:14%;}
    .xs-padding-fourteen-half-top {padding-top:14.5%;}
    .xs-padding-fifteen-top {padding-top:15%;}
    .xs-padding-fifteen-half-top {padding-top:15.5%;}
    .xs-padding-sixteen-top {padding-top:16%;}
    .xs-padding-sixteen-half-top {padding-top:16.5%;}
    .xs-padding-seventeen-top {padding-top:17%;}
    .xs-padding-seventeen-half-top {padding-top:17.5%;}
    .xs-padding-eighteen-top {padding-top:18%;}
    .xs-padding-eighteen-half-top {padding-top:18.5%;}
    .xs-padding-nineteen-top {padding-top:19%;}
    .xs-padding-nineteen-half-top {padding-top:19.5%;}
    .xs-padding-twenty-top {padding-top:20%;}
    .xs-padding-twenty-half-top {padding-top:20.5%;}
    .xs-padding-twenty-one-top {padding-top:21%;}
    .xs-padding-twenty-one-half-top {padding-top:21.5%;}
    .xs-padding-twenty-two-top {padding-top:22%;}
    .xs-padding-twenty-two-half-top {padding-top:22.5%;}
    .xs-padding-twenty-three-top {padding-top:23%;}
    .xs-padding-twenty-three-half-top {padding-top:23.5%;}
    .xs-padding-twenty-four-top {padding-top:24%;}
    .xs-padding-twenty-four-half-top {padding-top:24.5%;}
    .xs-padding-twenty-five-top {padding-top:25%;}
    .xs-padding-5px-top {padding-top:5px !important;}
    .xs-padding-10px-top {padding-top:10px !important;}
    .xs-padding-15px-top {padding-top:15px !important;}
    .xs-padding-20px-top {padding-top:20px !important;}
    .xs-padding-25px-top {padding-top:25px !important;}
    .xs-padding-30px-top {padding-top:30px !important;}
    .xs-padding-35px-top {padding-top:35px !important;}
    .xs-padding-40px-top {padding-top:40px !important;}
    .xs-padding-45px-top {padding-top:45px !important;}
    .xs-padding-50px-top {padding-top:50px !important;}
    .xs-padding-55px-top {padding-top:55px !important;}
    .xs-padding-60px-top {padding-top:60px !important;}
    .xs-padding-65px-top {padding-top:65px !important;}
    .xs-padding-70px-top {padding-top:70px !important;}
    .xs-padding-75px-top {padding-top:75px !important;}
    .xs-padding-80px-top {padding-top:80px !important;}
    .xs-padding-85px-top {padding-top:85px !important;}
    .xs-padding-90px-top {padding-top:90px !important;}
    .xs-padding-95px-top {padding-top:95px !important;}
    .xs-padding-100px-top {padding-top:100px !important;}

    /* padding bottom */
    .xs-padding-one-bottom {padding-bottom:1%;}
    .xs-padding-one-half-bottom {padding-bottom:1.5%;}
    .xs-padding-two-bottom {padding-bottom:2%;}
    .xs-padding-two-half-bottom {padding-bottom:2.5%;}
    .xs-padding-three-bottom {padding-bottom:3%;}
    .xs-padding-three-half-bottom {padding-bottom:3.5%;}
    .xs-padding-four-bottom {padding-bottom:4%;}
    .xs-padding-four-half-bottom {padding-bottom:4.5%;}
    .xs-padding-five-bottom {padding-bottom:5%;}
    .xs-padding-five-half-bottom {padding-bottom:5.5%;}
    .xs-padding-six-bottom {padding-bottom:6%;}
    .xs-padding-six-half-bottom {padding-bottom:6.5%;}
    .xs-padding-seven-bottom {padding-bottom:7%;}
    .xs-padding-seven-half-bottom {padding-bottom:7.5%;}
    .xs-padding-eight-bottom {padding-bottom:8%;}
    .xs-padding-eight-half-bottom {padding-bottom:8.5%;}
    .xs-padding-nine-bottom {padding-bottom:9%;}
    .xs-padding-nine-half-bottom {padding-bottom:9.5%;}
    .xs-padding-ten-bottom {padding-bottom:10%;}
    .xs-padding-ten-half-bottom {padding-bottom:10.5%;}
    .xs-padding-eleven-bottom {padding-bottom:11%;}
    .xs-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .xs-padding-twelve-bottom {padding-bottom:12%;}
    .xs-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .xs-padding-thirteen-bottom {padding-bottom:13%;}
    .xs-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .xs-padding-fourteen-bottom {padding-bottom:14%;}
    .xs-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .xs-padding-fifteen-bottom {padding-bottom:15%;}
    .xs-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .xs-padding-sixteen-bottom {padding-bottom:16%;}
    .xs-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .xs-padding-seventeen-bottom {padding-bottom:17%;}
    .xs-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .xs-padding-eighteen-bottom {padding-bottom:18%;}
    .xs-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .xs-padding-nineteen-bottom {padding-bottom:19%;}
    .xs-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .xs-padding-twenty-bottom {padding-bottom:20%;}
    .xs-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .xs-padding-twenty-one-bottom {padding-bottom:21%;}
    .xs-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .xs-padding-twenty-two-bottom {padding-bottom:22%;}
    .xs-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .xs-padding-twenty-three-bottom {padding-bottom:23%;}
    .xs-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .xs-padding-twenty-four-bottom {padding-bottom:24%;}
    .xs-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .xs-padding-twenty-five-bottom {padding-bottom:25%;}
    .xs-padding-5px-bottom {padding-bottom:5px !important;}
    .xs-padding-10px-bottom {padding-bottom:10px !important;}
    .xs-padding-15px-bottom {padding-bottom:15px !important;}
    .xs-padding-20px-bottom {padding-bottom:20px !important;}
    .xs-padding-25px-bottom {padding-bottom:25px !important;}
    .xs-padding-30px-bottom {padding-bottom:30px !important;}
    .xs-padding-35px-bottom {padding-bottom:35px !important;}
    .xs-padding-40px-bottom {padding-bottom:40px !important;}
    .xs-padding-45px-bottom {padding-bottom:45px !important;}
    .xs-padding-50px-bottom {padding-bottom:50px !important;}
    .xs-padding-55px-bottom {padding-bottom:55px !important;}
    .xs-padding-60px-bottom {padding-bottom:60px !important;}
    .xs-padding-65px-bottom {padding-bottom:65px !important;}
    .xs-padding-70px-bottom {padding-bottom:70px !important;}
    .xs-padding-75px-bottom {padding-bottom:75px !important;}
    .xs-padding-80px-bottom {padding-bottom:80px !important;}
    .xs-padding-85px-bottom {padding-bottom:85px !important;}
    .xs-padding-90px-bottom {padding-bottom:90px !important;}
    .xs-padding-95px-bottom {padding-bottom:95px !important;}
    .xs-padding-100px-bottom {padding-bottom:100px !important;}

    /* padding right */
    .xs-padding-one-right {padding-right:1%;}
    .xs-padding-one-half-right {padding-right:1.5%;}
    .xs-padding-two-right {padding-right:2%;}
    .xs-padding-two-half-right {padding-right:2.5%;}
    .xs-padding-three-right {padding-right:3%;}
    .xs-padding-three-half-right {padding-right:3.5%;}
    .xs-padding-four-right {padding-right:4%;}
    .xs-padding-four-half-right {padding-right:4.5%;}
    .xs-padding-five-right {padding-right:5%;}
    .xs-padding-five-half-right {padding-right:5.5%;}
    .xs-padding-six-right {padding-right:6%;}
    .xs-padding-six-half-right {padding-right:6.5%;}
    .xs-padding-seven-right {padding-right:7%;}
    .xs-padding-seven-half-right {padding-right:7.5%;}
    .xs-padding-eight-right {padding-right:8%;}
    .xs-padding-eight-half-right {padding-right:8.5%;}
    .xs-padding-nine-right {padding-right:9%;}
    .xs-padding-nine-half-right {padding-right:9.5%;}
    .xs-padding-ten-right {padding-right:10%;}
    .xs-padding-ten-half-right {padding-right:10.5%;}
    .xs-padding-eleven-right {padding-right:11%;}
    .xs-padding-eleven-half-right {padding-right:11.5%;}
    .xs-padding-twelve-right {padding-right:12%;}
    .xs-padding-twelve-half-right {padding-right:12.5%;}
    .xs-padding-thirteen-right {padding-right:13%;}
    .xs-padding-thirteen-half-right {padding-right:13.5%;}
    .xs-padding-fourteen-right {padding-right:14%;}
    .xs-padding-fourteen-half-right {padding-right:14.5%;}
    .xs-padding-fifteen-right {padding-right:15%;}
    .xs-padding-fifteen-half-right {padding-right:15.5%;}
    .xs-padding-sixteen-right {padding-right:16%;}
    .xs-padding-sixteen-half-right {padding-right:16.5%;}
    .xs-padding-seventeen-right {padding-right:17%;}
    .xs-padding-seventeen-half-right {padding-right:17.5%;}
    .xs-padding-eighteen-right {padding-right:18%;}
    .xs-padding-eighteen-half-right {padding-right:18.5%;}
    .xs-padding-nineteen-right {padding-right:19%;}
    .xs-padding-nineteen-half-right {padding-right:19.5%;}
    .xs-padding-twenty-right {padding-right:20%;}
    .xs-padding-twenty-hlaf-right {padding-right:20.5%;}
    .xs-padding-twenty-one-right {padding-right:21%;}
    .xs-padding-twenty-one-hlaf-right {padding-right:21.5%;}
    .xs-padding-twenty-two-right {padding-right:22%;}
    .xs-padding-twenty-two-hlaf-right {padding-right:22.5%;}
    .xs-padding-twenty-three-right {padding-right:23%;}
    .xs-padding-twenty-three-hlaf-right {padding-right:23.5%;}
    .xs-padding-twenty-four-right {padding-right:24%;}
    .xs-padding-twenty-four-hlaf-right {padding-right:24.5%;}
    .xs-padding-twenty-five-right {padding-right:25%;}
    .xs-padding-5px-right {padding-right:5px !important;}
    .xs-padding-10px-right {padding-right:10px !important;}
    .xs-padding-15px-right {padding-right:15px !important;}
    .xs-padding-20px-right {padding-right:20px !important;}
    .xs-padding-25px-right {padding-right:25px !important;}
    .xs-padding-30px-right {padding-right:30px !important;}
    .xs-padding-35px-right {padding-right:35px !important;}
    .xs-padding-40px-right {padding-right:40px !important;}
    .xs-padding-45px-right {padding-right:45px !important;}
    .xs-padding-50px-right {padding-right:50px !important;}
    .xs-padding-55px-right {padding-right:55px !important;}
    .xs-padding-60px-right {padding-right:60px !important;}
    .xs-padding-65px-right {padding-right:65px !important;}
    .xs-padding-70px-right {padding-right:70px !important;}
    .xs-padding-75px-right {padding-right:75px !important;}
    .xs-padding-80px-right {padding-right:80px !important;}
    .xs-padding-85px-right {padding-right:85px !important;}
    .xs-padding-90px-right {padding-right:90px !important;}
    .xs-padding-95px-right {padding-right:95px !important;}
    .xs-padding-100px-right {padding-right:100px !important;}

    /* padding left */
    .xs-padding-one-left {padding-left:1%;}
    .xs-padding-one-half-left {padding-left:1.5%;}
    .xs-padding-two-left {padding-left:2%;}
    .xs-padding-two-half-left {padding-left:2.5%;}
    .xs-padding-three-left {padding-left:3%;}
    .xs-padding-three-half-left {padding-left:3.5%;}
    .xs-padding-four-left {padding-left:4%;}
    .xs-padding-four-half-left {padding-left:4.5%;}
    .xs-padding-five-left {padding-left:5%;}
    .xs-padding-five-half-left {padding-left:5.5%;}
    .xs-padding-six-left {padding-left:6%;}
    .xs-padding-six-half-left {padding-left:6.5%;}
    .xs-padding-seven-left {padding-left:7%;}
    .xs-padding-seven-half-left {padding-left:7.5%;}
    .xs-padding-eight-left {padding-left:8%;}
    .xs-padding-eight-half-left {padding-left:8.5%;}
    .xs-padding-nine-left {padding-left:9%;}
    .xs-padding-nine-half-left {padding-left:9.5%;}
    .xs-padding-ten-left {padding-left:10%;}
    .xs-padding-ten-half-left {padding-left:10.5%;}
    .xs-padding-eleven-left {padding-left:11%;}
    .xs-padding-eleven-half-left {padding-left:11.5%;}
    .xs-padding-twelve-left {padding-left:12%;}
    .xs-padding-twelve-half-left {padding-left:12.5%;}
    .xs-padding-thirteen-left {padding-left:13%;}
    .xs-padding-thirteen-half-left {padding-left:13.5%;}
    .xs-padding-fourteen-left {padding-left:14%;}
    .xs-padding-fourteen-half-left {padding-left:14.5%;}
    .xs-padding-fifteen-left {padding-left:15%;}
    .xs-padding-fifteen-half-left {padding-left:15.5%;}
    .xs-padding-sixteen-left {padding-left:16%;}
    .xs-padding-sixteen-half-left {padding-left:16.5%;}
    .xs-padding-seventeen-left {padding-left:17%;}
    .xs-padding-seventeen-half-left {padding-left:17.5%;}
    .xs-padding-eighteen-left {padding-left:18%;}
    .xs-padding-eighteen-half-left {padding-left:18.5%;}
    .xs-padding-nineteen-left {padding-left:19%;}
    .xs-padding-nineteen-half-left {padding-left:19.5%;}
    .xs-padding-twenty-left {padding-left:20%;}
    .xs-padding-twenty-half-left {padding-left:20.5%;}
    .xs-padding-twenty-one-left {padding-left:21%;}
    .xs-padding-twenty-one-half-left {padding-left:21.5%;}
    .xs-padding-twenty-two-left {padding-left:22%;}
    .xs-padding-twenty-two-half-left {padding-left:22.5%;}
    .xs-padding-twenty-three-left {padding-left:23%;}
    .xs-padding-twenty-three-half-left {padding-left:23.5%;}
    .xs-padding-twenty-four-left {padding-left:24%;}
    .xs-padding-twenty-four-half-left {padding-left:24.5%;}
    .xs-padding-twenty-five-left {padding-left:25%;}
    .xs-padding-5px-left {padding-left:5px !important;}
    .xs-padding-10px-left {padding-left:10px !important;}
    .xs-padding-15px-left {padding-left:15px !important;}
    .xs-padding-20px-left {padding-left:20px !important;}
    .xs-padding-25px-left {padding-left:25px !important;}
    .xs-padding-30px-left {padding-left:30px !important;}
    .xs-padding-35px-left {padding-left:35px !important;}
    .xs-padding-40px-left {padding-left:40px !important;}
    .xs-padding-45px-left {padding-left:45px !important;}
    .xs-padding-50px-left {padding-left:50px !important;}
    .xs-padding-55px-left {padding-left:55px !important;}
    .xs-padding-60px-left {padding-left:60px !important;}
    .xs-padding-65px-left {padding-left:65px !important;}
    .xs-padding-70px-left {padding-left:70px !important;}
    .xs-padding-75px-left {padding-left:75px !important;}
    .xs-padding-80px-left {padding-left:80px !important;}
    .xs-padding-85px-left {padding-left:85px !important;}
    .xs-padding-90px-left {padding-left:90px !important;}
    .xs-padding-95px-left {padding-left:95px !important;}
    .xs-padding-100px-left {padding-left:100px !important;}

    /* padding top bottom */
    .xs-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .xs-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .xs-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .xs-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .xs-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .xs-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .xs-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .xs-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .xs-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .xs-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .xs-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .xs-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .xs-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .xs-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .xs-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .xs-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .xs-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .xs-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .xs-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .xs-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .xs-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .xs-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .xs-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .xs-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .xs-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .xs-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .xs-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .xs-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .xs-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .xs-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .xs-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .xs-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .xs-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .xs-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .xs-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .xs-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .xs-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .xs-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .xs-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .xs-padding-twenty-one-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .xs-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .xs-padding-twenty-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .xs-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .xs-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .xs-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .xs-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .xs-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .xs-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .xs-padding-twenty-five-tb {padding-top:25%; padding-bottom:25%;}
    .xs-padding-5px-tb {padding-top:5px !important; padding-bottom:5px !important;}
    .xs-padding-8px-tb {padding-top:8px !important; padding-bottom:8px !important;}
    .xs-padding-10px-tb {padding-top:10px !important; padding-bottom:10px !important;}
    .xs-padding-15px-tb {padding-top:15px !important; padding-bottom:15px !important;}
    .xs-padding-20px-tb {padding-top:20px !important; padding-bottom:20px !important;}
    .xs-padding-25px-tb {padding-top:25px !important; padding-bottom:25px !important;}
    .xs-padding-30px-tb {padding-top:30px !important; padding-bottom:30px !important;}
    .xs-padding-35px-tb {padding-top:35px !important; padding-bottom:35px !important;}
    .xs-padding-40px-tb {padding-top:40px !important; padding-bottom:40px !important;}
    .xs-padding-45px-tb {padding-top:45px !important; padding-bottom:45px !important;}
    .xs-padding-50px-tb {padding-top:50px !important; padding-bottom:50px !important;}
    .xs-padding-55px-tb {padding-top:55px !important; padding-bottom:55px !important;}
    .xs-padding-60px-tb {padding-top:60px !important; padding-bottom:60px !important;}
    .xs-padding-65px-tb {padding-top:65px !important; padding-bottom:65px !important;}
    .xs-padding-70px-tb {padding-top:70px !important; padding-bottom:70px !important;}
    .xs-padding-75px-tb {padding-top:75px !important; padding-bottom:75px !important;}
    .xs-padding-80px-tb {padding-top:80px !important; padding-bottom:80px !important;}
    .xs-padding-85px-tb {padding-top:85px !important; padding-bottom:85px !important;}
    .xs-padding-90px-tb {padding-top:90px !important; padding-bottom:90px !important;}
    .xs-padding-95px-tb {padding-top:95px !important; padding-bottom:95px !important;}
    .xs-padding-100px-tb {padding-top:100px !important; padding-bottom:100px !important;}

    /* padding left right */
    .xs-padding-one-lr {padding-left:1%; padding-right:1%;}
    .xs-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .xs-padding-two-lr {padding-left:2%; padding-right:2%;}
    .xs-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .xs-padding-three-lr {padding-left:3%; padding-right:3%;}
    .xs-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .xs-padding-four-lr {padding-left:4%; padding-right:4%;}
    .xs-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .xs-padding-five-lr {padding-left:5%; padding-right:5%;}
    .xs-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .xs-padding-six-lr {padding-left:6%; padding-right:6%;}
    .xs-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .xs-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .xs-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .xs-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .xs-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .xs-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .xs-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .xs-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .xs-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .xs-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .xs-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .xs-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .xs-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .xs-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .xs-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .xs-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .xs-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .xs-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .xs-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .xs-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .xs-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .xs-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .xs-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .xs-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .xs-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .xs-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .xs-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .xs-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .xs-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .xs-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .xs-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .xs-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .xs-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .xs-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .xs-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .xs-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .xs-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .xs-padding-twenty-lr {padding-left:25%; padding-right:25%;}
    .xs-padding-5px-lr {padding-left:5px !important; padding-right:5px ;}
    .xs-padding-10px-lr {padding-left:10px !important; padding-right:10px !important;}
    .xs-padding-15px-lr {padding-left:15px !important; padding-right:15px !important;}
    .xs-padding-20px-lr {padding-left:20px !important; padding-right:20px !important;}
    .xs-padding-25px-lr {padding-left:25px !important; padding-right:25px !important;}
    .xs-padding-30px-lr {padding-left:30px !important; padding-right:30px !important;}
    .xs-padding-35px-lr {padding-left:35px !important; padding-right:35px !important;}
    .xs-padding-40px-lr {padding-left:40px !important; padding-right:40px !important;}
    .xs-padding-45px-lr {padding-left:45px !important; padding-right:45px !important;}
    .xs-padding-50px-lr {padding-left:50px !important; padding-right:50px !important;}
    .xs-padding-55px-lr {padding-left:55px !important; padding-right:55px !important;}
    .xs-padding-60px-lr {padding-left:60px !important; padding-right:60px !important;}
    .xs-padding-65px-lr {padding-left:65px !important; padding-right:65px !important;}
    .xs-padding-70px-lr {padding-left:70px !important; padding-right:70px !important;}
    .xs-padding-75px-lr {padding-left:75px !important; padding-right:75px !important;}
    .xs-padding-80px-lr {padding-left:80px !important; padding-right:80px !important;}
    .xs-padding-85px-lr {padding-left:85px !important; padding-right:85px !important;}
    .xs-padding-90px-lr {padding-left:90px !important; padding-right:90px !important;}
    .xs-padding-95px-lr {padding-left:95px !important; padding-right:95px !important;}
    .xs-padding-100px-lr {padding-left:100px !important; padding-right:100px !important;}

    /* width */
    .xs-width-10 {width:10%;}
    .xs-width-15 {width:15%;}
    .xs-width-20 {width:20%;}
    .xs-width-25 {width:25%;}
    .xs-width-30 {width:30%;}
    .xs-width-35 {width:35%;}
    .xs-width-40 {width:40%;}
    .xs-width-45 {width:45%;}
    .xs-width-50 {width:50%;}
    .xs-width-55 {width:55%;}
    .xs-width-60 {width:60%;}
    .xs-width-65 {width:65%;}
    .xs-width-70 {width:70%;}
    .xs-width-75 {width:75%;}
    .xs-width-80 {width:80%;}
    .xs-width-85 {width:85%;}
    .xs-width-90 {width:90%;}
    .xs-width-95 {width:95%;}
    .xs-width-100 {width:100%;}
    .xs-width-auto {width: auto;}

    .xs-width-50px {width:50px;}
    .xs-width-70px {width:70px;}
    .xs-width-75px {width:75px;}
    .xs-width-80px {width:80px;}
    .xs-width-85px {width:85px;}
    .xs-width-90px {width:90px;}
    .xs-width-95px {width:95px;}
    .xs-width-100px {width:100px;}
    .xs-width-120px {width:120px;}
    .xs-width-130px {width:130px;}
    .xs-width-150px {width:150px;}
    .xs-width-180px {width:180px;}
    .xs-width-200px {width:200px;}
    .xs-width-250px {width:250px;}
    .xs-width-300px {width:300px;}
    .xs-width-350px {width:350px;}
    .xs-width-400px {width:400px;}
    .xs-width-450px {width:450px;}
    .xs-width-500px {width:500px;}
    .xs-width-550px {width:550px;}
    .xs-width-600px {width:600px;}
    .xs-width-650px {width:650px;}
    .xs-width-700px {width:700px;}
    .xs-width-750px {width:750px;}
    .xs-width-800px {width:800px;}
    .xs-width-850px {width:850px;}
    .xs-width-900px {width:900px;}
    .xs-width-950px {width:950px;}
    .xs-width-1000px {width:1000px;}
 
    /* display */
    .xs-display-block {display:block !important;}
    .xs-overflow-hidden {overflow:hidden !important;}
    .xs-overflow-auto {overflow:auto !important;}
    .xs-display-inline-block {display:inline-block !important;}
    .xs-display-inline {display:inline !important;}
    .xs-display-none {display:none !important;}
    .xs-display-inherit {display:inherit !important;}
    .xs-display-table {display:table !important;}
    .xs-display-table-cell {display:table-cell !important;}

    /* position */
    .xs-position-inherit {position:inherit}
    .xs-position-relative {position:relative; z-index: 5}
    .xs-position-absolute {position:absolute;}
    .xs-position-fixed {position:fixed;}

    /* boder align */
    .xs-border-top {border-top: 1px solid;}
    .xs-border-bottom {border-bottom: 1px solid;}
    .xs-border-left {border-left: 1px solid;}
    .xs-border-right {border-right: 1px solid;}
    .xs-border-lr {border-left: 1px solid; border-right: 1px solid;}
    .xs-border-tb {border-top: 1px solid; border-bottom: 1px solid;}

    /* border */
    .xs-no-border-top {border-top:0 !important;}
    .xs-no-border-bottom {border-bottom:0 !important;}
    .xs-no-border-right {border-right:0 !important;}
    .xs-no-border-left {border-left:0 !important;}
    .xs-no-border { border: 0 !important;}
}

/* ipad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .parallax {background-attachment: inherit; background-position: center;}
}

/* ipad Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .parallax {background-attachment: inherit; background-position: center;}
}

/* ===================================
	Default Header style
====================================== */

header {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  position: relative;
  z-index: 99999;
}
.navbar-brand img {
  max-height: 43px;
  transition-duration: 0.5s;
}
.navbar-header-custom {
  padding: 22px 0 20px 0;
}
.navbar-default {
  transition: all 0.5s ease 0s;
  -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.navbar-default .container,
.navbar-default .container-fluid {
  position: relative;
}
.menu_area {
  position: relative;
  z-index: 2;
}
.menu_area .navbar-brand {
  transition: all 0.5s ease 0s;
}

.navbar-nav li {
  list-style: none;
  transition: background 0.3s ease;
}
.navbar-nav li.has-sub > a {
  position: relative;
}
.navbar-nav li.has-sub a:hover {
  color: #fff;
}
.navbar-nav li a {
  display: block;
  color: #9d9d9d;
  font-size: 13px;
}
.navbar-nav > li {
  margin-left: 10px;
  float: left;
}
.navbar-nav > li.has-sub {
  padding-right: 13px;
}
.navbar-nav > li > a {
  position: relative;
  display: block;
  font-size: 12px;
  font-weight: 500;
  padding: 21px 0.5rem;
  color: #232323;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition-duration: 500ms;
}
.navbar-nav li.current > a {
  color: #3498db;
}
.navbar-nav > li.has-sub > a:hover {
  color: #000;
}
.navbar > ul > li.has-sub > a:hover:after {
  border-color: transparent #000 #000 transparent;
}
.navbar > ul > li.has-sub > a:after {
  position: absolute;
  top: 28px;
  right: -8px;
  content: "";
  border-color: transparent #232323 #232323 transparent;
  border-style: solid;
  border-width: 0 1px 1px 0;
  display: block;
  height: 6px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: border 0.3s ease 0s;
  width: 6px;
}
.navbar > ul > li.current > a:after {
  border-color: transparent #3498db #3498db transparent;
}

.navbar-nav ul {
  position: absolute;
  left: -9999px;
}
.navbar ul ul li {
  transition: all 0.25s ease;
  margin-bottom: 12px;
  padding: 0 20px;
}
.navbar ul ul li:last-child {
  margin-bottom: 0;
}
.navbar ul ul li.active > a {
  color: #fff;
}
.navbar-nav li > ul {
  padding: 15px 0;
  margin: 0 0 0 10px;
  background: #232323;
  min-width: 225px;
  -ms-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}
.navbar-nav li:hover > ul {
  left: auto;
}

.navbar-nav > li.has-sub > ul {
  opacity: 0;
  margin-top: 10px;
  transition: all 0.5s ease;
}
.navbar-nav > li.has-sub:hover > ul {
  margin-top: 0;
  opacity: 1;
}
.navbar-nav > li.has-sub:last-child:hover ul {
  left: inherit;
  right: 0;
}
.navbar li > ul ul {
  margin-top: -39px;
  margin-left: 200px;
  min-width: 200px;
}
.navbar li > ul ul ul {
  margin-left: 175px;
  min-width: 130px;
}
.navbar ul ul li:last-child > a,
.navbar ul ul li.last-item > a {
  border-bottom: 0;
}
.navbar ul ul li.has-sub > a:after {
  position: absolute;
  top: 12px;
  right: -3px;
  width: 8px;
  height: 2px;
  display: block;
  background: #9d9d9d;
  content: "";
}
.navbar ul ul li.has-sub > a:before {
  position: absolute;
  top: 9px;
  right: 0;
  display: block;
  width: 2px;
  height: 8px;
  background: #9d9d9d;
  content: "";
  transition: all 0.25s ease;
}
.navbar ul ul > li.has-sub:hover > a:before {
  top: 17px;
  height: 0;
}

/* atribute navigation */
.attr-nav {
  float: right;
  margin-left: 11px;
  margin-right: -15px;
}
.attr-nav .dropdown-toggle:after {
  content: none;
}
.attr-nav > ul {
  padding: 0;
  margin: 0 0 -5px 0;
  list-style: none;
  display: inline-block;
}
.attr-nav > ul > li {
  float: left;
  display: block;
}
.attr-nav > ul > li > a {
  color: #232323;
  display: block;
  padding: 21px 15px;
  position: relative;
}
.attr-nav > ul > li > a span.badge {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 5px;
  font-size: 10px;
  padding: 2px 0 0 1px;
  width: 15px;
  height: 15px;
  color: #fff;
  border-radius: 15px;
  line-height: 12px;
  font-weight: 400;
}
.attr-nav > ul > li.dropdown ul.dropdown-menu {
  position: absolute;
  border: none;
  border-radius: 0;
  padding: 0;
  background: #232323;
  min-width: 225px;
  margin: 10px 0 0 10px;
  -ms-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
}

.attr-nav ul.cart-list {
  padding: 0;
  width: 250px;
}
.attr-nav ul.cart-list > li {
  position: relative;
  border-bottom: solid 1px #323232;
  padding: 15px;
  margin: 0;
}
.attr-nav ul.cart-list > li:last-child,
.attr-nav ul.cart-list > li:nth-last-child(2) {
  border-bottom: none;
}
.attr-nav ul.cart-list > li > a.photo {
  padding: 0;
  margin-right: 15px;
  float: left;
  width: 50px;
  height: 50px;
}
.navbar ul.cart-list > li img {
  width: 50px;
  height: 50px;
  border: 1px solid #323232;
  display: inline-block;
}
.navbar ul.cart-list > li > h6 {
  margin: 0;
  font-size: 12px;
  display: inline-block;
}
.navbar ul.cart-list > li > h6 a {
  color: #9d9d9d;
  padding: 0;
}
.navbar ul.cart-list > li > h6 a:hover {
  color: #fff;
}
.navbar ul.cart-list > li > h6 > a.photo {
  padding: 0;
  display: block;
}
.navbar ul.cart-list > li > p {
  margin-bottom: 0;
  font-size: 12px;
  color: #f5f5f5;
}
.navbar ul.cart-list > li.total {
  padding: 15px;
  border: none;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.navbar ul.cart-list > li.total > .butn {
  float: right;
  width: auto;
}
.navbar ul.cart-list > li .price {
  font-weight: 500;
}
.navbar ul.cart-list > li.total > span {
  padding-top: 4px;
  color: #fff;
  padding-left: 5px;
  font-size: 14px;
  display: inline-block;
}

/* top search */
.top-search {
  padding: 10px 0;
  display: none;
}
.top-search input.form-control {
  background-color: transparent;
  border: none;
  -o-box-shadow: 0px 0px 0px;
  box-shadow: 0px 0px 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
}
.top-search input.form-control::-webkit-input-placeholder {
  color: #f1f1f1;
  opacity: 1;
}
.top-search input.form-control::-ms-input-placeholder {
  color: #f1f1f1;
  opacity: 1;
}
.top-search input.form-control::placeholder {
  color: #f1f1f1;
  opacity: 1;
}
.top-search input.form-control::-ms-input-placeholder,
.top-search input.form-control::-moz-input-placeholder {
  color: #f1f1f1;
}
.top-search .input-group-addon {
  background-color: transparent;
  border: none;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
  line-height: 36px;
  font-size: 16px;
}
.top-search .input-group-addon.close-search {
  cursor: pointer;
}

/* top search */
.top-search.white {
  background: #fff;
}
.top-search.white input.form-control {
  color: #232323;
}
.top-search.white input.form-control::-webkit-input-placeholder {
  color: #6f6f6f;
  opacity: 1;
}
.top-search.white input.form-control::-ms-input-placeholder {
  color: #6f6f6f;
  opacity: 1;
}
.top-search.white input.form-control::placeholder {
  color: #6f6f6f;
  opacity: 1;
}
.top-search.white input.form-control::-ms-input-placeholder,
.header-style6 .top-search input.form-control::-moz-input-placeholder {
  color: #6f6f6f;
}
.top-search.white .input-group-addon {
  color: #232323;
}

/* Responsive CSS */
@media screen and (min-width: 992px) {
  /* ===================================
		Default Header style
	====================================== */

  header.scrollHeader .navbar-default {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    background: #fff;
    left: 0;
    transition: all 0.2s ease 0s;
    -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
  .wrapper-boxed .scrollHeader .navbar-default {
    left: 0;
    margin: 0 auto;
    max-width: 1200px;
    right: 0;
  }
  .scrollHeader .navbar-brand img {
    max-height: 35px;
    transition-duration: 0.5s;
  }
  .scrollHeader .navbar-header-custom {
    padding: 10px 0;
    transition-duration: 0.5s;
  }
  .navbar-nav .megamenu {
    margin: 0;
    padding: 30px 15px;
    right: 0;
    width: 100%;
  }
  .navbar .megamenu > li {
    margin: 0;
    float: left;
  }
  .navbar .sub-title {
    color: #fff;
  }
  .megamenu > li > ul {
    left: 0;
    margin: 0;
    min-width: auto;
    position: static;
    width: 100%;
  }
  .navbar .megamenu li > ul {
    margin: 0;
    min-width: auto;
    padding: 0;
    -ms-box-shadow: none;
    box-shadow: none;
  }
  .navbar .megamenu ul li {
    padding: 0;
  }
  .navbar .megamenu li.has-sub > a:after,
  .navbar .megamenu li.has-sub > a:before {
    background: none;
  }

  /* atribute navigation */
  .scrollHeader .attr-nav > ul > li > a {
    padding: 21px 15px;
  }
  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    visibility: hidden;
    top: 66px;
    border-radius: 0;
    opacity: 0;
    left: -225px;
  }
  .attr-nav > ul > li.dropdown:hover ul.dropdown-menu {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  .scrollHeader .attr-nav > ul > li.dropdown ul.dropdown-menu {
    top: 67px;
  }

  /* ===================================
	    Header style01
	====================================== */

  .header-style1 {
    box-shadow: none;
  }
  .header-style1 #top-bar {
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .header-style1 {
    position: absolute;
    left: 0;
    width: 100%;
  }
  .header-style1 .navbar-default {
    -ms-box-shadow: none;
    box-shadow: none;
  }

  .header-style1 .navbar-nav li.has-sub a:hover {
    color: #fff;
  }
  .header-style1 .navbar-nav > li > a {
    color: #fff;
  }
  .header-style1 .navbar-nav li.current > a {
    color: #3498db;
  }
  .header-style1 .navbar-nav > li.has-sub > a:hover {
    color: #fff;
  }
  .header-style1 .navbar > ul > li.has-sub > a:hover:after {
    border-color: transparent #fff #fff transparent;
  }
  .header-style1 .navbar > ul > li.has-sub > a:after {
    border-color: transparent #fff #fff transparent;
  }
  .header-style1 .navbar > ul > li.current > a:after {
    border-color: transparent #3498db #3498db transparent;
  }

  .header-style1.scrollHeader .navbar-nav > li > a {
    color: #232323;
  }
  .header-style1.scrollHeader .navbar-nav > li.has-sub > a:hover {
    color: #000;
  }
  .header-style1.scrollHeader .navbar > ul > li.has-sub > a:after {
    border-color: transparent #232323 #232323 transparent;
  }
  .header-style1.scrollHeader .navbar-nav li.current > a {
    color: #3498db;
  }
  .header-style1.scrollHeader .navbar-nav li.current > a:hover {
    color: #3498db;
  }
  .header-style1.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent #3498db #3498db transparent;
  }

  /* atribute navigation */
  .header-style1 .attr-nav > ul > li > a {
    color: #fff;
  }
  .header-style1.scrollHeader .attr-nav > ul > li > a {
    color: #232323;
  }

  /* ===================================
	    Header style02
	====================================== */

  .header-style2 {
    box-shadow: none;
    position: absolute;
    left: 0;
    width: 100%;
  }
  .header-style2 #top-bar {
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  }
  .header-style2 .navbar-default {
    -ms-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .header-style2 .navbar-header-custom {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 17px 0 15px 0;
  }

  .header-style2 .navbar-nav li a {
    color: #6f6f6f;
  }
  .header-style2 .navbar-nav > li > a {
    font-size: 13px;
    color: #fff;
  }
  .header-style2 .navbar-nav > li > a:hover {
    color: #fff;
  }
  .header-style2 .navbar-nav li > ul {
    background: #fff;
  }
  .header-style2 .navbar ul ul li.active > a {
    color: #232323;
  }
  .header-style2 .navbar-nav li.has-sub a:hover {
    color: #232323;
  }
  .header-style2 .navbar-nav li.current > a {
    color: #3498db;
  }
  .header-style2 .navbar .sub-title {
    color: #232323;
  }
  .header-style2 .navbar-nav > li.has-sub > a:hover {
    color: #fff;
  }
  .header-style2 .navbar > ul > li.has-sub > a:after {
    top: 29px;
  }
  .header-style2 .navbar > ul > li.has-sub > a:hover:after {
    border-color: transparent #fff #fff transparent;
  }
  .header-style2 .navbar > ul > li.has-sub > a:after {
    border-color: transparent #fff #fff transparent;
  }
  .header-style2 .navbar > ul > li.current > a:after {
    border-color: transparent #3498db #3498db transparent;
  }

  .header-style2.scrollHeader .navbar-default {
    border-bottom: none;
  }
  .header-style2.scrollHeader .navbar-header-custom {
    padding: 10px 0 10px 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .header-style2.scrollHeader .navbar-nav > li > a {
    color: #232323;
  }
  .header-style2.scrollHeader .navbar-nav > li.has-sub > a:hover {
    color: #000;
  }
  .header-style2.scrollHeader .navbar > ul > li.has-sub > a:after {
    border-color: transparent #232323 #232323 transparent;
  }
  .header-style2.scrollHeader .navbar-nav li.current > a {
    color: #3498db;
  }
  .header-style2.scrollHeader .navbar-nav li.current > a:hover {
    color: #3498db;
  }
  .header-style2.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent #3498db #3498db transparent;
  }

  /* atribute navigation */
  .header-style2 .attr-nav {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  .header-style2.scrollHeader .attr-nav {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .header-style2.scrollHeader .attr-nav > ul > li > a {
    color: #232323;
    padding: 20px 15px;
  }
  .header-style2 .attr-nav > ul > li.dropdown ul.dropdown-menu {
    background: #fff;
  }
  .header-style2 .attr-nav ul.cart-list > li {
    border-bottom: 1px solid #dcdcdc;
  }
  .header-style2 .attr-nav ul.cart-list > li.total {
    border: none;
  }
  .header-style2 .navbar ul.cart-list > li > h6 a {
    color: #6f6f6f;
  }
  .header-style2 .navbar ul.cart-list > li > h6 a:hover {
    color: #232323;
  }
  .header-style2 .navbar ul.cart-list > li > p {
    color: #232323;
  }
  .header-style2 .navbar ul.cart-list > li img {
    border: 1px solid #c5c5c5;
  }
  .header-style2 .attr-nav > ul {
    padding: 0 15px;
  }
  .header-style2 .attr-nav > ul > li > a {
    color: #fff;
    padding: 29px 15px;
  }
  .header-style2.scrollHeader .attr-nav > ul > li.dropdown ul.dropdown-menu {
    top: 65px;
  }
  .header-style2 .attr-nav > ul > li.dropdown ul.dropdown-menu {
    left: -215px;
  }

  /* ===================================
	    Header style03
	====================================== */
  .header-style3 {
    box-shadow: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 2px solid rgba(52, 152, 219, 0.9);
  }
  .header-style3 .navbar-header-custom {
    padding: 12px 0 10px;
  }
  .header-style3 .navbar-default {
    -ms-box-shadow: none;
    box-shadow: none;
  }

  /* ===================================
	    Header style04
	====================================== */
  .header-style4 {
    box-shadow: none;
  }
  header.header-style4.scrollHeader .navbar-default {
    position: relative;
    transition: all 0s ease 0s;
  }
  header.header-style4 .menu_area {
    background: #f5f5f5;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
  }
  .header-style4 .navbar-default {
    box-shadow: none;
  }

  .header-style4 .navbar-nav > li:first-child {
    margin-left: 0;
  }
  .header-style4 .navbar-brand img {
    transition: all 0s ease 0s;
  }

  .header-style4 .navbar-nav li a {
    color: #6f6f6f;
  }
  .header-style4 .navbar-nav > li > a {
    font-size: 13px;
    color: #6f6f6f;
  }
  .header-style4 .navbar-nav li > ul {
    background: #fff;
  }
  .header-style4 .navbar ul ul li.active > a {
    color: #232323;
  }
  .header-style4 .navbar-nav li.has-sub a:hover {
    color: #232323;
  }
  .header-style4 .navbar-nav li.current > a {
    color: #3498db;
  }
  .header-style4 .navbar .sub-title {
    color: #232323;
  }
  .header-style4 .navbar-nav > li.has-sub > a:hover {
    color: #232323;
  }
  .header-style4 .navbar > ul > li.has-sub > a:after {
    top: 29px;
  }
  .header-style4 .navbar > ul > li.has-sub > a:hover:after {
    border-color: transparent #232323 #232323 transparent;
  }
  .header-style4 .navbar > ul > li.has-sub > a:after {
    border-color: transparent #6f6f6f #6f6f6f transparent;
  }
  .header-style4 .navbar > ul > li.current > a:after {
    border-color: transparent #3498db #3498db transparent;
  }

  .header-style4 .attr-nav > ul > li > a {
    color: #6f6f6f;
  }
  .header-style4 .attr-nav > ul > li > a:hover {
    color: #232323;
  }
  .header-style4 .attr-nav .cart-search {
    border-right: 1px solid #e0e0e0;
    padding-right: 15px;
    margin-right: 15px;
    vertical-align: middle;
    margin-bottom: 0;
  }
  .header-style4 .attr-nav > ul {
    float: none;
    margin: 0;
  }
  .header-style4 .top-social-icon {
    vertical-align: middle;
  }
  .header-style4 .attr-nav .top-social-icon > li:first-child > a {
    padding-left: 0;
  }
  .header-style4 .attr-nav .top-social-icon > li > a {
    padding: 5px;
  }

  /* ===================================
	    Header style05
	====================================== */
  .header-style5 #top-bar {
    background: #3498db;
  }
  .header-style5 #top-bar i {
    color: #fff;
  }

  /* ===================================
	    Header style06
	====================================== */
  .header-style6 {
    box-shadow: none;
    position: absolute;
    left: 0;
    width: 100%;
  }
  .header-style6 .navbar-header-custom {
    padding: 17px 0 15px 0;
  }
  .header-style6 .navbar-default {
    background: rgba(52, 152, 219, 0.85);
    -ms-box-shadow: none;
    box-shadow: none;
  }

  .header-style6 .navbar-nav li.has-sub a:hover {
    color: #fff;
  }
  .header-style6 .navbar-nav > li > a {
    color: #fff;
  }
  .header-style6 .navbar-nav li.current > a {
    color: #fff;
    font-weight: 700;
  }
  .header-style6 .navbar-nav > li.has-sub > a:hover {
    color: #fff;
  }
  .header-style6 .navbar > ul > li.has-sub > a:hover:after {
    border-color: transparent #fff #fff transparent;
  }
  .header-style6 .navbar > ul > li.has-sub > a:after {
    border-color: transparent #fff #fff transparent;
  }
  .header-style6 .navbar > ul > li.current > a:after {
    border-color: transparent #fff #fff transparent;
  }

  .header-style6.scrollHeader .navbar-header-custom {
    padding: 10px 0;
  }
  .header-style6.scrollHeader .navbar-default {
    background: #3498db;
  }
  .header-style6.scrollHeader .navbar-nav > li > a {
    color: #fff;
  }
  .header-style6.scrollHeader .navbar-nav > li.has-sub > a:hover {
    color: #fff;
  }
  .header-style6.scrollHeader .navbar > ul > li.has-sub > a:after {
    border-color: transparent #fff #fff transparent;
  }
  .header-style6.scrollHeader .navbar-nav li.current > a {
    color: #fff;
    font-weight: 700;
  }
  .header-style6.scrollHeader .navbar-nav li.current > a:hover {
    color: #fff;
  }
  .header-style6.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent #fff #fff transparent;
  }

  /* atribute navigation */
  .header-style6 .attr-nav > ul > li > a {
    color: #fff;
  }
  .header-style6 .attr-nav > ul > li > a span.badge {
    background: #232323;
    color: #fff;
  }
  .header-style6.scrollHeader .attr-nav > ul > li > a {
    color: #fff;
  }

  /* ===================================
		Menu area light
	====================================== */
  .menu_area-light .navbar-nav li a {
    color: #6f6f6f;
  }
  .menu_area-light .navbar-nav > li > a {
    font-size: 13px;
    color: #fff;
  }
  .menu_area-light .navbar-nav li > ul {
    background: #fff;
  }
  .menu_area-light .navbar ul ul li.active > a {
    color: #232323;
  }
  .menu_area-light .navbar-nav li.has-sub a:hover {
    color: #232323;
  }
  .menu_area-light .navbar-nav li.current > a {
    color: #3498db;
  }
  .menu_area-light .navbar .sub-title {
    color: #232323;
  }
  .menu_area-light .navbar-nav > li.has-sub > a:hover {
    color: #fff;
  }
  .menu_area-light .navbar > ul > li.has-sub > a:hover:after {
    border-color: transparent #fff #fff transparent;
  }
  .menu_area-light .navbar > ul > li.has-sub > a:after {
    border-color: transparent #fff #fff transparent;
  }
  .menu_area-light .navbar > ul > li.current > a:after {
    border-color: transparent #3498db #3498db transparent;
  }

  .menu_area-light.scrollHeader .navbar-nav > li > a {
    color: #232323;
  }
  .menu_area-light.scrollHeader .navbar-nav > li.has-sub > a:hover {
    color: #000;
  }
  .menu_area-light.scrollHeader .navbar > ul > li.has-sub > a:after {
    border-color: transparent #232323 #232323 transparent;
  }
  .menu_area-light.scrollHeader .navbar-nav li.current > a {
    color: #3498db;
  }
  .menu_area-light.scrollHeader .navbar-nav li.current > a:hover {
    color: #3498db;
  }
  .menu_area-light.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent #3498db #3498db transparent;
  }
}

@media screen and (max-width: 1199px) {
  .navbar-nav > li {
    margin-left: 5px;
  }
}

@media screen and (max-width: 991px) {
  /* ===================================
		Default Header style
	====================================== */

  .navbar-header-custom {
    padding: 4px 0 8px;
  }
  .menu_area .navbar-brand {
    margin-top: 6px;
  }

  .navbar-nav li > ul {
    -ms-box-shadow: none;
    box-shadow: none;
  }
  .navbar-nav > li.has-sub > ul {
    opacity: 1;
    margin: 0;
    -ms-box-shadow: none;
    box-shadow: none;
    transition: all 0s ease;
  }
  .navbar li > ul ul {
    margin: 0;
    min-width: auto;
  }
  .navbar-nav > li.has-sub {
    padding-right: 0;
  }
  .navbar-nav > li > a {
    padding: 10px 20px;
    color: #232323;
  }
  .navbar-nav > li > a:hover {
    opacity: 1;
  }
  .navbar-nav li.has-sub a:hover {
    color: #232323;
  }
  .navbar ul ul li.active > a {
    color: #000;
  }
  .navbar .sub-title {
    color: #232323;
  }

  .navbar-nav {
    background: #fff;
    -ms-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 71px;
    left: 0;
    width: 100%;
    transition-duration: 0;
    -ms-transition-duration: 0;
    -moz-transition-duration: 0;
    -webkit-transition-duration: 0;
    -o-transition-duration: 0;
    width: 100%;
    display: none;
    transition: none;
    -ms-transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
  }
  .navbar-nav li.active > a {
    opacity: 1;
  }
  .navbar-nav ul {
    width: 100%;
    display: none;
    transition: none;
    -ms-transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
  }
  .navbar-nav li {
    width: 100%;
    border-top: 1px solid #e6e6e6;
    position: relative;
    padding: 0;
    margin: 0;
  }
  .navbar-nav ul li:hover {
    background: #f5f5f5;
  }
  .navbar-nav ul ul li {
    height: auto;
  }
  .navbar-nav li:hover > ul > li {
    height: auto;
  }

  .navbar ul li a {
    width: 100%;
    border-bottom: 0;
  }
  .navbar ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
    background: #f5f5f5;
    padding: 0;
  }
  .navbar ul ul li {
    margin-bottom: 0;
    padding: 0;
    border-color: #e6e6e6;
  }
  .navbar ul ul li a {
    width: 100%;
    border-bottom: 0;
    color: #616161;
    background: none;
    padding: 8px 30px;
  }
  .navbar ul ul ul li a {
    padding-left: 45px;
  }
  .navbar > ul > li {
    float: none;
  }
  .navbar-nav ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
    background: #f5f5f5;
    padding: 0;
  }

  .navbar > ul > li.has-sub > a:after,
  .navbar > ul > li.has-sub > a:before {
    display: none;
  }
  .navbar ul ul > li.has-sub > a:after,
  .navbar ul ul > li.has-sub > a:before {
    display: none;
  }
  .navbar-nav ul ul ul li.active a {
    border-left: none;
  }
  .navbar-nav > ul > li.has-sub > ul > li.active > a,
  .navbar-nav > ul ul > li.has-sub > ul > li.active > a {
    border-top: none;
  }
  .navbar li > ul ul ul {
    margin-left: 0;
    background: #fff;
  }
  .navbar ul ul ul ul li a {
    padding-left: 55px;
  }
  .navbar-nav .has-sub .has-sub .submenu-button {
    height: 40px;
  }
  .navbar-nav .has-sub .has-sub .submenu-button:after {
    top: 19px;
  }
  .navbar-nav .has-sub .has-sub .submenu-button:before {
    top: 16px;
  }

  .navbar-toggler {
    background: #232323;
    width: 50px;
    height: 45px;
    padding: 0;
    border-radius: 0;
    position: absolute;
    right: 0;
    top: 13px;
    cursor: pointer;
    z-index: 12399994;
    border: none;
  }
  .navbar-toggler:after {
    position: absolute;
    top: 22px;
    right: 15px;
    display: block;
    height: 8px;
    width: 20px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    content: "";
  }
  .navbar-toggler:before {
    transition: all 0.3s ease;
    position: absolute;
    top: 16px;
    right: 15px;
    display: block;
    height: 2px;
    width: 20px;
    background: #fff;
    content: "";
  }
  .navbar-toggler.menu-opened:after {
    transition: all 0.3s ease;
    top: 23px;
    border: 0;
    height: 2px;
    width: 19px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar-toggler.menu-opened:before {
    top: 23px;
    background: #fff;
    width: 19px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar-nav .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    height: 44px;
    width: 100%;
    cursor: pointer;
  }
  .navbar-nav .submenu-button:after {
    position: absolute;
    top: 21px;
    right: 17px;
    width: 8px;
    height: 2px;
    display: block;
    background: #232323;
    content: "";
  }
  .navbar-nav .submenu-button:before {
    position: absolute;
    top: 18px;
    right: 20px;
    display: block;
    width: 2px;
    height: 8px;
    background: #232323;
    content: "";
  }
  .navbar-nav .submenu-button.submenu-opened:before {
    display: none;
  }

  /* atribute navigation */
  .attr-nav {
    float: left;
  }
  .attr-nav > ul > li {
    display: inline-block;
  }
  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    left: -193px;
    top: 38px;
    display: none !important;
  }
  .attr-nav > ul > li.dropdown.show ul.dropdown-menu {
    display: block !important;
  }
  .attr-nav > ul > li > a {
    padding: 0;
  }
  .attr-nav > ul > li > a span.badge {
    right: -8px;
  }

  /* ===================================
	    Header style01
	====================================== */
  .header-style1 {
    box-shadow: none;
    position: absolute;
    left: 0;
    width: 100%;
  }
  .header-style1 #top-bar {
    display: none;
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  }
  .header-style1 .navbar-default {
    -ms-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  }

  .header-style1 .navbar-toggler {
    background: #fff;
  }
  .header-style1 .navbar-toggler:after {
    border-top: 2px solid #232323;
    border-bottom: 2px solid #232323;
  }
  .header-style1 .navbar-toggler:before {
    background: #232323;
  }
  .header-style1 .navbar-toggler.menu-opened:after {
    background: #232323;
    border-bottom: none;
  }
  .header-style1 .navbar-toggler.menu-opened:before {
    background: #232323;
  }

  .header-style1 .attr-nav > ul > li > a {
    color: #fff;
  }

  /* ===================================
	    Header style02
	====================================== */
  .header-style2 {
    box-shadow: none;
    position: absolute;
    left: 0;
    width: 100%;
  }
  .header-style2 .navbar-default {
    -ms-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .header-style2 .navbar-toggler {
    background: #fff;
  }
  .header-style2 .navbar-toggler:after {
    border-top: 2px solid #232323;
    border-bottom: 2px solid #232323;
  }
  .header-style2 .navbar-toggler:before {
    background: #232323;
  }
  .header-style2 .navbar-toggler.menu-opened:after {
    background: #232323;
    border-bottom: none;
  }
  .header-style2 .navbar-toggler.menu-opened:before {
    background: #232323;
  }

  /* atribute navigation */
  .header-style2.scrollHeader .attr-nav > ul > li > a {
    color: #232323;
  }
  .header-style2 .attr-nav > ul > li.dropdown ul.dropdown-menu {
    background: #fff;
  }
  .header-style2 .attr-nav ul.cart-list > li {
    border-bottom: 1px solid #dcdcdc;
  }
  .header-style2 .attr-nav ul.cart-list > li.total {
    border: none;
  }
  .header-style2 .navbar ul.cart-list > li > h6 a {
    color: #6f6f6f;
  }
  .header-style2 .navbar ul.cart-list > li > h6 a:hover {
    color: #232323;
  }
  .header-style2 .navbar ul.cart-list > li > p {
    color: #232323;
  }
  .header-style2 .navbar ul.cart-list > li img {
    border: 1px solid #c5c5c5;
  }
  .header-style2 .attr-nav > ul > li > a {
    color: #fff;
  }

  /* ===================================
	    Header style03
	====================================== */
  .header-style3 {
    box-shadow: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    border-bottom: 2px solid rgba(52, 152, 219, 0.9);
  }
  .header-style3 .navbar-default {
    -ms-box-shadow: none;
    box-shadow: none;
  }

  /* ===================================
	    Header style04
	====================================== */
  .header-style4 .navbar-header-custom {
    padding: 8px 0;
  }
  .header-style4 .navbar {
    position: unset;
    float: right;
  }
  .header-style4 .navbar-nav {
    top: 0;
    background: none;
    padding: 0 15px;
  }
  .header-style4 .navbar-toggler {
    top: -57px;
    right: 15px;
  }
  .header-style4 .navbar > ul > li {
    background: #fff;
  }
  .header-style4 .attr-nav {
    margin-top: -65px !important;
  }
  .header-style4 .top-social-icon {
    display: none;
  }
  .header-style4 .attr-nav > ul > li.dropdown ul.dropdown-menu {
    top: 35px;
  }

  /* ===================================
	    Header style05
	====================================== */
  .header-style5 #top-bar {
    background: #3498db;
  }
  .header-style5 #top-bar i {
    color: #fff;
  }
  .header-style5 .navbar-toggler {
    background: #3498db;
  }

  /* ===================================
	    Header style06
	====================================== */
  .header-style6 {
    position: absolute;
    left: 0;
    width: 100%;
  }
  .header-style6 .navbar-default {
    background: rgba(52, 152, 219, 0.85);
  }

  .header-style6 .navbar-toggler {
    background: #fff;
  }
  .header-style6 .navbar-toggler:after {
    border-top: 2px solid #232323;
    border-bottom: 2px solid #232323;
  }
  .header-style6 .navbar-toggler:before {
    background: #232323;
  }
  .header-style6 .navbar-toggler.menu-opened:after {
    background: #232323;
    border-bottom: none;
  }
  .header-style6 .navbar-toggler.menu-opened:before {
    background: #232323;
  }

  .header-style6 .attr-nav > ul > li > a {
    color: #fff;
  }
  .header-style6 .attr-nav > ul > li > a span.badge {
    background: #232323;
    color: #fff;
  }
  .header-style6.scrollHeader .attr-nav > ul > li > a {
    color: #fff;
  }
}

/*========================================================
                      Contact Form
=========================================================*/
/* MF
========================================================*/
.mailform {
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.mailform input[type="file"] { padding: 11px 16px; }
.mailform input, .mailform textarea {
  padding: 14px 16px;
}
.mailform input:-moz-placeholder, .mailform select:-moz-placeholder, .mailform textarea:-moz-placeholder {
  color: #888;
  opacity: 1;
}
.mailform input::-webkit-input-placeholder, .mailform select::-webkit-input-placeholder, .mailform textarea::-webkit-input-placeholder {
  color: #888;
  opacity: 1;
}
.mailform input::-moz-placeholder, .mailform select::-moz-placeholder, .mailform textarea::-moz-placeholder {
  color: #888;
  opacity: 1;
}
.mailform input:-ms-input-placeholder, .mailform select:-ms-input-placeholder, .mailform textarea:-ms-input-placeholder {
  color: #888;
  opacity: 1;
}

/* MF Input
========================================================*/
.mfInput input:-webkit-autofill ~ .mfIcon span:before,
.mfInput textarea:-webkit-autofill ~ .mfIcon span:before {
  color: #2D2D2D;
}

/* MF Select
========================================================*/
.mailform select {
  padding: 0;
  border: none;
}
.select-option {
  position: relative;
}
.select-departments .dropdown,.select-doctors .dropdown {
  display: none;
}
.select-departments .dropdown.current,.select-doctors .dropdown.current {
  display: block;
}
.mfSelect .show .dropdown, .mfSelect .focus .dropdown { 
  display: block;
  opacity: 1;
}
.mfSelect .dropdown { 
  margin-bottom: 0;
  background: #fff;
  width: 100%;
  border: 1px solid #d1d1d1;
  padding: 0;
  margin-top: -1px;
  position: absolute;
  z-index: 9;
  opacity: 0;
}
.mfSelect .dropdown li {
    border-bottom: 1px solid #e8e8e8;
    padding: 5px 18px;
}
.mfSelect .dropdown li:last-child { 
  border: none;
}
.mfSelect.show .dropdown, .mfSelect.focus .dropdown {
  opacity: 1;
}
.mfSelect .value {
  padding: 14px 18px;
  border: 1px solid #d1d1d1;
  color: #888;
}
.mfSelect .value:after {
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #d1d1d1;
  content: "";
  margin-top: 10px;
  float: right;
}

/* MF Controls
========================================================*/
.mfControls {
  word-spacing: 10px;
  text-align: left;
}
.mfControls > * {
  word-spacing: normal;
  margin-bottom: 5px;
  min-width: 200px;
  width: auto;
  font-family: inherit;
  font-size: 17px;
  letter-spacing: -0.5px;
  transition: 0.3s all ease;
}
@media (max-width: 767px) {
  .mfControls > * {
    width: 100%;
  }
}

/* Mail Form PlaceHolder
========================================================*/
.mfPlaceHolder {
  transition: 0.3s all ease;
  font: inherit;
  cursor: text;
  position: absolute;
  left: 10px;
  top: 0;
  padding: 14px 18px;
  line-height: 26px;
  color: #888;
  opacity: 1;
}
*:-webkit-autofill ~ .mfPlaceHolder {
  opacity: 1;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  font-size: 14px;
}
.mfPlaceHolder.state-1 {
  opacity: 1;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  font-size: 14px;
}

/* Mail Form Validation
  ========================================================*/
.mfValidation {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  transition: 0.3s all ease;
  -webkit-animation: notifanim-fo 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  animation: notifanim-fo 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  background: #888;
  position: absolute;
  width: 210px;
  margin-left: -105px;
  padding: 10px;
  height: 40px;
  line-height: 20px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  bottom: -30px;
  right: 0px;
  font-weight: 300;
  font-size: 12px;
  color: #FFF;
  z-index: 999;
}
.mfValidation:hover {
  background: #6f6f6f;
}
.mfValidation:before {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 10%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent #888 transparent;
}
.mfValidation:hover:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent #6f6f6f transparent;
}
.mfValidation.error {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  -webkit-animation: notifanim 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  animation: notifanim 0.4s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}
@media (max-width: 767px) {
  .mfValidation {
    text-align: right;
    top: auto;
    left: auto;
    top: -18px;
    right: 15px;
    background: none;
    padding: 0;
    margin: 0 0 3px;
    min-height: 0;
    color: #DA7B77;
    box-shadow: none;
  }
  .mfValidation:before {
    display: none;
  }
  .mfValidation:hover {
    background: none;
    right: 5px;
  }

  .mailform.fail, .mailform.success {
    padding-bottom: 60px;
  }

}
@-webkit-keyframes notifanim-fo {
  0% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    opacity: 0;
  }
}
@keyframes notifanim-fo {
  0% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    opacity: 0;
  }
}
@-webkit-keyframes notifanim {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  1% {
    visibility: visible;
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes notifanim {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  1% {
    visibility: visible;
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
/* Mail Form Date Picker
========================================================*/
/* Mail Form Icon
========================================================*/
/* Mail Form Submit
========================================================*/
.mfProgress {
  position: relative;
}
.mfProgress .cnt, .mfProgress .loader {
  transition: 0.4s all ease-in-out;
}
.mfProgress .loader {
  opacity: 0;
  text-align: center;
  letter-spacing: 10px;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.mfProgress .loader, .mfProgress .loader:before, .mfProgress .loader:after {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto;
  height: auto;
}
.mfProgress .loader:before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
}
.mfProgress .loader:after {
  content: '';
  width: 30px;
  height: 50px;
  color: #fff;
  font: 400 30px "FontAwesome";
  line-height: 50px;
  transition: 0.4s all ease-in-out;
  -webkit-transform: scale(0) rotate(-60deg);
  transform: scale(0) rotate(-60deg);
}
.mfProgress.sending .cnt, .mfProgress.fail .cnt, .mfProgress.success .cnt {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
}
.mfProgress.sending .loader, .mfProgress.fail .loader, .mfProgress.success .loader {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mfProgress.fail .loader:before, .mfProgress.success .loader:before {
  -webkit-animation: fout 0.4s ease-in-out 1 forwards;
  animation: fout 0.4s ease-in-out 1 forwards;
  opacity: 0;
}
.mfProgress.fail .loader:after, .mfProgress.success .loader:after {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.mfProgress.sending .loader:before {
  -webkit-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.mfProgress.fail .loader:after {
  content: '\F06A';
}
.mfProgress.success .loader:after {
  content: '\F058';
}

.mfProgress .msg {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  transition: 0.3s all ease;
  -webkit-animation: notifanim-fo 0.4s ease-in-out forwards;
  animation: notifanim-fo 0.4s ease-in-out forwards;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 15px;
  font-size: 16px;
  line-height: 20px;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 100%;
  width: 400px;
  margin-left: 20px;
  margin-top: 0;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  background: #DA7B77;
  color: #fff;
  z-index: 9;
}
.mfProgress .msg:before {
  content: '';
  position: absolute;
  margin-top: -6px;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #111 transparent transparent;
  transition: 0.4s all ease-in-out;
}
@media (max-width: 767px) {
  .mfProgress .msg {
    width: 230px;
    top: 100%;
    left: 50%;
    margin-top: 20px;
    margin-left: -115px;
  }
  .mfProgress .msg:before {
    right: 50%;
    top: auto;
    bottom: 100%;
    margin-right: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #111 transparent;
  }
}
.mfProgress.fail .msg, .mfProgress.success .msg {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  visibility: visible;
  -webkit-animation: notifanim 0.4s ease-in-out forwards;
  animation: notifanim 0.4s ease-in-out forwards;
}
.mfProgress.fail .msg {
  background: #F44336;
}
.mfProgress.fail .msg:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #F44336 transparent transparent;
}
@media (max-width: 767px) {
  .mfProgress.fail .msg:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #F44336 transparent;
  }
}
.mfProgress.success .msg {
  background: #2E7D32;
}
.mfProgress.success .msg:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #2E7D32 transparent transparent;
}

@media (max-width: 767px) {
    .mfProgress.success .msg:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #2E7D32 transparent;    
  }
}
@-webkit-keyframes fout {
  0% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
  100% {
    -webkit-transform: scale(0) translateX(0);
            transform: scale(0) translateX(0);
  }
}
@keyframes fout {
  0% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
  100% {
    -webkit-transform: scale(0) translateX(0);
            transform: scale(0) translateX(0);
  }
}
@-webkit-keyframes motion {
  0% {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
  25% {
    -webkit-transform: translateX(-50px) scale(0.3);
            transform: translateX(-50px) scale(0.3);
  }
  50% {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
  75% {
    -webkit-transform: translateX(50px) scale(0.3);
            transform: translateX(50px) scale(0.3);
  }
  100% {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}
@keyframes motion {
  0% {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
  25% {
    -webkit-transform: translateX(-50px) scale(0.3);
            transform: translateX(-50px) scale(0.3);
  }
  50% {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
  75% {
    -webkit-transform: translateX(50px) scale(0.3);
            transform: translateX(50px) scale(0.3);
  }
  100% {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}
/* Mail Form Select
========================================================*/
@-webkit-keyframes dropd-anim-fo {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
}
@keyframes dropd-anim-fo {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
}
@-webkit-keyframes dropd-anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes dropd-anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/*-----------------------------------------------------------------------------------

    Theme Name: Fabrex - Business Multipurpose and Corporate Template
    Description: Business Multipurpose and Corporate Template
    Author: chitrakootweb
    Version: 2.0

-----------------------------------------------------------------------------------*/

/* ----------------------------------
        
    01. Common styles
    02. Section heading
    03. Topbar
    04. Navigation
    05. Page title
    06. Slider and Banner
    07. Feature boxes
    08. Service blocks
    09. Process with Icon
    10. Client section
    11. Counter box
    12. Call to action
    13. Video
    14. About area style
    15. Progress bars
    16. Testmonial
    17. Owl carousel
    18. Team member
    19. Project grid
    20. Accordion style
    21. Service detail
    22. Portfolio masonry
    23. Tabs
    24. Pricing table
    25. Shop
    26. Blog block
    27. Count and error box
    28. Contact info
    29. Footer
    
---------------------------------- */

/* ===================================
    Common styles
====================================== */

/* loader */
#preloader{ position:fixed; left:0; top:0; z-index:99999; width:100%; height:100%; overflow:visible; background:#fff; display:table; text-align:center; }
.loader{ display:table-cell; vertical-align:middle; position:relative; width:200px; height:200px; }
.loader-icon{ width:80px; height:80px; border:5px solid #86bc42; border-right-color:#eee; border-radius:50%; position:relative; -webkit-animation:loader-rotate 1s linear infinite; animation:loader-rotate 1s linear infinite; margin:0 auto; }
@-webkit-keyframes loader-rotate{ 0%{ -webkit-transform:rotate(0); transform:rotate(0) }
 100%{ -webkit-transform:rotate(360deg); transform:rotate(360deg) }
 }
@keyframes loader-rotate{ 0%{ -webkit-transform:rotate(0); transform:rotate(0) }
 100%{ -webkit-transform:rotate(360deg); transform:rotate(360deg) }
 }

/* theme color and basic options */
a:hover, a:active{color: #86bc42; text-decoration: none;}
.bg-theme {background-color:#86bc42;}
.bg-theme-90 { background:rgba(134, 188, 66,0.90);  }
.bg-theme-80 { background:rgba(134, 188, 66,0.80);  }
.theme-overlay[data-overlay-dark]:before { background: #86bc42; }
.text-theme-color {color:#86bc42;}
.text-content-big p{ font-weight:400; line-height:200%; margin-bottom:25px; font-size:15px; }
.shadow-primary{ box-shadow:0 20px 40px rgba(0, 0, 0, .2); }
.red { color: red; }
.line-through { text-decoration: line-through; }

/* spacing between columns*/
.half-gutter { margin: 0 -8px; }
.half-gutter > [class*="col-"] { padding: 7px; }

/* primary listing */
.primary-list li{ font-size:14px; font-weight:500; margin-top:15px; list-style-type:none; color:#222; }
.primary-list{ padding-left:0; }
.primary-list li i{ color:#86bc42; padding-right:10px; }
.white-list{ padding-left:0; }
.white-list li{ color:#fff; font-size:14px; font-weight:500; margin-top:15px; list-style-type:none; }
.white-list li i{ color:#86bc42; padding-right:15px; }

/* scroll to top */
.scroll-to-top{ font-size:20px; text-align:center; color:#fff; text-decoration:none; position:fixed; bottom:20px; right:20px; display:none; border-radius:50%; background:#000; border:1px solid #2a2a2a; width:35px; height:35px; line-height:30px; z-index:9999; outline:0; -webkit-transition:all .3s ease; -moz-transition:all .3s ease; -o-transition:all .3s ease }
.scroll-to-top i{ color:#fff; }
.scroll-to-top:hover{ color:#232323; background:#fff; }
.scroll-to-top:hover i{ color:#232323; }
.scroll-to-top:visited{ color:#232323; text-decoration:none; }

/* Button style */ 
.butn{ background:#232323; color:#fff; text-align:center; border-radius:4px; padding:12px 30px; line-height:normal; font-weight:500; text-transform:none !important; position:relative; z-index:9999; display:inline-block; white-space:nowrap; border:none; cursor:pointer; }
.butn span{ z-index:9; position:relative; }
.butn:hover{ color:#fff; border-radius:3px;  }
.butn:after{ content:''; position:absolute; border-radius:4px; bottom:6px; left:0; height:2px; transition:width .4s; width:0; background:#86bc42; height:100%; top:0; z-index:1; }
.butn:hover:after{ width:100%; border-radius:3px;  }
.butn.white{ background:#fff; color:#232323; }
.butn.white:hover{ color:#fff; border-radius:3px; }
.butn.white:after{ background:#232323; border-radius:3px; }
.butn.theme{ background:#86bc42; color:#fff; }
.butn.theme span { color: #fff; }
.butn.theme:hover{ color:#fff; border-radius:3px;  }
.butn.theme:after{ background:#232323; border-radius:3px; }
.butn.theme.white-hover:hover span { color: #232323; }
.butn.theme.white-hover:hover{ color:#232323; }
.butn.theme.white-hover:after{ background:#fff; border-radius:3px; }

.butn.small{ padding:8px 20px; }
.butn.medium{ padding:10px 20px; }

/* Button Style 2*/
.btn-style2 {  display: inline-block; font-size: 14px;  padding: 12px 20px; border: 2px solid #222; color: #232323; letter-spacing: .5px; line-height: 26px; border-radius: 0; text-transform: uppercase; width: auto; font-family: 'Montserrat', sans-serif; font-weight: 600; transition-duration: 0.3s; transition-timing-function: ease-in-out; }
.btn-style2:hover, .btn-style2:active{ background: #86bc42; color: #fff; border-color: #86bc42; }
.btn-style2:focus{ background: #86bc42; color: #fff; box-shadow: none; border-color: #86bc42; }

.btn-style2.white { border-color: #fff; color: #fff; }
.btn-style2.white:hover { border-color: #86bc42;}
.btn-style2 span { text-shadow: none !important; color: #fff !important; }

/* Button Style 3*/
.butn-style3 { text-align: center; display: inline-block; font-size: 14px; padding: 9px 22px; min-width: 150px; background: transparent; border: 2px solid #86bc42; border-radius: 25px; box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important; color: #86bc42; letter-spacing: .5px;  line-height: inherit; width: auto; font-family: 'Montserrat', sans-serif;    font-weight: 600; transition-duration: 0.3s; transition-timing-function: ease-in-out; }
.butn-style3:hover { background: #86bc42; color: #fff; }

.butn-style3.theme { background: #86bc42; border: 2px solid #86bc42; color: #fff; }
.butn-style3.theme:hover { background: transparent; color: #fff; border-color: #fff; }

.butn-style3.white { background: transparent; border: 2px solid #fff; color: #fff; }
.butn-style3:hover { background: #86bc42; color: #fff; border-color: #86bc42; }

@media (max-width: 991px) {
    .butn { padding:10px 28px; }
    .butn.small{ padding:7px 16px; }
    .butn.medium{ padding:8px 18px; }

    .btn-style2 { padding: 10px 18px; }
    .butn-style3 { min-width: 130px; }
}
@media (max-width: 767px) { 

    .butn { padding:8px 26px; }
    .butn.small{ padding:6px 14px; }
    .butn.medium{ padding:6px 16px; }

    .btn-style2 { padding: 8px 14px; }
    .butn-style3 { min-width: inherit; }

    /* spacing between columns*/
    .xs-default-gutter { margin: 0 -15px; }
    .xs-default-gutter > [class*="col-"] { padding-right: 15px; padding-left: 15px; padding-bottom: 0; padding-top: 0; }
}

/* ===================================
    Section heading
====================================== */

.section-heading{ margin-bottom:50px; text-align:center; }
.section-heading.half{ margin-bottom:30px; }
.section-heading h1,.section-heading h2,.section-heading h3,.section-heading h4,.section-heading h5,.section-heading h6{ position:relative; padding-bottom:10px; font-weight:600; margin-bottom:10px; position:relative; line-height:140%; }
.section-heading h1:after,.section-heading h2:after, .section-heading h3:after, .section-heading h4:after, .section-heading h5:after, .section-heading h6:after{ content:''; display:block; width:100px; height:2px; background:#86bc42; margin:0 auto; position:absolute; left:0; right:0; bottom:0; }
.section-heading.white h1,.section-heading.white h2, .section-heading.white h3, .section-heading.white h4, .section-heading.white h5, .section-heading.white h6{ color:#fff; }
.section-heading.grey h1,.section-heading.grey h2, .section-heading.grey h3, .section-heading.grey h4, .section-heading.grey h5, .section-heading.grey h6{ color:#b7b7b7; }
.section-heading.grey h1:after,.section-heading.grey h2:after, .section-heading.grey h3:after, .section-heading.grey h4:after, .section-heading.grey h5:after, .section-heading.grey h6:after{ background:#b7b7b7; }
.section-heading.left{ text-align:left; }
.section-heading.left h1,.section-heading.left h2, .section-heading.left h3, .section-heading.left h4, .section-heading.left h5, .section-heading.left h6{ padding-bottom:15px; }
.section-heading.left h1:after,.section-heading.left h2:after, .section-heading.left h3:after,.section-heading.left h4:after,.section-heading.left h5:after,.section-heading.left h6:after{ margin:0; left:0; }
.section-heading strong{ font-weight:600; }
.section-heading.white p{ color:#fff; }
.section-heading p{ font-size:15px; font-weight:400; padding-top:15px; margin:0 auto; }
.section-heading span{ position:relative; font-size:15px; font-weight:500; color:#86bc42; line-height:200%; margin-bottom:10px; }
.section-heading.white span{ color:#fff; }
.section-heading.grey span{ color:#b7b7b7; }

.section-heading .badge { display: inline-block; padding: 7px 19px; font-size: 12px; font-weight: 700; line-height: 1; text-align: center; white-space: nowrap;
    vertical-align: baseline; border-radius: 14px; color: #86bc42; border:1px solid #86bc42; background: transparent; text-transform: uppercase; letter-spacing: .075em; }

/* title style2 */
.title-style2{ position: relative; padding-bottom: 20px; margin-bottom: 0; }
.title-style2:after{ content: ''; width: 60px; height: 2px; background: #86bc42; position: absolute; bottom: 0; left: calc(50% - 30px); }
.title-style2:before{ content: ''; height: 1px; background: rgba(0, 0, 0, 0.2); position: absolute; bottom: 0; left: 0; right: 0; margin: 0 auto; width: 250px; }

.section-heading.left .title-style2 { left: 0; margin: 0; }
.section-heading.left .title-style2:before { width: 125px; left: 0; margin: 0; }
.section-heading.left .title-style2:after { width: 50px; left: 37px; }

h1.title-style2.white, h2.title-style2.white, h3.title-style2.white, h4.title-style2.white, h5.title-style2.white, h6.title-style2.white { color: #fff; }
.section-heading .title-style2.white:after{ background: #86bc42; }
.section-heading .title-style2.white:before{ background: rgba(255, 255, 255, 0.8); }

/* title style3 */
.section-heading.title-style3 { text-align: left; margin-bottom: 30px; padding-bottom: 10px; border-bottom: 1px solid #eee; }
.section-heading.title-style3 h1, .section-heading.title-style3 h2, .section-heading.title-style3 h3, .section-heading.title-style3 h4, .section-heading.title-style3 h5, .section-heading.title-style3 h6 { padding: 0; margin: 0; }
.section-heading.title-style3 h1:after,.section-heading.title-style3 h2:after, .section-heading.title-style3 h3:after, .section-heading.title-style3 h4:after, .section-heading.title-style3 h5:after, .section-heading.title-style3 h6:after { content: none;  }

/* title overlay */
.title-overlay{ mix-blend-mode: lighten; overflow: hidden; background-color: rgba(255, 255, 255, 0.85); margin: 0; }

/* title style4 */
.section-heading.title-style4 h1, .section-heading.title-style4 h2, .section-heading.title-style4 h3, .section-heading.title-style4 h4, .section-heading.title-style4 h5, .section-heading.title-style4 h6 { text-transform: uppercase; font-weight: 700; padding: 0; margin: 0;}
.section-heading.title-style4 p { padding-top: 0; }
.section-heading.title-style4 span { color: #86bc42; font-size: 32px; font-weight: 700; text-transform: uppercase; }
.section-heading.title-style4 h1:after, .section-heading.title-style4 h2:after, .section-heading.title-style4 h3:after, .section-heading.title-style4 h4:after, .section-heading.title-style4 h5:after, .section-heading.title-style4 h6:after { content: none; }

/* title style5 */
.section-heading.title-style5 h1, .section-heading.title-style5 h2, .section-heading.title-style5 h3, .section-heading.title-style5 h4, .section-heading.title-style5 h5, .section-heading.title-style5 h6 { padding: 0; margin: 0;  }
.section-heading.title-style5.white h1, .section-heading.title-style5.white h2, .section-heading.title-style5.white h3, .section-heading.title-style5.white h4, .section-heading.title-style5.white h5, .section-heading.title-style5.white h6 { color: #fff;  }
.section-heading.title-style5 h1:after, .section-heading.title-style5 h2:after, .section-heading.title-style5 h3:after, .section-heading.title-style5 h4:after, .section-heading.title-style5 h5:after, .section-heading.title-style5 h6:after { content: none; }
.section-heading.title-style5 .separator-left,
.section-heading.title-style5 .separator-right { height: 1px; position:absolute; top:50%; width:70px; margin-top:-1px; margin-bottom: 0;}
.section-heading.title-style5 .separator-left{ left:-80px; }
.section-heading.title-style5 .separator-right{ right:-80px; }
.section-heading.title-style5 .square { width: 10px; height: 10px; background-color: transparent; border-width: 1px; border-style: solid; border-color: #86bc42; display: inline-block; vertical-align: text-bottom; position: relative; }
.section-heading.title-style5.left .square .separator-left{ display: none; }

@media screen and (max-width:1199px){

	.section-heading.title-style4 span { font-size: 30px; }
}

@media screen and (max-width:991px){

    .section-heading { margin-bottom: 30px; }
    .section-heading p { padding-top: 10px; font-size: 14px; }
    .section-heading.title-style4 span { font-size: 28px; }
}

@media screen and (max-width:767px){
    .section-heading.title-style4 span { font-size: 28px; }
}

/* ===================================
    Topbar
====================================== */

/* topbar */
.top-bar-info{ display:inline-block; vertical-align: middle; }
.top-bar-info ul{ margin-bottom:0; }
.top-bar-info li{ font-weight:500; color:#fff; list-style-type:none; font-size:13px; padding:0 5px 0; display:inline-block; margin-bottom:0; }
#top-bar{ display:block; position:relative; z-index:999; background:#1c1c1c; padding: 7px 0; }
.top-bar-info li i{ font-size:16px; color:#86bc42; margin-right:8px; margin-top: 0; display: inline-block; vertical-align: text-bottom; }

.top-social-icon{ padding:0; float:right; margin:0; }
.top-social-icon li{ font-size:14px; list-style-type:none; float:left; text-align:center; margin:0; padding:0 7px; }
.top-social-icon li:last-child { padding-right: 0; }
.top-social-icon li:last-child a { padding-right: 0; }
.top-social-icon li a{  color:#fff; line-height: 28px; transition-duration:.3s; padding:0 3px;  }
.top-social-icon li a:hover { color: #86bc42; }

#top-bar.top-bar-style2 {  background:#f5f5f5; border-bottom: 1px solid rgba(0,0,0,0.1); }
.top-bar-style2 .top-bar-info li{ color:#6f6f6f; }
.top-bar-style2 .top-bar-info li i{ color:#6f6f6f; }

.top-bar-style2 .top-social-icon li a{  color:#6f6f6f; }
.top-bar-style2 .top-social-icon li a:hover { color: #232323; }

/* ===================================
    Navigation
====================================== */

.navbar-nav li.current > a{ color: #86bc42; }
.navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

@media screen and (min-width: 992px){
    
    /*Header style01*/
    .header-style1 .navbar-nav li.current > a{ color: #86bc42; }
    .header-style1 .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }    

    .header-style1.scrollHeader .navbar-nav li.current > a{ color: #86bc42; }
    .header-style1.scrollHeader .navbar-nav li.current > a:hover{ color: #86bc42; }
    .header-style1.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    /*Header style02*/
    .header-style2 .navbar-nav li.current > a{ color: #86bc42; }
    .header-style2 .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    .header-style2.scrollHeader .navbar-nav li.current > a{ color: #86bc42; }
    .header-style2.scrollHeader .navbar-nav li.current > a:hover{ color: #86bc42; }
    .header-style2.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }    

    /*Header style03*/
    .header-style3 { border-bottom: 2px solid rgba(134, 188, 66,0.9); }

    /*Header style04*/
    .header-style4 .navbar-nav li.current > a{ color: #86bc42; }
    .header-style4 .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    /*Header style05*/
    .header-style5 #top-bar { background: #86bc42; }

    /*Header style06*/
    .header-style6 .navbar-default{ background: rgba(134, 188, 66,0.85);}
    .header-style6.scrollHeader .navbar-default { background: #86bc42;  }

    /*Menu area light*/
    .menu_area-light .navbar-nav li.current > a{ color: #86bc42; }
    .menu_area-light .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }

    .menu_area-light.scrollHeader .navbar-nav li.current > a{ color: #86bc42; }
    .menu_area-light.scrollHeader .navbar-nav li.current > a:hover{ color: #86bc42; }
    .menu_area-light.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #86bc42 #86bc42 transparent; }     
}

@media screen and (max-width: 991px){

    /*Header style03*/
    .header-style3 { border-bottom: 2px solid rgba(134, 188, 66, 0.9); }

    /*Header style05*/
    .header-style5 #top-bar { background: #86bc42; }
    .header-style5 .navbar-toggler { background: #86bc42; }    

    /*Header style06*/
    .header-style6 .navbar-default{ background: rgba(134, 188, 66, 0.85); }
}

/* ===================================
    Page title
====================================== */

/*style1*/
.page-title-section{ background-size:cover; background-position:center; }
.page-title-section h1{ font-size:40px; font-weight:600; letter-spacing:1px; line-height:1; color:#fff; margin-bottom:0; }
.page-title-section ul{ margin-bottom:0; margin-top:15px; text-align:left; }
.page-title-section ul li{ display:inline-block; }
.page-title-section ul li:last-child a{ color: #86bc42; }
.page-title-section ul li:after{ content:'\F105'; font-weight: 700; vertical-align: bottom; color:#fff; font-family: Font Awesome\ 5 Free; padding:0 10px; }
.page-title-section ul li:last-child:after{ content:none; }
.page-title-section ul li a{ color:#fff; font-size:14px; font-weight:500; }
.page-title-section .active a, .page-title-section li.active:last-child a { color: #fff;}

@media screen and (max-width:1199px){
    .page-title-section h1{ font-size: 36px; line-height: 40px;  }
}
@media screen and (max-width:991px){
    .page-title-section h1{ font-size: 30px; line-height: 38px;  }
}

@media screen and (max-width:767px){
    .page-title-section h1{ margin-bottom:10px; font-size: 28px; line-height: 36px;  }
    .page-title-section ul{ margin-top: 5px; }
}

/*style2*/
.page-title-section2 { padding: 250px 0 150px; text-align: center; }
.page-title-section2 h1{ font-size:40px; font-weight:600; letter-spacing:1px; line-height:1; color:#fff; margin-bottom:0; }
.page-title-section2 ul{ margin-bottom:0; margin-top:15px; text-align:center; }
.page-title-section2 ul li{ display:inline-block; }
.page-title-section2 ul li:last-child a{ color: #86bc42; }
.page-title-section2 ul li:after{ content:'\F105'; font-weight: 700; vertical-align: middle; color:#fff; font-family: Font Awesome\ 5 Free; padding:0 8px 0 10px; }
.page-title-section2 ul li:last-child:after{ content:none; }
.page-title-section2 ul li a{ color:#fff; font-size:14px; font-weight:500; }
.page-title-section2 .active a, .page-title-section2 li.active:last-child a { color: #fff;}

@media screen and (max-width:1199px){
    .page-title-section2 { padding: 200px 0 100px; }
    .page-title-section2 h1{ font-size: 36px; line-height: 42px;  }
}
@media screen and (max-width:991px){
    .page-title-section2 { padding: 148px 0 75px; }
    .page-title-section2 h1{ margin-bottom:10px; font-size: 30px; line-height: 40px;  }
    .page-title-section2 ul{ margin-top: 5px; }
}

/*style3*/
.page-title-section3 h1{ color: #232323; margin-bottom: 0; }
.page-title-section3 ul{ margin-bottom:0; margin-top:15px; text-align:center; }
.page-title-section3 ul li{ display:inline-block; }
.page-title-section3 ul li:last-child a{ color: #232323; }
.page-title-section3 ul li:after{ content:' \F152'; color:#fff; font-weight: 700; font-family: Font Awesome\ 5 Free; padding:0 10px; }
.page-title-section3 ul li:last-child:after{ content:none; }
.page-title-section3 ul li a{ color:#fff; font-size:14px; font-weight:500; }

@media screen and (max-width:991px){
    .page-title-section3 ul{ margin-top: 5px; }
}

/*style4*/
.page-title-section.small h1{ font-size:28px; font-weight:600; letter-spacing:1px; line-height:normal; color:#fff; margin-bottom:0; }
.page-title-section.small ul{ margin-bottom:0; margin-top:6px; text-align:left; }
.page-title-section.small ul li{ display:inline-block; }
.page-title-section.small ul li:last-child a{ color: #86bc42; }
.page-title-section.small ul li:after{ content:' \F152'; color:#fff; font-weight: 700; font-family: Font Awesome\ 5 Free; padding:0 10px; }
.page-title-section.small ul li:last-child:after{ content:none; }
.page-title-section.small ul li a{ color:#fff; font-size:14px; font-weight:500; }

@media screen and (max-width:767px){
    .page-title-section.small h1{ margin-bottom:5px; font-size: 24px; }
    .page-title-section.small ul{ margin-top: 5px; }
}


/* ===================================
    Slider and Banner
====================================== */

/* Banner */ 
.owl-carousel .caption .overflow-hidden{ display: inline-block; }
.owl-carousel .caption h3{ font-weight: 200; -webkit-animation-delay: 0.5s; animation-delay: 0.5s; position: relative; display: inline-block; }
.owl-carousel .caption h1{ margin: 10px 0; font-size: 70px; font-weight: 500; text-transform: uppercase; -webkit-animation-delay: 0.8s; animation-delay: 0.8s; }
.owl-carousel .caption p{ font-size: 18px; color: #eee; word-spacing: 2px; -webkit-animation-delay: 1.2s; animation-delay: 1.2s; }
.owl-carousel .caption span{ display: inline-block; padding: .2em 0; }
.owl-carousel .caption .butn{ -webkit-animation-delay: 1.4s; animation-delay: 1.4s; }
.owl-carousel .caption .butn i{ position: relative; top: 1px; z-index: 9; }

/* carousel-style3 */ 
.carousel-style3 .owl-carousel .owl-item img{ width: 100%; }

/* carousel-style4 */ 
.carousel-style4 .owl-carousel .owl-item img{ width: 100%; }

/*home-business-slider*/
.home-business-slider .owl-nav .owl-next { position: absolute; right: 15px; top: 50%; background: none !important; opacity: 0.35; }
.home-business-slider .owl-nav .owl-next:hover { opacity: 1; }
.home-business-slider .owl-nav .owl-prev { position: absolute; left: 15px; top: 50%; background: none !important; opacity: 0.35; }
.home-business-slider .owl-nav .owl-prev:hover { opacity: 1; }

@media only screen and (max-width: 991px){
    .owl-carousel .caption h1{ font-size: 50px; }
    .owl-carousel .caption p{ font-size: 16px; }
}

@media only screen and (max-width: 767px){
    .owl-carousel .caption h1{ font-size: 32px; margin-top: 5px; }
    .owl-carousel .caption h3{ letter-spacing: 1px; }

}
@media only screen and (max-width: 575px){
    .owl-carousel .caption h1{ font-size: 20px; margin-top: 5px; }
}

.slider-fade .owl-item{ height: 100vh; position: relative; }
.slider-fade .item{ position: absolute; top: 0; left: 0; height: 100%; width: 100%; background-size: cover; background-position: center center; }
.slider-fade .item .caption{ width: 100%; z-index: 9; }
.slider-fade .owl-theme .owl-dots{ position: absolute; bottom: 5vh; width: 100%; left: 0; }

/* revolution slider */
.rev_slider_wrapper .tp-caption span{ color:#86bc42; }
.rev_slider_wrapper .butn span{ font-size:14px !important; line-height:24px !important; color:#fff; text-shadow: none; }
.rev_slider_wrapper .butn.white span { color: #232323; }
.rev_slider_wrapper .butn.white:hover span { color: #fff; }

@media (min-width:767px){ 
    .custom-controls .slider-text p{ width:450px !important; font-size:16px !important; line-height:24px !important; }
}

@media (min-width:1024px){
    .rev_slider .max-style{ font-size: 60px !important; line-height: 70px !important; }
    .rev_slider .max-style span{ font-size: 60px !important; line-height: 70px !important; }
}

@media (max-width:1023px){
    .rev_slider .max-style{ font-size: 45px !important; line-height: 50px !important; }
    .rev_slider .max-style span{ font-size: 45px !important; line-height: 50px !important; }
}

@media (max-width:766px){
    .rev_slider .max-style{ font-size: 30px !important; line-height: normal !important; }
    .rev_slider .max-style span{ font-size: 30px !important; line-height: normal !important; }
    .custom-controls .slider-text p{ text-align:center !important; font-size:14px !important; line-height:22px !important; width:480px !important; padding:0 15px; }
    .custom-controls .tp-leftarrow, .custom-controls .tp-rightarrow{ width:40px !important; height:40px !important; }
    .custom-controls .tp-leftarrow{ left:-15px !important; }
    .custom-controls .tp-rightarrow{ left:inherit !important; right:-55px !important; }
    .custom-controls .tparrows:before{ line-height:20px !important; }
 }

@media (max-width:479px){
    .rev_slider .max-style{ font-size: 20px !important; line-height: normal !important; }
    .rev_slider .max-style span{ font-size: 20px !important; line-height: normal !important; }
    .custom-controls .slider-text p{ width:320px !important; padding:0 15px; }
}

/* ===================================
    Feature boxes
====================================== */

/* feature box 01 */
.feature-box-01{ background:#fff; border:5px solid rgba(0, 0, 0, 0.05); padding:25px 20px; text-align:center; transition:all 0.5s ease 0s; border-radius:5px; }
.feature-box-01 p{ margin-bottom:10px; }
.feature-box-01 .icon-tools{ color:#939393; }
.feature-box-01:hover{ background:#ffffff; border:5px solid #86bc42; }
.feature-box-01:hover .icon-tools{ color:#86bc42; }

/* feature box 02 */
.feature-box-02{ transition:all 0.3s; -o-transition:all 0.3s; -webkit-transition:all 0.3s; }
.feature-box-02:hover{ box-shadow:0 5px 10px 0 rgba(0, 0, 0, 0.15); }
.feature-box-02:before{ background:#86bc42; content:""; height:0; left:14px; position:absolute; top:0; transition:all 0.2s ease-in-out 0s; width:2px; }
.feature-box-02:hover:before{ height:100%; }

/* feature box 03 */
.feature-box-03{ border:2px solid #383838; padding:25px 20px; text-align:center; transition:all 0.5s ease 0s;}
.feature-box-03 .icon-tools{ color:#939393; }
.feature-box-03 p{color:#9d9d9d;} 
.feature-box-03:hover{ background:#86bc42; border:2px solid #86bc42; }
.feature-box-03:hover p{color:#fff;}

/* feature box 04 */
.feature-box-04{ position: relative; overflow: hidden; transition: all 0.3s; -o-transition: all 0.3s; -webkit-transition: all 0.3s; }
.feature-box-04:hover{ box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15); }
.feature-box-04 .fetaure-image{ position: relative; overflow: hidden; z-index: 1; }
.feature-box-04 .fetaure-image img{ transition: all 0.3s; }
.feature-box-04 .fetaure-image:hover img{ -webkit-transform: scale(1.2); transform: scale(1.2); }
.feature-box-04 .fetaure-image:after{ content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; box-shadow: 0 -130px 70px -70px #000 inset; transition: all 0.3s; }
.feature-box-04 .package-detail{ width: 100%; bottom: 0; left: 0; padding: 20px 18px; position: absolute; z-index: 9; }
.feature-box-04 .title{ color: #fff; margin-right: 5px; }
.feature-box-04 .price{ width: 60%; float: left; }
.feature-box-04 .people-duration{ float: right; margin-left: 5px; }
.feature-box-04 .package-detail p{ margin: 0 0 0 10px; display: inline-block; color: #fff; text-transform: uppercase; font-weight: 500; }
.feature-box-04 .package-detail p .theme-color{ margin-right: 5px; }
.feature-box-04 .rating li{ float: left; margin-left: 3px; }

@media (max-width:767px){
    .feature-box-04 .package-detail { padding: 20px 10px; }
    .feature-box-04 .price { width: 55%; }
}

/* feature box 05 */
.feature-box-05{ position: relative; overflow: hidden; transition: all 0.3s; -o-transition: all 0.3s; -webkit-transition: all 0.3s; }
.feature-box-05 .fetaure-image{ position: relative; overflow: hidden; }
.feature-box-05 .fetaure-image img{ transition: all 0.3s; }
.feature-box-05 .fetaure-image:hover img{ -webkit-transform: scale(1.2); transform: scale(1.2); }
.feature-box-05 .fetaure-image:after{ content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; box-shadow: 0 -130px 70px -70px rgba(0,0,0,0.65) inset; transition: all 0.3s; }
.feature-box-05 .package-detail{ width: 100%; bottom: 0; left: 0; padding: 20px 18px; position: absolute; z-index: 9; }

/* feature box 06 */
.feature-box-06{ text-align:left; transition:all 0.5s ease 0s;}
.feature-box-06 i{ color:#86bc42; }
.feature-box-06 p{color:#6f6f6f;} 

/* feature flex box */
.feature-flex{ position:relative; margin:30px 0; transition-duration:.3s; }
.feature-flex-square{ position:relative; border-radius:5px; transition-duration:.3s; }
.feature-flex-square-icon{ float:left; width:50px; transition-duration:.3s; }
.feature-flex-square-icon i{ color:#86bc42; border-radius:10px; font-size:32px; transition-duration:.3s; }
.feature-flex-square-content{ float:left; width:75%; padding-left:10px; }
.feature-flex-square-content h4,.feature-flex-square-content h4 a{ color:#232323; font-size:18px; font-weight:500; margin-bottom:0; line-height:normal; }
.feature-flex-square-content p{ font-size:15px; font-weight:400; margin-top:10px; margin-bottom:15px; }
.feature-flex-square-content-button{ display:inline-block; color:#232323; font-size:14px; font-weight:500; }
.feature-flex-square-content-button:after{ content:'\F0DA'; font-weight: 700; font-size:14px; font-family: Font Awesome\ 5 Free; color:#232323; margin-left:7px; transition-duration:.3s; }
.feature-flex-square-content-button:hover,.feature-flex-square-content-button:hover:after{ color:#86bc42; }

@media (max-width:991px){
    .feature-flex-square-icon{ text-align:center; }
    .feature-flex-square-icon i{ font-size:30px; }
    .feature-flex-square-content h4,.feature-flex-square-content h4 a{ font-size:16px; }
    .feature-flex-square-content p { font-size: 14px; }
 }

@media (max-width:767px){
    .feature-flex-square-icon{ text-align:left; width: 40px; }
 }

/* feature case block */ 
.case-block{ background:#fff; border:1px solid #ececec; border-radius:5px; height:100%; transition-duration:.3s; overflow:hidden; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); position:relative; }
.case-block:hover{ box-shadow:0 10px 30px 0 rgba(50, 50, 50, .3); }
.case-block img{ width:100%; }
.case-block-inner{ padding:20px 25px 20px 0; }
.case-block h4{ font-size:18px; margin-bottom:0; line-height:normal; font-weight:500; }
.case-block h4:after{ content:''; display:block; width:80px; height:2px; background:#86bc42; margin-top:10px; margin-bottom:15px; transition-duration:.4s; }
.case-block p{ color:#999; font-size:15px; font-weight:400; margin-bottom:12px; }
.case-block-inner a{ font-size:15px; font-weight:500; color:#86bc42; }
.case-block-inner a:after{ content:'\F0DA'; font-size:15px; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#86bc42; margin-left:10px; transition-duration:.3s; }
.case-block-inner a:hover,.case-block-inner a:hover:after{ color:#232323; }

@media (max-width:767px){
    .case-block-inner{ padding:25px; }
 }

/* feature partner box */  
.partner-box{ border:1px solid #ececec; }
.partner-box img{ width:100%; }
.partner-text{ padding:25px 40px 25px 0; }
.partner-text span{ color:#86bc42; font-size:13px; font-weight:500; letter-spacing:3px; text-transform:uppercase; }
.partner-box:hover .partner-text span{ color:#999; }
.partner-text h4{ color:#232323; font-size:21px; font-weight:600; margin:5px 0 20px 0; line-height:normal; }
.partner-text h4:after{ content:''; display:block; width:40px; height:2px; background:#86bc42; margin-top:10px; transition-duration:.4s }
.partner-box:hover .partner-text h4:after{ width:80px; }

@media (max-width:767px){
    .partner-text{ padding:25px; }
 }

/* feature feedback box */   
.feedback-box{ position:relative; background:#f9f9f9; border:1px solid #ececec; padding:25px;  }
.feedback-box h5{ font-size:20px; font-weight:500; margin-bottom:15px; }

/* ===================================
    Service blocks
====================================== */

/* services images */
/* .content-bg1 { background-image: url("../img/content/feature-01.jpg"); } */

@media screen and (max-width:991px){
    .sm-bg { min-height: 400px;}
 }

@media screen and (max-width:767px){
    .sm-bg { min-height: 320px;}
 }

/* services blocks */
.services-blocks { margin-top: -90px; }

@media screen and (max-width:1199px){
    .services-blocks { margin-top: -70px; }
}
@media screen and (max-width:991px){
    .services-blocks { margin-top: -50px; }
}

/* service simple */
.service-simple{ position:relative; background:#fff; border:1px solid #ececec; border-radius:5px; overflow:hidden; height:100%; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.service-simple img{ width:100%; transition:all 0.5s ease 0s; }
.service-simple img:hover{ -webkit-transform:scale(1.1); transform:scale(1.1); }
.service-simple-inner{ position:relative; padding:25px 25px 20px; }
.service-simple-inner p{ max-width:95%; }
.service-simple-inner a{ display:inline-block; }
.service-simple h4{ font-size:18px; line-height:normal; margin-bottom:0; }

/* services block one */
.owl-carousel .service-box{ margin:0 15px 25px 15px; }
.service-box{ background:#fff; overflow:hidden; border:1px solid #eee; border-radius:5px; box-shadow:0 10px 20px 0 rgba(50, 50, 50, .12); margin:15px 0; transition-duration:.3s; }
.service-inner-box{ padding:20px; }
.service-icon-box{ width:20%; max-width:50px; float:left; padding-top:2px; }
.service-content-box{ width:80%; float:left; }
.service-box .img-holder{ position:relative; }
.service-box .img-holder:before{ background-color:rgba(0, 26, 87, 0.6); bottom:0; content:""; height:100%; left:0; opacity:0; position:absolute; top:auto; transition:all 0.3s ease 0s; width:100%; z-index:10; }
.service-box:hover .img-holder:before{ opacity:1; }
.service-box .img-holder img{ width:100%; }
.service-box h3{ font-size:16px; margin-bottom:0; line-height:normal; font-weight:500; }
.service-box p{ color:#999; font-size:15px; font-weight:400; margin-top:5px; margin-bottom:0; }
.service-box i{ color:#86bc42; font-size:28px; transition-duration:0.3s; }
.service-box:hover i{ color:#232323; }

/* services block two */
.services-block-two{ position:relative; }
.services-block-two .inner-box{ background:#ffffff; border:1px solid #ededed; border-radius:5px; padding:30px 30px 30px 95px; height:100%; position:relative; transition-timing-function:ease-in-out; -ms-transition-timing-function:ease-in-out; -moz-transition-timing-function:ease-in-out; -webkit-transition-timing-function:ease-in-out; -o-transition-timing-function:ease-in-out; transition-duration:.5s; -ms-transition-duration:.5s; -moz-transition-duration:.5s; -webkit-transition-duration:.5s; -o-transition-duration:.5s; }
.services-block-two p{ margin-bottom:0; }
.services-block-two h3{ margin-bottom:0; line-height:normal; display: inline-block; }
.services-block-two h3 a{ color:#3c3c3c; display:block; font-size:18px; font-weight:500; margin-bottom:10px; }
.services-block-two h3 a:hover { color:#86bc42; }
.services-block-two h2{ bottom:0; color:#f0f0f0; font-size:36px; font-weight:600; right:10px; margin-bottom:0; position:absolute; }
.services-block-two .icon-box{ color:#86bc42; font-size:36px; left:35px; position:absolute; top:35px; }
.services-block-two.dark .inner-box{ background:#000; border:1px solid #000; }
.services-block-two.dark .inner-box:hover{ background:#000; }
.services-block-two.dark .inner-box:hover h5 a, .services-block-two.dark .inner-box:hover p, .services-block-two.dark .inner-box:hover .icon-box{ color:#fff; }
.services-block-two.dark h3 a{ color:#6f6f6f; }
.services-block-two.dark h2{ color:rgba(255, 255, 255, 0.1); }
.services-block-two.dark .inner-box:hover h2{ color:rgba(255, 255, 255, 0.04); }
.services-block-two.dark .icon-box{ color:#6f6f6f; }

@media screen and (max-width:991px){
    .services-block-two h2{ font-size:36px; }
    .services-block-two h3 a{ font-size:18px; margin-bottom:10px; }
    .services-block-two .icon-box{ top:25px; left:21px; font-size:28px; }
    .services-block-two .inner-box{ padding:20px 20px 20px 75px; }
 }

/* services block three */
 .services-block-three > a, .services-block-three > span{ display: block;  border: 3px solid #d5d5d5; border-radius: 0; text-align: center; background: #fff; padding: 20px; position: relative; }
 .services-block-three > a:before, .services-block-three > span:before{ display: block; content: ""; width: 9%; height: 17%; position: absolute; bottom: -3px; right: -3px; border-bottom: 3px solid #86bc42; border-right: 3px solid #86bc42; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
 .services-block-three > a:after, .services-block-three > span:after{ display: block; content: ""; width: 9%; height: 17%; position: absolute; top: -3px; left: -3px; border-top: 3px solid #86bc42; border-left: 3px solid #86bc42; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
 .services-block-three > a:hover, .services-block-three > span:hover{ opacity: 1; border-color: #d5d5d5; }
 .services-block-three > a:hover:before, .services-block-three > span:hover:before{ width: 95%; height: 90%; }
 .services-block-three > a:hover:after, .services-block-three > span:hover:after{ width: 95%; height: 90%; }
 .services-block-three i{ font-size: 32px; }
 .services-block-three p { margin-bottom: 0; }
 .services-block-three:hover p{ color: #232323; }
 .services-block-three h4{ color: #6f6f6f; font-size: 14px; margin-bottom: 10px; font-weight: 600; }
 .services-block-three:hover h4{ color: #232323; }

/* service-block */
.service-grids .service-block{ overflow:hidden; position:relative; }
.service-grids .img-holder{ position:relative; }
.service-grids .service-block:hover .img-holder:before{ opacity:1; }
.service-grids .service-block .details{ background-color:#fff; box-shadow:0 3px 13px rgba(0, 0, 0, 0.07); padding:45px 45px 50px; position:relative; text-align:center; transition:all 0.5s ease 0s; z-index:20; }
.service-grids .service-block .number{ background-color:#86bc42; border-radius:75px; color:#fff; height:75px; font-size:30px; left:50%; line-height:75px; position:absolute; top:-37.5px; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:75px; }
.service-grids .service-block .details h4{ color:#86bc42; font-size:20px; margin:10px 0 15px 0; line-height:normal; }
.service-grids .service-block .details .read-more{ color:#86bc42; font-weight:600; }

@media screen and (min-width:992px){
    .service-grids .service-block{ height:335px; }
    .service-grids .img-holder:before{ background-color:rgba(0, 26, 87, 0.6); bottom:20px; content:""; height:100%; left:0; opacity:0; position:absolute; top:auto; transition:all 0.3s ease 0s; width:100%; z-index:10; }
    .service-grids .service-block .details{ height:195px; position:absolute; top:168px; }
    .service-grids .service-block:hover .details{ height:230px; top:105px; }    
}

@media screen and (min-width:1200px){
    .service-grids .service-block{ height:370px; }
    .service-grids .service-block .details{ height:195px; position:absolute; top:205px; }
    .service-grids .service-block:hover .details{ height:230px; top:170px; }
}

@media (max-width:991px){
    .service-grids .service-block .details{ padding:45px 25px 35px; }
}

@media (max-width:767px){
    .service-grids .service-block .number{ height:65px; line-height:65px; top:-32.5px; width:65px; border-radius:65px; }
 }

/* ===================================
    Process with Icon
====================================== */

.process-step-xs{ position: relative; margin: 15px 0; padding: 0; }
.process-step-xs .process-step-icon { text-align: center; }
.process-step-xs .process-step-icon i{ color: #86bc42; font-size: 47px }
.process-step-xs h3{ position: relative; font-size: 14px; font-weight: 600; color: #6f6f6f; margin: 10px 0; text-align: center; }

.process-steps-xs li{ position: relative; float: left; list-style-type: none; display: inline-block; }

.process-steps-xs li:after{ content: ""; display: block; width: 50%; height: 1px; border-bottom: 2px dotted rgba(0, 0, 0, .1); border-radius: 2px; position: absolute; right: -20%; top: 40px; text-align: center }
.process-steps-xs li:last-child:after{ display: none; }

@media (max-width: 767px){
  .process-steps-xs li:nth-child(2):after{ display: none;  }
}

/* ===================================
    Client section
====================================== */

.section-clients{ background:#fff; padding:50px 0; }
.clients img{ opacity:.6; transition-duration:.3s; }
.clients img:hover{ opacity:1; }

@media (max-width:992px){
    .section-clients .item{ text-align:center; width:100%; }
    .section-clients{ padding:30px 0; }
    .section-clients .owl-carousel .owl-item img{ max-width:75%; display:inline-block; }
}

/* ===================================
    Counter box
====================================== */

.counter-box h3,.counter-box h4{ display:inline-block; font-weight:600; }
.social-icons a:hover{ opacity:1; }
.counter-box h3:after,.counter-box h4:after{ content:'+'; }
.counter-box{ position:relative; text-align:center; }
.counter-box h4{ font-size:35px; line-height:normal; margin:0; }
.counter-box h3{ font-size:45px; }

@media (max-width:767px){
    .counter-box p:before{ margin:0; position:absolute; top:45px; }
    .counter-box p:after{ margin:0; left:14px; right:0; top:45px; }
    .counter-box.black p:after{ margin:0; left:14px; right:0; top:45px; }
    .counter-box h4{ font-size:26px; }
}

/* ===================================
    Call to action
====================================== */

.callto-action-imgbox{ display:block; margin-right:50px; padding-left:40px; padding-top:40px; position:relative; z-index:1; }
.callto-action-imgbox:before{ border:2px solid #86bc42; bottom:40px; content:""; left:0; position:absolute; right:40px; top:0; z-index:-1; }
.callto-action-imgbox img{ width:100%; }

@media (max-width:991px){
    .callto-action-imgbox{ margin-right:0; margin-bottom:30px; padding:20px 20px 0 20px; }
 }

/* ===================================
    Video
====================================== */

.story-video{ min-height:400px; height:100%; }
.video_btn{ background-color:#fff; border:2px solid #86bc42; border-radius:50%; color:#ffffff; display:inline-block; font-size:16px; height:50px; line-height:50px; text-align:center; width:50px; transition-duration:.2s; -ms-transition-duration:.2s; -moz-transition-duration:.2s; -webkit-transition-duration:.2s; -o-transition-duration:.2s; }
.video_btn .fa{ color:#86bc42; }
.video:hover .video_btn{ background:#86bc42; }
.video:hover .video_btn .fa{ color:#fff; }

/* ===================================
    About area style
====================================== */

.about-area{ padding:0; }
.about-text{ display:block; padding-bottom:90px; padding-top:90px; }
.about-text .sec-title{ padding-bottom:34px; }
.about-text .inner-content h2{ font-size:24px; font-weight:400; line-height:36px; margin:0 0 14px; color:#fff; }
.about-text .inner-content h2 span{ font-weight:700; }
.about-text .inner-content p{ color:#fff; }
.about-text .inner-content .bottom{ display:block; overflow:hidden; margin-top:25px; }
.about-text .inner-content .bottom p{ margin:0; font-weight:500; }
.about-text .inner-content .bottom .signature{ display:block; margin-top:22px; }
.about-right-box{ position:relative; display:block; overflow:hidden; padding:90px 40px; z-index:1; height:100%; }
.about-right-box:before{ position:absolute; top:0; left:0; width:100%; height:100%; background:#86bc42; opacity:.92; content:""; z-index:-1; }
.about-right-box ul{ margin-bottom:0; }
.about-right-box ul li{ display:block; overflow:hidden; border-bottom:1px solid rgba(255, 255, 255, 0.10); padding-bottom:29px; margin-bottom:30px; }
.about-right-box ul li:last-child{ border-bottom:none; padding-bottom:0; margin-bottom:0; }
.about-right-box ul li .left-content{ display:block; width:70%; }
.about-right-box ul li .left-content .icon{ width:55px; display:table-cell; vertical-align:top; color:rgba(255, 255, 255, 0.5); }
.about-right-box ul li .left-content .icon span:before{ font-size:55px; line-height:55px; }
.about-right-box ul li .left-content .title{ padding-left:30px; display:table-cell; vertical-align:middle; color:rgba(255, 255, 255, 0.5); }
.about-right-box ul li .left-content .title h3{ color:#ffffff; font-size:16px; font-weight:600; text-transform:uppercase; line-height:normal; letter-spacing:1px; margin:0 0 1px; }
.about-right-box ul li .left-content .title span{ color:rgba(255, 255, 255, 0.75); }
.about-right-box ul li .right-content .title{ display:block; margin-top:3px; }
.about-right-box ul li .right-content .title h4{ color:#ffffff; font-size:3.4em; font-weight:600; line-height:normal; margin-bottom:0; }

@media (max-width:1199px){
    .about-text{ padding-top:70px; padding-bottom:70px; }
    .about-right-box{ padding-top:70px; padding-bottom:70px; }
}

@media (max-width:991px){
    .about-area { padding-bottom: 50px; }
    .about-text{ padding-top:50px; padding-bottom:50px; }
    .about-right-box{ padding-top:50px; padding-bottom:50px; }
}
@media (max-width:767px){
    .about-right-box{ padding:40px 20px; }
    .about-right-box ul li{ padding-bottom:20px; margin-bottom:20px; }
    .about-right-box ul li .left-content .title{ padding-left:10px; }
    .about-right-box ul li .left-content .icon span:before{ font-size:36px; line-height:normal; }
    .about-right-box ul li .right-content .title h4{ font-size:26px; }
}

/* ===================================
    Progress bars
====================================== */

.progress-text{ font-size:13px; font-weight:500; color:#232323; margin-bottom:5px; }
.custom-progress{ height:4px; border-radius:50px; box-shadow:none; margin-bottom:15px; }
.custom-bar{ height:100%; background-color:#86bc42; box-shadow:none; }
.custom-bar-dark{ height:100%; background-color:#232323; box-shadow:none; }
.progress-medium{ height:8px; border-radius:50px; box-shadow:none; }
.progress-bold{ height:12px; border-radius:50px; box-shadow:none; }

/* ===================================
    Testmonial
====================================== */

.testmonial-single{ text-align:center; }
#testmonials-carousel .testmonial-single img{ width:auto; display:inline-block; margin-bottom:10px; }
.testmonial-single p:before{ margin:auto auto 15px; content:"\F10D"; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#86bc42; font-size:23px; display:block; }
.testmonial-single p{ text-align:center; font-style:italic; font-weight:600; }
.testmonial-single h4{ font-size:22px; font-weight:500; text-align:center; margin:0 0 5px 0; line-height:normal; }
.testmonial-single h6{ color:#aaa; font-size:12px; text-transform: uppercase; font-weight:500; text-align:center; line-height:normal; }

.testmonial-box{ position:relative; background:#fff; border-radius:5px; padding:25px; border:1px solid #ececec; margin:15px 0; transition-duration:.3s; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.testmonial-box img{ border:2px solid #ececec; border-radius:50%; padding:2px; }
.owl-carousel .testmonial-box img{ width:auto; max-height:120px; display:inline-block; }
.testmonial-box h5{ font-size:18px; font-weight:500; margin:5px 0 0; line-height:normal; }
.testmonial-box span{ font-size:13px; }
.testmonial-box p{ font-size:15px; color:#999; line-height:1.7; margin-top:10px; margin-bottom:10px; }

.testmonial-style2{ position:relative; background:#fff; border-radius:5px; padding:25px; border:1px solid #ececec; margin:0 0 30px 0; transition-duration:.3s; box-shadow:0 0 8px rgba(0, 0, 0, 0.2); -webkit-box-shadow:0 0 8px rgba(0, 0, 0, 0.2); -moz-box-shadow:0 0 8px rgba(0, 0, 0, 0.2); }
.testmonial-style2 img{ border:2px solid #ececec; border-radius:50%; padding:2px; }
.owl-carousel .testmonial-style2 img{ width:auto; max-height:120px; display:inline-block; }
.testmonial-style2 h5{ font-size:18px; font-weight:500; margin:5px 0 0; line-height:normal; }
.testmonial-style2 span{ font-size:13px; }
.testmonial-style2 p{ font-size:15px; color:#999; line-height:1.7; margin-top:10px; margin-bottom:10px; }

.testimonial-style3 .testimonial-item{ display: flex; flex-wrap: nowrap; align-items: center; }
.testimonial-style3 .testimonial-review p { margin-bottom: 0; }
.testimonial-style3 .testimonial-left-col{ order: -1; flex: 1 0 177px; margin-right: 100px; text-align: center; }
.testimonial-style3 .testimonial-pic{ position: relative; margin: 0 0 25px; }
.testimonial-style3 .testimonial-pic:before{ content:'\F10D'; font-weight: 700; font-family: Font Awesome\ 5 Free; display: block; position: absolute; top: 50%; right: -62px; margin-top: -8px; font-size: 21px; color: #86bc42;  }
.testimonial-style3 .testimonial-separator:before, .testimonial-style3 .testimonial-separator:after{ content: ''; display: block; width: 1px; height: 60px; position: absolute; right: -53px; background-color: #6f6f6f; }
.testimonial-style3 .testimonial-separator:after{ bottom: 0; }
.testimonial-style3 .client-info h6{ margin-bottom: 0; font-size: 15px; color: #fff; }
.testimonial-style3 .client-info span{ color: #d6d5d5; }
.testimonial-style3 .testimonial-review{ padding-bottom: 70px; font-size: 18px; line-height: 36px; font-weight: 300; }

.testimonial-style3 .custom-dot{ display:inline-block; z-index:1; margin-top:30px; }
.testimonial-style3 .custom-dot span{ width:12px; height:12px; margin:0 7px; background:none; cursor:pointer; display:block; border:1px solid #fff; -webkit-backface-visibility:visible; border-radius:0; transition-duration:.3s; -webkit-transition-duration:.3s; }
.testimonial-style3 .custom-dot span:hover{ background:#fff; -webkit-backface-visibility:visible; border-radius:0; }

 @media (max-width: 991px){ 
    .testimonial-style3 .testimonial-review{ padding-bottom: 65px; }
 }

 @media (max-width: 767px){ 
    .testimonial-style3 .client-info h6 { font-style: 14px; }
    .testimonial-style3 .custom-dot { margin-top: 20px; }
 }

 @media (max-width: 575px){ 
     .testimonial-style3 .testimonial-review { padding-bottom: 0; }
     .testimonial-style3 .testimonial-left-col{ margin-bottom: 20px; text-align: left; }
     .testimonial-style3 .testimonial-item{ flex-wrap: wrap; }
     .testimonial-style3 .testimonial-separator{ display: none; }
     .testimonial-style3 .testimonial-pic:before{ display: none; }
 }

/* ===================================
    Owl carousel
====================================== */

.custom-dot{ display:inline-block; z-index:1; margin-top:30px; }
.custom-dot span{ width:12px; height:12px; margin:5px; background:none; cursor:pointer; display:block; border:1px solid #fff; -webkit-backface-visibility:visible; border-radius:30px; transition-duration:.3s; -webkit-transition-duration:.3s; }
.custom-dot span:hover{ background:#aaa; -webkit-backface-visibility:visible; border-radius:30px; }
.custom-dot.active span{ width:12px; height:12px; background:#fff; transition-duration:.3s; }
.owl-nav i{ color:#232323; font-size:18px; padding-top:6px; }
.owl-nav .owl-next,.owl-nav .owl-prev{ color:#86bc42; font-size:43px; text-align:center; width:40px; height:40px; background:#fff !important; }
.owl-nav .owl-prev{ left:0; transition:all .4s ease 0s; box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); }
.owl-nav .owl-next{ transition:all .4s ease 0s; box-shadow:0 5px 28px 0 rgba(50, 50, 50, .1); }

/* cretive slider */
.about-carousel { position: relative; }
.about-carousel:after{ content: ""; position: absolute; top: 70px; left: -70px; width: 100%; height: 100%; background-color: transparent; border: 30px solid rgba(0,0,0,0.1); }

@media only screen and (max-width: 991px){ 
    .about-carousel{ margin-bottom: 50px; }
    .about-carousel:after{ top: 50px; left: -50px; border: 20px solid rgba(0,0,0,0.1); }    
}
@media only screen and (max-width: 767px){ 
    .about-carousel { margin: 0; }
    .about-carousel:after{ display: none; }
}

/* ===================================
    Team member
====================================== */ 

.team-member{ border:1px solid #ececec; border-radius:5px; transition-duration:.3s; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); background:#fff; overflow:hidden; }
.team-member:hover{ box-shadow:0 10px 30px 0 rgba(50, 50, 50, .28); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.team-member-img{ width:100%; height:auto; }
.team-member-img img{ width:100%; }
.team-member-text{ text-align:center; padding:20px; }
.team-member-text h4{ font-size:18px; font-weight:500; line-height:normal; margin-bottom:3px; transition-duration:.6s; }
.team-member:hover .team-member-text h4{ color:#86bc42; }
.team-member-text h4 a{ color:#232323; }
.team-member-text span{ color:#86bc42; font-size:15px; font-weight:500; letter-spacing:2px; }
.team-member-text p{ color:#999; font-size:14px; font-weight:400; line-height:170%; margin:20px auto 0; max-width:90%; }
.team-member-text ul{ margin-top:15px; padding:8px 0; }
.team-member-text ul li a i{ font-size:17px; line-height:34px; }

 @media screen and (max-width:994px){
    .team-member-text{ padding:20px 10px; }
 }

/* team single img */
.team-single-img{ width:100%; }
.team-single-img img{ border-radius:5px; }
.team-single-text{ padding:5px 50px 15px 15px; }
.team-single-text span{ color:#86bc42; font-size:14px; font-weight:500; }
.team-single-text h4{ color:#232323; font-size:25px; font-weight:500; margin-bottom:10px; }
.team-single-info{ padding:0; }
.team-single-info li strong{ color:#555; font-size:16px; font-weight:500; margin-right:10px; }
.team-single-text li span{ color:#777; font-size:15px; font-weight:400; }

/* team single icon */
.team-single-icons ul li{ display:inline-block; border:1px solid #86bc42; border-radius:50%; color:#86bc42; margin-right:8px; margin-bottom:5px; transition-duration:.3s; }
.team-single-icons ul li a{ color:#86bc42; display:block; font-size:14px; height:25px; line-height:26px; text-align:center; width:25px; }
.team-single-icons ul li:hover{ background:#86bc42; border-color:#86bc42; }
.team-single-icons ul li:hover a{ color:#fff; }

/* team style1 */
.team-style1 .team-member-img{ overflow:hidden; border-radius:5px; }
.team-style1 .team-member-img img{ transition:all 0.5s ease 0s; width:100%; }
.team-style1 .team-member-img:hover img{ -webkit-transform:scale(1.1); transform:scale(1.1); }
.team-style2 .team-member-img img{ transition:all 0.3s ease 0s; }
.team-style2 .team-member-img:hover img{ -webkit-filter:grayscale(100%); filter:grayscale(100%); }

/* team style2 */
.team-style2 .team-description{ position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: transparent; opacity: 0; transition: all 200ms ease-in-out; padding: 15px; z-index: 9; }
.team-style2 .social-links{ -webkit-transform: translateY(15px); transform: translateY(15px); transition: opacity 150ms ease-in, -webkit-transform 300ms ease-in-out; transition: transform 300ms ease-in-out, opacity 150ms ease-in; transition: transform 300ms ease-in-out, opacity 150ms ease-in, -webkit-transform 300ms ease-in-out; opacity: 0; color: #fff; -webkit-font-smoothing: antialiased; }
.team-style2 .social-links a:hover{ opacity: 0.65; }
.team-style2 .team-description-wrapper{ position: relative; display: table; width: 100%; height: 100%; }
.team-style2 .team-description-content{ display: table-cell; vertical-align: middle; }
.team-style2 .team-member-img{ position: relative; display: block; overflow: hidden; }
.team-style2 .bg-cover{ background-color: rgba(255, 66, 41, 0); opacity: 1; position: absolute; height: 100%; width: 100%; top: 0; -webkit-transform: scale(2); transform: scale(2); transition: all 200ms ease-in-out; display: none; z-index: 1; }
.team-style2 .team-member-img > img{ transition: all 0.2s ease 0s; }
.team-style2:hover .bg-cover{ background-color: rgba(0, 0, 0, 0.75); display: block; }
.team-style2:hover .social-links{ transition-delay: 100ms, 100ms; opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
.team-style2:hover .team-member-img > img{ -webkit-filter: blur(4px) grayscale(4%); filter: blur(4px) grayscale(4%); }
.team-style2:hover .team-description{ opacity: 1; }

/* team style2 */
.team-style3 .team-description{ position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: transparent; opacity: 0; transition: all 200ms ease-in-out; padding: 15px; z-index: 9; }
.team-style3 .social-links, .team-style3 .about-me{ -webkit-transform: translateY(15px); transform: translateY(15px); transition: opacity 150ms ease-in,-webkit-transform 300ms ease-in-out; transition: transform 300ms ease-in-out,opacity 150ms ease-in; transition: transform 300ms ease-in-out,opacity 150ms ease-in,-webkit-transform 300ms ease-in-out; opacity: 0; color: #fff; -webkit-font-smoothing: antialiased; }
.team-style3 .about-me{ position: relative; display: inline-block; color: #f7f7f7; }
.team-style3 .social-links:after{ background: #86bc42; bottom: -12px; content: ""; height: 1px; position: absolute; left: 0; right: 0; margin: 0 auto; width: 75px; }
.team-style3 .social-links a:hover{ opacity: 0.65; }
.team-style3 .team-description-wrapper{ position: relative; display: table; width: 100%; height: 100%; }
.team-style3 .team-description-content{ display: table-cell; vertical-align: middle; }
.team-style3 .team-member-img{ position: relative; display: block; overflow: hidden; }
.team-style3 .bg-cover{ background-color: rgba(255, 66, 41, 0); opacity: 1; position: absolute; height: 100%; width: 100%; top: 0; -webkit-transform: scale(2); transform: scale(2); transition: all 200ms ease-in-out; display: none; z-index: 1; }
.team-style3 .team-member-img > img{ transition: all 0.2s ease 0s; }
.team-style3:hover .bg-cover{ background-color: rgba(0, 0, 0, 0.83); display: block; }
.team-style3:hover .social-links{ transition-delay: 100ms,100ms; opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
.team-style3:hover .about-me{ transition-delay: 200ms,200ms; opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
.team-style3:hover .team-member-img > img{ -webkit-filter: blur(4px) grayscale(4%); filter: blur(4px) grayscale(4%); }
.team-style3:hover .team-description{ opacity: 1; }


/* ===================================
    Project grid
====================================== */

/* filtering */
.filtering{ margin-bottom:10px; }
.filtering span{ border-bottom:1px solid rgba(0, 0, 0, 0); color:#333333; cursor:pointer; font-size:14px; font-weight:600; letter-spacing:1px; margin-right:20px; display:inline-block; margin-bottom:5px; }
.filtering span:last-child{ margin:0; }
.filtering .active{ border-color:#86bc42; color:#86bc42; }

@media screen and (max-width:767px){
    .filtering span { margin-right: 15px;}
}

.gallery-style2 .project-grid { border: 1px solid rgba(0,0,0,0.1); }

/* project grid */
.project-grid{ display:block; position:relative; margin:15px 0; }
.project-grid-img img{ display:block; width:100%; height:auto; }
.project-grid-overlay{ display: flex; align-items: center;   position:absolute; background:rgba(255, 255, 255, 0.95); overflow:hidden; top: 5px; left: 5px; right: 5px; bottom: 5px;  opacity:0; transition-duration:.3s; }
.project-grid-overlay h4 { margin-bottom: 0;  }
.project-grid-overlay h4 a,.project-grid-overlay p{ text-align:center; }
.project-grid:hover .project-grid-overlay{ opacity:1; }
.project-grid-overlay h4 a{ font-size:22px; opacity:0;}
.project-grid-overlay h4 a:hover{ color:#86bc42; }
.project-grid-overlay h4 a:after{ content:''; display:block; width:70px; height:1px; background:#86bc42; margin:15px auto; }
.project-grid:hover h4 a{ opacity:1; line-height:normal; }
.project-grid-overlay p{ color:#666; font-size:15px; margin:0; opacity:0;  }
.project-grid:hover p{  opacity:1; }

.project-grid-overlay.theme{ background:rgba(134, 188, 66, 0.95); left: 0; right: 0; top: 0; bottom: 0; border:5px solid rgba(0, 0, 0, 0.15); }
.project-grid-overlay.theme h4 a { color:#fff; }
.project-grid-overlay.theme h4 a:hover{ color:rgba(255,255,255,0.55); }
.project-grid-overlay.theme h4 a:after{ height:1px; background:#fff;}
.project-grid-overlay.theme p{ color:#fff; }

@media screen and (max-width:1199px){
    .project-grid-overlay h4 { line-height: 20px; }
    .project-grid-overlay h4 a{ font-size:18px;}
}
@media screen and (max-width:767px){
    .project-grid{ margin:20px 0 0 0; }
    .project-grid:hover p{ top:52%; }
 }


/* project grid style2 */
.project-grid-style2 .project-details{ position: relative; overflow: hidden; }
.project-grid-style2 .project-details img{ width: 100%; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); transition: all 400ms; }
.project-grid-style2 .portfolio-icon{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; text-align: center; background: rgba(0, 0, 0, 0.5); opacity: 0; transition: opacity 0.3s; }
.project-grid-style2 .portfolio-icon a{ position: absolute; top: 50%; -webkit-transform: scale3d(0, 0, 0); transform: scale3d(0, 0, 0); transition: all 0.3s; color: #fff; font-size: 26px; z-index: 9; }
.project-grid-style2 .portfolio-title{ padding: 15px 15px 0 15px; text-align: center; display: block; transition: all 0.3s;  }
.project-grid-style2 .portfolio-title h4{ font-size: 16px; margin-bottom: 0; }
.project-grid-style2 .portfolio-title p { margin-bottom: 0; text-transform: uppercase; font-size: 12px; }
.project-grid-style2:hover .project-details img{ -webkit-transform: scale3d(1.15, 1.15, 1); transform: scale3d(1.15, 1.15, 1); }
.project-grid-style2:hover .portfolio-icon{ opacity: 1; }
.project-grid-style2:hover .portfolio-icon a{ -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
.project-grid-style2:hover .portfolio-icon a:hover{ color: #86bc42; }
.portfolio-post-border{ border: 1px solid #86bc42; bottom: 20px; left: 20px; position: absolute; right: 20px; top: 20px; visibility: hidden; }
.project-grid-style2:hover .portfolio-post-border{ visibility: visible; }


/* project single */
.project-carousel h4:after,.project-single-text h4:after{ content:" "; background:#86bc42; height:2px; }
.project-single-text p{ font-size:15px; font-weight:400; line-height:1.8; }
.project-single-text h5{ color:#232323; font-size:18px; font-weight:500; margin:25px 0 20px; }
.project-single-text h5:after{ display:block; width:70px; margin-top:8px; content:" "; background:#86bc42; height:2px; }
.project-single-text ul li{ color:#232323; font-size:15px; font-weight:500; }
.project-single-text ul li i{ color:#86bc42; font-weight:500; font-size:15px; padding-right:10px; }
.project-single-info{ border:1px solid #ececec; display:block; width:100%; padding:0 20px; }
.project-single-info ul li{ color:#777; font-size:15px; font-weight:500; border-bottom:1px solid #ececec; padding:12px 0; }
.project-single-info ul li:last-child{ border-bottom:none; }
.project-single-info ul li span{ display:inline-block; color:#232323; font-size:15px; font-weight:500; margin-right:25px; width:70px; max-width:100%; }
.project-single-info ul li .value { width: auto; margin-right: 0; color:#6f6f6f;  }
.project-single-img{ padding-bottom:30px; }
.project-single-box-img img{ width:100%; border-radius:10px; box-shadow:0 20px 40px rgba(0, 0, 0, .07); }

/* ===================================
    Accordion style
====================================== */

/*accordion style 1*/
.accordion-style .card{ background:transparent; box-shadow:none; margin-top:0 !important; border:none; }
.accordion-style .card-header{ border:0px; padding:0; border-bottom:1px solid rgba(255, 255, 255, 0.15); background:none; }
.accordion-style .btn-link{ background:#86bc42 !important; color:#fff; font-size:14px; line-height:20px; position:relative; border:none; display:block; width:100%; text-align:left; white-space:normal; border-radius:4px; padding:15px 30px 15px 15px; text-decoration:none; }
.accordion-style .btn-link:hover{ background:#86bc42 !important; border:none; text-decoration:none; }
.accordion-style .btn-link.collapsed:after{ background:none; content:"+"; right:15px; left:inherit; font-size:20px; height:auto; -webkit-transform:none; transform:none; width:auto; top:15px; }
.accordion-style .btn-link:after{ background:none; content:"-"; right:17px; left:inherit; font-size:20px; height:auto; -webkit-transform:none; transform:none; width:auto; top:15px; position:absolute; color:#fff; }
.accordion-style .card-body{ padding:20px; line-height:24px; text-align:left; }

/*accordion style 2*/
.accordion-style2 .card{ background:transparent; box-shadow:none; margin-top:0 !important; border:none; }
.accordion-style2 .card-header{ border:0px; padding:0; border-bottom:1px solid rgba(255, 255, 255, 0.15); background:none; }
.accordion-style2 .btn-link{ background:#ededed !important; color:#232323; font-size:14px; line-height:20px; position:relative; border:none; display:block; width:100%; text-align:left; white-space:normal; border-radius:0; padding:15px 30px 15px 15px; text-decoration:none; }
.accordion-style2 .btn-link:hover{ background:#ededed !important; border:none; text-decoration:none; }
.accordion-style2 .btn-link.collapsed:after{ background:none; content:"+"; right:15px; left:inherit; font-size:20px; height:auto; -webkit-transform:none; transform:none; width:auto; top:15px; }
.accordion-style2 .btn-link:after{ background:none; content:"-"; right:17px; left:inherit; font-size:20px; height:auto; -webkit-transform:none; transform:none; width:auto; top:15px; position:absolute; color:#232323; }
.accordion-style2 .card-body{ padding:10px 15px; line-height:24px; text-align:left; background: #f9f9f9; }

/* ===================================
    Service detail
====================================== */

.services-single-menu{ border:1px solid #ececec }
.services-single-menu ul li{ border-bottom:1px solid #ececec; background:#fff; }
.services-single-menu ul li:last-child{ border-bottom:none; }
.services-single-menu ul li:hover{ background:#f9f9f9; }
.services-single-menu ul li.services-active{ background:#86bc42; }
.services-single-menu ul li.services-active a{ color:#fff; }
.services-single-menu ul li a{ display:block; color:#232323; font-size:15px; font-weight:500; padding:17px 0 17px 15px; transition-duration:.3s; }
.callback-box{ background:#232323; border:1px solid #ececec; border-radius:5px; padding:20px 15px 25px 15px; position:relative; }

/* ===================================
    Portfolio masonry
====================================== */

.masonry{ margin:10px; padding:0; -moz-column-gap:10px; -webkit-column-gap:10px; grid-column-gap:10px; column-gap:10px; transition-duration:.4s; -webkit-transition-duration:.4s; }
.masonry a{ display:block; margin-top:10px; }
.masonry a:first-child{ margin-top:0; }
.masonry-item{ position: relative; overflow:hidden; transition-duration:.4s; }
.masonry-item img{ display:block; width:100%; height:auto; transition-duration:.4s; }
.masonry-item-overlay{ position:absolute; bottom:0; left:0; right:0; height:100%; max-height:100%; width:100%; opacity:0; background:rgba(0, 0, 0, .5); border: 8px solid rgba(255, 255, 255, 0.65); padding:3%; transition-duration:.4s; }
.masonry-item:hover img{ -webkit-transform:scale(1.2); transform:scale(1.2); }
.masonry-item:hover .masonry-item-overlay{ position:absolute; opacity:1; transition-duration:.5s; }
.masonry-item:hover .masonry-item-overlay h4{ opacity:1; -webkit-transform:translate(0, -50%); transform:translate(0, -50%); -ms-transform:translate(0, -50%); text-align:center; transition-duration:.6s; }
.masonry-item:hover .masonry-item-overlay ul{ opacity:1; -webkit-transform:translate(0, -50%); transform:translate(0, -50%); -ms-transform:translate(0, -50%); text-align:center; transition-duration:.5s; }
.masonry-item-overlay h4{ color:#fff; font-size:20px; font-weight:400; position:absolute; bottom:40px; margin:0 0 20px 0; line-height:20px; }
.masonry-item-overlay ul{ position:absolute; padding-left:0; bottom:25px; margin:0; }
.masonry-item-overlay ul li{ list-style-type:none; display:inline-block; font-size:16px; font-weight:600; color:#fff; padding-left:0; opacity:.7; }
.masonry-item-overlay ul li:last-child:after{ content:none; }
.masonry-item-overlay ul li:after{ content:' / '; font-size:8px; font-weight:700; margin:0 5px; }
.masonry-item-overlay ul li a{ color:#fff; }
.masonry-item:first-child{ margin-top:0; }

@media only screen and (min-width:576px){
    .masonry{ -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:768px){
    .masonry{ -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:992px){
    .masonry{ -webkit-column-count:3; column-count:3; }
 }

@media only screen and (min-width:1200px){
    .masonry{ -webkit-column-count:3; column-count:3; }
 }

/* masonry 4 */
.masonry-4{ margin:10px; padding:0; -moz-column-gap:10px; -webkit-column-gap:10px; grid-column-gap:10px; column-gap:10px; }
.masonry-4 a{ display:block; margin-top:10px; }
.masonry-4 a:first-child{ margin-top:0; }

@media only screen and (min-width:576px){
    .masonry-4{ -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:768px){
    .masonry-4{ -webkit-column-count:2; column-count:2; }
 }

@media only screen and (min-width:992px){
    .masonry-4{ -webkit-column-count:3; column-count:3; }
 }

@media only screen and (min-width:1200px){
    .masonry-4{ -webkit-column-count:4; column-count:4; }
 }

/* ===================================
    Tabs
====================================== */ 

ul.resp-tabs-list{ margin: 0px; padding: 0px; }
.resp-tabs-list li{ font-weight: 600; font-size: 14px; display: inline-block; padding: 13px 15px; margin: 0 4px 0 0; list-style: none; cursor: pointer; }
.resp-tabs-list li:last-child { margin-right: 0; }
.resp-tabs-list li i { font-size: 20px; padding-right: 5px; vertical-align: text-bottom; }
.resp-tabs-container{ padding: 0px; background-color: #fff; clear: left; }
.resp-tab-content{ display: none; padding: 20px; }
.resp-tabs-list li.resp-tab-active{ border: 1px solid #86bc42; border-bottom: none; border-color: #86bc42 !important; margin-bottom: -1px; padding: 12px 14px 14px 14px; border-top: 4px solid #86bc42 !important; border-bottom: 0px #fff solid; border-bottom: none; background-color: #fff; color: #86bc42; }
.resp-content-active, .resp-accordion-active{ display: block; }
.resp-tab-content{ border: 1px solid #c1c1c1; border-top-color: #c1c1c1; float: left; width: 100%; }
h2.resp-accordion{  cursor: pointer; display: none; font-size: 14px; border: 1px solid #c1c1c1; border-top: 0px solid #c1c1c1; margin: 0px; padding: 14px 15px; float: left; width: 100%; }
h2.resp-tab-active{ border-bottom: 0px solid #c1c1c1 !important; background-color: #86bc42 !important; color: #fff; }
h2.resp-tab-title:last-child{ border-bottom: 12px solid #c1c1c1 !important; background: blue; }

/* vertical tabs */
.resp-vtabs ul.resp-tabs-list{ float: left; width: 30%; }
.resp-vtabs .resp-tabs-list li{ display: block; padding: 15px 15px !important; margin: 0 0 4px; cursor: pointer; float: none; }
.resp-vtabs .resp-tabs-container{ padding: 0px; background-color: #fff; border: 1px solid #86bc42 !important; float: left; width: 70%; min-height: 250px; clear: none; }
.resp-vtabs .resp-tab-content{ border: none; word-wrap: break-word; }
.resp-vtabs li.resp-tab-active{ position: relative; z-index: 1; margin-right: -1px !important; padding: 14px 15px 15px 11px !important; border-top: 1px solid; border: 1px solid #86bc42 !important; border-left: 4px solid #86bc42 !important; margin-bottom: 4px !important; border-right: 1px #FFF solid !important; }
.resp-arrow{ border-color: transparent #232323 #232323 transparent; border-style: solid; border-width: 0 1px 1px 0; float: right; display: block; height: 8px; -webkit-transform: rotate(45deg); transform: rotate(45deg); width: 8px; margin-top: 4px;  }
h2.resp-tab-active span.resp-arrow{ border-color: #fff transparent transparent #fff; border-style: solid; border-width: 1px 0 0 1px; float: right; display: block; height: 8px; -webkit-transform: rotate(45deg); transform: rotate(45deg); width: 8px; margin-top: 7px; }

/* accordion styles */
.resp-easy-accordion h2.resp-accordion{ display: block; }
.resp-easy-accordion .resp-tab-content{ border: 1px solid #c1c1c1; }
.resp-easy-accordion .resp-tab-content:last-child{ border-bottom: 1px solid #c1c1c1; /* !important; */}
.resp-jfit{ width: 100%; margin: 0px; }
.resp-tab-content-active{ display: block; border-color: #86bc42 !important; }
h2.resp-accordion:first-child{ border-top: 1px solid #c1c1c1; }
h2.resp-accordion.resp-tab-active { border-color: #86bc42 !important; }

/* tab-style4 */
.tab-style4 .resp-tabs-list li.resp-tab-active { background:#86bc42 !important; color: #fff;  }
.tab-style4 .resp-tab-content-active { background:#86bc42 !important; color: #fff; }

/* tab-style5 */
@media only screen and (min-width: 768px){
    .tab-style5 ul.resp-tabs-list { text-align: center; }
    .tab-style5 ul.resp-tabs-list.text-left { padding-left: 15px; }
    .tab-style5 ul.resp-tabs-list li:last-child { margin-right: 0; }
    .tab-style5 ul.resp-tabs-list li.resp-tab-active { border-bottom-color: #86bc42;  color: #86bc42; }
    .tab-style5 .resp-tab-content { border: 1px solid #e6e6e6; border-width: 1px 0 0 0; border-color: #86bc42; }
}

/* tab-style6 */
@media only screen and (min-width: 768px){
    .tab-style6 ul.resp-tabs-list { text-align: center;  border: 1px solid #e6e6e6; border-width: 0 0 1px 0;  }
    .tab-style6 ul.resp-tabs-list li:last-child { margin-right: 0; }
    .tab-style6 ul.resp-tabs-list li.resp-tab-active { border-bottom-color: #86bc42; }
    .tab-style6 ul.resp-tabs-list li.resp-tab-active { color: #86bc42; }
    .tab-style6 .resp-tab-content { border: none; padding: 30px 20px; }
    .tab-style6 .resp-tabs-list .tab-box { display: inline-block; vertical-align: middle; text-align: left; margin-top: 8px; }
    .tab-style6 .resp-tabs-list li.resp-tab-active h6 { color:#86bc42;  }
    .tab-style6 .resp-tabs-list .tab-box h6 { margin: 0; font-weight: 600; font-size: 16px; color: #6f6f6f; line-height: 12px; }
    .tab-style6 .resp-tabs-list .tab-box span { text-transform: lowercase; font-size: 12px; }
    .tab-style6 .resp-tabs-list li { font-size: 18px; border-bottom: 2px solid transparent !important; margin: 0; background:none !important; padding: 0 35px 15px 35px;  text-transform: uppercase;   }
    .tab-style6 .resp-tabs-list li.resp-tab-active { border-width: 0 0 2px 0 !important; border-color: #86bc42 !important; }
    .tab-style6 ul.resp-tabs-list i { margin-right: 15px; border: 1px solid #d5d5d5; border-radius: 70px; width: 70px;  height: 70px; line-height: 68px; font-size: 30px; padding: 0; vertical-align: middle; }
    .tab-style6 ul.resp-tabs-list li.resp-tab-active i { background: #86bc42; border-color: #86bc42; color: #fff; }
}

@media only screen and (max-width: 991px){
    .tab-style6 .resp-tab-content { padding: 20px; }
    .tab-style6 .resp-tabs-list li { padding: 0 15px 15px 15px; } 
}

/* change the breakpoint to set the accordion */
@media only screen and (max-width: 767px){
    ul.resp-tabs-list{ display: none; }
    h2.resp-accordion{ display: block;  }
    h2.resp-accordion i { margin-right: 8px; font-size: 18px; }
    .resp-vtabs .resp-tab-content{ border: 1px solid #C1C1C1; }
    .resp-vtabs .resp-tabs-container{ border: none !important; float: none; width: 100%; min-height: 100px; clear: none; }
    .resp-accordion-closed{ display: none !important; }
    .resp-vtabs .resp-tab-content:last-child{ border-bottom: 1px solid #c1c1c1 !important; }
    .resp-tab-content { background-color: #fff; }

    /* tab-style6 */
    .tab-style6 .tab-box { display: inline-block; vertical-align: top; }
    .tab-style6 h2.resp-accordion i { margin-right: 15px; }
    .tab-style6 .tab-box h6 { margin-bottom: 2px; font-size: 14px; }
    .tab-style6 .tab-box span { font-size: 12px; }
    .tab-style6 .resp-tab-active h6 { color: #fff;  }
} 

/* ===================================
    Pricing table
====================================== */

.price-table{ border-radius:10px; overflow:hidden; background:#fff; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); position:relative; }
.price-table-header{ background:#86bc42; text-align:center; padding:30px; }
.price-table-recommended{ position:relative; z-index:9999; -webkit-transform:scale(1.09); transform:scale(1.09); }
.pricing-list > i{ background:#ffffff; border:1px solid #dddddd; border-radius:100%; color:#86bc42; display:inline-block; font-size:30px; height:80px; line-height:78px; margin-bottom:15px; width:80px; }

.price-table-header h5{ border-bottom:1px solid rgba(255, 255, 255, 0.2); color:#ffffff; display:block; font-size:20px; font-weight:500; margin-bottom:30px; padding:5px 15px 15px; position:relative; text-align:center; text-transform:uppercase; }
.price-table-header h4{ color:#fff; font-size:60px; margin:15px 0 10px; }
.price-table-header h4 sup{ font-size:35px; margin:0 5px; }
.price-table-header span{ color:#fff; font-weight:400; font-size:16px; }

.price-table-content{ padding:20px; }
.price-table-content ul{ padding-left:0; padding-bottom:0; }
.price-table-content ul li{ display:block; list-style-type:none; color:#232323; font-size:14px; font-weight:500; padding:15px; }
.price-table-content ul li:before{ content:'\F00C'; font-weight: 700; font-family: Font Awesome\ 5 Free; display:inline-block; color:#86bc42; margin-right:12px; }
.price-table-content ul li:nth-child(2){ background:#eee; }
.price-table-content a{ display:inline-block; border:2px solid #86bc42; background:#fff; font-family:"Montserrat",sans-serif; color:#232323; font-size:16px; font-weight:500; border-radius:10px; padding:12px 15px; width:100%; margin-top:15px; }
.price-table-content a:hover{ background:#86bc42; color:#fff; }

.pricing-list{ text-align:center; border-radius:10px; background:#fff; border:2px solid #ececec; padding:30px 40px; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); transition-duration:.5s; }
.pricing-list.highlight{ background:#86bc42; border-color: transparent; }
.pricing-list.highlight .pricing-list-button{ background:#fff; margin:20px 15px 0; border:2px solid #fff; border-radius:5px; transition-duration:.3s; }
.pricing-list.highlight .pricing-list-button:hover{ background:#86bc42; }
.pricing-list.highlight .pricing-list-button a{ display:block; color:#86bc42; font-size:16px; font-weight:500; padding:8px; transition-duration:.3s; }
.pricing-list.highlight .pricing-list-button:hover a{ color:#fff; }
.pricing-list h4{ color:#232323; font-size:25px; font-weight:500; letter-spacing:2px; margin-bottom:20px; }
.pricing-list h2{ display:inline-block; color:#232323; letter-spacing:2px; }
.pricing-list h2 sup{ color:#232323; font-size:30px; font-weight:500; vertical-align: sub; }
.pricing-list span{ display:inline-block; color:#86bc42; font-weight:500; }
.pricing-list ul{ margin-top:20px }
.pricing-list ul li{ color:#666; font-size:15px; font-weight:400; padding:7px; }
.pricing-list ul li:after{ content:''; display:block; width:240px; max-width:100%; height:1px; background:#ececec; margin:10px auto 0; }
.pricing-list-button{ background:#86bc42; margin:20px 15px 0; border:2px solid #86bc42; border-radius:5px; transition-duration:.3s; }
.pricing-list-button:hover{ background:#fff; }
.pricing-list-button a{ display:block; color:#fff; font-size:16px; font-weight:500; padding:8px; transition-duration:.3s; }
.pricing-list-button:hover a{ color:#86bc42; }

.price-table-style2 .price-table-header{ background:#000; }
.price-table-style2 .price-table-content a{ border-color:#232323; }
.price-table-style2 .price-table-content a:hover{ background:#232323; }
.price-table-style2 .price-table-content ul li:before{ color:#232323; }

@media (max-width:767px){
    .price-table-recommended{ position:relative; z-index:9999; -webkit-transform:scale(1); transform:scale(1); }
 }

/* ===================================
    Shop
====================================== */

/* home shop */
.shop-category { box-shadow: 1px 5px 22px -3px rgba(0,0,0,0.1); }
.shop-category li { border-color: #eaeaea; padding: 0 0 10px 40px; }
.shop-category.list-style-5 li:before { left: 15px; }
.label-offer { position: absolute; left: 0; top: 0; height: 25px; line-height: 25px; display: inline-block;  padding: 0px 12px;   color: #fff; text-transform: uppercase; font-weight: 600; font-size: 12px; z-index: 1; }

.product-grid .border { float: left; width: 100%; position: relative; }
.product-grid .product-info{ padding: 15px; float: left; width: 100%; background:#fafafa; border-top: 1px solid #eeeeee; text-align: center; font-size: 18px; }
.product-grid .product-info > a { margin-bottom: 5px; display: inline-block; font-size: 14px; }
.product-grid .price { font-weight: 600; }
.product-grid .product-block:hover > img  {  opacity: 0.65;  }
.product-grid .buttons { float: left; width: 100%; }
.product-grid .buttons a{ width: 50%; float: left; text-align: center; padding: 8px 0; }

.control-top .owl-nav{ margin: 0; position: absolute; right: -2px; top: -73px; }
.control-top.owl-theme .owl-nav [class*='owl-'] { background:#fff !important; padding: 0 7px; width: 30px; height: 30px; margin: 0 2px; border-radius: 0; border:1px solid #d5d5d5;  }
.control-top.owl-theme .owl-nav i { color: #6f6f6f; padding-top: 4px; }

.v-middle-content{ padding: 50px 8.5%; padding-top: 0; padding-bottom: 0; position: absolute; left: 0; top: 50%; transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); max-width: 65%; display: block; }
.banner-content:before{ position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.65); content: ""; opacity: 0; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
.banner-content:hover:before{ background: rgba(0,0,0,0.65); opacity: 1; }
.banner-content{ letter-spacing: 2px; }
.banner-content .subtitle{ line-height: 22px; text-transform: uppercase; margin-bottom: 5px; font-weight: 600; }
.banner-content .title{ font-size: 44px; line-height: 44px; text-transform: uppercase; font-weight: 500; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
.banner-content:hover .title{ margin-left: 20px; }
.banner-content .view{ font-style: italic; font-size: 12px; line-height: 16px; text-transform: uppercase; border-bottom: 1px solid #373737; display: inline-block; transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; }
.banner-content:hover .view{ margin-left: 20px; border-bottom: 1px solid #fff; }

.feature-product-grid .border { float: left; width: 100%; background: #fafafa; }
.feature-product-grid .product-block{ width: 90px;  height: 90px;  border: 1px solid #eee;  border-width: 0 1px 0 0; float: left; }
.feature-product-grid .product-info { max-width: 61%; padding: 8px 15px; float: left;  }

/* shop product detail*/
.product-detail .label-sale{ margin-left: 5px; vertical-align: top; padding: 2px 7px; }
.product-detail .offer-price { text-decoration: line-through; color: red; }
.product-rating .fas:hover { color: #232323; }

 /* shop product cart*/
.shop-cart-table { width: 100%; overflow-x: auto; }
.shop-cart-table .shop-cart td{ line-height: 26px; vertical-align: middle; padding: 16px 8px; }
.table.cart-sub-total, .table.cart-sub-total td, .table.cart-sub-total th{ border-color: transparent; vertical-align: middle; padding: 10px 30px; }
.table.cart-sub-total td{ padding-right: 0; }
.shop-cart-table input[type="text"]{ height: 35px; width: 50px; margin: 0; }

 /* shop product grid and list*/
.product-list .product-block:hover > img  {  opacity: 0.65;  }
.product-list .buttons { float: left; width: 100%; }
.product-list .buttons a{ float: left; text-align: center; padding: 8px 23px; }
.product-list .buttons a:hover { opacity: 0.8;}

@media (max-width:991px){
    .categories .collapse-sm:after { font-family: Font Awesome\ 5 Free; content: "\F105"; float: right; }
    .categories .collapse-sm.current:after { font-family: Font Awesome\ 5 Free; content: "\F107"; float: right; }
    .categories .shop-category { display: none; }
}

/* ===================================
    Blog block
====================================== */

.latest_blog_inner{ overflow:hidden; }
.image_blog{ position:relative; }
.image_blog > a:after{ background:#86bc42 none repeat scroll 0 0; content:""; height:100%; left:0; opacity:0; position:absolute; top:0; transition:all 0.3s ease 0s; width:100%; }
.image_blog > a:hover:after{ opacity:0.6; }
.image_blog a img{ max-width:100%; }
.image_blog .img_blog_text{ bottom:35px; position:absolute; padding:0 20px; }
.image_blog .img_blog_text h5{ color:#ffffff; font-size:13px; letter-spacing:0.26px; padding-bottom:10px; margin-bottom:0; line-height:normal; }
.image_blog .img_blog_text h3{ color:#ffffff; font-size:18px; font-weight:600; margin-bottom:0; line-height:24px; }
.text_blog{ background:#fafafa none repeat scroll 0 0; padding:35px 40px 25px; transition:all 0.3s linear 0s; }
.text_blog:hover{ background:#86bc42; }
.text_blog h5{ color:#616161; font-size:13px; letter-spacing:0.26px; padding-bottom:18px; margin:0; line-height:normal; transition:all 300ms linear 0s; }
.text_blog:hover h5 { color:#ffffff; }
.text_blog h3{ color:#282828; font-size:18px; font-weight:600; margin-bottom:0; line-height:24px; }
.text_blog:hover h3, .text_blog:hover h3 a{ color:#ffffff; }
.text_blog p{ margin:0; padding:20px 0; transition:all 300ms linear 0s; }
.text_blog:hover p { color:#ffffff; }
.text_blog .blog_user{ border-top:2px solid #ececec; padding-top:15px; }
.text_blog .blog_user h4{ color:#616161; font-size:15px; margin-bottom:0; line-height:normal; letter-spacing:0.3px; transition:all 300ms linear 0s; }
.text_blog .blog_user h4 a{ color:#86bc42; transition:all 300ms linear 0s; }
.text_blog:hover .blog_user h4, .text_blog:hover .blog_user h4 a { color:#ffffff;  }

@media (max-width:992px){
    .text_blog{ padding:16px 20px; }
 }

/* ===================================
    Blog
====================================== */

.blog-grid{ position:relative; background:#fff; border:1px solid #eee; border-radius:5px; overflow:hidden; box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); }
.blog-grid img{ width:100%; }

/* blog style1 */
.blog-style1 .item{ box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15); background: #fff; height: 100%; }
.blog-style1 .item .post-img{ position: relative; overflow: hidden; }
.blog-style1 .item .post-img:hover img{ -webkit-filter: grayscale(100%); filter: grayscale(100%); }
.blog-style1 .item .post-img img{ width: 100%; -webkit-filter: none; filter: none; transition: 0.3s; }
.blog-style1 .item .content{ padding: 30px; text-align: left; }
.blog-style1 .item .content .tag{ font-size: 12px; margin-bottom: 5px; letter-spacing: 1px; }
.blog-style1 .item .content .read-more{ margin-top: 20px; font-weight: 600; }
.blog-style1 .item .content .read-more:hover{ text-decoration: underline; }
.blog-style1 .item .content p{ margin-bottom: 10px; line-height: normal; }
.blog-style1 .item .content h5{ font-size: 16px; line-height: normal; text-transform: inherit; }

.data-box-grid{ display: inline-block; }
.data-box-grid:before {  content: "/"; padding-right: 5px; }
.data-box-grid h5{ font-size:12px; line-height: normal; letter-spacing:2px; display:inline-block; vertical-align:middle; margin-bottom:0; color:#999; }
.blog-grid .data-box-grid p{ font-size:12px; margin-bottom:0; padding:0; border:none; color:#999; display:inline-block; vertical-align:middle; text-transform:uppercase; }
.blog-grid-text{ padding:25px; position:relative; }
.blog-grid-text > span{ color:#86bc42; font-size:13px; padding-right: 5px;  }
.blog-grid-text h4{ font-size:16px; line-height:normal; margin-bottom:3px; }
.blog-grid-text ul li{ display:inline-block; color:#999; font-size:14px; font-weight:500; margin:10px 25px 10px 0; }
.blog-grid-text ul li i{ color:#86bc42; font-size:14px; font-weight:500; margin-right:10px; }
.blog-grid-text p{ color:#999; font-size:15px; font-weight:400; line-height:170%; padding:15px 0 0 0;}

.owl-carousel .blog-grid-simple{ margin:10px 15px 25px; }
.blog-grid-simple-date{ width:100%; }
.blog-grid-simple{ position:relative; background:#fff; border-radius:5px; border:1px solid #ececec; box-shadow:0 10px 15px 0 rgba(50, 50, 50, .16);  -webkit-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16); -moz-box-shadow:0 10px 30px 0 rgba(50, 50, 50, .16);  padding:30px; }
.blog-grid-simple h4,.blog-grid-simple h4 a{ color:#232323; line-height:normal; font-size:21px; font-weight:500; margin-bottom:15px; }
.blog-grid-simple h4 a:hover{ color:#86bc42; }
.blog-grid-simple p{ color:#aaa; font-size:15px; font-weight:400; }
.blog-grid-simple-date i{ color:#888; font-size:20px; }
.blog-grid-simple-date h5{ color:#888; font-size:13px; margin-top:3px; margin-bottom:0; display:inline-block; margin-left:8px; line-height:normal; }
.blog-grid-simple-content{ border-top:1px solid #ececec; padding-top:20px; margin-top:15px; padding-left:15px; padding-right:15px; }
.blog-grid-simple-content a{ display:inline-block; color:#86bc42; font-weight:600; font-size:14px; margin-top:2px }
.blog-grid-simple-content a:after{ content:'\F105'; font-size:14px; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#86bc42; margin-left:10px; transition-duration:.3s; }

.blog-list-left-heading:after,.blog-title-box:after{ content:''; height:2px }
.blog-grid-simple-content a:hover,.blog-grid-simple-content a:hover:after{ color:#232323; }
.blog-list-simple{ margin:0 0 30px 0; border-bottom:1px solid #e8e8e8; padding-bottom:30px; }
.blog-list-simple:last-child{ border-bottom:0; padding-bottom:0; margin-bottom:0; }
.data-box-simple{ display:block; float:right; position:absolute; background:#86bc42; left:20px; top:5px; padding:5px; min-width:60px; text-align:center; }
.blog-list-simple-text > span{ color:#86bc42; font-size:13px; font-weight:500; letter-spacing:2px; text-transform:uppercase; }
.data-box-simple h4{ color:#fff; font-size:30px; font-weight:400; letter-spacing:2px; border-bottom:1px solid #fff; margin-bottom:5px; line-height:normal; }
.data-box-simple p{ color:#fff; font-size:14px; font-weight:400; margin-bottom:0; }

.blog-list-simple-text p{ border-top:1px solid #ececec; color:#999999; font-size:15px; font-weight:400; line-height:170%; margin-bottom:0; padding:15px 0; }
.blog-list-simple-text .meta{ margin:0 0 5px 0; }
.blog-list-simple-text li{ display:inline-block; font-size:12px; color:#777; margin:5px; }
.blog-list-simple-text{ color:#999999; }
.blog-list-simple-text h4{ color:#232323; font-size:20px; font-weight:500; margin:5px 0; line-height:normal; }
.blog-list-simple-text ul{ margin:10px 0; }
.blog-list-simple-text ul li{ color:#999; font-size:14px; font-weight:500; padding:3px 0; }
.blog-list-simple-text ul li i{ color:#86bc42; margin-right:5px; font-size:14px; }

.blogs .post{ margin-bottom:30px; }
.blogs .post .content{ padding:30px; background:#fafafa; }
.blogs .post .content .post-title h5{ font-size:26px; line-height:normal; font-weight:500; margin-bottom:0; }
.blogs .post .content .post-title h5 a{ color:#232323; }
.blogs .post .content .post-title h5 a:hover{ color:#F24259; }
.blogs .post .content .meta{ margin-bottom:15px; }
.blogs .post .content .meta li{ display:inline-block; font-size:12px; color:#777; margin:5px; }
.blogs .post .content .special{ padding:15px; margin:30px 0; border-left:2px solid #111; background:#f7f7f7; font-size:16px; }
.blogs .post .content .btn{ border-color:#6f6f6f; color:#6f6f6f; min-width:auto; padding:6px 20px; font-size:12px; }
.blogs .post .content .btn:hover{ color:#fff; border-color:#F24259; }
.blogs .post .share-post{ margin-top:30px; padding-top:20px; border-top:1px dashed #ddd; text-align:left; }
.blogs .post .share-post span{ font-weight:700; }
.blogs .post .share-post ul{ float:right; }
.blogs .post .share-post ul li{ display:inline-block; margin:0 10px; }
.blogs .post .post-img img{ width:100%; }

.blogs .posts .title-g h3{ font-weight:700; font-size:25px; margin-bottom:10px; }
.blogs .comments-area .comment-box{ padding-bottom:30px; margin-bottom:50px; border-bottom:1px solid #eee; }
.blogs .comments-area .comment-box:nth-child(odd){ margin-left:80px; }
.blogs .comments-area .comment-box:last-child{ margin-bottom:30px; }
.blogs .comment-box .author-thumb{ width:80px; float:left; }
.blogs .comment-box .comment-info{ margin-left:100px; }
.blogs .comment-box .comment-info h6{ font-size:14px; font-weight:600; margin-bottom:10px; }
.blogs .comment-box .comment-info .reply{ margin-top:10px; font-weight:600; }
.blogs .comment-box .comment-info .reply i{ padding-right:5px; font-size:12px; }

.pagination{ border-radius:0; padding:0; margin:0; }
.pagination ul{ display:inline-block; *display:inline; *zoom:1; margin:0 auto; padding:0; }
.pagination li{ display:inline; }
.pagination a{ float:left; padding:0 18px; line-height:40px; text-decoration:none; border:1px solid #dbdbdb; border-left-width:0; background:#fff; }
.pagination a:hover{ background-color:#232323; color:#fff; }
.pagination .active a{ background-color:#f7f7f7; color:#999; cursor:default; }
.pagination .disabled span{ color:#999; background-color:transparent; cursor:default; }
.pagination .disabled a{ color:#999; background-color:transparent; cursor:default; }
.pagination .disabled a:hover{ color:#999; background-color:transparent; cursor:default; }
.pagination li:first-child a{ border-left-width:1px; }

.blockquote{ background:#f1f1f1; border-left:4px solid #86bc42; font-size:16px; font-weight:500; margin:25px 0; padding:20px 30px 30px; }
.blockquote p{ font-size:15px; font-weight:500; line-height:190%; color:#232323; letter-spacing:.5px; }
.blockquote h4{ font-size:20px; font-weight:400; color:#232323; letter-spacing:.5px; }

.side-bar .widget{ margin-bottom:30px; }
.side-bar .widget:last-child{ margin-bottom:0; }
.side-bar .widget .widget-title h6{ position:relative; padding-bottom:5px; font-size:14px; font-weight:600; letter-spacing:1px; margin-bottom:20px; }
.side-bar .widget .widget-title h6:after{ content:''; width:30px; height:1px; background:#86bc42; position:absolute; bottom:0; left:0; }
.side-bar .widget li{ position:relative; padding:0 0 0 12px; margin:0 0 9px 0; color:#6f6f6f; }
.side-bar .widget li:last-child{ margin:0; }
.side-bar .widget li:after{ content:""; width:5px; height:1px; background-color:#6f6f6f; position:absolute; top:12px; left:0; }
.side-bar .widget .social-listing{ margin-bottom:0; }
.side-bar .widget .social-listing li{ list-style:none; display:inline-block; }
.side-bar .widget .social-listing li:after{ background:none; }
.side-bar .widget .social-listing li:first-child{ padding-left:0; }

.side-bar .search form input{ width:calc(100% - 50px); height:50px; padding:0 10px; margin:0; border:1px solid #d1d1d1; background:#f7f7f7; }
.side-bar .search form button{ width:50px; height:50px; line-height:50px; background:#1c1c1c; color:#fff; border:0; float:right; }

@media (max-width:767px){
    .blog-grid-simple-content a:after{ display:none; }
 }

/* ===================================
    Count and error box
====================================== */

/* count back box */
.count-back-box{ width:100%; text-align:center; background-position:center; background-size:cover; position: relative; z-index: 1; }
.count-back-box h1{ color:#232323; font-size:40px; font-weight:500; }

/* error box */
.error-box{ width:100%; height:100%; margin:0 auto; text-align:center; background-position:center; background-size:cover; position: relative; z-index: 1; }
.error-box h2{ color:rgba(204, 204, 204, .2); font-size:250px; font-weight:600; letter-spacing:10px; }
.error-box-text h1{ font-size:200px; color:#86bc42; line-height:1; }
.error-box-text h3{ font-size:40px; color:#232323; line-height:normal; }
.error-box-text h4{ font-size:20px; color:#333; margin-top:20px; }

@media (max-width:767px){ 
    .error-box{ padding:30px; }
    .back-box{ display:none; }
    .error-box-text h1{ font-size:100px; }
}

/* count down */
ul.countdown li{ border-right:1px solid #626262; display:inline-block; padding:0 30px; text-align:center; }
ul.countdown li:last-child{ border:medium none; padding-right:0; }
ul.countdown li span{ font-size:36px; font-weight:700; line-height:normal; position:relative; }
ul.countdown li span::before{ content:""; height:1px; position:absolute; width:100%; }
ul.countdown li p.timeRefDays, ul.countdown li p.timeRefHours, ul.countdown li p.timeRefMinutes, ul.countdown li p.timeRefSeconds{ font-size:12px; font-weight: 500; margin:0; padding:0; text-transform:uppercase; }

@media (max-width:767px){
    ul.countdown li{ padding:0 8px; }
    .social-links li{ margin-right:15px; }
    ul.countdown li span{ font-size:22px; }
}

countdown-style2
/* ===================================
    Contact info
====================================== */

/* social links */
.social-icon-style1{ margin-bottom:0; }
.social-icon-style1 li{ text-align:center; margin-right:4px; display:inline-block; }
.social-icon-style1 li a{ background:#86bc42; border:1px solid #86bc42; color:#fff; border-radius:35px; height:35px; width:35px; line-height:35px; display:inline-block; }
.social-icon-style1 li a:hover{ background:#fff; color:#86bc42; }

.social-icon-style2{ margin-bottom:0; }
.social-icon-style2 li{ text-align:center; margin-right:5px; display:inline-block; }
.social-icon-style2 li:last-child{ margin-right:0; }
.social-icon-style2 li a{ background:#f9f9f9; border:1px solid #86bc42; color:#86bc42; border-radius:35px; height:35px; width:35px; line-height:35px; display:inline-block; }
.social-icon-style2 li a:hover{ background:#86bc42; color:#fff; }

.social-icon-style3{ margin-bottom:0; }
.social-icon-style3 li{ text-align:center; margin-right:10px; display:inline-block; }
.social-icon-style3 li a{ color:#6f6f6f; display:inline-block; font-size:16px; }
.social-icon-style3 li a:hover{ color:#86bc42; }

.social-icon-style4{ padding:0; float:right; margin:0; }
.social-icon-style4 li{ font-size:14px; list-style-type:none; float:left; width:35px; text-align:center; margin:0; padding:13px 0; }
.social-icon-style4 li a{ opacity:.7; color:#fff; transition-duration:.3s; }

.social-icon-style5 li{ display:inline-block; margin-right:30px; }
.social-icon-style5 li:last-child{ margin-right:0; }
.social-icon-style5 li a{ font-size:22px; }
.social-icon-style5 li a:hover{ color:#fff; }

.social-icon-style6{ margin-bottom:0; }
.social-icon-style6 li{ text-align:center; margin-right:5px; display:inline-block; }
.social-icon-style6 li:last-child{ margin-right:0; }
.social-icon-style6 li a{border:1px solid #6f6f6f; color:#6f6f6f; border-radius:35px; height:35px; width:35px; line-height:35px; display:inline-block; }
.social-icon-style6 li a:hover{ background:#232323; color:#fff; }

/* contact detail */
#map{ height:400px; width:100%; }
.contact-info-section{ border-bottom:1px solid #ececec; padding:25px 0; margin-bottom:0; transition-duration:.5s; }
.contact-info-section i{ width:25px; height:25px; padding-top:7px; border-radius:50%; background:#86bc42; color:#fff; font-size:12px; margin-right:5px; line-height:11px; }
.contact-info-section h4{ color:#232323; font-size:18px; font-weight:500; margin-bottom:15px; line-height:normal; }
.contact-info-section p{ color:#999; font-size:15px; font-weight:400; margin:0; }
.contact-box{ text-align:center; background:#fff; border:1px solid #ececec; border-radius:2px; box-shadow:0 20px 80px rgba(0, 0, 0, .12); margin:10px 0; padding:20px 15px; transition-duration:.3s; }
.contact-box i{ background:#86bc42; color:#fff; font-size:35px; margin:10px 0 20px; height:75px; width:75px; line-height:75px; border-radius:50%; }
.contact-box h4{ color:#232323; font-size:18px; font-weight:500; margin:0 0 5px; line-height:normal; }
.contact-box span{ color:#999; font-size:14px; }

/* newsletter */
.newsletter-form input{ display:inline-block; background:#fff; font-size:14px; margin-bottom:0; font-weight:500; outline:0; border:2px solid #eee; min-width:270px; max-width:100%; border-top-left-radius:30px; border-bottom-left-radius:30px; padding:12px 20px; width:auto; }
.newsletter-form button{ display:inline-block; background:#86bc42; cursor:pointer; color:#fff; border:none; font-size:14px; font-weight:500; border-top-right-radius:30px; border-bottom-right-radius:30px; padding:14px 20px; }
.newsletter-form button:hover{ background:#232323; }

@media (max-width: 767px) {
    .newsletter-form button { margin-top: 15px; border-radius: 30px; width: 100%; display: block; }
    .newsletter-form input { border-radius: 30px; width: 100%; display: block; }
}

/* ===================================
    Footer
====================================== */

footer{ padding:50px 0 0; background:#1f1f1f; color:#939393; }
footer p{ margin-bottom:0; }
footer h3{ font-size:18px; font-weight:500; margin-bottom:30px; line-height:1; position:relative; }
footer h3:after{ content:''; display:block; width:70px; height:2px; background:#86bc42; margin-top:12px; }

.footer-social-icons ul{ margin-bottom:0; }
.footer-social-icons ul li{ display:inline-block; border:1px solid #fff; border-radius:50%; color:#fff; margin-right:10px; margin-bottom:5px; transition-duration:.3s }
.footer-social-icons ul li a{ color:#939393; display:block; font-size:15px; height:35px; line-height:34px; text-align:center; width:35px; }
.footer-social-icons ul li:hover { background:#86bc42; border-color:#86bc42; }
.footer-social-icons ul li:hover i { color: #fff; }

/*small*/
.footer-social-icons.small ul li { margin-bottom: 0; }
.footer-social-icons.small ul li a { font-size: 12px; height: 25px; line-height: 26px; width: 25px; }

.footer-social-icons2 ul{ margin-bottom:0; }
.footer-social-icons2 ul li{ display:inline-block; border:1px solid #6f6f6f; border-radius:50%; color:#6f6f6f; margin-right:10px; margin-bottom:0; transition-duration:.3s }
.footer-social-icons2 ul li a{ color:#939393; display:block; font-size:15px; height:35px; line-height:34px; text-align:center; width:35px; }
.footer-social-icons2 ul li:hover { background:#86bc42; border-color:#86bc42; }
.footer-social-icons2 ul li:hover i { color: #fff; }

.footer-subscribe{ margin-top:20px; margin-bottom: 0; position:relative;  }
.footer-subscribe input, .footer-subscribe input:focus{ background:#fff; border-radius:6px; font-size: 14px; border:0px solid; height:40px; outline:none; box-shadow:none; padding:6px 95px 6px 12px; margin-bottom: 0;  }
.footer-subscribe .butn{ position:absolute; right:0; top:0; height:100%; -webkit-border-radius: 0; -webkit-border-top-right-radius: 4px; -webkit-border-bottom-right-radius: 4px; -moz-border-radius: 0; -moz-border-radius-topright: 4px; -moz-border-radius-bottomright: 4px;
border-radius: 0; border-top-right-radius: 4px; border-bottom-right-radius: 4px; color: #fff; line-height: 15px; }
.footer-subscribe .butn:after { -webkit-border-radius: 0; -webkit-border-top-right-radius: 3px; -webkit-border-bottom-right-radius: 3px; -moz-border-radius: 0; -moz-border-radius-topright: 3px; -moz-border-radius-bottomright: 3px;
border-radius: 0; border-top-right-radius: 3px; border-bottom-right-radius: 3px; background: #e5e5e5; }
.footer-subscribe .butn:hover { -webkit-border-radius: 0; -webkit-border-top-right-radius: 3px; -webkit-border-bottom-right-radius: 3px; -moz-border-radius: 0; -moz-border-radius-topright: 3px; -moz-border-radius-bottomright: 3px;
border-radius: 0; border-top-right-radius: 3px; border-bottom-right-radius: 3px;}
.footer-subscribe .butn:hover:after { -webkit-border-radius: 0; -webkit-border-top-right-radius: 3px; -webkit-border-bottom-right-radius: 3px; -moz-border-radius: 0; -moz-border-radius-topright: 3px; -moz-border-radius-bottomright: 3px;
border-radius: 0; border-top-right-radius: 3px; border-bottom-right-radius: 3px;}
.footer-subscribe .butn span { color: #fff; }
.footer-subscribe .butn:hover span { color: #232323; }
.footer-subscribe .butn.theme:after {  background: #000; }
.footer-subscribe .butn.theme:hover span { color: #fff; }
.footer-subscribe .butn.white{ color: #232323; }
.footer-subscribe .butn.white:after { background: #232323; }
.footer-subscribe .butn.theme.white-hover:hover { background:#fff; }
.footer-subscribe .butn.theme.white-hover:hover span { color: #232323; }
.footer-subscribe .butn.theme.white-hover:after { background:#fff; }
.footer-subscribe .butn.theme.grey-hover:hover { background:#e5e5e5; }
.footer-subscribe .butn.theme.grey-hover:hover span { color: #232323; }
.footer-subscribe .butn.theme.grey-hover:after { background:#e5e5e5; }

.footer-list{ margin:0; }
.footer-list li{ list-style-type:none; color:#939393; padding:6px 0; }
.footer-list li:first-child{ padding-top:0; }
.footer-list li a:before{ content:'\F105'; font-weight: 700; vertical-align: bottom; font-family: Font Awesome\ 5 Free; color:#939393; padding-right:8px; transition-duration:.3s; }
.footer-list li a{ font-size:15px; font-weight:400; color:#939393; }
.footer-list li a:hover,.footer-list li a:hover:before{ color:#fff; }

.footer-list-style2{ margin:0; }
.footer-list-style2 li{ list-style-type:none; color:#fff; padding:6px 0; }
.footer-list-style2 li:first-child{ padding-top:0; }
.footer-list-style2 li a:before{ content:'\F105'; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#fff; padding-right:8px; transition-duration:.3s; }
.footer-list-style2 li a{ font-size:15px; font-weight:400; color:#fff; }
.footer-list-style2 li a:hover,.footer-list-style2 li a:hover:before{ opacity: 0.65; }

.footer-list-style3{ margin:0; }
.footer-list-style3 li{ list-style-type:none; color:#6f6f6f; padding:6px 0; }
.footer-list-style3 li:first-child{ padding-top:0; }
.footer-list-style3 li a:before{ content:'\F105'; font-weight: 700; font-family: Font Awesome\ 5 Free; color:#6f6f6f; padding-right:8px; transition-duration:.3s; }
.footer-list-style3 li a{ font-size:15px; font-weight:400; color:#6f6f6f; }
.footer-list-style3 li a:hover,.footer-list-style2 li a:hover:before{ color: #232323; }

.footer-recent-post{ margin:15px 0; }
.footer-recent-post-thumb{ float:left; width:25%; }
.footer-recent-post-content{ float:left; width:75%; padding-left:10px; }
.footer-recent-post-content a{ display:block; color:#939393; font-size:15px; font-weight:400; line-height:normal; margin-top:-5px; }
.footer-recent-post-content a:hover{ color:#fff; }
.footer-recent-post-content span{ font-size:13px; font-weight:300; color: #fff; }

.footer-bar{ padding-top:20px; padding-bottom:20px; margin-top:50px; text-align:center; background:#191919; color: #939393; }
.footer-bar span{ color:#86bc42; font-size:15px; font-weight:400; }

/*footer-top-bar*/
.footer-top-bar { background: #191919; border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

/*footer style2*/
footer h3.footer-title-style2 { margin-bottom: 28px; }
h3.footer-title-style2:after { width: 100%; height: 1px; background: #363637; margin-top: 15px; }

/*footer style3*/
.footer-title-style3{ position: relative; padding-bottom: 20px; margin-bottom: 28px; color: #fff; }
.footer-title-style3:after{ content: ''; width: 60px; height: 2px; background: #86bc42; position: absolute; bottom: 0; left: 20px; }
.footer-title-style3:before{ content: ''; width: 100%; height: 1px; background: rgba(255, 255, 255, 0.2); position: absolute; bottom: 0; left: 0; right: 0; }

/*footer style4*/
.footer-title-style4:after { margin-left: auto; margin-right: auto; }
h3.footer-title-style4 { margin-bottom: 20px; }

/*footer style5*/
h3.footer-title-style5 { color: #fff; }
h3.footer-title-style5:after { background: #fff; }

/*footer style6*/
footer h3.footer-title-style6 { margin-bottom: 28px; color: #636363; }
h3.footer-title-style6:after { width: 100%; height: 1px; background: #d0d0d0; margin-top: 15px; }

/*footer style7*/
footer h3.footer-title-style7 { margin-bottom: 30px; padding-bottom: 20px; }
footer h3.footer-title-style7:after { content: ""; position: absolute; bottom: -10px; left: 18px;  height: 1px;     margin: 0 0 0; width: 70px; margin: 10px 0; background-color: #86bc42; }
footer h3.footer-title-style7:before { content: ""; position: absolute; bottom: -14px; left: 0; display: inline-block; width: 10px; height: 10px; border: 1px solid #86bc42; margin-bottom: 10px; }


@media (max-width: 767px) {
    footer { padding-top: 30px; }
    .footer-bar { margin-top: 30px;}
    .footer-subscribe .butn { line-height: 22px; }

    .footer-bar p { font-size: 13px; }
}
.max-wid_50 {
  max-width: 50%;
}

.footer-social-icons ul li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.project-grid-overlay {
  overflow: hidden;
}

.project-grid-overlay h4 {
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  color: #777;
}

.page-title-section2 {
  padding: 210px 0 70px;
}

.navbar-brand img {
  max-height: 60px;
}

.clients img {
  opacity: 1;
}

.history-mid-line:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  height: 92%;
  width: 2px;
  border: 2px dashed #aaa;
  content: "";
}

.history-img {
  display: block;
  position: relative;
}

.history-img img {
  z-index: 1;
  position: relative;
  box-shadow: 2px 4px 7px #aaa;
}

.history-text {
  margin-bottom: 0;
}

.history-years {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
  color: #444;
}

.history-years span {
  font-size: 46px;
  line-height: 52px;
  opacity: 0.3;
  display: block;
}

.history-years span strong {
  font-size: 58px;
}

.project-grid-style2 .portfolio-icon a {
  margin-top: -13px;
}

.dark-link {
  color: #232323;
}

.mailform select {
  margin: 0 0 15px 0;
}

.mfSelect .dropdown li {
  cursor: pointer;
}

.links-foot:before {
  display: none;
}

.cebit-small-img {
  max-height: 100px;
  width: auto !important;
  /*margin-top: -40px !important;*/
  margin-bottom: 10px !important;
  display: block;
}

.bg-theme-home {
  background-color: #000;
}

.rev_slider .caption,
.rev_slider .tp-caption {
  line-height: normal !important;
}

.home-text-img:after {
  top: 40px;
  left: -30px;
  border-radius: 5px;
}

.portfolio-page .project-grid-overlay {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 3px solid #86bc42;
}

.page-title-section2 .page-title {
  border: none;
  background: none;
  color: #86bc42;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .page-title-section2 {
    padding: 150px 0 30px;
  }
  .history-years {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .history-years span {
    font-size: 30px;
  }
  .history-years span strong {
    font-size: 38px;
  }
  .history-mid-line:before {
    border: 0;
  }
}

